import * as React from "react";

import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { RecentLoginModal } from "@bokio/components/RecentLoginModal/RecentLoginModal";
import { Button } from "@bokio/designsystem/components/Button";
import { RenderRequestError } from "@bokio/elements/Loading";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useCompanyUser } from "@bokio/shared/state/requests";
import { noop } from "@bokio/shared/utils";

import { BbaNotAvailableInFreePlanModal } from "./components/BbaNotAvailableInFreePlanModal";
import { PromotionModal } from "./components/PromotionModal/PromotionModal";
import { SummerCampaignModal } from "./components/SummerCampaignModal";
import { TrialExtendedModal } from "./components/TrialExtendedModal";
import { TrialOverModal } from "./components/TrialOverModal";

import SupportMessageType = m.Entities.Support.SupportMessageType;
export enum SupportMessageCompletedType {
	Resolved = "Resolved",
	Approved = "Approved",
	Rejected = "Rejected",
	Dismissed = "Dismissed",
}

export interface SupportMessageModalProps {
	companyId: string;
	onComplete: (type?: SupportMessageCompletedType) => void;
	supportMessage: m.Bokio.Common.Contract.ViewData.SupportMessageActiveDto;
}

export const SupportMessageModal: React.FC<SupportMessageModalProps> = (props: SupportMessageModalProps) => {
	const [resolveExec, resolveRequest] = useLazyApi(proxy.Settings.SupportMessagesController.Resolve.Post, {
		onSuccess: () => {
			props.onComplete(SupportMessageCompletedType.Resolved);
		},
	});
	const [dismissExec] = useLazyApi(proxy.Settings.SupportMessagesController.Dismiss.Post, {
		onSuccess: () => {
			props.onComplete(SupportMessageCompletedType.Dismissed);
		},
	});

	const [requireNewLogin, setRequireNewLogin] = React.useState(false);

	const [approveExec, approveRequest] = useLazyApi(proxy.Settings.SupportMessagesController.Approve.Post, {
		onSuccess: result => {
			if (result.RequireNewLogin) {
				setRequireNewLogin(true);
			} else {
				props.onComplete(SupportMessageCompletedType.Approved);
			}
		},
	});

	const [rejectExec, rejectRequest] = useLazyApi(proxy.Settings.SupportMessagesController.Reject.Post, {
		onSuccess: () => {
			props.onComplete(SupportMessageCompletedType.Rejected);
		},
	});

	const resolve = () => resolveExec(props.companyId, props.supportMessage.Id);
	const dismiss = () => dismissExec(props.companyId, props.supportMessage.Id);

	const approve = () => approveExec(props.companyId, props.supportMessage.Id);
	const reject = () => rejectExec(props.companyId, props.supportMessage.Id);

	const { companyUserPermissions } = useCompanyUser();

	//ME: So far only blocking modals are implemented. This is the reason there is no close or similar
	if (props.supportMessage.DisplayMode !== "BlockingModal" || companyUserPermissions?.SupportUser) {
		return null;
	}

	const generalLang = GeneralLangFactory();

	switch (props.supportMessage.MessageType) {
		case SupportMessageType.TrialOver:
			return <TrialOverModal resolve={resolve} />;
		case SupportMessageType.SummerCampaign:
			return (
				<SummerCampaignModal
					title={props.supportMessage.Subject}
					markdown={props.supportMessage.BodyMarkdown}
					resolve={resolve}
				/>
			);
		case SupportMessageType.BbaNotAvailableInFreePlan:
			return <BbaNotAvailableInFreePlanModal resolve={resolve} />;

		case SupportMessageType.TrialExtended:
			return <TrialExtendedModal resolve={resolve} dismiss={dismiss} />;

		case SupportMessageType.TrialToPaidPlan:
		case SupportMessageType.FreeToPaidPlan:
		case SupportMessageType.PremiumToBba:
		case SupportMessageType.BbaToPremiumPlus:
		case SupportMessageType.LowDepositFree:
		case SupportMessageType.HighDepositFree:
			return <PromotionModal type={props.supportMessage.MessageType} resolve={resolve} dismiss={dismiss} />;
	}

	return (
		<>
			<Modal
				visible={true}
				disableBackgroundClick={true}
				testId="SMM_modal"
				onClose={noop} // Not closable
				title={props.supportMessage.Subject}
				disabled={true} // disables close button
			>
				<ModalContent>
					<Markdown markdownContent={props.supportMessage.BodyMarkdown} />
					<RenderRequestError request={resolveRequest} />
				</ModalContent>
				{props.supportMessage.MessageType === SupportMessageType.BlockingApproval && (
					<ModalFooter>
						<Button testId="SMM_button" type="button" onClick={resolve}>
							{generalLang.Accept}
						</Button>
					</ModalFooter>
				)}
				{props.supportMessage.MessageType === SupportMessageType.Informational && (
					<ModalFooter>
						<Button testId="SMM_button" type="button" onClick={resolve}>
							{generalLang.OK}
						</Button>
					</ModalFooter>
				)}
				{props.supportMessage.MessageType === SupportMessageType.SupportSign && (
					<ModalFooter>
						<Button
							appearance="destructive"
							disabled={approveRequest.isLoading}
							loading={rejectRequest.isLoading}
							type="button"
							onClick={reject}
						>
							{generalLang.Todo_Action_Reject}
						</Button>
						<Button
							disabled={rejectRequest.isLoading}
							loading={approveRequest.isLoading}
							testId="SMM_button"
							type="button"
							onClick={approve}
						>
							{generalLang.Accept}
						</Button>
					</ModalFooter>
				)}
			</Modal>
			<RecentLoginModal onCancel={() => setRequireNewLogin(false)} onLogin={approve} visible={requireNewLogin} />
		</>
	);
};
