import * as styles from "@bokio/elements/Form/form.scss";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

type FieldProps = {
	className?: string;
};

const Field: React.FC<React.PropsWithChildren<FieldProps>> = ({ children, className }) => {
	return <div className={mergeClassNames(styles.field, className)}>{children}</div>;
};

export default Field;
