/**
 * Reorders an array by moving one item to a new place and then returns the new array
 * @param list the array to reorder
 * @param fromIndex The item at this index will be moved
 * @param toIndex The item will be moved to this index
 */
export const reorder = <T>(list: T[], fromIndex: number, toIndex: number) => {
	if (fromIndex < 0 || list.length <= fromIndex || toIndex < 0 || list.length <= toIndex) {
		throw new Error("Index out of bounds");
	}
	const result = Array.from(list);
	const [removed] = result.splice(fromIndex, 1);
	result.splice(toIndex, 0, removed);
	return result;
};

/**
 * A faster alternative of Array.prototype.map that doesn't bother with `this`.
 * Mapped backward just because it's slightly faster than forward,
 * and usually you don't care the order when mapping with pure function.
 *
 * SS 2021-09-17
 * Array.prototype.map is still a lot slower than forward `for (...)` loop in Chrome now.
 */
export const fastMapBackward = <T, P>(arr: T[], pureMapper: (item: T, index: number) => P): P[] => {
	const length = arr.length;
	const newArr: P[] = Array(length);

	for (let i = length - 1; i > -1; i--) {
		newArr[i] = pureMapper(arr[i], i);
	}

	return newArr;
};
