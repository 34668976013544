import * as React from "react";

import { createEventHub } from "@bokio/shared/services/api/signalRHub";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export interface BankFeedActivityState {
	/**
	 * Child component should refresh its state (e.g. API call) when this counter changes.
	 */
	counter: number;
	lastSynced: Date;
}

export const defaultContextValue: BankFeedActivityState = {
	counter: 0,
	lastSynced: new Date(),
};

export const BankFeedActivityContext = React.createContext(defaultContextValue);

export const bankFeedActivityHub = createEventHub<m.Bokio.Integration.Plaid.BankFeedEventDto>();
