import * as React from "react";
import { useHistory } from "react-router";

import { useCompanyUser, useUser } from "@bokio/shared/state/requests";
import { setUserId, setUserIdAndDimensions, trackPageView } from "@bokio/utils/t";

import { useScript } from "../useScript/useScript";

import type { BokioRouterLocationState } from "@bokio/shared/containers/router/useRouter";

export const useGoogleAnalyticsScript: (onGALoaded?: () => void) => void = onGALoaded => {
	const history = useHistory<BokioRouterLocationState>();
	const { company } = useCompanyUser();
	const { user } = useUser();

	React.useEffect(() => {
		// only trigger on following redirects
		const unregister = history.listen(location => {
			trackPageView(location.pathname, location.search);
		});
		return () => unregister();
	}, [history]);

	// Google Analytics 4
	useScript(`https://www.googletagmanager.com/gtag/js?id=${window.config.ga.measurementId}`);
	const gtagScriptRequest = useScript("scripts/gtag.js");
	const isScriptsReady = gtagScriptRequest.data;

	React.useEffect(() => {
		if (!isScriptsReady) {
			return;
		}

		trackPageView(location.pathname, location.search);
		onGALoaded?.();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isScriptsReady]);

	React.useEffect(() => {
		if (!isScriptsReady) {
			return;
		}

		if (company && user) {
			setUserIdAndDimensions(company, user.Id);
		}

		if (user) {
			user && setUserId(user.Id);
		}
	}, [isScriptsReady, company, user]);
};
