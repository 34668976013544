import { GeneralLangFactory } from "@bokio/lang";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./promoBadge.scss";

type BetaBadgeColor = "dark" | "gray" | "white";

type NewBadgeColor = "blue" | "light";

export type PromoBadgeProps = {
	testId?: string;
} & ({ type: "beta"; color: BetaBadgeColor } | { type: "new"; color: NewBadgeColor });

export const PromoBadge = ({ type, color, testId }: PromoBadgeProps) => {
	const generalLang = GeneralLangFactory();

	let badgeText = type === "beta" ? generalLang.Beta : generalLang.New;
	badgeText = badgeText.toUpperCase();

	const classNames = mergeClassNames(styles.promoBadge, styles[color]);

	return (
		<span className={classNames} data-testid={testId}>
			{badgeText}
		</span>
	);
};
