export type PageUpdaterListener = () => Promise<void>;

class PageUpdaterNotifier {
	listeners: PageUpdaterListener[] = [];

	notify = (): Promise<void[]> => {
		const promises = this.listeners.map(listener => listener());
		return Promise.all(promises);
	};

	subscribe = (item: PageUpdaterListener) => {
		this.listeners.push(item);
	};
}

export const pageUpdaterNotifier = new PageUpdaterNotifier();
