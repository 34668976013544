import { useCashbackContext } from "@bokio/contexts/CashbackContext/CashbackContext";
import { Heading, Paragraph, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import Icon from "@bokio/elements/Icon/Icon";
import { GeneralLangFactory, OnboardingLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { formatMessage, formatNumberCurrency } from "@bokio/shared/utils/format";
import { mergeClassNames } from "@bokio/utils/classes";
import { formatPriceplanPercentage } from "@bokio/utils/priceplanUtils";

import { trialAndFreeChecklist } from "../Checklist/TrialAndFreeChecklist";
import { PromotionModalButtons } from "../PromotionModalButtons/PromotionModalButtons";
import { PromotionModalChecklist } from "../PromotionModalChecklist";

import type { PromotionListItemProps, PromotionModalInnerProps } from "../promotionModalModels";
import type * as React from "react";

import * as styles from "../promotionModal.scss";

import BokioPlan = m.Entities.BokioPlan;
import SubscriptionInterval = m.Bokio.Common.Billing.Model.SubscriptionInterval;

const PromotionListItem: React.FC<PromotionListItemProps> = props => {
	const onboardingLang = OnboardingLangFactory();

	const containerClass = props.recommended
		? mergeClassNames([styles.promotionListItem, styles.recommended])
		: styles.promotionListItem;

	const checklist = trialAndFreeChecklist(props);

	const formattedPlusInfo = () => {
		const translation = onboardingLang.Onboarding_TrialToPaidPlan_PromotionItem_tagline_plus;
		const formattedCapitalNumber = formatPriceplanPercentage(
			props.cashbackContract?.DepositedCapitalCashbackPercentage || 0,
		);
		const formattedCardNumber = formatPriceplanPercentage(props.cashbackContract?.CardSpendCashbackPercentage || 0);

		return formatMessage(translation, formattedCapitalNumber, formattedCardNumber);
	};

	return (
		<div className={containerClass}>
			{props.recommended && (
				<span className={styles.recommendedBadge}>{onboardingLang.Onboarding_PromotionListItem_Recommended}</span>
			)}
			<Section resetHeadingLevelTo={3}>
				<Heading>Premium {props.plus && <span className={styles.premiumPlus}>Plus</span>}</Heading>
				{props.bba && (
					<Paragraph>
						{onboardingLang.Onboarding_TrialToPaidPlan_PromotionListItem_with_bba}
						<Icon color="blue" name="bba-filled" />
					</Paragraph>
				)}
			</Section>
			<Section>
				<PromotionModalChecklist list={checklist} />

				{!props.bba && !props.plus && (
					<Paragraph>{onboardingLang.Onboarding_TrialToPaidPlan_PromotionItem_tagline_premium}</Paragraph>
				)}

				{props.bba && !props.plus && (
					<Paragraph>{onboardingLang.Onboarding_TrialToPaidPlan_PromotionItem_tagline_bba}</Paragraph>
				)}

				{props.bba && props.plus && <Paragraph>{formattedPlusInfo()}</Paragraph>}
			</Section>
		</div>
	);
};

export const TrialToPaidPlan: React.FC<PromotionModalInnerProps> = ({ resolve, dismiss }) => {
	const generallang = GeneralLangFactory();
	const onboardingLang = OnboardingLangFactory();

	const cashbacks = useCashbackContext();
	if (!cashbacks.initialized) {
		return null;
	}

	const recommendPlus = cashbacks.wouldBenefitFromPlus(BokioPlan.Free, SubscriptionInterval.Month, "Both");

	const formattedTagline = () => {
		const translation = onboardingLang.Onboarding_TrialToPaidPlan_PromotionModalPreamble_cashback;
		const cashbackMonth = (
			<strong>
				{formatNumberCurrency(cashbacks.estimatedCashback || 0, "SEK", 0)} / {generallang.Month}
			</strong>
		);
		const plusMonth = (
			<strong>
				{formatNumberCurrency(cashbacks.premiumPlusMonthlyCost, "SEK", 0)} / {generallang.Month}
			</strong>
		);

		return formatMessage(translation, cashbackMonth, plusMonth);
	};

	return (
		<div className={styles.promotionModal} data-testid="PromotionModal_TrialToPaidPlan">
			<Section resetHeadingLevelTo={2}>
				<Heading>{onboardingLang.Onboarding_TrialToPaidPlan_PromotionModalHeading_noCashback}</Heading>
				<Paragraph>
					{recommendPlus ? formattedTagline() : onboardingLang.Onboarding_TrialToPaidPlan_PromotionModalPreamble}
				</Paragraph>
			</Section>

			<ol className={styles.promotionItemList}>
				<li>
					<PromotionListItem />
				</li>
				<li>
					<PromotionListItem bba={true} recommended={false} />
				</li>
				<li>
					<PromotionListItem
						bba={true}
						plus={true}
						recommended={recommendPlus}
						cashbackContract={cashbacks.defaultContract}
						cashbackValues={cashbacks.bbaValues}
					/>
				</li>
			</ol>

			<PromotionModalButtons
				ctaButtonText={onboardingLang.Onboarding_TrialToPaidPlan_PromotionModal_CTA_button}
				cancelButtonText={onboardingLang.Onboarding_TrialToPaidPlan_PromotionModal_cancelButton}
				resolve={resolve}
				dismiss={dismiss}
			/>
		</div>
	);
};
