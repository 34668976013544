export function visibleInSearchResult(data: string | (string | undefined)[], search: string): boolean {
	if (!search) {
		return true;
	}

	// Escaping any regular expression special characters
	let str = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

	// Replace whitespace characters with special RegEx whitespace character
	str = str.replace(/\s/g, "\\s");

	const regex = new RegExp(str, "i");

	if (data instanceof Array) {
		for (let index = 0; index < data.length; index++) {
			const element = data[index];
			if (element !== undefined && regex.test(element)) {
				return true;
			}
		}
		return false;
	} else {
		return regex.test(data);
	}
}
