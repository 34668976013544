export enum StorageTypes {
	localStorage = "localStorage",
	sessionStorage = "sessionStorage",
}

export const isStorageSupported = (type: StorageTypes) => {
	try {
		const testKey = "__Bokio_polyfillStorageWhenNeeded_test";
		const testValue = "testValue";
		const storage = window[type];

		// Throws
		storage.setItem(testKey, testValue);

		const retrived = storage.getItem(testKey);
		if (retrived !== testValue) {
			throw new Error();
		}

		storage.removeItem(testKey);
		const retrivedAfterRemoval = storage.getItem(testKey);
		if (retrivedAfterRemoval !== null) {
			throw new Error();
		}

		return true;
	} catch {
		return false;
	}
};

class MemoryStorage implements Storage {
	/**
	 * This is not polyfilled because it requires Proxy and extremely rarely used in the wild.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[name: string]: any;

	#innerRecord: Record<string, string> = Object.create(null);

	get length() {
		return Object.keys(this.#innerRecord).length;
	}

	clear(): void {
		this.#innerRecord = Object.create(null);
	}
	getItem(key: string): string | null {
		return this.#innerRecord[key] ?? null;
	}
	key(index: number): string | null {
		return Object.keys(this.#innerRecord)[index] ?? null;
	}
	removeItem(key: string): void {
		delete this.#innerRecord[key];
	}
	setItem(key: string, value: string): void {
		this.#innerRecord[key] = String(value);
	}
}

const polyfillStorage = (type: StorageTypes) => {
	const storage = new MemoryStorage();
	// Browsers ignore assignment to storage properties so you need to define property here.
	Object.defineProperty(window, type, { get: () => storage });
};

export const polyfillStorageWhenNeeded = (type: StorageTypes) => {
	if (isStorageSupported(type)) {
		return;
	}
	polyfillStorage(type);
};
