// extracted by mini-css-extract-plugin
export var plan = "Faa";
export var premiumPlus = "Gt";
export var na = "Ht";
export var inTrial = "It";
export var planName = "Jt";
export var trialEnd = "V7a";
export var toggle = "Kt";
export var title = "Zaa";
export var arrow = "Tga";
export var planInfo = "dba";
export var bbaInfo = "Lt";
export var hightlightPlus = "Mt";
export var planInfoData = "hba";
export var planInfoDataCollapsed = "Uga hba";
export var end = "pja";
export var buttonContainer = "Qna";
export var storybookContainer = "Nt";