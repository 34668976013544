import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";
import Icon from "@bokio/elements/Icon/Icon";
import { LoadingText } from "@bokio/elements/Loading";
import { GeneralLangFactory } from "@bokio/lang";

import type * as React from "react";

import * as styles from "./menuCurrentUser.scss";

export interface MenuCurrentUserMobileProps {
	showManageUser: () => void;
}

const MenuCurrentUserMobile: React.FC<MenuCurrentUserMobileProps> = ({ showManageUser }) => {
	const { user, isLoadingUser } = useTopLevelUser();

	const lang = GeneralLangFactory();
	if (isLoadingUser) {
		return (
			<div>
				<LoadingText width="70%" />
			</div>
		);
	}

	if (!user) {
		return <div>{lang.NoAccount}</div>;
	}

	return (
		<div className={styles.userSettingsMobile}>
			<span className={styles.label}>{lang.Menu_Profile}</span>
			<a onClick={() => showManageUser()}>
				{user.FirstName ? `${user.FirstName} ${user.LastName}` : lang.MyAccount}
				<Icon name="right-open-big" size="12" />
			</a>
		</div>
	);
};

export default MenuCurrentUserMobile;
