import type { KeyIn, KeyInObject } from "./SimpleTable";

export const generateSummaryRow = <T extends object>(
	rows: T[],
	keysToInclude?: KeyIn<T>[],
	keysToOverride?: KeyInObject<T>,
): T => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return rows.reduce((acc: any, row: T) => {
		const keys = Object.keys(row) as KeyIn<T>[];
		keys.forEach(key => {
			if (!keysToInclude || keysToInclude.includes(key)) {
				if (!acc[key]) {
					acc[key] = 0;
				}
				acc[key] += row[key];
			} else {
				acc[key] = "";
			}

			if (keysToOverride && key in keysToOverride) {
				acc[key] = keysToOverride[key];
			}
		});
		return acc;
	}, {});
};
