import { trackError, trackTrace } from "@bokio/utils/t";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export const runPlaidFlow = (token: string) =>
	new Promise<{ publicToken: string; metaData: m.Bokio.Integration.Plaid.MetadataDto }>((resolve, reject) => {
		if (!window.Plaid) {
			reject(new Error("window.Plaid is not loaded yet."));
			return;
		}

		const handler = window.Plaid.create({
			token,
			onLoad: () => {
				// Optional, called when Link loads
				trackTrace("PlaidFlow.Load", {});
			},
			onSuccess: (publicToken, metaData) => {
				// Send the public_token to your app server.
				// The metadata object contains info about the institution the
				// user selected and the account ID or IDs, if the
				// Select Account view is enabled.
				trackTrace("PlaidFlow.Success", {});
				resolve({
					publicToken,
					metaData: {
						Institution: { InstitutionId: metaData.institution.institution_id, Name: metaData.institution.name },
						Accounts: metaData.accounts.map(acc => ({
							Id: acc.id,
							Name: acc.name,
							Mask: acc.mask,
							Type: acc.type as m.Bokio.Integration.Plaid.AccountType,
						})),
					},
				});
			},
			onExit: (err, metadata) => {
				if (err === null) {
					// The user exited the Link flow.
					trackTrace("PlaidFlow.Exit", {
						err: JSON.stringify(err),
						metadata: JSON.stringify(metadata),
					});
				} else {
					// The user encountered a Plaid API error prior to exiting.
					trackError(err, "app.usePlaid", {
						err: JSON.stringify(err),
						metadata: JSON.stringify(metadata),
					});
				}
				// metadata contains information about the institution
				// that the user selected and the most recent API request IDs.
				// Storing this information can be helpful for support.

				reject(new Error());
			},
			onEvent: (eventName, metaData) => {
				// onEvent is called a bunch of time during the Link process.
				// https://plaid.com/docs/#onevent-callback
				trackTrace(`PlaidFlow.${eventName}`, { metadata: JSON.stringify(metaData) });
			},
		});
		handler.open();
	});
