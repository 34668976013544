import { SignUpLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getAccountingOptions } from "@bokio/shared/utils/companyInfoUtils";

import VatSetting = m.Entities.VatSetting;
import AccountingType = m.Entities.AccountingType;
import CompanyType = m.Entities.CompanyType;
type RulesByCompanyTypeAndVatSetting = m.Classes.RulesByCompanyTypeAndVatSetting;
import CountryCode = m.CountryCode;
export interface VatSettingAccountingTypeInputState {
	accountingType: AccountingType;
	vatSetting: VatSetting;
}

export const getAccountingTypeOptions = (
	country: CountryCode,
	companyType: CompanyType,
	vatSetting: VatSetting,
	rules: RulesByCompanyTypeAndVatSetting[],
) => getAccountingOptions(country, companyType, vatSetting, rules);

export const getVisibleAccountingTypeOptions = (
	country: CountryCode,
	companyType: CompanyType,
	vatSetting: VatSetting,
	rules: RulesByCompanyTypeAndVatSetting[],
) => getAccountingTypeOptions(country, companyType, vatSetting, rules).filter(o => !o.hidden);

export const getAccountingTypeSelectLabelText = (
	country: CountryCode,
	companyType: CompanyType,
	vatSetting: VatSetting,
) => {
	const signUpLang = SignUpLangFactory();
	if (country === CountryCode.GB) {
		if (companyType !== CompanyType.LimitedCompany && vatSetting === VatSetting.ExternalVatProvider) {
			return signUpLang.WhatAccountingMethodAndVatSchemeDoYouUse;
		} else if (companyType !== CompanyType.LimitedCompany && vatSetting === VatSetting.NotRegisteredForVat) {
			return signUpLang.WhatAccountingMethodDoYouUse;
		}
		return signUpLang.WhatVatSchemeDoYouUse;
	}
	return signUpLang.WhatAccountingMethodDoYouUse;
};
