import * as React from "react";

import { useAppContext } from "@bokio/contexts/AppContext/AppContext";
import { Box } from "@bokio/elements/Box";
import LoadingBox from "@bokio/elements/Loading/LoadingBox";
import { useLoader } from "@bokio/hooks/useLoader/useLoader";
import { noop } from "@bokio/shared/utils";
import { trackError } from "@bokio/utils/t";

import type { RouteComponentProps } from "react-router";

type Module<P extends { [K in keyof P]?: string | undefined }> = {
	default: React.ComponentType<RouteComponentProps<P>>;
};
type LoaderFunc<P extends { [K in keyof P]?: string | undefined }> = () => Promise<Module<P>>;
type BundleLoaderParams<P extends { [K in keyof P]?: string | undefined }> = {
	loader: LoaderFunc<P>;
	narrowPage?: boolean;
};

const createBundleLoader = <P extends { [K in keyof P]?: string | undefined }>({
	loader,
	narrowPage,
}: BundleLoaderParams<P>) => {
	const ComponentLoader: React.FunctionComponent<RouteComponentProps<P>> = props => {
		const app = useAppContext();

		const { request, load } = useLoader({
			endpoint: async () => {
				if (app.existsNewerVersion) {
					// Before we start loading the bundle, check if this
					// version of the frontend is old.
					// If it is, then trigger a browser refresh.
					// This saves us from 404:s.

					throw new Error("NewVersion");
				}

				// This will throw if webpack can't download the js bundle,
				try {
					return await loader();
				} catch (e) {
					trackError(e, "app.createBundleLoader", {});
					// Here we probably have gotten a 404.
					// Wait some time to avoid refreshing to often in the worst case.
					await new Promise(resolve => setTimeout(resolve, 5000));
					throw new Error("BundleFail");
				}
			},
			onError: err => app.reloadForNewVersion((err as Error | undefined) && (err as Error).message),
		});

		React.useEffect(() => {
			load({}).then(noop).catch(noop);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const module = request.data;

		if (!module) {
			return (
				<Box>
					<LoadingBox center={true} narrow={narrowPage} spacingTop={true} request={request} />
				</Box>
			);
		}
		const Component = module.default;
		return <Component {...props} />;
	};
	return ComponentLoader;
};

export default createBundleLoader;
