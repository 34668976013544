import * as React from "react";

// asComponent.ts
//
// Utility for converting a stateless component to a React.ComponentClass
// It is useful when higher ordered functions
// expects a class and not a functional component.

const asComponent = <P>(statelessComponent: React.FunctionComponent<P>) => {
	return class extends React.Component<P> {
		render() {
			return statelessComponent(this.props);
		}
	};
};

export default asComponent;
