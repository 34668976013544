import { GeneralLangFactory } from "@bokio/lang";
import {
	EntityValidator,
	FieldRuleFactory,
	FieldRuleLevel,
	FieldValidator,
} from "@bokio/shared/validation/entityValidator";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { Validator } from "@bokio/shared/validation/entityValidator";

export interface AddEditTaskFormData {
	Text: string;
	TaskStatus: m.Entities.Partners.WorkStatusType;
	Assignee?: m.Classes.UserDto;
	DueDate?: m.Day | undefined;
	IsClientAssignee: boolean;
	QuoteRequestId?: string;
	Title: string;
	Priority: m.Entities.Priority;
	EditAccess: m.Entities.EditAccess;
	ClientCompanyId: string | undefined;
	AssignToMeCheck: boolean;
}
export class TaskModalValidator extends EntityValidator<AddEditTaskFormData> {
	constructor(protected dueDateCheck: boolean) {
		super(true);
	}
	getRules(): Validator[] {
		const generalLang = GeneralLangFactory();
		const factory = new FieldRuleFactory(generalLang);
		const rules = [
			new FieldValidator(this.propertyOf("Text"), [factory.Required("Text", FieldRuleLevel.MustFixNow)]),
			new FieldValidator(this.propertyOf("Title"), [factory.Required("Title", FieldRuleLevel.MustFixNow)]),
			new FieldValidator(this.propertyOf("Priority"), [factory.Required("Priority", FieldRuleLevel.MustFixNow)]),
			new FieldValidator(this.propertyOf("EditAccess"), [factory.Required("EditAccess", FieldRuleLevel.MustFixNow)]),
			new FieldValidator(this.propertyOf("ClientCompanyId"), [
				factory.Required("ClientCompanyId", FieldRuleLevel.MustFixNow),
			]),
		];
		!!this.dueDateCheck &&
			rules.push(
				new FieldValidator(this.propertyOf("DueDate"), [factory.Required("DueDate", FieldRuleLevel.MustFixNow)]),
			);
		return rules;
	}
}
