import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./table.scss";

export interface TableProps {
	className?: string;
	testId?: string;
	children: React.ReactNode;
}

const Table = ({ className, children, testId }: TableProps) => {
	return (
		<table className={mergeClassNames(styles.table, className)} data-testid={testId}>
			{children}
		</table>
	);
};

export default Table;
