import * as React from "react";

interface UseClickOutsideProps {
	ref: React.RefObject<HTMLElement>;
	onClickOutside: (e?: Event) => void;
	condition?: boolean;
}

export const useClickOutside = ({ ref, onClickOutside, condition = true }: UseClickOutsideProps) => {
	const handleClickOutside = React.useCallback(
		(e: Event) => {
			if (condition && ref.current && !ref.current.contains(e.target as Node)) {
				onClickOutside(e);
			}
		},
		[condition, onClickOutside, ref],
	);

	React.useEffect(() => {
		window.addEventListener("click", handleClickOutside);
		return () => window.removeEventListener("click", handleClickOutside);
	}, [handleClickOutside]);
};
