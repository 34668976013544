import { differenceInCalendarDays } from "date-fns";

import type * as React from "react";
import type { DayModifiers, RangeModifier } from "react-day-picker";

import * as styles from "./datePicker.scss";

const DayPicker = require("react-day-picker"); // eslint-disable-line -- SS 2024-03-12 Require statement kept to make Jest and Webpack work

interface DatePickerProps {
	label?: string;
	value?: Date;
	month?: Date;
	onChange: (date: Date) => void;
	onBlur?: () => void;
	disabled?: boolean;
	isLoading?: boolean;
	maxDate?: Date;
	minDate?: Date;
	maxMonth?: Date;
	minMonth?: Date;
	disabledDaysRange?: RangeModifier[];
}

const DatePicker = (props: DatePickerProps) => {
	const handleDayClick = (day: Date, modifiers: DayModifiers, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.stopPropagation();
		day.setHours(0, 0, 0, 0);

		if (props.disabled || props.isLoading) {
			return;
		}

		if (props.minDate && differenceInCalendarDays(day, props.minDate) < 0) {
			return;
		}

		if (props.maxDate && differenceInCalendarDays(day, props.maxDate) > 0) {
			return;
		}
		if (props.disabledDaysRange && props.disabledDaysRange.some(d => d.from && day >= d.from && d.to && day <= d.to)) {
			return;
		}

		props.onChange(day);
	};

	const disabledDays = props.disabledDaysRange
		? props.minDate && props.maxDate
			? [...props.disabledDaysRange, { before: props.minDate, after: props.maxDate }]
			: props.disabledDaysRange
		: // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			{ before: props.minDate!, after: props.maxDate! };

	return (
		<DayPicker
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			classNames={styles as any}
			onDayClick={handleDayClick}
			selectedDays={props.value}
			onBlur={props.onBlur}
			month={props.month ? props.month : new Date()}
			fromMonth={props.minMonth}
			toMonth={props.maxMonth}
			disabledDays={disabledDays}
			firstDayOfWeek={1} // 0-Sunday 1-Monday...
			fixedWeeks
		/>
	);
};

export default DatePicker;
