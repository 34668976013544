import * as React from "react";

import { AddEditTaskModal } from "@bokio/backoffice/src/scenes/AgencyClientDetails/NotesTab/AddEditTaskModal";
import * as styles from "@bokio/bookkeeping/src/scenes/components/BookkeepingMenuButton/bookkeepingMenuButton.scss";
import { LoadingProgress } from "@bokio/elements/Loading";
import { SplitButton } from "@bokio/elements/SplitButton/SplitButton";
import { SplitButtonItem } from "@bokio/elements/SplitButton/SplitButtonItem";
import { AgencyAnalyticsEventCategory, useAgencyMetric } from "@bokio/hooks/useAgencyMetric/useAgencyMetric";
import { useModalsAsync } from "@bokio/hooks/useModalsAsync/useModalsAsync";
import { BackOfficeLangFactory, GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { useAgencyStatus } from "@bokio/shared/state/requests";

import { useAgencyCESRating } from "../../utils/useAgencyCESRating";
import { AddEditCommentModal } from "../AgencyClientDetails/NotesTab/AddEditCommentModal";
import { AddUserModal } from "../Users/AddUserModal/AddUserModal";

export enum AgencyDropdownMenuOptions {
	Task = "Task",
	Client = "Client",
	Accountant = "Accountant",
	Comment = "Comment",
}

export const AgencyMenuButton = () => {
	const [createDraft, createDraftRequest] = useLazyApi(proxy.Settings.CompanyDraftController.Create.Post);
	const { agencyStatus } = useAgencyStatus();
	const generalLang = GeneralLangFactory();
	const backofficeLang = BackOfficeLangFactory();
	const router = useRouter();
	const modals = useModalsAsync();
	const [addMetric] = useAgencyMetric();
	const [noteAdded, setNoteAdded] = React.useState(false);
	useAgencyCESRating(
		m.Entities.PartnerRatingType.CreateNote,
		"",
		backofficeLang.CreateNoteRating_Rate_Title,
		backofficeLang.CreateNoteRating_Feedback_Description,
		!!noteAdded,
	);
	const handleCreateClient = async () => {
		const draft = await createDraft({
			Source: m.Entities.CompanyDraftSource.AgencyCreated,
			AgencyId: agencyStatus.Id,
		});
		router.redirect(
			getRoute(
				"createCompany",
				{ id: draft.Data?.Id, step: undefined },
				{
					source: m.Entities.CompanyDraftSource.AgencyCreated,
				},
			),
		);
	};

	const handleAddTask = () => {
		modals.showModal((promise, visible) => (
			<AddEditTaskModal
				visible={visible}
				partnerId={agencyStatus.Id}
				onClose={promise.resolve}
				onSuccess={() => {
					promise.resolve();
					setNoteAdded(true);
				}}
			/>
		));
	};

	const handleAddComment = () => {
		modals.showModal((promise, visible) => (
			<AddEditCommentModal
				visible={visible}
				partnerId={agencyStatus.Id}
				onClose={promise.resolve}
				onSuccess={() => {
					promise.resolve();
					setNoteAdded(true);
				}}
			/>
		));
	};

	const handleAddUser = () => {
		modals.showModal((promise, visible) => (
			<AddUserModal
				visible={visible}
				onSuccess={promise.resolve}
				onCancel={promise.resolve}
				partner={agencyStatus}
				isAgency
			/>
		));
	};

	const onAgencyMenuSubmit = (type: AgencyDropdownMenuOptions) => {
		addMetric(AgencyAnalyticsEventCategory.AgencyDropdownMenu, type, agencyStatus.Id);
		if (type == AgencyDropdownMenuOptions.Client) {
			handleCreateClient();
		} else if (type == AgencyDropdownMenuOptions.Accountant) {
			handleAddUser();
			router.redirect(getRoute("agencyUsers", { agencyId: agencyStatus.Id }));
		} else if (type == AgencyDropdownMenuOptions.Task) {
			handleAddTask();
		} else if (type == AgencyDropdownMenuOptions.Comment) {
			handleAddComment();
		}
	};

	const hasManageUsersPermission = agencyStatus && agencyStatus.Access.ManageUsers;
	const hasManageClientsPermission = agencyStatus && agencyStatus.Access.ManageCompanies;
	return (
		<React.Fragment>
			<SplitButton
				margin={["left", "right", "bottom"]}
				onlyDropdownMode={true}
				primaryText={generalLang.CreateNew}
				className={styles.uploadButton}
			>
				<SplitButtonItem
					value={AgencyDropdownMenuOptions.Task}
					onClick={() => onAgencyMenuSubmit(AgencyDropdownMenuOptions.Task)}
					testId={"AgencyMenuButton_Option_CreateTask"}
				>
					{backofficeLang.Task}
				</SplitButtonItem>
				<SplitButtonItem
					value={AgencyDropdownMenuOptions.Comment}
					onClick={() => onAgencyMenuSubmit(AgencyDropdownMenuOptions.Comment)}
					testId={"AgencyMenuButton_Option_CreateComment"}
				>
					{backofficeLang.Comment}
				</SplitButtonItem>
				{hasManageClientsPermission && (
					<SplitButtonItem
						value={AgencyDropdownMenuOptions.Client}
						onClick={() => onAgencyMenuSubmit(AgencyDropdownMenuOptions.Client)}
						testId={"AgencyMenuButton_Option_CreateClient"}
					>
						{backofficeLang.Client}
					</SplitButtonItem>
				)}
				<LoadingProgress paddingTop={true} request={createDraftRequest} />
				{hasManageUsersPermission && (
					<SplitButtonItem
						value={AgencyDropdownMenuOptions.Accountant}
						onClick={() => onAgencyMenuSubmit(AgencyDropdownMenuOptions.Accountant)}
						testId={"AgencyMenuButton_Option_InviteAccountant"}
					>
						{backofficeLang.Accountant}
					</SplitButtonItem>
				)}
			</SplitButton>
		</React.Fragment>
	);
};
