import * as React from "react";
import { Key } from "w3c-keys";

import { Modal, ModalContent } from "@bokio/components/Modal";
import { Caption } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { GeneralLangFactory } from "@bokio/lang";
import { getRoute } from "@bokio/shared/route";
import { useCompanyUser } from "@bokio/shared/state/requests";

import { CommandSearch } from "./components/CommandSearch/CommandSearch";
import {
	bookkeepWithTemplateCommandSource,
	commandsAsCommandSource,
	invoiceSearchCommandSource,
	pushSource,
	supplierInvoiceSearchCommandSource,
} from "./utils/commandPalette.helpers";

import type { PaletteCommand, PaletteCommandSource, PaletteContext } from "./utils/commandPalette.types";
import type { GeneralLang } from "@bokio/lang/GeneralLangFactory";
import type * as m from "@bokio/mobile-web-shared/core/model/model";

interface CommandPaletteModalProps {
	visible: boolean;
	source: PaletteCommandSource;
	onCancel: () => void;
}
export const CommandPaletteModal: React.FC<CommandPaletteModalProps> = ({ visible, source, onCancel }) => {
	const generalLang = GeneralLangFactory();
	return (
		<Modal visible={visible} onClose={onCancel}>
			<ModalContent>
				<Caption>{generalLang.CompanyCommandPalette_CaptionInstruction}</Caption>
				<CommandSearch source={source} onComplete={onCancel} />
			</ModalContent>
		</Modal>
	);
};

const createDefaultCommandSource = (
	companyId: string,
	permissions: m.Settings.Controllers.UserAccess,
	generalLang: GeneralLang,
	commands: PaletteCommand[],
	pushCommandSource: (cs: PaletteCommandSource) => void,
) => {
	const baseActions: PaletteCommand[] = [];
	if (permissions.Bookkeep) {
		baseActions.push(
			{
				kind: "action",
				action: () => pushCommandSource(bookkeepWithTemplateCommandSource(generalLang, companyId)),
				name: generalLang.CompanyCommandPalette_BookkeepWithTemplate,
				aliases: [],
				prefix: generalLang.Search,
			},
			{
				kind: "route",
				route: getRoute("bookkeepReceipt", { company: companyId, category: "other" }, { tab: "manual" }),
				name: generalLang.CompanyCommandPalette_ManualBookkeeping,
				aliases: [],
				prefix: generalLang.CommandSearch_GoTo_Prefix,
			},
		);
	}
	if (permissions.Invoices) {
		baseActions.push({
			kind: "action",
			action: () => pushCommandSource(invoiceSearchCommandSource(generalLang, companyId)),
			name: generalLang.CompanyCommandPalette_SearchInvoices,
			aliases: [],
			prefix: generalLang.Search,
		});
	}
	if (permissions.SupplierInvoices) {
		baseActions.push({
			kind: "action",
			action: () => pushCommandSource(supplierInvoiceSearchCommandSource(generalLang, companyId)),
			name: generalLang.CompanyCommandPalette_SearchSupplierInvoices,
			aliases: [],
			prefix: generalLang.Search,
		});
	}
	baseActions.push(...commands);
	baseActions.push({
		kind: "route",
		name: generalLang.CommandPalette_SwitchCompany,
		route: getRoute("selectCompany", {}),
		aliases: [],
		prefix: generalLang.CommandSearch_GoTo_Prefix,
	});
	return commandsAsCommandSource(generalLang.CompanyCommandPalette_SearchActions, baseActions);
};

interface CommandPaletteInnerProps {
	context: PaletteContext;
	resetContext: () => void;
}

export const CommandPalette: React.FC<CommandPaletteInnerProps> = ({ context, resetContext }) => {
	const [showCommandSearch, setShowCommandSearch] = React.useState(false);

	React.useEffect(() => {
		const handleGlobalKey = (e: KeyboardEvent): void => {
			if ((e.altKey || e.metaKey) && (e.key === Key.b || e.key === "∫")) {
				if (!showCommandSearch) {
					setShowCommandSearch(true);
				}
				resetContext();
			}
		};
		window.addEventListener("keydown", handleGlobalKey);
		return () => window.removeEventListener("keydown", handleGlobalKey);
	});
	const source = context?.stack[0] ?? { source: (): PaletteCommand[] => [] };
	return (
		<CommandPaletteModal visible={showCommandSearch} onCancel={() => setShowCommandSearch(false)} source={source} />
	);
};

interface CompanyCommandPaletteProps {
	commands: PaletteCommand[];
}

interface CompanyCommandPaletteInnerProps {
	companyId: string;
	permissions: m.Settings.Controllers.UserAccess;
}

const CompanyCommandPaletteInner: React.FC<CompanyCommandPaletteProps & CompanyCommandPaletteInnerProps> = ({
	companyId,
	permissions,
	commands,
}) => {
	const generalLang = GeneralLangFactory();

	const [context, setCommandStack] = React.useState<PaletteContext>(() => {
		return {
			stack: [
				createDefaultCommandSource(companyId, permissions, generalLang, commands, cs =>
					setCommandStack(pushSource(context, cs)),
				),
			],
		};
	});

	const resetContext = () => {
		setCommandStack({
			stack: [
				createDefaultCommandSource(companyId, permissions, generalLang, commands, cs =>
					setCommandStack(pushSource(context, cs)),
				),
			],
		});
	};

	return <CommandPalette context={context} resetContext={resetContext} />;
};

export const CompanyCommandPalette: React.FC<CompanyCommandPaletteProps> = ({ commands }) => {
	const { company, companyUserPermissions } = useCompanyUser();

	if (!company || companyUserPermissions === undefined) {
		return null;
	}
	return <CompanyCommandPaletteInner companyId={company.Id} permissions={companyUserPermissions} commands={commands} />;
};
