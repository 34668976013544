import type * as m from "@bokio/mobile-web-shared/core/model/model";

type EmailActivityDto = m.Core.ViewData.EmailActivityDto;

type Listener = (e: EmailActivityDto) => void;

let listeners: Listener[] = [];

export const dispatcher: Listener = e => {
	listeners.forEach(listener => listener(e));
};

export const emitter = {
	add: (listener: Listener) => {
		listeners = [...listeners, listener];
	},
	remove: (listener: Listener) => {
		listeners = listeners.filter(keep => keep !== listener);
	},
};
