import * as React from "react";

export interface TBodyProps {
	className?: string;
}

class TBody extends React.Component<React.PropsWithChildren<TBodyProps>> {
	render() {
		return <tbody className={this.props.className || ""}>{this.props.children}</tbody>;
	}
}

export default TBody;
