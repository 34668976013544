import uniqueId from "lodash-es/uniqueId";

import { GeneralLangFactory } from "@bokio/lang";
import classes, { mergeClassNames } from "@bokio/utils/classes";

import { Validation } from "../Form";
import LabelFor from "../Form/LabelFor/LabelFor";
import Icon from "../Icon/Icon";
import { Tooltip } from "../Tooltip";
import { SearchableSelectOption } from "./SearchableSelectOption";
import { useSearchableSelect } from "./useSearchableSelect";

import type { LabelTooltip } from "../Form/LabelFor/LabelFor";
import type { OptionRendererProps, SelectOption, SelectOptionCategory } from "./SearchableSelectOption";
import type { RuleValidationResult } from "@bokio/shared/validation/entityValidator";
import type * as React from "react";

import * as styles from "./searchableSelect.scss";

const defaultSearchFn = <T,>(option: SelectOption<T>, searchTerm: string) =>
	option.label.toString().toLowerCase().includes(searchTerm.toLowerCase());

const defaultSelectedFormatter = <T,>(option: SelectOption<T>) => option.label;

export type SearchableSelectProps<T> = {
	selected?: SelectOption<T>;
	onChange: (selected: SelectOption<T> | undefined) => void;
	onInputFieldChange?: (value: string) => void;
	onScrollEnd?: () => void;
	hideSelectOptions?: boolean;
	withoutControlIcon?: boolean;
	withoutClearAction?: boolean;
	tooltip?: LabelTooltip;
	fieldProps?: React.InputHTMLAttributes<HTMLInputElement>;
	label?: string;
	fullWidth?: boolean;
	className?: string;
	dropdownClassName?: string;
	dropdownWrapperClassName?: string;
	scrollSectionClassName?: string;
	labelClassName?: string;
	btnClassName?: string;
	disabled?: boolean;
	testId?: string;
	placeholder?: string;
	searchPlaceholder?: string;
	noSearchResultsText?: string;
	// Be sure to specify null as type so the component can mutate it. See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31065#issuecomment-453841404
	innerRef?: React.MutableRefObject<HTMLInputElement | null>;
	optionRenderer?: (option: SelectOption<T>, props: OptionRendererProps) => React.ReactNode;
	selectedFormatter?: (option: SelectOption<T>) => string;
	errors?: { errors: RuleValidationResult[] };
	/**
	 * When true, the search results will show even if the search input is empty.
	 */
	forceExpandSearch?: boolean;
	/* Example for a searchFn: (option, term) => option.value.bank.name.includes(term) */
	searchFn?: (option: SelectOption<T>, term: string) => boolean;
	clearInputOn?: "close";
	renderAfterOptionList?: () => React.ReactNode;
	stickyAfterOptionList?: React.ReactNode;
	/**
	 * Defaulted to true to be consitent with exisiting behaviour.
	 * @default true
	 */
	autoSelectWhenClosingMenu?: boolean;
} & (
	| {
			options: SelectOption<T>[];
	  }
	| {
			categorizedOptions: SelectOptionCategory<T>[];
	  }
);

export const SearchableSelect = <T,>({
	label,
	fullWidth,
	selected,
	className,
	dropdownClassName,
	dropdownWrapperClassName,
	scrollSectionClassName,
	onChange,
	onInputFieldChange,
	onScrollEnd,
	hideSelectOptions,
	withoutControlIcon,
	withoutClearAction,
	tooltip,
	fieldProps,
	disabled,
	labelClassName,
	btnClassName,
	testId,
	placeholder,
	noSearchResultsText,
	innerRef,
	optionRenderer,
	selectedFormatter = defaultSelectedFormatter,
	errors,
	forceExpandSearch,
	searchFn = defaultSearchFn,
	renderAfterOptionList,
	stickyAfterOptionList,
	autoSelectWhenClosingMenu = true,
	...props
}: SearchableSelectProps<T>) => {
	const flattenedOptions: SelectOption<T>[] = (() => {
		if ("options" in props) {
			return props.options;
		}

		const results: SelectOption<T>[] = [];

		const extractCategory = (category: SelectOptionCategory<T>) => {
			results.push(...category.options);
			for (const c of category.subCategories) {
				extractCategory(c);
			}
		};

		props.categorizedOptions.forEach(c => extractCategory(c));

		return results;
	})();

	const { actions, refs, searchTerm, selectedOption, displaySelected, isOpen, filteredOptions, focusedIndex } =
		useSearchableSelect({
			onChange,
			innerRef,
			options: flattenedOptions,
			searchFn,
			selected,
			focusOnSelect: !hideSelectOptions,
			onScrollEnd,
			autoSelectWhenClosingMenu,
		});

	const generalLang = GeneralLangFactory();

	const selectClass = mergeClassNames(
		classes(styles, "selectContainer", { selectContainerFullwidth: fullWidth }),
		className,
	);

	const dropdownWrapperClass = mergeClassNames(
		isOpen ? styles.selectDropDownVisible : styles.selectDropDown,
		label ? styles.selectDropDownPositionWithLabel : styles.selectDropDownPositionWithoutLabel,
		dropdownWrapperClassName,
	);

	const id = `searchable-select-id-${uniqueId()}`;

	const renderOptions = (options: SelectOption<T>[]): React.ReactNode => {
		return options.map((option, i) => (
			<SearchableSelectOption
				key={option.key}
				option={option}
				setRef={actions.setOptionRef(option.key)}
				onSelect={actions.select}
				selected={(selectedOption && selectedOption.value === option.value) || false}
				focused={
					"categorizedOptions" in props
						? focusedIndex === flattenedOptions.findIndex(o => o === option)
						: focusedIndex === i
				}
				renderer={optionRenderer}
			/>
		));
	};

	const renderCategorizedOptions = (categories: SelectOptionCategory<T>[]): React.ReactNode => {
		const filteredInOptionKeys: Record<string, boolean> = {};
		let categoryIdCounter = 0;

		const categoryIdHasDecendantOptionAvailable: Record<string, boolean> = {};

		filteredOptions.forEach(o => {
			filteredInOptionKeys[o.key] = true;
		});

		const renderCategory = (category: SelectOptionCategory<T>, categoryIdPaths: string[]): React.ReactNode => {
			const children = (
				<>
					<div>
						{(() => {
							const availableOptions = category.options.filter(o => filteredInOptionKeys[o.key]);

							if (availableOptions.length) {
								categoryIdPaths.forEach(id => {
									categoryIdHasDecendantOptionAvailable[id] = true;
								});
							}

							return renderOptions(availableOptions);
						})()}
					</div>
					{!!category.subCategories.length &&
						category.subCategories.map(c => {
							const categoryId = categoryIdCounter++;
							return renderCategory(c, [...categoryIdPaths, categoryId.toString()]);
						})}
				</>
			);

			if (!categoryIdHasDecendantOptionAvailable[categoryIdPaths.slice(-1)[0]]) {
				return null;
			}

			return (
				<div key={categoryIdPaths.join("-")}>
					<p
						className={mergeClassNames(
							styles.categoryTitleDefault,
							categoryIdPaths.length < 3 && styles[`categoryTitleDepth${categoryIdPaths.length}`],
						)}
					>
						{category.label}
					</p>
					{children}
				</div>
			);
		};

		return categories.map(c => {
			const categoryId = categoryIdCounter++;
			return renderCategory(c, [categoryId.toString()]);
		});
	};

	return (
		<div className={selectClass}>
			{/* Use `LabelFor` as a sibling as opposed to a parent to prevent click event propagation which causes some onClick handlers to be called twice */}
			<LabelFor htmlFor={id} label={label} className={labelClassName} />
			<div title={selectedOption && selectedOption.label} onKeyDown={actions.handleKeyDown} ref={refs.wrapper}>
				<div className={styles.flex}>
					<div
						className={mergeClassNames(styles.selectButton, btnClassName, disabled && styles.selectButtonDisabled)}
						aria-haspopup="true"
						aria-expanded={isOpen}
						onClick={() => {
							if (!disabled) {
								actions.toggleOpen();
								actions.focusInput();
							}
						}}
					>
						<input
							tabIndex={0}
							value={displaySelected && selectedOption ? selectedFormatter(selectedOption) : searchTerm}
							onChange={event => {
								onInputFieldChange?.(event.target.value);
								actions.handleSearchInputChange(event);
							}}
							onBlur={actions.handleSearchInputBlur}
							ref={actions.setInputRef}
							className={styles.searchInput}
							disabled={disabled}
							placeholder={placeholder}
							id={id}
							data-testid={testId}
							autoComplete="off"
							spellCheck={false}
							{...fieldProps}
						/>
						{!withoutControlIcon && (
							<>
								{!withoutClearAction && selectedOption && !disabled && (
									<Icon name="cancel" onClick={actions.clearSelection} className={styles.selectButtonArrowIcon} />
								)}
								<Icon
									testId="Select_Icon"
									name={isOpen ? "up-open-big" : "down-open-big"}
									className={styles.selectButtonArrowIcon}
									size="18"
								/>
							</>
						)}
					</div>
					{tooltip && (
						<Tooltip contentGenerator={() => tooltip.message} trackinfo={tooltip.trackinfo}>
							<Icon className={styles.tooltipIcon} size="24" name={tooltip.iconName || "info"} />
						</Tooltip>
					)}
				</div>
				<Validation testId={`Validation_Inline_${testId}`} errors={errors?.errors} />
				{isOpen && !hideSelectOptions && (
					<div className={dropdownWrapperClass}>
						<div className={mergeClassNames(styles.dropdown, dropdownClassName)} ref={refs.dropdown}>
							<div
								className={mergeClassNames(styles.scrollSection, scrollSectionClassName)}
								ref={refs.scroll}
								data-testid={`${testId}_Scroll`}
							>
								{(forceExpandSearch || searchTerm.length > 0) && filteredOptions.length === 0 ? (
									<div>
										<p className={styles.noSearchResults}>{noSearchResultsText || generalLang.NoSearchResults}</p>
									</div>
								) : (
									<div role="listbox" data-testid={`${testId}_Options`}>
										{"categorizedOptions" in props
											? renderCategorizedOptions(props.categorizedOptions)
											: renderOptions(filteredOptions)}
									</div>
								)}
								{renderAfterOptionList?.()}
							</div>
							{stickyAfterOptionList && <div>{stickyAfterOptionList}</div>}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
