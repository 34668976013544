import { BackOfficeLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { formatMessage } from "@bokio/shared/utils/format";

import type { Author } from "./NoteCard";
import type { CustomSuggestionDataItem } from "@bokio/elements/MentionsTextArea/MentionsTextArea";

export interface AssigneeDataItem {
	key: string;
	label: string | undefined;
	id: string;
	postText?: string;
}

export const getTranslation = (kind: m.Entities.NoteKind) => {
	const lang = BackOfficeLangFactory();
	switch (kind) {
		case m.Entities.NoteKind.Comment:
			return lang.Comment;
		case m.Entities.NoteKind.Task:
			return lang.Task;
	}
};

export const getAuthorName = (author: Author) => {
	return author.name.length > 0 ? author.name : author.email;
};
export const getAssigneeName = (assignee: m.Classes.UserDto) => {
	return assignee.Name.length > 0 ? assignee.Name : assignee.Email;
};

export const getButtonText = (isEdit: boolean, showNotice: boolean, type: m.Entities.NoteKind) => {
	const backofficeLang = BackOfficeLangFactory();
	return isEdit
		? formatMessage(backofficeLang.AddEditNote_ModalTitle_Edit, getTranslation(type).toLowerCase())
		: showNotice
			? formatMessage(backofficeLang.AddEditNote_ModalFooter_CreateAndSend, getTranslation(type).toLowerCase())
			: formatMessage(backofficeLang.AddEditNote_ModalFooter_Create, getTranslation(type).toLowerCase());
};

export const getAssigneeOptions = (
	responsibleAccountants: m.Classes.UserDto[],
	currentUserId?: string,
): AssigneeDataItem[] => {
	return responsibleAccountants
		?.filter(a => a.Id != currentUserId)
		.map(a => ({
			key: a.Id,
			label: getAssigneeName(a),
			id: a.Id,
		}));
};

export const getMentionableUsers = (users: m.Classes.UserDto[]): CustomSuggestionDataItem[] => {
	return users.map(a => ({
		display: getAssigneeName(a),
		id: a.Id,
		key: a.Id,
	}));
};

export const renderTaskTitle = (title: string, description: string) => {
	return title ?? (description.length > 60 ? description.substring(0, 60) : description);
};
