import type * as React from "react";

type BaseAlign = "flex-start" | "center" | "flex-end";
type Justify = BaseAlign | "space-between" | "space-around" | "space-evenly";
type HorizontalAlign = BaseAlign;
type VerticalAlign = BaseAlign | "baseline";

interface ContainerProps {
	box?: React.CSSProperties;
	className?: string;
	children: React.ReactNode;
}

interface BaseProps<AlignType> extends ContainerProps {
	alignItems?: AlignType;
	justifyContent?: Justify;
}

interface FlexBoxProps extends BaseProps<VerticalAlign> {
	direction: "column" | "row";
}

type VerticalProps = BaseProps<HorizontalAlign>;
type HorizontalProps = BaseProps<VerticalAlign>;
type CenterProps = ContainerProps;

const FlexBox = ({ box, className, direction, alignItems, justifyContent, children }: FlexBoxProps) => (
	<div className={className} style={{ display: "flex", flexDirection: direction, alignItems, justifyContent, ...box }}>
		{children}
	</div>
);

const Vertical = (props: VerticalProps) => (
	<FlexBox {...props} direction="column">
		{props.children}
	</FlexBox>
);

const Horizontal = (props: HorizontalProps) => (
	<FlexBox {...props} direction="row">
		{props.children}
	</FlexBox>
);

const Center = (props: CenterProps) => (
	<FlexBox {...props} direction="row" alignItems="center" justifyContent="center">
		{props.children}
	</FlexBox>
);

export default FlexBox;
export { Vertical, Horizontal, Center };
