import { CountriesLangFactory, GeneralLangFactory } from "@bokio/lang";

import { formatMessage } from "./format";

export interface VatCountriesList {
	code: string;
	regex: RegExp;
	helper: string;
	label: string;
}

export interface VatNumberError {
	success: boolean;
	errorMessage: string;
}

export const getVATChoices = (): VatCountriesList[] => {
	const countriesLang = CountriesLangFactory();
	const generalLang = GeneralLangFactory();
	return [
		{ code: "AT", regex: /^U\d{8}$/, helper: "ATUxxxxxxx", label: countriesLang.AUT },
		{ code: "BE", regex: /^0\d{9}|\d{9}$/, helper: "BE0xxxxxxxxx", label: countriesLang.BEL },
		{ code: "BG", regex: /^\d{9,10}$/, helper: "BGxxxxxxxxx, BGxxxxxxxxxx", label: countriesLang.BGR },
		{ code: "CY", regex: /^\d{8}[A-Z]$/, helper: "CYxxxxxxxxL", label: countriesLang.CYP },
		{ code: "CZ", regex: /^\d{8,10}$/, helper: "CZxxxxxxxx, CZxxxxxxxxx, CZxxxxxxxxxx", label: countriesLang.CZE },
		{ code: "DE", regex: /^\d{9}$/, helper: "DExxxxxxxxx", label: countriesLang.DEU },
		{ code: "DK", regex: /^\d{8}$/, helper: "DKxxxxxxxx", label: countriesLang.DNK },
		{ code: "EE", regex: /^\d{9}$/, helper: "EExxxxxxxxx", label: countriesLang.EST },
		{ code: "EL", regex: /^\d{9}$/, helper: "ELxxxxxxxxx", label: countriesLang.GRC },
		{ code: "ES", regex: /^[0-9A-Z]\d{7}[0-9A-Z]$/, helper: "ESXxxxxxxxX", label: countriesLang.ESP },
		{ code: "FI", regex: /^\d{8}$/, helper: "FIxxxxxxxx", label: countriesLang.FIN },
		{ code: "FR", regex: /^[0-9A-Z]{2}\d{9}$/, helper: "FRXXxxxxxxxxx", label: countriesLang.FRA },
		{
			code: "GB",
			regex: /^(\d{9}(\d{3})?|[A-Z]{2}\d{3})$/,
			helper: "GBxxxxxxxxx, GBxxxxxxxxxxxx, GBGDxxx, GBHAxxx",
			label: countriesLang.GBR,
		},
		{ code: "HR", regex: /^\d{11}$/, helper: "HRxxxxxxxxxxx", label: countriesLang.HRV },
		{ code: "HU", regex: /^\d{8}$/, helper: "HUxxxxxxxx", label: countriesLang.HUN },
		{
			code: "IE",
			regex: /^[0-9][A-Z][0-9]{5}[A-Z]|[0-9]{7}[A-Z]{1,2}$/,
			helper: "IExXxxxxxX, IExxxxxxxX, IExxxxxxxXX",
			label: countriesLang.IRL,
		},
		{ code: "IT", regex: /^\d{11}$/, helper: "ITxxxxxxxxxxx", label: countriesLang.ITA },
		{ code: "LT", regex: /^(\d{9}|\d{12})$/, helper: "LTxxxxxxxxx, LTxxxxxxxxxxxx", label: countriesLang.LTU },
		{ code: "LU", regex: /^\d{8}$/, helper: "LUxxxxxxxx", label: countriesLang.LUX },
		{ code: "LV", regex: /^\d{11}$/, helper: "LVxxxxxxxxxxx", label: countriesLang.LVA },
		{ code: "MT", regex: /^\d{8}$/, helper: "MTxxxxxxxx", label: countriesLang.MLT },
		{ code: "NL", regex: /^\d{9}B[/0-9]{2}$/, helper: "NLxxxxxxxxxBxx", label: countriesLang.NLD },
		{ code: "NO", regex: /^\d{9}$/, helper: "NOxxxxxxxxx", label: countriesLang.NOR },
		{ code: "PL", regex: /^\d{10}$/, helper: "PLxxxxxxxxxx", label: countriesLang.POL },
		{ code: "PT", regex: /^\d{9}$/, helper: "PTxxxxxxxxx", label: countriesLang.PRT },
		{ code: "RO", regex: /^\d{2,10}$/, helper: "ROxxxxxxxxx", label: countriesLang.ROU },
		{
			code: "SE",
			regex: /^\d{10}0[/0-9]{1}$/,
			helper: `SExxxxxxxxxx[01 ${generalLang.Or.toLowerCase()} 02, 03 ${generalLang.Etc_abbreviation}`,
			label: countriesLang.SWE,
		},
		{ code: "SI", regex: /^\d{8}$/, helper: "SIxxxxxxxx", label: countriesLang.SLE },
		{ code: "SK", regex: /^\d{10}$/, helper: "SKxxxxxxxxxx", label: countriesLang.SVK },
	];
};

export function getVatErrorMessage(country: string): string {
	const lang = GeneralLangFactory();
	const matchedCountry = getVATChoices().filter(vat => country === vat.code);
	if (matchedCountry[0]) {
		return formatMessage(lang.Validation_IncorrectEUVATFormat, matchedCountry[0].label, matchedCountry[0].helper);
	} else {
		return "";
	}
}

export function checkEUVATNumberFormat(vatNumber: string, country: string): boolean {
	const matchedCountry = getVATChoices().filter(vat => country === vat.code);
	const countryCode = vatNumber.substr(0, 2);
	if (matchedCountry[0]) {
		if (countryCode !== matchedCountry[0].code) {
			return false;
		}
		// if country code is valid check regex for specific country against remaining digits
		return matchedCountry[0].regex.test(vatNumber.substr(2));
	}
	// if code not found then return true as we don't validate non EU numbers
	return true;
}
