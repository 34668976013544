import * as React from "react";
import { Mention, MentionsInput } from "react-mentions";

import { Caption } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import LabelFor from "@bokio/elements/Form/LabelFor/LabelFor";
import { GeneralLangFactory } from "@bokio/lang";
import { formatMessage } from "@bokio/shared/utils/format";

import type { MentionItem, SuggestionDataItem } from "react-mentions";

import * as classNames from "./mentionsTextArea.scss";

export interface CustomSuggestionDataItem {
	id: string | number;
	display?: string;
	postText?: string;
}

interface MentionsProps {
	label?: string;
	placeholder?: string;
	users: CustomSuggestionDataItem[];
	tasks?: CustomSuggestionDataItem[];
	value: string;
	scroll?: boolean;
	hideCaption?: boolean;
	onChange: (props: MentionsOnChangeProps) => void;
	previousMentions?: string[];
}

export interface MentionsOnChangeProps {
	dynamicTextValue: string;
	plainTextValue: string;
	mentionedUsers: string[];
}

export const formatMentionText = (text: string) => {
	//Regex to match the GUID
	const regex = new RegExp(/([({]?[a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}[})]?)|(\[|\])/g);
	const formattedString = text.replace(regex, "");
	return formattedString;
};

const renderSuggestions = (suggestion: SuggestionDataItem, users: CustomSuggestionDataItem[]) => {
	return (
		<>
			{suggestion.display} <Caption>{users.find(x => x.id === suggestion.id)?.postText}</Caption>
		</>
	);
};

const MentionsTextArea = ({
	users,
	tasks,
	value,
	label,
	placeholder,
	scroll,
	hideCaption,
	onChange,
	previousMentions,
}: MentionsProps) => {
	const generalLang = GeneralLangFactory();

	const [mentionState, setMentionState] = React.useState<MentionItem[]>([]);

	const handleChange = (newValue: string, newPlainTextValue: string, mentions: MentionItem[]) => {
		const userMentions = mentions.filter(m => users.some(u => u.id === m.id));

		const mentionInputResult: MentionsOnChangeProps = {
			dynamicTextValue: newValue,
			plainTextValue: newPlainTextValue,
			mentionedUsers: userMentions.map(m => m.id),
		};
		const unique = new Set();
		const uniqueMentions = userMentions.filter(val => {
			// Filter out duplicate mentions
			const isDuplicate = unique.has(val.id);
			unique.add(val.id);
			if (!isDuplicate && !previousMentions?.includes(val.id)) {
				return true;
			}

			return false;
		});

		setMentionState(uniqueMentions);
		onChange(mentionInputResult);
	};

	const renderMentions = () => {
		const mentions = [
			<Mention
				key={1}
				trigger={"@"}
				markup="@[__display__](__id__)"
				displayTransform={(_, display) => "@" + display}
				className={classNames.mentions__mention}
				data={users}
				renderSuggestion={suggestion => renderSuggestions(suggestion, users)}
			/>,
		];

		if (tasks && tasks.length) {
			mentions.push(
				<Mention
					key={2}
					trigger={"#"}
					markup="#[__display__](__id__)"
					displayTransform={(_, display) => "#" + display}
					className={classNames.mentions__mention}
					data={tasks}
					renderSuggestion={s => s.display}
				/>,
			);
		}
		return mentions;
	};

	return (
		<div className={scroll ? classNames.scroll : ""}>
			<LabelFor htmlFor="mentionInput" label={label} />
			<MentionsInput
				data-testid="MentionTextArea"
				id="mentionInput"
				rows={6}
				placeholder={placeholder ?? generalLang.MentionsTextArea_Placeholder}
				value={value}
				onChange={(_, newValue, ptv, mentions) => handleChange(newValue, ptv, mentions)}
				className="mentions"
				classNames={classNames}
				a11ySuggestionsListLabel={"Suggested mentions"}
			>
				{renderMentions()}
			</MentionsInput>
			{!!mentionState.length && !hideCaption && (
				<span data-testid="MentionsTextArea_caption" className={classNames.caption}>
					{formatMessage(
						generalLang.MentionsTextArea_caption,
						mentionState.map(m => " " + m.display),
					)}
				</span>
			)}
		</div>
	);
};
export default MentionsTextArea;
