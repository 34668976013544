import { getVatSettingTitle } from "@bokio/shared/utils/companyInfoUtils";
import {
	EntityValidator,
	FieldRuleFactory,
	FieldRuleLevel,
	FieldValidator,
} from "@bokio/shared/validation/entityValidator";

import type { AccountingLang } from "@bokio/lang/AccountingLangFactory";
import type { GeneralLang } from "@bokio/lang/GeneralLangFactory";
import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { Validator } from "@bokio/shared/validation/entityValidator";

type Update = m.Commands.Accounting.FiscalYears.UpdateYear;
export class FiscalYearValidator extends EntityValidator<Partial<Update>> {
	constructor(
		protected general: GeneralLang,
		protected accounting: AccountingLang,
	) {
		super();
	}

	getRules(): Validator[] {
		const { general, accounting } = this;
		const factory = new FieldRuleFactory(general);
		return [
			new FieldValidator(this.propertyOf("AccountingForm"), [
				factory.Required(accounting.AccountingType, FieldRuleLevel.MustFixNow),
			]),
			new FieldValidator(this.propertyOf("VatSetting"), [
				factory.Required(getVatSettingTitle(), FieldRuleLevel.MustFixNow),
			]),
			new FieldValidator(this.propertyOf("StartDate"), [
				factory.Required(general.From, FieldRuleLevel.MustFixNow),
				factory.DateFormat(general.From, FieldRuleLevel.MustFixNow),
				factory.DateExists(general.From, FieldRuleLevel.MustFixNow),
			]),
			new FieldValidator(this.propertyOf("EndDate"), [
				factory.Required(general.To, FieldRuleLevel.MustFixNow),
				factory.DateFormat(general.To, FieldRuleLevel.MustFixNow),
				factory.DateExists(general.To, FieldRuleLevel.MustFixNow),
			]),
		];
	}
}
