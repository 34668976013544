import * as React from "react";

export const useOnUnmount = <T>(onUnMount: (...values: T[]) => void, ...values: T[]) => {
	const ref = React.useRef<T[]>(values);

	React.useEffect(() => {
		ref.current.forEach((_, i) => (ref.current[i] = values[i]));
	}, [values]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => () => onUnMount(...ref.current), []);
};
