import * as React from "react";

import type { HeadingLevelProps } from "./TypographicElements.types";

const headingLevelContext = React.createContext<number>(0);

const useHeadingLevelContext = () => React.useContext(headingLevelContext);
const HeadingLevelContextProvider = headingLevelContext.Provider;
const HeadingLevel: React.FC<React.PropsWithChildren<HeadingLevelProps>> = ({ children, resetHeadingLevelTo }) => {
	const headingLevelContext = useHeadingLevelContext();

	return (
		<HeadingLevelContextProvider value={resetHeadingLevelTo ?? headingLevelContext + 1}>
			{children}
		</HeadingLevelContextProvider>
	);
};

export const TypoGraphicElementInternals = {
	HeadingLevelContextProvider,
	useHeadingLevelContext,
	HeadingLevel,
};
