import { useOptimisticSetter } from "@bokio/hooks/useOptimisticSetter/useOptimisticSetter";

import { StatusSelect } from "./StatusSelect";

import type { StatusOption } from "../StatusIndicator/StatusIndicator";

interface OptimisticStatusSelectProps<T> {
	currentValue: T;
	setter: (status: T) => Promise<void>;
	onChanged: () => void;
	options: T[];
	getStatusOption: (v: T) => StatusOption;
}

export const OptimisticStatusSelect = <T extends string>({
	currentValue,
	setter,
	onChanged,
	options,
	getStatusOption,
}: OptimisticStatusSelectProps<T>) => {
	const status = useOptimisticSetter<T>({
		currentValue,
		setter,
		onChanged,
	});

	return (
		<StatusSelect
			onChange={s => status.setValue(s)}
			value={status.value}
			options={options}
			getStatusOption={getStatusOption}
		/>
	);
};
