import type { DropdownItem, DropdownItemGroup } from "./Dropdown.types";

export const isDropdownItem = (item: DropdownItem | DropdownItemGroup): item is DropdownItem =>
	typeof item === "object" && !item.hasOwnProperty("items");

export const isDropdownItemGroup = (item: DropdownItem | DropdownItemGroup): item is DropdownItemGroup =>
	!isDropdownItem(item);

// NOTE: MQ 2021-04-12
// There is limitation in typescript https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-3.html#caveats
export const isDropdownItems = (items: DropdownItem[] | DropdownItemGroup[]): items is DropdownItem[] =>
	(items as (DropdownItem | DropdownItemGroup)[]).every(isDropdownItem);

export const isDropdownItemGroups = (items: DropdownItem[] | DropdownItemGroup[]): items is DropdownItemGroup[] =>
	(items as (DropdownItem | DropdownItemGroup)[]).every(isDropdownItemGroup);

export function filterEnabledDropdownItem(item: DropdownItem) {
	return !item.disabled;
}
