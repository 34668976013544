// extracted by mini-css-extract-plugin
export var headingWrapper = "ak";
export var sectionWrapper = "lk";
export var headingHElement = "ok";
export var whiteSpacePreLine = "EL";
export var sectionWrapperMarginAway_0 = "rX";
export var headingWrapperMarginAway_0 = "n2";
export var sectionWrapperMarginAway_4 = "HUa";
export var headingWrapperMarginAway_4 = "IUa";
export var sectionWrapperMarginAway_6 = "C7";
export var headingWrapperMarginAway_6 = "D7";
export var sectionWrapperMarginAway_8 = "Ek";
export var headingWrapperMarginAway_8 = "Fk";
export var sectionWrapperMarginAway_12 = "Gk";
export var headingWrapperMarginAway_12 = "Hk";
export var sectionWrapperMarginAway_16 = "Pk";
export var headingWrapperMarginAway_16 = "Qk";
export var sectionWrapperMarginAway_24 = "Rk";
export var headingWrapperMarginAway_24 = "el";
export var sectionWrapperMarginAway_32 = "fl";
export var headingWrapperMarginAway_32 = "gl";
export var sectionWrapperMarginAway_40 = "ll";
export var headingWrapperMarginAway_40 = "ol";
export var sectionWrapperMarginAway_48 = "sl";
export var headingWrapperMarginAway_48 = "Fl";
export var sectionWrapperMarginAway_24t16m = "Il";
export var headingWrapperMarginAway_24t16m = "Kl";
export var fontCaptionDefault = "gCa";
export var fontCaptionBold = "E7";
export var fontBodyBold = "pCa";
export var fontBodyDefault = "sCa";
export var fontBodyLargeDefault = "tCa";
export var fontSubheadingDefault = "aEa";
export var fontHeadingDefault = "bEa";
export var fontLargeHeadingDefault = "QEa";
export var fontLargeHeadingBold = "_Fa";
export var fontCompanyListHeading = "F7";
export var strong = "op";
export var textCenter = "qp";
export var centerHeading = "Uf";
export var useVariableColor = "aGa";
export var useVariableMarginBottom = "k8";