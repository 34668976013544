import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingText } from "@bokio/elements/Loading";
import { GeneralLangFactory } from "@bokio/lang";
import { getRoute } from "@bokio/shared/route";
import { mergeClassNames } from "@bokio/utils/classes";

import LanguageSwitcherModal from "./LanguageSwitcherModal";

// prettier-ignore
import * as styles from "./menuCurrentUser.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

// prettier-ignore
import * as flagStyles from "./languageSwitcherModal.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";

export interface MenuCurrentUserMobileShowProps {
	onCloseMenu: () => void;
}

const MenuCurrentUserMobileShow: React.FC<MenuCurrentUserMobileShowProps> = ({ onCloseMenu }) => {
	const [showLangSwitcher, setShowLangSwitcher] = React.useState(false);
	const { isLoadingUser, user, signOut } = useTopLevelUser();

	const toggleLangSwitcher = () => {
		setShowLangSwitcher(x => !x);
	};

	const closeLangSwitcher = () => {
		setShowLangSwitcher(false);
	};

	const lang = GeneralLangFactory();

	if (isLoadingUser) {
		return (
			<div>
				<LoadingText width="70%" />
			</div>
		);
	}

	if (!user) {
		return <div>{lang.NoAccount}</div>;
	}

	return (
		<React.Fragment>
			<div className={mergeClassNames(styles.userSettings, styles.showUserSettings)}>
				<div className={styles.mobileNavWrapper}>
					<Link style="none" className={styles.backButton} onClick={onCloseMenu}>
						<Icon name="left-open-big" size="14" /> {lang.Back}
					</Link>
					<Link style="none" className={styles.closeButton} onClick={onCloseMenu}>
						<Icon name="cancel" size="22" />
					</Link>
				</div>
				<div className={styles.loggedInAs}>
					<p className={styles.name}>{user.FirstName ? `${user.FirstName} ${user.LastName}` : lang.MyAccount}</p>
					<em className={styles.email}>{user.Email}</em>
				</div>
				<Link
					className={styles.linkItem}
					style="none"
					route={getRoute("personalSettings", { returnUrl: window.location.pathname })}
				>
					<Icon name="pencil" size="18" />
					{lang.PersonalSettings}
				</Link>
				{user.Languages.length > 1 && (
					<Link
						className={mergeClassNames(
							styles.linkItem,
							flagStyles.flag,
							flagStyles[`flag${user.Language.substring(0, 2)}`],
						)}
						style="none"
						onClick={toggleLangSwitcher}
					>
						<Icon name="language" size="18" />
						{lang.ChangeLanguage_action}
					</Link>
				)}
				<Link className={mergeClassNames(styles.linkItem, styles.logoutMobile)} style="none" onClick={signOut}>
					<Icon name="cancel" size="16" />
					{lang.LogOut}
				</Link>
			</div>
			<LanguageSwitcherModal visible={showLangSwitcher} onClose={closeLangSwitcher} />
		</React.Fragment>
	);
};

export default MenuCurrentUserMobileShow;
