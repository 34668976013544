import * as React from "react";

import { noop } from "@bokio/shared/utils";

export type ScrollAreaContext = {
	scrollToTop: () => void;
};

export const scrollAreaContext = React.createContext<ScrollAreaContext>({
	scrollToTop: noop,
});

export const useScrollAreaContext = () => React.useContext(scrollAreaContext);
