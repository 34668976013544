import { matchPath } from "react-router-dom";

import { routes } from "@bokio/shared/route";

export function isActiveRoute(
	matchExact: boolean | undefined,
	currentRoute: string | undefined,
	route: string | undefined,
) {
	// Try to match the current internal route
	if (route && currentRoute) {
		const regexToMatch = matchExact ? route + "$" : route; // uses exact match or part match
		return !!currentRoute.match(regexToMatch);
	}
	return false;
}

export function isBookkeepingRoute(pathname: string) {
	const match =
		matchPath(pathname, routes.preBookkeep) ||
		matchPath(pathname, routes.preBookkeepSupplierInvoice) ||
		matchPath(pathname, routes.bookkeepReceipt) ||
		matchPath(pathname, routes.bookkeepExpense) ||
		matchPath(pathname, routes.bookkeepTransaction) ||
		matchPath(pathname, routes.bookkeepSupplier);

	return Boolean(match);
}
