import * as React from "react";

import * as m from "@bokio/mobile-web-shared/core/model/model";

import BankIdCollectionStatus = m.Bokio.Common.Contract.BankId.BankIdCollectionStatus;
import BankIdHintCode = m.Bokio.Common.Contract.BankId.BankIdHintCode;

export type BankIdState = {
	autoStartToken: string | undefined;
	collectionStatus: BankIdCollectionStatus | undefined;
	hintCode: BankIdHintCode | undefined;
	bankIdQrCode: string | undefined;
	hasError: boolean;
};

type BankIdInternalState = Omit<BankIdState, "bankIdQrCode">;

const initialState: BankIdInternalState = {
	autoStartToken: undefined,
	collectionStatus: undefined,
	hintCode: undefined,
	hasError: false,
};

export function useSveaBankIdState() {
	const [bankIdState, setBankIdState] = React.useState<BankIdInternalState>(initialState);
	const [bankIdQrCode, setBankIdQrCode] = React.useState<string>();

	// QRCode passthrough - prevents QR Code from flickering because of first empty response from Svea
	const allowQrUpdate = React.useRef(true);

	return {
		bankIdState: {
			...bankIdState,
			bankIdQrCode,
		},
		setBankIdState: (newState: Partial<BankIdState>, options?: { lockQrCode?: boolean }) => {
			const { bankIdQrCode: qrCode, ...rest } = newState;
			// Svea might not return QrCode payload when the payload is not chagned or when the QR code is already scanned.
			// Since this only happens on the first calls of this endpoint, we can unlock qr updates after the QR had a value at least once
			if (qrCode || allowQrUpdate.current) {
				allowQrUpdate.current = !options?.lockQrCode;
				setBankIdQrCode(qrCode);
			}
			setBankIdState(c => ({ ...c, ...rest }));
		},
		resetBankIdState: (newState?: Partial<BankIdState>) => {
			setBankIdQrCode(undefined);
			setBankIdState({ ...initialState, ...newState });
			allowQrUpdate.current = true;
		},
	};
}
