import { OnboardingLangFactory } from "@bokio/lang";
import { formatMessage, formatPercentage } from "@bokio/shared/utils/format";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export const premiumPrice = 299;
export const premiumPlusPrice = 599;

export const numberOfDecimals = (num = 0) => {
	const floor = Math.floor(num);
	const fixed = num.toFixed(2).toString();

	if (floor === num || /^\d+\.00?$/.test(fixed)) {
		return 0;
	}

	return /^\d+\.[1-9]0?$/.test(fixed) ? 1 : 2;
};

export const getPromotionModalPremiumPlusChecklist = (
	cashbackContract?: m.Bokio.Common.Billing.Model.CashbackContract,
) => {
	const onboardingLang = OnboardingLangFactory();

	const depositedCapitalCashbackPercentage = cashbackContract?.DepositedCapitalCashbackPercentage || 0;
	const depositedCapitalPercentDecimals = numberOfDecimals(depositedCapitalCashbackPercentage);

	const cardSpendCashbackPercentage = cashbackContract?.CardSpendCashbackPercentage || 0;
	const cardPercentDecimals = numberOfDecimals(cardSpendCashbackPercentage);

	// PA, 2023-09-20: This translation is a | separated string that is made into an Array of strings
	return formatMessage(
		onboardingLang.PromotionModal_LowDepositFree_PremiumPlus_checklist,
		formatPercentage(depositedCapitalCashbackPercentage / 100, depositedCapitalPercentDecimals),
		formatPercentage(cardSpendCashbackPercentage / 100, cardPercentDecimals),
	)
		.toString()
		.replace(/(\d+)(\s)(%)/g, "$1$3") // PA, 2023-09-20: This removes the space between a number and a "%". So "3 %" becomes "3%"
		.split("|")
		.map(listItemText => listItemText.trim());
};
