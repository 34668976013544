import * as React from "react";
import { Route } from "react-router";

import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";

import Layout from "../Layout/Layout";
import TopBar, { TopBarMode } from "../TopBar/TopBar";

import type { RouteProps } from "react-router";

const UserRoute: React.FC<RouteProps> = props => {
	const { clearUser } = useTopLevelUser();

	React.useEffect(() => {
		clearUser();
	}, [clearUser]);

	const { path, component, exact } = props;

	return (
		<Layout
			isDemo={false}
			noSideBar={true}
			menu={menuProps => (
				<div>
					<TopBar mode={TopBarMode.User} toggleMenu={menuProps.toggleMenu} />
				</div>
			)}
			content={<Route path={path} component={component} exact={exact} />}
		/>
	);
};

export default UserRoute;
