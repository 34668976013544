export interface Environment {
	lang: string;
	// in general, apiUrl's value is empty string because we return index.html from RController.cs
	apiUrl: string;
}

interface Config {
	env: Environment;
	subscribe: (listener: Listener) => void;
	setup: (env: Partial<Environment>) => void;
}

type Listener = (env: Environment) => void;

const listeners: Listener[] = [];

export const Config: Config = {
	env: {
		lang: "",
		apiUrl: "",
	},
	subscribe(listener: Listener) {
		listeners.push(listener);
	},
	setup(env: Partial<Environment>) {
		Object.assign(Config.env, env);
		listeners.forEach(listener => listener(Config.env));
	},
};
