// extracted by mini-css-extract-plugin
export var rotate = "__promotionModal_scss__rotate";
export var highlightPlus = "qpa";
export var premiumPlus = "rpa";
export var promotionBoxes = "spa";
export var promotionBoxMarkdown = "tpa";
export var promotionModal = "upa";
export var closePromotionModal = "vpa";
export var promotionItemList = "wpa";
export var premiumToBba = "xpa";
export var bbaToPremiumPlus = "ypa";
export var kickbackCalculator = "nqa";
export var kickbackCalculatorContainer = "xqa";
export var kickbackCalculatorTotal = "yqa";
export var total = "zqa";
export var promotionModalButtons = "Aqa";
export var promotionModalButtonsHorizontal = "Bqa";
export var recommended = "Gqa";
export var recommendedBadge = "Lqa";
export var promotionListItem = "Pqa";
export var promotionModalChecklist = "Sqa";
export var noBottomMargin = "Tqa";
export var coveredPromotionBox = "Uqa";
export var checklistHeader = "Wqa";
export var __promotionModal_scss__rotate = "bsa";