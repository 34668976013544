import * as React from "react";

import { Box } from "@bokio/elements/Box";
import { LoadingBox } from "@bokio/elements/Loading";
import { useRouter } from "@bokio/shared/containers/router/useRouter";

import { trackTrace } from "./t";
import { toPath } from "./url";

/**
 * This component is a placeholder for doing a hard reload.
 * It should be inserted at routes that does not exist in React frontend.
 * Visiting this page is a forbidden op so we log the event and redirect hard
 * to the backend.
 */
export const ServerRouteRedirect = () => {
	const router = useRouter();
	React.useEffect(() => {
		const targetPath = router.location ? toPath(router.location) : "/";
		trackTrace("ServerRouteRedirect", { targetPath });
		router.redirectHard(targetPath);
	}, [router]);
	return (
		<Box>
			<LoadingBox />
		</Box>
	);
};
