import * as React from "react";

import { Flyout } from "@bokio/components/Flyout/Flyout";
import { useNotificationActivity } from "@bokio/contexts/NotificationActivityContext/useNotificationActivity";
import { CloseButton } from "@bokio/designsystem/components/CloseButton/CloseButton";
import { EmptyState } from "@bokio/elements/EmptyState";
import { FeedbackGraphic } from "@bokio/elements/Feedback";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingContent } from "@bokio/elements/Loading";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { getRoute } from "@bokio/shared/route";
import { mergeClassNames } from "@bokio/utils/classes";
import { distanceInWords } from "@bokio/utils/time";

import RenderRequest from "../RenderRequest/RenderRequest";

import * as styles from "./notificationFlyout.scss";

import NotificationStatus = m.Entities.Notifications.NotificationStatusTypes;
import NotificationArea = m.Entities.Notifications.NotificationArea;
type NotificationDto = m.Core.Services.Notifications.Dtos.NotificationDto;
import NotificationItemType = m.Entities.Notifications.NotificationItemType;
export const formatNotifications = (notifications: NotificationDto[]): [NotificationDto[], NotificationDto[]] => {
	const maxRecentItems = 10;
	const maxRecentDays = 7;
	const recentNotifications: NotificationDto[] = [];
	let earlierNotifications: NotificationDto[] = [];
	let recent = 0;
	const now = new Date().getTime();
	for (const notification of notifications) {
		if (recent < maxRecentItems) {
			const timeDifference = now - notification.Timestamp.getTime();
			const dayDifference = timeDifference / (1000 * 3600 * 24);
			if (dayDifference <= maxRecentDays) {
				recent++;
				recentNotifications.push(notification);
			} else {
				break;
			}
		} else {
			break;
		}
	}
	earlierNotifications = notifications.slice(recent);
	return [recentNotifications, earlierNotifications];
};

const NotificationItem: React.FC<{
	notification: NotificationDto;
	onClick: () => void;
	companyId: string;
	isBackOffice: boolean;
}> = ({ notification, onClick, companyId, isBackOffice }) => {
	const [setNotificationsStatuses] = useLazyApi(
		proxy.Notifications.NotificationController.SetNotificationsStatuses.Put,
	);

	const handleNotificationClick = (notification: NotificationDto) => {
		const notificationIds = [notification.Id];
		setNotificationsStatuses(companyId, notificationIds, m.Entities.Notifications.NotificationStatusTypes.Read);
	};
	return (
		<Link
			style="none"
			className={mergeClassNames(
				styles.notificationItem,
				notification.Status === NotificationStatus.Read && styles.notificationItemRead,
			)}
			data-testid="NotificationItem"
			onClick={e => {
				if (notification.ItemType === NotificationItemType.MentionComment && !isBackOffice) {
					handleNotificationClick(notification);
					e.preventDefault();
				}
				onClick;
			}}
			route={
				notification.Area === NotificationArea.System
					? notification.LinkTo
					: !!isBackOffice
						? getRoute("agencyNotificationRedirect", {
								agencyId: companyId,
								area: notification.Area,
								areaType: notification.AreaType,
								itemId: notification.ItemId,
								itemType: notification.ItemType,
								linkTo: notification.LinkTo,
							})
						: getRoute("notificationRedirect", {
								company: companyId,
								area: notification.Area,
								areaType: notification.AreaType,
								itemId: notification.ItemId,
								itemType: notification.ItemType,
							})
			}
		>
			<Markdown markdownContent={notification.Message} className={styles.notificationItemTitle} />
			<p className={styles.notificationItemCaption}>{distanceInWords(notification.Timestamp)}</p>
		</Link>
	);
};

interface NotificationFlyoutProps {
	companyId: string;
	isOpen: boolean;
	onClose: () => void;
	isBackOffice: boolean;
}

export const NotificationFlyout = ({ companyId, isOpen, onClose, isBackOffice }: NotificationFlyoutProps) => {
	const generalLang = GeneralLangFactory();
	const ping = useNotificationActivity();

	const [setNotificationsStatuses] = useLazyApi(
		isBackOffice
			? proxy.BackOffice.NotificationController.SetNotificationsStatuses.Put
			: proxy.Notifications.NotificationController.SetNotificationsStatuses.Put,
	);

	const [getNotifications, getNotificationsRequest] = useLazyApi(
		isBackOffice
			? proxy.BackOffice.NotificationController.GetNotificationsForUser.Get
			: proxy.Notifications.NotificationController.GetNotificationsForUser.Get,

		{
			onSuccess: data => {
				const newNotificationIds = data.filter(n => n.Status === NotificationStatus.New).map(n => n.Id);
				if (newNotificationIds.length > 0) {
					setNotificationsStatuses(companyId, newNotificationIds, NotificationStatus.Seen);
				}
			},
		},
	);

	React.useEffect(() => {
		if (isOpen) {
			getNotifications(companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ping, isOpen, companyId]);

	const [localReadNotifications, setLocalReadNotifications] = React.useState(new Set<string>());

	const readNotifications = (notificationIds: string[] = []) => {
		setLocalReadNotifications(set => new Set([...set, ...notificationIds]));
		setNotificationsStatuses(companyId, notificationIds, m.Entities.Notifications.NotificationStatusTypes.Read);
	};

	const handleNotificationClick = (notification: NotificationDto) => {
		if (notification.Status !== NotificationStatus.Read) {
			readNotifications([notification.Id]);
		}
		onClose();
	};

	const renderNotificationItem = (notification: NotificationDto) => (
		<NotificationItem
			notification={{
				...notification,
				Status: localReadNotifications.has(notification.Id) ? NotificationStatus.Read : notification.Status,
			}}
			key={notification.Id}
			onClick={() => handleNotificationClick(notification)}
			companyId={companyId}
			isBackOffice={isBackOffice}
		/>
	);

	return (
		<Flyout
			asideClassName={styles.flyoutAside}
			backgroundClassName={styles.flyoutBackground}
			isOpen={isOpen}
			onClickOutside={onClose}
		>
			<div className={styles.header}>
				<div className={!isBackOffice ? styles.closeButtonContainer : ""}>
					<CloseButton onClick={onClose} />
				</div>
				<h3 className={styles.headerText}>{generalLang.NotificationFlyout_Notifications}</h3>
				{companyId && !!isBackOffice && (
					<Link
						className={styles.manage}
						route={getRoute("agencyNotificationSettings", { agencyId: companyId })}
						onClick={onClose}
					>
						{generalLang.Manage}
					</Link>
				)}
				{companyId && !isBackOffice && (
					<Link
						className={styles.manage}
						route={getRoute("notificationSettings", { company: companyId })}
						onClick={onClose}
					>
						{generalLang.Manage}
					</Link>
				)}
			</div>
			<RenderRequest request={getNotificationsRequest} whenLoading={<LoadingContent />} useCacheWhenLoading>
				{data => {
					const unreadCount = data.filter(
						n => n.Status !== NotificationStatus.Read && !localReadNotifications.has(n.Id),
					).length;
					const [recentNotifications, earlierNotifications] = formatNotifications(data);
					return (
						<>
							{unreadCount > 0 && (
								<div className={styles.header}>
									<p className={styles.unreadText}>
										<strong className={styles.regularText}>{unreadCount}</strong>{" "}
										{unreadCount === 1
											? generalLang.NotificationFlyout_UnreadNotifications_S
											: generalLang.NotificationFlyout_UnreadNotifications_P}
									</p>
									<Link
										onClick={() =>
											readNotifications(data.filter(n => n.Status !== NotificationStatus.Read).map(n => n.Id))
										}
									>
										{generalLang.NotificationFlyout_MarkAllAsRead}
									</Link>
								</div>
							)}
							{recentNotifications.length > 0 || earlierNotifications.length > 0 ? (
								<div className={styles.scrollable}>
									{recentNotifications.length > 0 && (
										<>
											<div className={styles.sectionHeader}>{generalLang.NotificationFlyout_Recent}</div>
											{recentNotifications.map(renderNotificationItem)}
										</>
									)}
									{earlierNotifications.length > 0 && (
										<>
											<div className={styles.sectionHeader}>{generalLang.NotificationFlyout_Earlier}</div>
											{earlierNotifications.map(renderNotificationItem)}
										</>
									)}
								</div>
							) : (
								<div className={styles.emptyState}>
									<EmptyState>
										<FeedbackGraphic type="noNotifications" />
										<div className={styles.paragraph}>
											<h3>{generalLang.NotificationsEmptyState_Title}</h3>
											<span className={styles.subduedText}>{generalLang.NotificationsEmptyState_Content}</span>
										</div>
									</EmptyState>
								</div>
							)}
						</>
					);
				}}
			</RenderRequest>
		</Flyout>
	);
};
