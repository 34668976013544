import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";
import { createConnectHoc } from "@bokio/utils/createConnectHoc";

import type { LoginsControls } from "@bokio/contexts/TopLevelUserContext/TopLevelUserContext";

export const useLoginsControls = (): LoginsControls => {
	const { loginsControls } = useTopLevelUser();
	return loginsControls;
};

/**
 * @deprecated Use {@link useLoginsControls} instead.
 */
const withLoginsControls = createConnectHoc(() => useLoginsControls());

export default withLoginsControls;
