import * as React from "react";

import { Menu } from "@bokio/components/Menu/Menu";
import { SelectCompany } from "@bokio/components/SelectCompany/SelectCompany";
import Overlay from "@bokio/elements/Overlay/Overlay";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { Entities } from "@bokio/mobile-web-shared/core/model/model";
import withActiveOrganisationInfo, {
	ActiveOrganisationType,
} from "@bokio/shared/containers/withActiveOrganisationInfo";
import { mergeClassNames } from "@bokio/utils/classes";

import MenuCurrentUserMobileShow from "../Menu/components/MenuCurrentUser/MenuCurrentUserMobileShow";

import type { MenuSection } from "../Menu/utils/MenuHelper";
import type { MenuHeaderProps } from "@bokio/components/Menu/Menu";
import type { ActiveOrganisationProps } from "@bokio/shared/containers/withActiveOrganisationInfo";

import * as styles from "./sidebar.scss";

export interface SidebarProps {
	expanded?: boolean;
	children?: React.ReactNode;
	onToggle: () => void;
	onClose: () => void;
	sections: MenuSection[];
	showBookkeepingButton: boolean;
	header?: MenuHeaderProps;
	isAgency?: boolean;
}

interface SidebarState {
	showCompanySelector: boolean;
	showUserSettingsMobile: boolean;
}

const Sidebar = withActiveOrganisationInfo((props: SidebarProps & ActiveOrganisationProps) => {
	const { companyInfo } = useCompanyInfo();
	const isTestCompany =
		props.activeOrganisationType === ActiveOrganisationType.Company
			? companyInfo?.CompanySystem === Entities.CompanySystem.TestCompany
			: false;

	const [state, setState] = React.useState<SidebarState>({
		showCompanySelector: false,
		showUserSettingsMobile: false,
	});

	const showMobile = () => setState(s => ({ ...s, showUserSettingsMobile: true }));
	const hideMobile = () => setState(s => ({ ...s, showUserSettingsMobile: false }));
	const showCompanySelector = () => setState(s => ({ ...s, showCompanySelector: true }));
	const close = () => setState(s => ({ ...s, showCompanySelector: false }));

	return (
		<React.Fragment>
			<div onClick={props.onToggle} className={props.expanded ? styles.sidebarMaskVisible : styles.sidebarMask} />
			<div className={styles.sidebarWrapper}>
				<div
					className={mergeClassNames(
						props.expanded ? styles.sidebarExpanded : styles.sidebar,
						isTestCompany && styles.sidebarBottomPadding,
					)}
				>
					<Menu
						showUserSettingsMobile={showMobile}
						showCompanySelector={showCompanySelector}
						closeMenu={props.onClose}
						sections={props.sections}
						showBookkeepingButton={props.showBookkeepingButton}
						header={props.header}
						isAgency={props.isAgency}
					/>
				</div>
				{state.showUserSettingsMobile && <MenuCurrentUserMobileShow onCloseMenu={hideMobile} />}
				{props.organisationId && (
					<Overlay isVisible={state.showCompanySelector}>
						<SelectCompany currentOrganisationId={props.organisationId} onChange={close} />
					</Overlay>
				)}
			</div>
		</React.Fragment>
	);
});

export default Sidebar;
