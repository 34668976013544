import { BackOfficeLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import { ToggleListItem } from "../ToggleListItem/ToggleListItem";

import type { BackOfficeLang } from "@bokio/lang/types/BackOfficeLang";

import PartnerMembershipType = m.Entities.PartnerMembershipType;
export interface PermissionType {
	key: PartnerMembershipType;
}

export interface Permissions {
	ManageUsers: boolean;
	PartnerSettings: boolean;
	ClosureSupport: boolean;
	BookkeepingSupport: boolean;
	ManageCompanies: boolean;
	SelfAssign: boolean;
	ManageQuoteRequests: boolean;
}

export const permissionTypes = (isAgency: boolean, isPartnerAgency?: boolean): PermissionType[] => [
	{ key: PartnerMembershipType.ManageUsers },
	{ key: PartnerMembershipType.PartnerSettings },
	...(isAgency ? [] : [{ key: PartnerMembershipType.ClosureSupport }]),
	...(isAgency ? [] : [{ key: PartnerMembershipType.BookkeepingSupport }]),
	{ key: PartnerMembershipType.ManageCompanies },
	{ key: PartnerMembershipType.SelfAssign },
	...(isPartnerAgency ? [{ key: PartnerMembershipType.ManageQuoteRequests }] : []),
];

/**
 * Translates an array of permission toggles (on/off) to a permission string, i.e "ManageUsers, ClosureSupport";
 */
export const toMembershipType = (permissions: Permissions) => {
	const selectedMemberships = Object.keys(permissions).filter(p => permissions[p]);
	const membershipsEncoded = [PartnerMembershipType.Viewer, ...selectedMemberships].join(", ");

	// NE 2019-06-11
	// The type assertion here is needed because our proxy does not
	// handle these flag-ish enums.
	// The endpoint expects a list of comma separated enum values.
	// But the parameter is typed as a single enum value.
	return membershipsEncoded as PartnerMembershipType;
};

export interface PermissionsInputProps {
	permissions: Permissions;
	isAgency: boolean;
	isAgencyOwner?: boolean;
	isPartnerAgency?: boolean;
	onChange: (permissions: Permissions) => void;
}

export const getPermissionTypeLangEntry = (backOfficeLang: BackOfficeLang, key: PartnerMembershipType) => ({
	title: backOfficeLang[`Permission_${key}_Title`] || "",
	description: backOfficeLang[`Permission_${key}_Description`] || "",
});

export const PermissionsInput = ({
	permissions,
	isAgency,
	onChange,
	isAgencyOwner,
	isPartnerAgency,
}: PermissionsInputProps) => {
	const backOfficeLang = BackOfficeLangFactory();
	return (
		<div>
			{permissionTypes(isAgency, isPartnerAgency).map(pt => {
				const lang = getPermissionTypeLangEntry(backOfficeLang, pt.key);
				const onHandleChange = (permissions: Permissions, key: PartnerMembershipType) => {
					const newPermissions: Permissions = { ...permissions, [pt.key]: !permissions[pt.key] };
					// Toggle on Self Assign permissions if also toggling on Manage Companies
					if (key === PartnerMembershipType.ManageCompanies && !permissions[PartnerMembershipType.ManageCompanies]) {
						newPermissions.SelfAssign = true;
					}
					//Toggle on Manage Companies permissions if also toggling on Manage Quote Request
					if (
						key === PartnerMembershipType.ManageQuoteRequests &&
						!permissions[PartnerMembershipType.ManageQuoteRequests]
					) {
						newPermissions.ManageCompanies = true;
						newPermissions.SelfAssign = true;
					}

					onChange(newPermissions);
				};
				return (
					<ToggleListItem
						key={pt.key}
						disabled={
							(pt.key === PartnerMembershipType.ManageUsers && isAgencyOwner) ||
							(pt.key === PartnerMembershipType.SelfAssign && permissions.ManageCompanies) ||
							(pt.key === PartnerMembershipType.ManageCompanies && permissions.ManageQuoteRequests)
						}
						title={lang.title}
						content={lang.description}
						checked={permissions[pt.key]}
						onChange={() => onHandleChange(permissions, pt.key)}
						testId={`PermissionsInput_Toggle_${pt.key}`}
					/>
				);
			})}
		</div>
	);
};
