import { Heading, Paragraph, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { mergeClassNames } from "@bokio/utils/classes";

import { ProgressBar } from "../ProgressBar/ProgressBar";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type * as React from "react";

import * as styles from "./floatingTutorialModal.scss";

interface FloatingTutorialModalProps {
	visible: boolean;
	activeTutorial: m.Bokio.Accounting.Contract.BoookkeepingTutorial.Dtos.ActiveTutorialDto;
}

export const FloatingTutorialModal: React.FC<FloatingTutorialModalProps> = ({ visible, activeTutorial }) => {
	//TODO need to pick current step in some way...probably use the state and save that we have clicked a button or so.
	//Although that means state wont be reset if we for example moved away from the todo page
	const currentStep = activeTutorial.TutorialSteps[0];
	return (
		<div
			className={mergeClassNames(styles.helpMenu, visible && styles.showHelpMenu)}
			data-testid="BS_Lessons_GuideModal"
		>
			<Section>
				<Heading style="caption-default">Lektion 1</Heading>
				<ProgressBar percentage={currentStep.Progress} />
				<div className={styles.heading}>
					<Heading style="heading-default">{currentStep.Title}</Heading>
					<Paragraph style="body-default">{currentStep.Ingress}</Paragraph>
				</div>
				<div className={styles.details}>
					<Section>
						<Heading style="subheading-default">{currentStep.DescriptionTitle}</Heading>
						<Paragraph style="body-default">{currentStep.DescriptionText}</Paragraph>
					</Section>
				</div>
			</Section>
		</div>
	);
};
