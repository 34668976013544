import * as React from "react";

import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { useAgencyStatus, useCompanyUser, useUserMemberships } from "@bokio/shared/state/requests";

export const useAgencyDetails = () => {
	const { companyInfo } = useCompanyInfo();
	const { memberships } = useUserMemberships();
	const companyUser = useCompanyUser();
	const { agencyStatus } = useAgencyStatus();

	const isAccountant = React.useMemo(
		() =>
			companyInfo &&
			!!memberships?.partners.find(p => p.PartnerId === companyInfo.ActiveAgencyStatus?.PartnerId) &&
			!!companyUser.companyUserPermissions?.Partner,
		[companyInfo, companyUser.companyUserPermissions?.Partner, memberships?.partners],
	);

	const agencyId = React.useMemo(
		() =>
			agencyStatus === undefined
				? isAccountant && companyInfo && companyInfo.ActiveAgencyStatus?.PartnerId
				: agencyStatus.Id,
		[agencyStatus, companyInfo, isAccountant],
	);

	const isAgency = React.useMemo(() => (agencyStatus === undefined ? false : true), [agencyStatus]);

	return { agencyId, isAgency, isAccountant };
};
