import * as React from "react";

import * as m from "@bokio/mobile-web-shared/core/model/model";

import {
	defaultContextValue,
	NotificationActivityContext,
	notificationActivityHub,
} from "./NotificationActivityContext";

import type { NotificationActivityState } from "./NotificationActivityContext";

export const NotificationActivityProvider: React.FC<React.PropsWithChildren> = props => {
	const [state, setState] = React.useState<NotificationActivityState>(defaultContextValue);

	const onMessage = React.useCallback((message: m.Core.Services.Notifications.Dtos.NotificationWebActivityDto) => {
		setState(prev => ({
			badgeCount: message.BadgeCount,
			activityCounter: {
				eInvoices:
					prev.activityCounter.eInvoices +
					(message.AreaType === m.Entities.Notifications.NotificationAreaType.IncomingEInvoice ? 1 : 0),
			},
		}));
	}, []);

	React.useEffect(() => {
		notificationActivityHub.add(onMessage);

		return () => {
			notificationActivityHub.remove(onMessage);
		};
	}, [onMessage]);

	return <NotificationActivityContext.Provider value={state}>{props.children}</NotificationActivityContext.Provider>;
};
