import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./table.scss";

export interface THeadProps {
	className?: string;
}

class THead extends React.Component<React.PropsWithChildren<THeadProps>> {
	render() {
		return <thead className={mergeClassNames(this.props.className || "", styles.thead)}>{this.props.children}</thead>;
	}
}

export default THead;
