import * as React from "react";

import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

import { useLazyApi } from "../useApi/useApi";

import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

import AnalyticsEventCategory = m.Bokio.Common.Contract.Metrics.Analytics.Requests.AnalyticsEventCategory;
export { AnalyticsEventCategory };

type UseMetric = [
	(
		Category: AnalyticsEventCategory,
		Action: string,
		Label?: string | undefined,
		Value?: number | undefined,
	) => Promise<m.Envelope<boolean, m.SimpleError>>,
	RequestState<m.Envelope<boolean, m.SimpleError>>,
];

export const useMetric = (): UseMetric => {
	const {
		companyInfo: { Id: companyId },
	} = useCompanyInfo();
	const [execute, request] = useLazyApi(proxy.Bokio.Common.Web.Metrics.MetricsController.SaveAnalytics.Post);

	const addMetric = React.useCallback(
		(Category: AnalyticsEventCategory, Action: string, Label?: string, Value?: number) =>
			execute(companyId, { Category, Action, Label, Value }),
		[companyId, execute],
	);

	return [addMetric, request];
};
