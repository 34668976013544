import { GeneralLangFactory, SalaryLangFactory } from "@bokio/lang";
import { getRoute } from "@bokio/shared/route";
import { getMonthByNumber } from "@bokio/shared/utils/dateUtils";
import { formatDate, formatMessage } from "@bokio/shared/utils/format";

import type { ParentPage } from "@bokio/elements/Page/Page";
import type * as m from "@bokio/mobile-web-shared/core/model/model";

/**
 * @param company The companyId
 * @returns The url for the overview page based on which version of salary the company have
 */
export const getSalaryOverviewRoute: (company: string) => string = company =>
	getRoute("salaryTaxPeriodList", { company: company });

export const getSalaryTaxPeriodRoute: (company: string, year: number, isoMonth: number) => string = (
	company,
	year,
	isoMonth,
) => getRoute("salaryTaxPeriodView", { company, year: year.toString(), month: isoMonth.toString() });

export const getSalaryTaxPeriodTitle: (year: number, isoMonth: number) => string = (year, isoMonth) => {
	const salaryLang = SalaryLangFactory();
	return formatMessage(salaryLang.TaxPeriod_PeriodTitle, `${getMonthByNumber(isoMonth - 1)} ${year}`);
};

export const getSalaryTaxPaymentTitle: (year: number, isoMonth: number) => string = (year, isoMonth) => {
	const salaryLang = SalaryLangFactory();
	return formatMessage(salaryLang.TaxPeriod_TaxPaymentTitle, `${getMonthByNumber(isoMonth - 1)} ${year}`);
};

export const getPayrollTitle: (payDate: m.Day) => string = payDate => {
	const salaryLang = SalaryLangFactory();
	return `${salaryLang.Payroll_Title} ${formatDate(payDate)}`;
};

export class SalaryBreadcrumbs {
	getOverviewBreadcrumb(company: string): ParentPage {
		const lang = GeneralLangFactory();
		return {
			title: lang.Menu_SalaryOverview,
			url: getSalaryOverviewRoute(company),
		};
	}

	getMonthBreadcrumb(company: string, year: number, isoMonth: number): ParentPage {
		return {
			title: getSalaryTaxPeriodTitle(year, isoMonth),
			url: getSalaryTaxPeriodRoute(company, year, isoMonth),
		};
	}

	getPayrollBreadcrumb(company: string, independentpayrollid: string, payDate: m.Day): ParentPage {
		return {
			title: getPayrollTitle(payDate),
			url: getRoute("salaryPayrollDetails", { company, paydate: formatDate(payDate), independentpayrollid }),
		};
	}
}
