// extracted by mini-css-extract-plugin
export var notice = "zl";
export var contentGrid = "bGa";
export var content = "U";
export var iconContainer = "Zh";
export var symbol = "N";
export var alignSymbolWithActionButton = "cGa";
export var symbolCircle = "_h";
export var titleRow = "Cl";
export var title = "Dl";
export var colorInfo = "ai";
export var colorWarning = "bi";
export var colorError = "ci";
export var colorSuccess = "di";
export var colorWhite = "ei";
export var colorTips = "Zk";
export var colorGrey = "Bl";
export var colorPromotion = "mva";
export var clickable = "Ol";
export var iconCancel = "Ua";
export var iconCancelBox = "Va";
export var contentAndButtonWrapper = "an";