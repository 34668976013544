import { Article } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Badge } from "@bokio/elements/Badge/Badge";
import PageTitle from "@bokio/elements/PageTitle/PageTitle";
import { mergeClassNames } from "@bokio/utils/classes";

import { LayoutFull } from "../LayoutFull/LayoutFull";

import type { BadgeColor } from "@bokio/elements/Badge/Badge";
import type * as React from "react";

import * as styles from "./singleFormPage.scss";

export interface SingleFormPageProps {
	/**
	 * This is displayed at the top of the box content of the page.
	 * We use it in the sign up flows for indicating the current step.
	 */
	indicator?: React.ReactNode;
	illustration?: React.ReactNode;
	title: string;
	/**
	 * This is usually a short text that is added right below the title.
	 */
	preamble?: React.ReactNode;
	children?: React.ReactNode;
	/**
	 * The epilogue is added below the main page box and is used for extra content in the page.
	 * We use it, for example, for displaying which country the user is logging in to.
	 */
	epilogue?: React.ReactNode;
	/**
	 * This is a bar that is appended to the bottom of the page's box, but it's still part of the page's main content.
	 */
	contentFooter?: React.ReactNode;
	className?: string;
	pageTitle?: string;
	status?: { name: string; color: BadgeColor };
	/** Should be set for pages on GuestRoute, will show popup help rather than topbar */
	noTopBarMode: boolean;
}

const SingleFormPage = (props: SingleFormPageProps) => (
	<LayoutFull noTopBarMode={props.noTopBarMode}>
		<PageTitle title={props.pageTitle || props.title} />
		<Article resetHeadingLevelTo={1}>
			<div className={mergeClassNames(styles.signUp, props.className)}>
				<div className={styles.content}>
					{props.indicator && <div className={styles.indicator}>{props.indicator}</div>}
					{props.illustration && <div className={styles.illustration}>{props.illustration}</div>}
					<div className={styles.header}>
						{props.status && <Badge {...props.status} noMargin />}
						<h1 className={props.status && styles.smallerMarginHeader}>{props.title}</h1>
						<div className={styles.preamble}>{props.preamble}</div>
					</div>
					{props.children}
				</div>
				{props.contentFooter && <div className={styles.contentFooter}>{props.contentFooter}</div>}
			</div>
		</Article>
		{styles.epilogue && <div className={styles.epilogue}>{props.epilogue}</div>}
	</LayoutFull>
);

export default SingleFormPage;
