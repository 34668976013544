import * as React from "react";

import { noop } from "@bokio/shared/utils";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export interface PlaidConnectResult {
	result: boolean;
	metaData: m.Bokio.Integration.Plaid.MetadataDto;
}

interface Cleanup {
	(): void;
}

export interface Effect {
	(): Cleanup;
}

export interface PlaidLink {
	connect: () => Promise<PlaidConnectResult>;
	reconnect: (bankId: string) => Promise<void>;
}

export interface PlaidContextHandle {
	isPlaidReady: boolean;
	plaid: PlaidLink | undefined;
}

export const PlaidLinkScriptContext = React.createContext<
	PlaidContextHandle & {
		initialisePlaid: () => void;
	}
>({
	isPlaidReady: false,
	plaid: undefined,
	initialisePlaid: noop,
});
