// ME: Because nano-memoize has commonJs modules and that doesn't play well with ES6 and esbuild in particular we wrap that function to contain the mess.
// ME: Types taken from https://github.com/anywhichway/nano-memoize/issues/8

// SS: We are making exception to use require() due to the module itself doesn't handle `import` well in Jest,
// although we should be moving away from require() for future bundler updates.
// We have the same issue in math-expression-evaluator@2.0.2
const nanomemoize = require("nano-memoize"); // eslint-disable-line -- SS 2024-03-12 Require statement kept to make Jest and Webpack work

// eslint-disable-next-line
export default function memoize<T extends Function>(
	func: T,
	options?: {
		/**
		 * Only use the provided maxArgs for cache look-up, useful for ignoring final callback arguments
		 */
		maxArgs?: number;
		/**
		 * Number of milliseconds to cache a result, set to `Infinity` to never create timers or expire
		 */
		maxAge?: number;
		/**
		 * The serializer/key generator to use for single argument functions (optional, not recommended)
		 * must be able to serialize objects and functions, by default a WeakMap is used internally without serializing
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		serializer?: (...args: any[]) => any;
		/**
		 * the equals function to use for multi-argument functions (optional, try to avoid) e.g. deepEquals for objects
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		equals?: (...args: any[]) => boolean;
		/**
		 * Forces the use of multi-argument paradigm, auto set if function has a spread argument or uses `arguments` in its body.
		 */
		vargs?: boolean;
	},
): T {
	return nanomemoize(func, options);
}
