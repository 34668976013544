import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./toggleSwitch.scss";

interface ToggleSwitchProps {
	checked: boolean;
	onChange: (value: boolean) => void;
	size?: "medium" | "small";
	className?: string;
	testId?: string;
	disabled?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
	size = "small",
	checked,
	onChange,
	className,
	testId,
	disabled,
}) => {
	const labelClass = mergeClassNames(
		styles.label,
		checked && styles.active,
		disabled && styles.disabled,
		size && styles[size],
	);

	const handleChange = () => {
		onChange(!checked);
	};

	return (
		<React.Fragment>
			<label className={mergeClassNames(labelClass, className)} data-testid={testId}>
				<input type="checkbox" className={styles.input} onChange={handleChange} checked={checked} disabled={disabled} />
				{checked ? (
					<span className={styles.labelYes}>
						<Icon name="check" size={size === "small" ? "18" : "24"} />
					</span>
				) : (
					<span className={styles.labelNo}>
						<Icon name="cancel" size={size === "small" ? "18" : "24"} />
					</span>
				)}
			</label>
		</React.Fragment>
	);
};

export default ToggleSwitch;
