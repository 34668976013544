import * as React from "react";

class KeyboardListener extends React.Component<{ onEscape: () => void; children: JSX.Element }> {
	componentDidMount() {
		window.addEventListener("keydown", this.onKeyPress);
	}
	componentWillUnmount() {
		window.removeEventListener("keydown", this.onKeyPress);
	}

	onKeyPress = (e: KeyboardEvent) => {
		if (e.keyCode === 27) {
			this.props.onEscape();
		}
	};

	render() {
		return this.props.children;
	}
}

export default KeyboardListener;
