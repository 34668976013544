// extracted by mini-css-extract-plugin
export var link = "LO";
export var dropdown = "MO";
export var loggedInAs = "OO";
export var name = "PO";
export var email = "QO";
export var userSettings = "SO";
export var linkItem = "TO";
export var logout = "uP";
export var logoutMobile = "ZQ";
export var showUserSettings = "sS";
export var userSettingsMobile = "vS";
export var mobileNavWrapper = "AS";
export var closeButton = "PX";
export var backButton = "OX";
export var label = "Xfa";