import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./table.scss";

export interface TFootProps {
	className?: string;
}

class TFoot extends React.Component<React.PropsWithChildren<TFootProps>> {
	render() {
		return <tfoot className={mergeClassNames(this.props.className, styles.tfoot)}>{this.props.children}</tfoot>;
	}
}

export default TFoot;
