// extracted by mini-css-extract-plugin
export var modal = "F8a";
export var closeWrapper = "G8a";
export var closeButton = "H8a";
export var content = "I8a";
export var contentExtraWide = "J8a";
export var contentWide = "K8a";
export var contentMedium = "L8a";
export var contentSmall = "M8a";
export var closeIcon = "N8a";
export var imageContainer = "O8a";
export var mediumImage = "P8a";
export var largeImage = "Q8a";
export var burgundyBackground = "R8a";
export var blueBackground = "S8a";
export var greenBackground = "T8a";
export var whiteBackground = "U8a";