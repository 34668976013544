// extracted by mini-css-extract-plugin
export var column = "Wn";
export var row = "Xn";
export var noSpacing = "Yn";
export var smallColumn = "Zn";
export var smallRow = "_n";
export var mediumColumn = "ao";
export var dividers = "bo";
export var mediumRow = "co";
export var largeColumn = "do";
export var largeRow = "eo";
export var justifyFlexStart = "We";
export var justifyFlexEnd = "Xe";
export var justifyCenter = "Ye";
export var hidden = "fo";