import { mergeClassNames } from "@bokio/utils/classes";

import Icon from "../Icon/Icon";

import type { FontelloIcons } from "@bokio/assets/fontello";
import type * as React from "react";

import * as styles from "./badge.scss";

export type BadgeColor =
	| "active"
	| "activeDark"
	| "draft"
	| "credit"
	| "rejected"
	| "errorLight"
	| "overdue"
	| "attention"
	| "amend"
	| "complete"
	| "archived"
	| "locked"
	| "ghost"
	| "campaign"
	| "base";

export type BadgeProps = {
	color: BadgeColor;
	name: React.ReactNode;
	/* 2023-05-22 PP
		Title here will only add something when you hover over the badge and
		just setting the name is useful for most usecases.
	*/
	title?: string;
	className?: string;
	noMargin?: boolean;
	testId?: string;
	iconTestId?: string;
	stopPropagation?: boolean;
	onClick?: () => void;
	iconOnClick?: () => void;
} & ({ icon: FontelloIcons; iconPosition: "left" | "right" } | { icon?: undefined; iconPosition?: undefined });

export const Badge = ({
	color,
	name,
	title,
	className,
	noMargin,
	onClick,
	iconOnClick,
	testId,
	iconTestId,
	stopPropagation,
	icon,
	iconPosition,
}: BadgeProps) => {
	const classNames = mergeClassNames(
		styles.status,
		styles[color],
		className,
		onClick && styles.clickable,
		noMargin && styles.noMargin,
	);

	const renderIcon = (iconName?: FontelloIcons) => {
		return (
			iconName && (
				<span
					className={mergeClassNames(
						name == null || name == "" ? undefined : iconPosition === "left" ? styles.leftIcon : styles.rightIcon,
						iconOnClick ? styles.clickableIcon : "",
					)}
					onClick={iconOnClick}
					data-testid={iconTestId}
				>
					<Icon name={iconName} noMargin size="16" />
				</span>
			)
		);
	};

	const handleClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		if (onClick) {
			stopPropagation && e.stopPropagation();
			onClick();
		}
	};

	return (
		<span className={classNames} title={title} data-testid={testId} onClick={handleClick}>
			{iconPosition === "left" && renderIcon(icon)}
			{name}
			{iconPosition === "right" && renderIcon(icon)}
		</span>
	);
};
