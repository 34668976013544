import bronzeLogo from "@bokio/assets/images/backoffice/bronze-tier.svg";
import goldLogo from "@bokio/assets/images/backoffice/gold-tier.svg";
import silverLogo from "@bokio/assets/images/backoffice/silver-tier.svg";
import { BackOfficeLangFactory } from "@bokio/lang";

import type { GeneralLang } from "@bokio/lang/types/GeneralLang";

export const getTiersInfo = (tiersNumber: number | undefined, generalLang: GeneralLang) => {
	const lang = BackOfficeLangFactory();

	if (tiersNumber != undefined) {
		switch (true) {
			case tiersNumber < 10:
				return {
					image: bronzeLogo,
					label: generalLang.LoyaltyProgram_BronzeTier,
					tier: lang.AgencyPartnerTier_Bronze,
					clientsLeft: 10 - tiersNumber,
					nextTier: generalLang.LoyaltyProgram_SilverTier,
					next: lang.AgencyPartnerTier_Silver,
					nextTierLogo: silverLogo,
				};
			case tiersNumber < 50:
				return {
					image: silverLogo,
					label: generalLang.LoyaltyProgram_SilverTier,
					tier: lang.AgencyPartnerTier_Silver,
					clientsLeft: 50 - tiersNumber,
					nextTier: generalLang.LoyaltyProgram_GoldTier,
					next: lang.AgencyPartnerTier_Gold,
					nextTierLogo: goldLogo,
				};
			case tiersNumber >= 50:
				return { image: goldLogo, label: generalLang.LoyaltyProgram_GoldTier, tier: lang.AgencyPartnerTier_Gold };
			default:
				return null;
		}
	}
	return null;
};
