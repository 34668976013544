import * as React from "react";
import { Key } from "w3c-keys";

type KeyEvent = React.KeyboardEvent<HTMLElement>;

type Handler<T> = (e: KeyEvent, options: T[], focusedIndex: number) => void;

export type CustomKeyboardBinding<T> = [Key, Handler<T>];

const pageSize = 10;

export function useAccessibleKeyboardBinding<T>(options: T[] = [], bindings: CustomKeyboardBinding<T>[] = []) {
	const [focusedIndex, setFocusedIndex] = React.useState(-1);
	const resetFocusedIndex = () => setFocusedIndex(-1);

	React.useEffect(() => () => resetFocusedIndex(), []);

	const keyMap = new Map<Key, Handler<T>>();
	const preventDefaultMap = new Map<string, boolean>();

	keyMap.set(Key.ArrowUp, () => setFocusedIndex(i => (i <= 0 ? options.length - 1 : i - 1)));
	keyMap.set(Key.ArrowDown, () => setFocusedIndex(i => (i + 1) % options.length));

	keyMap.set(Key.PageUp, () =>
		setFocusedIndex(i => (i - pageSize > 0 ? i - pageSize : options.length - (i + pageSize))),
	);
	keyMap.set(Key.PageDown, () => setFocusedIndex(i => (i + pageSize) % options.length));

	keyMap.set(Key.Home, () => setFocusedIndex(0));
	keyMap.set(Key.End, () => setFocusedIndex(options.length - 1));

	keyMap.forEach((_, key) => preventDefaultMap.set(key, true));

	bindings.forEach(([key, customHandler]) => {
		if (keyMap.has(key)) {
			const defaultHandler = keyMap.get(key);

			// This check may be removed in the future. See https://github.com/microsoft/TypeScript/issues/13086
			if (!defaultHandler) {
				return;
			}

			const addonHandler: Handler<T> = (e: KeyEvent, options: T[], focusedIndex: number) => {
				defaultHandler(e, options, focusedIndex);
				customHandler(e, options, focusedIndex);
			};

			keyMap.set(key, addonHandler);
		} else {
			keyMap.set(key, customHandler);
		}
	});

	const handleKeyDown = (e: KeyEvent) => {
		const handler = keyMap.get(e.key as Key);
		if (handler) {
			const shouldPreventDefault = preventDefaultMap.get(e.key as Key);
			if (shouldPreventDefault) {
				e.preventDefault();
			}
			handler(e, options, focusedIndex);
		}
	};

	return { handleKeyDown, focusedIndex, resetFocusedIndex };
}
