export interface Fetch {
	(input: RequestInfo, options: FetchOptions): Promise<Response>;
}

export interface FetchOptions {
	method: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	body?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	headers?: any;
	mode?: "cors" | "no-cors";
	credentials?: "include";
	cache?: "no-store";
}

interface AppMiddleware {
	fetch: Fetch;
}

/**
 * NE 2019-09-25
 * The app mutates this middleware with a new fetch function
 * to intercept requests and reponses to/from the backend.
 **/

export const appMiddleware: AppMiddleware = {
	fetch: window.fetch.bind(window),
};
