import * as React from "react";

import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { useFeatureAvailability } from "@bokio/hooks/useFeatureAvailability/useFeatureAvailability";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useCompanyUser } from "@bokio/shared/state/requests";

import { MenuCurrentCompany } from "../Menu/components/MenuCurrentCompany/MenuCurrentCompany";
import LanguageSwitcherModal from "../Menu/components/MenuCurrentUser/LanguageSwitcherModal";
import MenuCurrentUser from "../Menu/components/MenuCurrentUser/MenuCurrentUser";
import { MenuHelpDropDown } from "../Menu/components/MenuHelpDropdown/MenuHelpDropDown";
import { MenuAccountantTopBarButton } from "../MenuAccountantTopBarButton/MenuAccountantTopBarButton";
import { MenuTopBarNotificationButton } from "../MenuTopBarNotificationButton/MenuTopBarNotificationButton";
import { BbaTopBarButton } from "./components/BbaTopBarButton/BbaTopBarButton";

import * as styles from "./menuTopBar.scss";

interface MenuTopBarProps {
	userMode: boolean;
	backOfficeMode: boolean;
}

export const MenuTopBar: React.FC<MenuTopBarProps> = ({ userMode, backOfficeMode }) => {
	const { isMobile } = useDeviceQuery();
	const featureAvailability = useFeatureAvailability();
	const { companyInfo } = useCompanyInfo();
	const [showLangSwitcher, setShowLangSwitcher] = React.useState(false);
	const { memberships } = useTopLevelUser();
	const companyUser = useCompanyUser();
	const openLangSwitcher = () => setShowLangSwitcher(true);
	const closeLangSwitcher = () => setShowLangSwitcher(false);

	const isAccountant =
		companyInfo &&
		!!memberships?.partners.find(p => p.PartnerId === companyInfo.ActiveAgencyStatus?.PartnerId) &&
		!!companyUser.companyUserPermissions?.Partner;

	const freeUser = companyInfo && companyInfo.Plan === m.Entities.BokioPlan.Free;

	const blockedDueToFailedPayment =
		companyInfo &&
		companyInfo.BillingPaymentState === m.Entities.BillingSubscriptionPaymentState.BlockedDueToMissingPayment;

	return (
		<>
			{!isMobile && !userMode && <MenuCurrentCompany />}
			<div className={styles.leftActions}>
				{featureAvailability.BokioBusinessAccount && !userMode && !blockedDueToFailedPayment && (
					<BbaTopBarButton backOfficeMode={backOfficeMode} />
				)}
				{!userMode && !freeUser && !blockedDueToFailedPayment && (
					<MenuTopBarNotificationButton
						isAccountantPanelIconVisible={!userMode && !!isAccountant}
						backOfficeMode={backOfficeMode}
					/>
				)}
				{!userMode && !!isAccountant && <MenuAccountantTopBarButton backOfficeMode={backOfficeMode} />}
				<MenuHelpDropDown />
				{(!isMobile || userMode) && <MenuCurrentUser openLangModal={openLangSwitcher} />}
			</div>
			<LanguageSwitcherModal visible={showLangSwitcher} onClose={closeLangSwitcher} />
		</>
	);
};
