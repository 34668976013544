// extracted by mini-css-extract-plugin
export var zoomin = "__counterBadge_scss__zoomin";
export var root = "M7a";
export var border = "N7a";
export var colorHighlight = "O7a";
export var colorSubdued = "P7a";
export var colorError = "_2cbUR1x-85z45SbwPloqry";
export var colorOk = "_9e2JfR6-W0OFXcQY5g_7m";
export var sizeNormal = "Q7a";
export var sizeSmall = "R7a";
export var sizeXsmall = "S7a";
export var isAnimating = "T7a";
export var __counterBadge_scss__zoomin = "U7a";