import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import Field from "./Field/Field";
import { FormContext } from "./Form";
import LabelFor from "./LabelFor/LabelFor";

import type { FormContextValue } from "./Form";
import type { LabelBadge, LabelTooltip } from "./LabelFor/LabelFor";
import type { RuleValidationResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./form.scss";

type InputElementAttributes = React.InputHTMLAttributes<HTMLInputElement>;

export type InputFieldPropsBase<T> = {
	/**
	 * Formats the input field value on blur.
	 */
	formatter?: (value: string) => string;
	/**
	 * Prepend text inside the input field. Useful for adding e.g. currency symbol.
	 *
	 * Known implementation issue: Doesn't work on long prefix.
	 */
	prefix?: string;
	/**
	 * Append text inside the input field. Useful for adding e.g. counting unit.
	 *
	 * Known implementation issue: Doesn't work with tooltip.
	 */
	postfix?: string;
	placeholder?: string;
	/**
	 * Append a tooltip icon next to input field.
	 */
	tooltip?: LabelTooltip;
	/**
	 * Visually blur the input value to hide sensitive info
	 * until the user focuses into the input field.
	 */
	blur?: boolean;

	/**
	 * Validation errors from validators using `FieldRuleFactory`.
	 */
	errors?: { errors: RuleValidationResult[] };
	/**
	 * Render a node that could be absolutely positioned in relation to the actual <input>.
	 */
	inlineControls?: (props: { disabled: boolean | undefined; value: T }) => React.ReactNode;

	label?: React.ReactNode;
	labelTooltip?: LabelTooltip;
	hint?: React.ReactNode;
	info?: React.ReactNode;

	onChange?: (val: T) => void;
	onFocus?: (e: React.FormEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
	onClick?: (e: React.FormEvent<HTMLInputElement>) => void;

	testId?: string;
	labelTestId?: string;

	/**
	 * Set class name to the actual input element.
	 */
	className?: string;
	/**
	 * Set class name to the {@link LabelFor} component that is wrapping the actual input element.
	 */
	labelClassName?: string;
	/**
	 * Set class name to the outermost {@link Field} component thatis wrapping {@link LabelFor}.
	 */
	wrapperClassName?: string;

	/**
	 * Attach ref to the actual input element.
	 */
	innerRef?: React.MutableRefObject<HTMLInputElement | null>;
	// SS 2022-03-01
	// The T here should extend from string instead.
	value: T;
	disabled?: boolean;
	mandatory?: boolean;
	readOnly?: boolean;
	maxLength?: number;
	autoFocus?: boolean;
	// JR 2018-10-24:
	// some password managers aggressively autofill input fields based on the name attribute,
	// since we don't post with forms we should only pass a name attr when we want to allow autofill
	nameForAutofill?: string;
	inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";
	// SS 2020-11-24:
	// This list is not exhaustive, please refer to MDN and add new ones yourself if anything is missing https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
	autoComplete?:
		| "off"
		| "name"
		| "given-name"
		| "family-name"
		| "email"
		| "username"
		| "new-password"
		| "current-password"
		| "one-time-code"
		| "organization"
		| "address-line1"
		| "address-line2"
		| "country-name"
		| "postal-code"
		| "tel"
		| "url";
	/**
	 * Additional props to the actual input element.
	 */
	fieldProps?: InputElementAttributes;
	badge?: LabelBadge | LabelBadge[];
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

// SS 2022-03-01
// The usage of "any" here is not good,
// but the issue is outside of InputField component,
// because this type is being shared by non-<input> based components (e.g. SelectField, Checkbox...etc),
// and those components shouldn't share the attributes of <input>.
// This is kept as is because it takes some time to fix those areas.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InputFieldProps = InputFieldPropsBase<any>;

interface InputFieldState {
	hasEdited: boolean;
	message?: string;
}

export class InputField extends React.Component<InputFieldProps, InputFieldState> {
	state: InputFieldState = {
		hasEdited: false,
	};

	static contextType = FormContext;
	declare context: React.ContextType<React.Context<FormContextValue>>;

	onBlur(e: React.FormEvent<HTMLInputElement>) {
		if (this.props.formatter) {
			const value = e.currentTarget.value;
			const formatted = this.props.formatter(value);

			if (value !== formatted) {
				e.currentTarget.value = formatted;
				this.setValue(formatted);
			}
		}
		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	}

	onFocus(e: React.FormEvent<HTMLInputElement>) {
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	}

	onClick(e: React.FormEvent<HTMLInputElement>) {
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}

	/**
	 * Run validation and send the results and new value to the onChange listener
	 */
	setValue(value: string) {
		this.setState({ hasEdited: true });

		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

	render() {
		const { disableAllFields } = this.context;
		const {
			inlineControls,
			value,
			disabled,
			className,
			label,
			hint,
			errors,
			labelClassName,
			placeholder,
			info,
			tooltip,
			labelTooltip,
			postfix,
			testId,
			fieldProps,
			nameForAutofill,
			labelTestId,
			inputMode,
			readOnly,
			maxLength,
			blur,
			prefix,
			innerRef,
			badge,
			mandatory = false,
		} = this.props;
		const inputValue = typeof this.props.value !== "undefined" ? this.props.value : "";

		const inputElement = (
			<input
				{...fieldProps}
				value={inputValue}
				type={this.getInputType()}
				onChange={e => this.setValue(e.currentTarget.value)}
				onBlur={e => this.onBlur(e)}
				onFocus={e => this.onFocus(e)}
				onClick={e => this.onClick(e)}
				className={mergeClassNames(
					styles.field__input,
					className,
					postfix && styles.inputPostfix,
					blur && styles.blurClass,
					prefix && styles.inputPrefix,
				)}
				ref={innerRef}
				autoFocus={this.props.autoFocus}
				placeholder={placeholder}
				autoComplete={this.props.autoComplete ?? (nameForAutofill === undefined ? "off" : undefined)}
				name={nameForAutofill}
				disabled={disableAllFields || disabled}
				data-testid={testId}
				inputMode={inputMode}
				readOnly={readOnly}
				maxLength={maxLength}
				onKeyDown={this.props.onKeyDown}
			/>
		);

		const inputContent: React.ReactNode = inlineControls ? (
			<div style={{ position: "relative" }}>
				{inputElement}
				{inlineControls && inlineControls({ disabled, value })}
			</div>
		) : (
			inputElement
		);

		return (
			<Field className={this.props.wrapperClassName}>
				<LabelFor
					label={label}
					hint={hint}
					info={info}
					errors={errors && errors.errors}
					className={labelClassName}
					testId={labelTestId}
					tooltip={tooltip}
					labelTooltip={labelTooltip}
					badge={badge}
					mandatory={mandatory}
				>
					{
						prefix && label && (
							<span className={styles.prefix}>{prefix}</span>
						) /* Used for currencies with prefix symbols (£ and $ among others)*/
					}
					{prefix && !label && <span className={styles.prefixWithoutLabel}>{prefix}</span>}
					{inputContent}
					{postfix && label && <span className={styles.postfix}>{postfix}</span>}
					{postfix && !label && <span className={styles.postfixWithoutLabel}>{postfix}</span>}
				</LabelFor>
			</Field>
		);
	}

	getInputType() {
		const { fieldProps } = this.props;
		return fieldProps && fieldProps.type ? fieldProps.type : "text";
	}
}
