import * as React from "react";

import { noop } from "@bokio/shared/utils";

/**
 * Determine if we are in a browser
 * or a server environment
 * @type {Boolean}
 * @private
 */
const IS_BROWSER = (window === undefined ? "undefined" : typeof window) === "object";

/**
 * Default element to listen for events on
 * @type {HTMLElement}
 * @private
 */
const DEFAULT_ELEMENT = document;

/**
 * The default events to determine activity
 * @type {string[]}
 * @private
 */
const DEFAULT_EVENTS = [
	"mousemove",
	"keydown",
	"wheel",
	"DOMMouseScroll",
	"mouseWheel",
	"mousedown",
	"touchstart",
	"touchmove",
	"MSPointerDown",
	"MSPointerMove",
];

/**
 * Type checks for every property
 * @type {Object}
 * @private
 */
interface IdleTimerProps {
	/**
	 * Activity Timeout in milliseconds
	 * default: 1200000
	 * @type {Number}
	 */
	timeout: number;
	/**
	 * DOM events to listen to
	 * default: see [default events](https://github.com/SupremeTechnopriest/react-idle-timer#default-events)
	 * @type {Array}
	 */
	events?: string[];
	/**
	 * Function to call when user is idle
	 * default: () => {}
	 * @type {Function}
	 */
	onIdle?: (e: Event) => void;
	/**
	 * Function to call when user becomes active
	 * default: () => {}
	 * @type {Function}
	 */
	onActive?: (e: Event) => void;
	/**
	 * Element reference to bind activity listeners to
	 * default: document
	 * @type {Object}
	 */
	element?: HTMLElement;
	/**
	 * Start the timer on mount
	 * default: true
	 * @type {Boolean}
	 */
	startOnMount?: boolean;
	/**
	 * Bind events passively
	 * default: true
	 * @type {Boolean}
	 */
	passive?: boolean;
	/**
	 * Capture events
	 * default: true
	 * @type {Boolean}
	 */
	capture?: boolean;
}

interface IdleTimerState {
	idle: boolean;
	oldDate: number;
	lastActive: number;
	remaining?: number;
	pageX?: number;
	pageY?: number;
}

/**
 * Detects when your user is idle. This is a copy of the npm package https://github.com/SupremeTechnopriest/react-idle-timer bu ported to TypeScript
 * @class IdleTimer
 * @private
 */
export class IdleTimer extends React.Component<React.PropsWithChildren<IdleTimerProps>, IdleTimerState> {
	/**
	 * Sets default property values
	 * @type {Object}
	 * @private
	 */
	static defaultProps = {
		timeout: 1000 * 60 * 20,
		element: DEFAULT_ELEMENT,
		events: DEFAULT_EVENTS,
		onIdle: noop,
		onActive: noop,
		startOnMount: true,
		capture: true,
		passive: true,
	};

	/**
	 * Sets initial component state
	 * @type {Object}
	 * @private
	 */
	state: IdleTimerState = {
		idle: false,
		oldDate: +new Date(),
		lastActive: +new Date(),
		remaining: undefined,
		pageX: undefined,
		pageY: undefined,
	};

	/**
	 * The timer instance
	 * @type {Timeout}
	 * @private
	 */
	tId: number | undefined;

	/**
	 * Creates an instance of IdleTimer
	 * bind all of our internal events here
	 * for best performance
	 * @param {Object} props
	 * @return {IdleTimer}
	 * @private
	 */
	constructor(props: IdleTimerProps) {
		super(props);
		// If startOnMount is set, idle state defaults to true
		if (!props.startOnMount) {
			this.state.idle = true;
		}
	}

	/**
	 * Runs once the component has mounted
	 * here we handle automatically starting
	 * the idletimer
	 * @private
	 */
	componentDidMount() {
		// Dont bind events if
		// we are not in a browser
		if (!IS_BROWSER) {
			return;
		}
		// Otherwise we bind all the events
		// to the supplied element
		const { element, events, passive, capture } = this.props;
		(events || DEFAULT_EVENTS).forEach((e: string) => {
			(element || DEFAULT_ELEMENT).addEventListener(e, this.handleEvent, {
				capture,
				passive,
			});
		});

		// If startOnMount is enabled
		// start the timer
		const { startOnMount } = this.props;
		if (startOnMount) {
			this.reset();
		}
	}

	/**
	 * Called before the component unmounts
	 * here we clear the timer and remove
	 * all the event listeners
	 * @private
	 */
	componentWillUnmount() {
		// Clear timeout to prevent delayed state changes
		clearTimeout(this.tId);
		// If we are not in a browser
		// we dont need to unbind events
		if (!IS_BROWSER) {
			return;
		}
		// Unbind all events
		const { element, events, capture } = this.props;
		(events || DEFAULT_EVENTS).forEach((e: string) => {
			(element || DEFAULT_ELEMENT).removeEventListener(e, this.handleEvent, {
				capture,
			});
		});
	}

	/**
	 * Render children if IdleTimer is used as a wrapper
	 * @return {Component} children
	 * @private
	 */
	render() {
		const { children } = this.props;
		return children || null;
	}

	/**
	 * Toggles the idle state and calls
	 * the correct action function
	 * @private
	 */
	toggleIdleState = (e: Event) => {
		// Toggle the idle state
		const { idle } = this.state;
		this.setState({
			idle: !idle,
		});

		// Fire the appropriate action
		// and pass the event through
		const { onActive, onIdle } = this.props;
		if (idle) {
			onActive && onActive(e);
		} else {
			onIdle && onIdle(e);
		}
	};

	/**
	 * Event handler for supported event types
	 * @param  {Object} e event object
	 * @private
	 */
	handleEvent = (event: Event) => {
		const e = event as MouseEvent;
		const { remaining, pageX, pageY } = this.state;
		// Already idle, ignore events
		if (remaining) {
			return;
		}

		// Mousemove event
		if (e.type === "mousemove") {
			// If coord are same, it didn't move
			if (e.pageX === pageX && e.pageY === pageY) {
				return;
			}
			// If coord don't exist how could it move
			if (e.pageX === undefined && e.pageY === undefined) {
				return;
			}
			// Under 200 ms is hard to do
			// continuous activity will bypass this
			// TODO: Cant seem to simulate this event with pageX and pageY for testing
			// making this block of code unreachable by test suite
			// opened an issue here https://github.com/Rich-Harris/simulant/issues/19
			const elapsed = this.getElapsedTime();
			if (elapsed < 200) {
				return;
			}
		}

		// Clear any existing timeout
		clearTimeout(this.tId);

		// If the idle timer is enabled, flip
		if (this.state.idle) {
			this.toggleIdleState(e);
		}

		// Store when the user was last active
		// and update the mouse coordinates
		this.setState({
			lastActive: +new Date(), // store when user was last active
			pageX: e.pageX, // update mouse coord
			pageY: e.pageY,
		});

		// Set a new timeout
		const { timeout } = this.props;
		this.tId = window.setTimeout(this.toggleIdleState, timeout); // set a new timeout
	};

	/**
	 * Restore initial state and restart timer
	 * @name reset
	 */
	reset = () => {
		// Clear timeout
		clearTimeout(this.tId);

		// Reset state
		this.setState({
			idle: false,
			oldDate: +new Date(),
			lastActive: this.state.oldDate,
			remaining: undefined,
		});

		// Set new timeout
		const { timeout } = this.props;
		this.tId = window.setTimeout(this.toggleIdleState, timeout);
	};

	/**
	 * Store remaining time and stop timer
	 * @name pause
	 */
	pause = () => {
		// Timer is already paused
		const { remaining } = this.state;
		if (remaining !== null) {
			return;
		}

		// Clear existing timeout
		clearTimeout(this.tId);
		this.tId = undefined;

		// Define how much is left on the timer
		this.setState({
			remaining: this.getRemainingTime(),
		});
	};

	/**
	 * Resumes a paused timer
	 * @name resume
	 */
	resume = () => {
		// Timer is not paused
		const { remaining, idle } = this.state;
		if (remaining === null) {
			return;
		}

		// Start timer and clear remaining
		// if we are in the idle state
		if (!idle) {
			this.setState({ remaining: undefined });
			// Set a new timeout
			this.tId = window.setTimeout(this.toggleIdleState, remaining);
		}
	};

	/**
	 * Time remaining before idle
	 * @name getRemainingTime
	 * @return {Number} Milliseconds remaining
	 */
	getRemainingTime = () => {
		const { remaining, idle, lastActive } = this.state;
		// If idle there is no time remaining
		if (idle) {
			return 0;
		}

		// If timer is in a paused state
		// just return its remaining time
		if (remaining !== null) {
			return remaining;
		}

		// Determine remaining, if negative idle didn't finish flipping, just return 0
		const { timeout } = this.props;
		let timeLeft = timeout - (+new Date() - lastActive);
		if (timeLeft < 0) {
			timeLeft = 0;
		}
		return timeLeft;
	};

	/**
	 * How much time has elapsed
	 * @name getElapsedTime
	 * @return {Timestamp}
	 */
	getElapsedTime = () => {
		const { oldDate } = this.state;
		return +new Date() - oldDate;
	};

	/**
	 * Last time the user was active
	 * @name getLastActiveTime
	 * @return {Timestamp}
	 */
	getLastActiveTime = () => {
		const { lastActive } = this.state;
		return lastActive;
	};

	/**
	 * Returns wether or not the user is idle
	 * @name isIdle
	 * @return {Boolean}
	 */
	isIdle = () => {
		const { idle } = this.state;
		return idle;
	};
}
