import * as React from "react";

import { Link } from "@bokio/elements/Link/Link";

import type { HelpSearchSuggestion } from "@bokio/shared/models/HelpSearchSuggestion";

import * as styles from "./helpStyle.scss";

export interface HelpSearchResultItemProps {
	searchItem: HelpSearchSuggestion;
	id: string;
	onClick: (item: HelpSearchSuggestion) => void;
}

class HelpSearchResultItem extends React.Component<HelpSearchResultItemProps> {
	constructor(props: HelpSearchResultItemProps) {
		super(props);
	}

	render() {
		return (
			<li className={styles.helpResultItem} key={this.props.id}>
				<Link style="none" onClick={() => this.props.onClick(this.props.searchItem)}>
					{this.props.searchItem.Value}
				</Link>
			</li>
		);
	}
}

export default HelpSearchResultItem;
