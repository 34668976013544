import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { Button } from "@bokio/designsystem/components/Button";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { Notice } from "@bokio/elements/Notice/Notice";
import { useUserPreference } from "@bokio/hooks/useUserPreference/useUserPreference";
import { BankLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useActiveBankConnection } from "@bokio/mobile-web-shared/hooks/useActiveBankConnection/useActiveBankConnection";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";

import type * as React from "react";

const SveaKycReminderModalInner: React.FC<{
	companyId: string;
	activeBusinessAccount: m.Core.ActiveBankConnection;
}> = ({ companyId, activeBusinessAccount }) => {
	const router = useRouter();
	const { preferenceValue, setPreference, refreshPreference, getPreferenceRequest, setPreferenceRequest } =
		useUserPreference(companyId, m.Entities.UserPreferenceType.BbaKycModalDismissed);
	const bbaKycPreferenceStoredDate = preferenceValue?.BbaKycModalDismissedPreference?.Value;
	const kycInfo = activeBusinessAccount?.KycInformation;

	if (!kycInfo || !kycInfo.Date) {
		return null;
	}
	const visible =
		!getPreferenceRequest.isLoading &&
		kycInfo.RequiredAction === m.Bokio.Common.Helpers.SveaKycAction.BlockPayments &&
		bbaKycPreferenceStoredDate?.getTime() !== kycInfo.Date.getTime();

	const bankLang = BankLangFactory();

	const setKycPreference = async () => {
		await setPreference({
			isCompanySpecific: true,
			value: {
				BbaKycModalDismissedPreference: {
					// AP 2023-11-24 type narrowing doesn't work for some reason
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					Value: kycInfo.Date!,
				},
			},
		});
	};

	return (
		<Modal
			title={bankLang.SveaKyc_ReminderModal_Title}
			visible={visible}
			onClose={async () => {
				await setKycPreference();
				refreshPreference();
			}}
			disableBackgroundClick
			testId="SveaKycModal"
		>
			<ModalContent>
				<Notice margin={["bottom"]} collapsible={false} title={bankLang.SveaKyc_ReminderModal_NoticeTitle}>
					{bankLang.SveaKyc_ReminderModal_NoticeContent}
				</Notice>
				<Markdown markdownContent={bankLang.SveaKyc_ReminderModal_MainContent} />
			</ModalContent>
			<ModalFooter>
				<Button
					loading={setPreferenceRequest.isLoading}
					onClick={async () => {
						await setKycPreference();
						router.redirect(getRoute("sveaKycScene", { company: companyId }));
					}}
				>
					{bankLang.SveaKyc_ReminderModal_Button}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export const SveaKycReminderModal: React.FC = () => {
	const companyInfoContextValue = useCompanyInfo();
	const bankConnection = useActiveBankConnection();
	if (!bankConnection?.activeBusinessAccount || !companyInfoContextValue?.companyInfo?.Id) {
		return null;
	}
	return (
		<SveaKycReminderModalInner
			companyId={companyInfoContextValue?.companyInfo?.Id}
			activeBusinessAccount={bankConnection?.activeBusinessAccount}
		/>
	);
};
