import * as React from "react";
import { v1 as uuidv1 } from "uuid";

import { Validation } from "@bokio/elements/Form";
import { mergeClassNames } from "@bokio/utils/classes";

import type { FieldValidationResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./checkBox.scss";

export interface CheckBoxProps {
	label?: React.ReactNode;
	id?: string;
	checked?: boolean;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
	className?: string;
	rightMargin?: boolean; // This should be controlled by the parent not here
	errors?: FieldValidationResult;
	testId?: string;
	/**
	 * Partial should be used in cases where we have a group checkbox and some of the children are checked.
	 * "checked" must be true for this to work
	 */
	partial?: boolean;
}

class CheckBox extends React.Component<CheckBoxProps> {
	private uniqueId: string;
	constructor(props: CheckBoxProps) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.uniqueId = props.id || uuidv1();
	}

	onChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (this.props.onChange) {
			this.props.onChange(!this.props.checked);
		}
		e.stopPropagation();
	}

	render() {
		const { label, errors, className } = this.props;

		return (
			<React.Fragment>
				{label ? (
					<label htmlFor={this.uniqueId} className={mergeClassNames(styles.label, className)}>
						{this.renderInput()}
						{label}
						<Validation errors={errors && errors.errors} />
					</label>
				) : (
					this.renderInput(className)
				)}
			</React.Fragment>
		);
	}

	renderInput(className?: string) {
		const { rightMargin, testId, checked, partial, disabled, id } = this.props;

		return (
			<input
				data-testid={testId}
				disabled={disabled}
				type="checkbox"
				onChange={this.onChange}
				className={mergeClassNames(
					styles.checkBox,
					className,
					rightMargin && styles.rightMargin,
					checked && partial && styles.partial,
				)}
				checked={!!checked}
				onClick={e => e.stopPropagation()}
				id={id ?? this.uniqueId}
			/>
		);
	}
}

export default CheckBox;
