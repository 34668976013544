import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./filePreview.scss";

export const APPLICATION_TYPE_PDF = "application/pdf";
export const IMAGE_TYPE = "image/";

export interface FileWithPreview extends File {
	preview: string;
}

interface FilePreviewProps {
	files: FileWithPreview[];
	openPreview: (file: FileWithPreview) => void;
}

interface DocumentOrFilePreviewProps {
	file: FileWithPreview;
}

const DocumentPreview: React.FC<DocumentOrFilePreviewProps> = () => {
	return (
		<div className={styles.previewDocumentOrFileWrapper}>
			<Icon name="file-pdf" size="24" />
		</div>
	);
};

export const OtherFilePreview: React.FC<DocumentOrFilePreviewProps> = () => {
	return (
		<div className={styles.previewDocumentOrFileWrapper}>
			<Icon name="doc-text" size="24" />
		</div>
	);
};

export const FilePreview: React.FC<FilePreviewProps> = ({ files, openPreview }) => {
	return (
		<ul className={styles.previewList}>
			{files.map(file => (
				<li key={file.preview} className={styles.previewItem}>
					<div
						className={styles.previewOverlay}
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							openPreview(file);
						}}
					>
						<Icon name="search" size="14" />
					</div>
					{file.type === APPLICATION_TYPE_PDF ? (
						<DocumentPreview file={file} />
					) : file.type.includes(IMAGE_TYPE) ? (
						<img className={styles.previewImage} src={file.preview} />
					) : (
						<OtherFilePreview file={file} />
					)}
				</li>
			))}
			<li className={mergeClassNames(styles.previewItem, styles.addFile)}>
				<Icon name="plus" size="20" color="grey" />
			</li>
		</ul>
	);
};
