import classes from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./style.scss";

type direction = "row" | "column";

interface GridProps {
	small?: direction;
	medium?: direction;
	large?: direction;
	children?: React.ReactNode;
	dividers?: boolean;
	noColumnSpacing?: boolean;
	width?: string;
	justify?: "flex-start" | "flex-end" | "center";
	hide?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

export const Grid = (props: GridProps) => {
	let classNames = classes(styles, "row", {
		smallRow: props.small === "row",
		smallColumn: props.small === "column",
		mediumRow: props.medium === "row",
		mediumColumn: props.medium === "column",
		largeRow: props.large === "row",
		largeColumn: props.large === "column",
		dividers: props.dividers,
		noSpacing: props.noColumnSpacing,
		hidden: props.hide,
		justifyFlexStart: props.justify === "flex-start",
		justifyFlexEnd: props.justify === "flex-end",
		justifyCenter: props.justify === "center",
	});

	if (props.className) {
		classNames += ` ${props.className}`;
	}
	return (
		<div className={classNames} style={props.style}>
			{props.children}
		</div>
	);
};
