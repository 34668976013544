import * as React from "react";

import { GeneralLangFactory, SignUpLangFactory } from "@bokio/lang";
import { noop } from "@bokio/shared/utils";

import { LoginForm } from "../LoginForm";
import { Modal } from "../Modal";

import * as styles from "./authenticationChecker.scss";

interface LoginModalProps {
	visible: boolean;
	idleLoggedOut: boolean;
	handleLoginSuccess: () => Promise<void>;
}

class LoginModal extends React.Component<LoginModalProps> {
	render() {
		const lang = GeneralLangFactory();
		const signUpLang = SignUpLangFactory();
		return (
			<Modal
				alwaysMounted={true}
				visible={this.props.visible}
				disableBackgroundClick={true}
				onClose={noop}
				disabled={true}
				noHeader={true}
			>
				<div className={styles.login}>
					<div className={styles.header}>
						<h1>{lang.LogIn}</h1>
						{this.props.idleLoggedOut && <p>{signUpLang.Idle_LoginAgainText}</p>}
						{!this.props.idleLoggedOut && <p>{signUpLang.Verify_LoginAgainText}</p>}
					</div>
					<LoginForm loginSuccess={() => this.props.handleLoginSuccess()} />
				</div>
			</Modal>
		);
	}
}
export default LoginModal;
