// extracted by mini-css-extract-plugin
export var sectionHeader = "SUa";
export var mainTableWrapper = "UUa";
export var bankIdLoading = "VUa";
export var margin0 = "WUa";
export var invisibleWidthIconPlaceholder = "XUa";
export var invisibleWidthPlaceholder = "YUa XUa";
export var invisbleBorderLeft = "ZUa";
export var total = "cVa nVa";
export var totalDescription = "dVa";
export var sum = "eVa nVa";
export var bold = "fVa";
export var submitButton = "gVa";
export var row = "iVa";
export var rowTitle = "jVa";
export var rowDesc = "kVa";
export var sumTr = "lVa";
export var thead = "mVa";
export var td = "nVa";
export var emptyView = "oVa";