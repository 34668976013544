import * as React from "react";

interface LottieAnimationProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	animation: Promise<{ default: any }>;
	autoplay?: boolean;
	loop?: boolean;
	width?: string;
	height: string;
	maxWidth?: string;
	testId?: string;
}

export const LottieAnimation = ({
	animation,
	autoplay = true,
	loop = false,
	width = "100%",
	height,
	maxWidth,
	testId,
}: LottieAnimationProps) => {
	const ref = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		let destroy: () => void;
		(async () => {
			const lottiePromise = import(/* webpackChunkName: "lottie-web" */ "lottie-web");
			const animationData = (await animation).default;
			const lottie = (await lottiePromise).default;
			if (ref.current) {
				const lottieAnimation = lottie.loadAnimation({
					container: ref.current,
					renderer: "svg",
					loop,
					autoplay,
					animationData,
				});
				// SS 2024-09-18
				// lottieAnimation might be undefined in unit test
				destroy = () => lottieAnimation?.destroy();
			}
		})();
		return () => {
			destroy?.();
		};
		// Animation props will not be added into deps, the promise will keep changing
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoplay, loop]);

	return <div data-testid={testId} style={{ width, height, maxWidth }} ref={ref} />;
};
