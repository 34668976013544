import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./linkButton.scss";

interface LinkButtonProps {
	children: React.ReactText | React.ReactText[];
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	testId?: string;
	disabled?: boolean;
	className?: string;
	type?: "submit" | "reset" | "button";
}

/**
 * TODO: move this button to the appropriate folder. Possibly with `Link`
 */
export const LinkButton = React.forwardRef<HTMLButtonElement, LinkButtonProps>(
	({ children, onClick, testId, disabled, className, type }, ref) => {
		return (
			<button
				ref={ref}
				className={mergeClassNames(styles.btnLink, className)}
				onClick={e => {
					e.preventDefault();
					onClick && onClick(e);
				}}
				disabled={disabled}
				data-testid={testId}
				type={type}
			>
				{children}
			</button>
		);
	},
);
