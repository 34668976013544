// extracted by mini-css-extract-plugin
export var error = "wm";
export var whiteIcon = "wb";
export var greenIcon = "xm";
export var redIcon = "ym";
export var blueIcon = "kq";
export var yellowIcon = "xy";
export var lightGreyIcon = "NG";
export var greyIcon = "Pf";
export var darkGreyIcon = "yH";
export var warningIcon = "Ql";
export var burgundyIcon = "Vn";
export var clickHandler = "Ay";
export var noMargin = "yk";