import * as React from "react";
import { renderNodes } from "simple-commonmark-react";
import CommonMarkRenderer from "simple-commonmark-react/dist/renderers/CommonMarkRenderer";
import CustomRendererDict from "simple-commonmark-react/dist/renderers/CustomRendererDict";
import LinkRenderer from "simple-commonmark-react/dist/renderers/LinkRenderer";
import ParagraphRenderer from "simple-commonmark-react/dist/renderers/ParagraphRenderer";

import { ContactSupportLink } from "@bokio/components/ContactSupportLink/ContactSupportLink";
import { helpArticleAliases, HelpLink } from "@bokio/components/HelpLink/HelpLink";
import { Link } from "@bokio/elements/Link/Link";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { mergeClassNames } from "@bokio/utils/classes";
import { trackError } from "@bokio/utils/t";
import { getCompanyIdFromUrl } from "@bokio/utils/url";

import {
	MarkdownInteropContextProvider,
	TypographicElementsHeadingRenderer,
	TypographicElementsListRenderer,
	TypographicElementsParagraphRenderer,
	TypographicElementsStrongRenderer,
	TypographicElementsTextSpanRenderer,
} from "./MarkdownTypographicElementsInterop";

import type { UseTypographicElementsProps } from "./MarkdownTypographicElementsInterop";
import type { ContactSupportLinkProps } from "@bokio/components/ContactSupportLink/ContactSupportLink";
import type { HelpArticleAlias, HelpLinkProps } from "@bokio/components/HelpLink/HelpLink";
import type { LinkProps } from "@bokio/elements/Link/Link";
import type RenderOptions from "simple-commonmark-react/dist/RenderOptions";

import * as interopStyles from "./markdownTypographicElementsInterop.scss";

interface MarkdownProps {
	markdownContent: string;
	className?: string;
	testId?: string;
	noWrappingDiv?: boolean;
	/**
	 * Instead of rendering Markdown paragraph as <Paragraph> (<p>), render it using <TextSpan> (<span>) instead.
	 *
	 * This is mainly for the case when you only need some simple inline text with the markdown style.
	 *
	 * Implies {@link noWrappingDiv} when true.
	 *
	 * @default false
	 */
	inline?: boolean;
	/**
	 * Use Typographic Elements instead of raw DOM elements as much as possible.
	 * Should eliminate issues like default margin top at the first paragraph,
	 * therefore the rendered result should be as close to the most commonly used pattern in Figma as possible.
	 *
	 * @experimental
	 * **This is an ongoing improvment**
	 * so please jump in and fix things if some elements are not covered.
	 *
	 * Something to think about during experimentation:
	 * - Is auto Section needed or does that adds more confusion?
	 *
	 * In {@link TypographicElementsListRenderer} and {@link interopStyles.interop} you can find some more complex cases to implement.
	 */
	useTypographicElements?: UseTypographicElementsProps;
}

const createInternalLinkProps = (path: string, locationPathName: string): LinkProps => {
	const placeholder = "/companyid/";
	let adjustedPath = path;
	if (path.indexOf(placeholder) >= 0 && locationPathName) {
		const companyid = getCompanyIdFromUrl(locationPathName);
		if (companyid) {
			adjustedPath = path.replace(placeholder, `/${companyid}/`);
		}
	}
	return { route: adjustedPath };
};

const createInternalHelpLinkProps = (helpArticle: string): HelpLinkProps | undefined => {
	if (!helpArticleAliases.map(a => a.toString()).includes(helpArticle)) {
		return undefined;
	}

	return { helpArticle: helpArticle as HelpArticleAlias };
};

const renderHelpLink = (helpArticle: string, key: string | number | undefined, className: string | undefined) => {
	const refProps = createInternalHelpLinkProps(helpArticle);
	const props = { key, className };
	if (!refProps) {
		// If the helpArticle doesn't exist, log an error and return a span with the link anchor text
		trackError("HelpLink helpArticle from language string in Markdown doesn't exist", "Markdown", { helpArticle });
		return React.createElement("span", props, []);
	}

	const linkProps = { ...refProps, ...props };
	return React.createElement(HelpLink, linkProps, []);
};

const renderSupportLink = (area: string, key: string | number | undefined) => {
	if (!m.Contracts.SupportFormArea[area]) {
		// If area is not in SupportFormArea, it will be undefined.
		trackError("Support link area from language string in Mardown doesn't exist", "Markdown", { area });
	}
	const props: ContactSupportLinkProps = {
		area: m.Contracts.SupportFormArea[area] ?? m.Contracts.SupportFormArea.NotSet,
		children: null,
	};
	return React.createElement<ContactSupportLinkProps>(ContactSupportLink, { ...props, key }, []);
};

const renderLink = (
	href: string,
	locationPathName: string,
	key: string | number | undefined,
	className: string | undefined,
) => {
	const isInternal = href.startsWith("bokio:");
	const refProps: LinkProps = isInternal
		? createInternalLinkProps(href.substring(6), locationPathName)
		: { external: href, target: "_blank" };

	const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.stopPropagation();
	};
	// Hardcoded for external links now - for internal routes we'd need to build support
	const linkProps = { ...refProps, key, className, onClick };
	// Have to use React.createElement rather than <Link> because the npm package is broken
	return React.createElement(Link, linkProps, []);
};

// Do NOT inherit from HtmlBlockRenderer because it adds a dangerouslySetInnerHTML property in its getDefaultProps implementation
class DontEmitHtmlBlockRenderer extends CommonMarkRenderer {
	renderNodeWithProps(props: object): React.ReactElement<object> {
		const text = this.node.literal;
		return React.createElement("div", props, [text]);
	}
}

const renderMarkdown = (
	markdown: string,
	className: string | undefined,
	useTypographicElements: MarkdownProps["useTypographicElements"],
	inline: boolean | undefined,
	testId: string | undefined,
	locationPathName: string,
) => {
	const renderers: CustomRendererDict = new CustomRendererDict();

	renderers.link = class ReactRouterLinkRender extends LinkRenderer {
		override renderNodeWithProps(props: {
			href: string;
			className: string | undefined;
			key: string | number | undefined;
		}): React.ReactElement<unknown> {
			const href: string = props.href;
			const bokioHelpPrefix = "bokio-help:/";
			const bokioSupportLinkPrefix = "bokio-support:";

			const isInternalHelpLink = href.startsWith(bokioHelpPrefix);
			const isSupportLink = href.startsWith(bokioSupportLinkPrefix);
			const className = props.className;
			const key = props.key;
			if (isInternalHelpLink) {
				return renderHelpLink(href.substring(bokioHelpPrefix.length), key, className);
			}
			if (isSupportLink) {
				return renderSupportLink(href.substring(bokioSupportLinkPrefix.length), key);
			}
			return renderLink(href, locationPathName, key, className);
		}
	};

	if (inline) {
		class SpanRenderer extends ParagraphRenderer {
			override renderNodeWithProps(props: object) {
				// Respect parent behaviour where it doesn't render within a tight list
				const proxy = super.renderNodeWithProps(props);
				if (!proxy?.type) {
					return proxy;
				}
				const propsWithTestId = testId ? { ...props, "data-testid": testId } : props;
				return React.createElement("span", propsWithTestId, []);
			}
		}

		renderers.paragraph = SpanRenderer;
	}

	if (useTypographicElements) {
		renderers.paragraph = inline ? TypographicElementsTextSpanRenderer : TypographicElementsParagraphRenderer;
		renderers.heading = TypographicElementsHeadingRenderer;
		renderers.strong = TypographicElementsStrongRenderer;
		renderers.list = TypographicElementsListRenderer;
	}

	// The markdown-react package is missing an html_block property in the CustomRendererDict class so we can't use any typed property
	renderers["html_block"] = DontEmitHtmlBlockRenderer;
	const markdownOptions: RenderOptions = {
		className: mergeClassNames(className || "markdown", useTypographicElements && interopStyles.interop),
		allowSoftBreaks: true,
		customRenderers: renderers,
	};
	return renderNodes(markdown, markdownOptions);
};

/**
 * @abstract Renders Markdown to React components. Doesn't ever render raw html.
 * For links, you can use the special url syntax bokio:/companyid/path to generate internal react links
 * where the companyid is replaced with the current company guid
 */
const Markdown: React.FC<MarkdownProps> = ({
	testId,
	markdownContent,
	className,
	useTypographicElements,
	noWrappingDiv,
	inline,
}) => {
	const router = useRouter();
	const markdown = () =>
		renderMarkdown(
			markdownContent,
			className,
			useTypographicElements,
			inline,
			testId,
			router.location?.pathname || "/",
		);

	const rendered = noWrappingDiv || inline ? <>{markdown()}</> : <div data-testid={testId}>{markdown()}</div>;

	if (typeof useTypographicElements === "object") {
		return <MarkdownInteropContextProvider value={useTypographicElements}>{rendered}</MarkdownInteropContextProvider>;
	}

	return rendered;
};

export default Markdown;
