import * as React from "react";

import { InputField } from "@bokio/elements/Form/InputField";

import type { InputFieldProps } from "@bokio/elements/Form/InputField";

// NOTE: MQ 2020-05-12 onChange's value should be number but it is string now
interface NumberFieldProps extends InputFieldProps {
	min?: number;
	max?: number;
	step?: number;
}

export class NumberField extends React.Component<NumberFieldProps> {
	render() {
		const props: InputFieldProps = {
			...this.props, // NE 2018-05-28 issue here: The min, max, step props are spread to the InputField which dont have those attributes.
			fieldProps: {
				...this.props.fieldProps,
				type: "number",
				min: this.props.min,
				max: this.props.max,
				step: this.props.step || 1,
				inputMode: "numeric",
			},
		};

		return <InputField {...props} />;
	}
}
