import { HubConnectionBuilder } from "@microsoft/signalr";

import { bankFeedActivityHub } from "@bokio/contexts/BankFeedActivityContext/BankFeedActivityContext";
import { emailActivityHub } from "@bokio/contexts/EmailDeliveryContext";
import { notificationActivityHub } from "@bokio/contexts/NotificationActivityContext/NotificationActivityContext";
import { Config } from "@bokio/shared/config";

let isStarted = false;

export const signalRStart = () => {
	if (isStarted) {
		return;
	}

	const start = () => {
		// The library has a policy for automatic reconnects enabled by default.  To refine it, use one of
		// the `.withAutomaticReconnect(…)` overloads.
		const connection = new HubConnectionBuilder().withUrl(`${Config.env.apiUrl}/signalr`).build();

		connection.on("emailActivity", emailActivityHub.dispatch);
		connection.on("bankFeedActivity", bankFeedActivityHub.dispatch);
		connection.on("notificationActivity", notificationActivityHub.dispatch);

		connection.start();

		isStarted = true;
	};

	if (process.env.NODE_ENV !== "test" && !window.config.isStorybook) {
		// Start signalR (delay start to not connect to SignalR before we sometimes get redirect loops, this causes a lot of requests to SignalR serivce)
		// SS - 2024-10-14: Anyone knows does the above still apply? I didn't remove the timeout just because I'm unsure.
		// Quan - 2020-11: this timeout cause a bug on edge case for notification: before 3s passed, user won't see new notification in notifications list
		// HB: We should stop initiating SignalR connections from guest pages (like `/login`).  Tracked by work item #23450
		setTimeout(() => start(), 3000);
	}
};
