import * as React from "react";

import {
	LoadingBox,
	LoadingContent,
	LoadingList,
	LoadingPage,
	LoadingProgress,
	LoadingSpinner,
	LoadingText,
} from "@bokio/elements/Loading";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { Notice } from "@bokio/elements/Notice/Notice";

import type { NoticeProps } from "@bokio/elements/Notice/Notice";
import type { Envelope } from "@bokio/mobile-web-shared/core/model/model";
import type { MetaData } from "@bokio/mobile-web-shared/core/model/types";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

export type LoadingComponents = "progress" | "spinner" | "list" | "box" | "text" | "content" | "page";

const mapLoadingComponent = (
	whenLoading: LoadingComponents | React.ReactNode | (() => React.ReactNode),
): React.ReactNode | null => {
	if (typeof whenLoading === "function") {
		return whenLoading();
	}

	switch (whenLoading) {
		case "spinner":
			return <LoadingSpinner />;
		case "progress":
			return <LoadingProgress />;
		case "box":
			return <LoadingBox />;
		case "list":
			return <LoadingList />;
		case "text":
			return <LoadingText />;
		case "content":
			return <LoadingContent />;
		case "page":
			return <LoadingPage />;
		default:
			return whenLoading ?? null;
	}
};

export interface RenderRequestError<TError> {
	Error: TError | undefined;
	ErrorMessage: string | undefined;
}

interface RenderRequestProps<Result, TError> {
	request: RequestState<Envelope<Result, TError>>;
	whenLoading: LoadingComponents | React.ReactNode | (() => React.ReactNode);
	whenError: "useDefault" | ((result: RenderRequestError<TError>) => React.ReactNode);
	errorNoticeProps?: NoticeProps;
	useCacheWhenLoading?: boolean;
	children?: (state: Result, meta?: MetaData) => React.ReactNode;
}

/** Renders a request and helps you with null-propagation.
 * @example
 * <RenderRequest
 * 	request={SomeRequestWithEnvelope}
 * 	whenLoading="progress"
 * 	whenError="useDefault"
 * >
 * 	{data => <Content data={data} />}
 * </RenderRequest>
 *
 */
export default class RenderRequest<Result, TError> extends React.Component<
	RenderRequestProps<Result, TError>,
	RequestState<Envelope<Result, TError>>
> {
	static defaultProps = {
		whenError: "useDefault",
	};

	render() {
		const { request, children, whenLoading, useCacheWhenLoading } = this.props;

		const useCache = useCacheWhenLoading && request.data !== undefined;

		if (request.isLoading && !useCache) {
			return mapLoadingComponent(whenLoading);
		}

		// Standard Envelope error
		if (request.error) {
			const error: RenderRequestError<TError> = {
				Error: undefined,
				ErrorMessage: request.errorMessage,
			};
			return this.renderError(error);
		}

		if (request.data && !request.data.Success) {
			const error: RenderRequestError<TError> = {
				Error: request.data.Error,
				ErrorMessage: request.data.ErrorMessage,
			};
			return this.renderError(error);
		}

		if (!request.data) {
			return null;
		}

		return children ? children(request.data.Data, request.data.Meta) : null;
	}

	renderError = (error: RenderRequestError<TError>) => {
		const { whenError, errorNoticeProps } = this.props;
		if (whenError === "useDefault") {
			return (
				<Notice
					// Test ID is matching RenderRequestError by default. Consider replacing the implementation here with that component.
					testId="LoadingWithError_ErrorMessage"
					color="warning"
					{...errorNoticeProps}
				>
					<Markdown markdownContent={error.ErrorMessage || ""} />
				</Notice>
			);
		}
		return whenError(error);
	};
}
