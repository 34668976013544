export function noop() {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return {} as any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toString(value: any): string {
	return value == null ? "" : value.toString();
}

export const isAlphanumeric = (str: string): boolean => {
	const r = new RegExp(/^[0-9a-zA-Z\-\–]*$/);
	return r.test(str);
};

export const containsNoWhitespace = (str: string): boolean => {
	const r = new RegExp(/^\S*$/);
	return r.test(str);
};
