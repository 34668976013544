import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import { getMarginClassName } from "@bokio/designsystem/components/Button/button.helper";
import { mergeClassNames } from "@bokio/utils/classes";

import Icon from "../Icon/Icon";

import type { FontelloIcons } from "@bokio/assets/fontello";
import type { Margin } from "@bokio/designsystem/components/Button/button.types";

import * as styles from "./splitButton.scss";

interface SplitButtonRenderHandle {
	onClose: () => void;
}

export interface SplitButtonProps {
	primaryText: string;
	testId?: string;
	dropDownTriggerTestId?: string;
	onClick?: (e: Event, value: string) => void;
	onClickPrimary?: () => void;
	onClickSecondary?: () => void;
	target?: "_self" | "_blank" | "_parent" | "_top";
	className?: string;
	/** Will disable both the primary and secondary  */
	disabled?: boolean;
	/** Will disable only primary and secondary  */
	primaryDisabled?: boolean;
	icon?: FontelloIcons;
	isIconLeftAlign?: boolean;
	innerRef?: (element: HTMLDivElement) => void;
	noIconLeft?: boolean;
	/**
	 * NE 2019-06-26
	 * Optional render prop for rendering content
	 * that can trigger events, like closing the menu.
	 */
	menuContent?: (handle: SplitButtonRenderHandle) => JSX.Element;
	stretch?: boolean;
	primaryRoute?: string;
	margin?: Margin;
	/**
	 * Render as a single button that only behaves as the "split". Used in the bookkeeping menu for example
	 */
	onlyDropdownMode?: boolean;
	appearance?: "primaryDark" | "secondary" | "primary" | "destructive";
}

interface SplitButtonState {
	showDropDown: boolean;
}

export class SplitButton extends React.Component<React.PropsWithChildren<SplitButtonProps>, SplitButtonState> {
	secondary: HTMLButtonElement | null = null;
	clickedInside = false;
	clickTimeout?: number;

	state: SplitButtonState = {
		showDropDown: false,
	};

	componentWillUnmount() {
		clearTimeout(this.clickTimeout);
	}

	static getDerivedStateFromProps(props: SplitButtonProps, state: SplitButtonState) {
		return props.disabled && state.showDropDown ? { showDropDown: false } : null;
	}

	renderButton = () => {
		const props = {
			disabled: this.props.disabled || this.props.primaryDisabled,
			className: styles.primary, //ME: Stupid style on splitbutton is fixed to primary
		};

		const textAndIcon = (
			<>
				{this.props.icon && this.props.isIconLeftAlign && (
					<Icon className={styles.iconAlignLeft} name={this.props.icon} size="20" />
				)}
				<span>{this.props.primaryText}</span>
				{this.props.icon && !this.props.isIconLeftAlign && (
					<Icon className={styles.icon} name={this.props.icon} size="20" />
				)}
			</>
		);

		if (this.props.primaryRoute && !props.disabled) {
			return (
				<RouterLink
					to={this.props.primaryRoute}
					data-testid={this.props.testId}
					onClick={this.props.onClickPrimary}
					className={props.className}
					target={this.props.target}
				>
					{textAndIcon}
				</RouterLink>
			);
		} else {
			return (
				<button data-testid={this.props.testId} {...props} onClick={this.props.onClickPrimary} type="button">
					{textAndIcon}
				</button>
			);
		}
	};

	getTriggerTestId = () =>
		this.props.dropDownTriggerTestId ? this.props.dropDownTriggerTestId : "SplitButton_Trigger";

	renderDropDownModeButton = () => {
		const ficonShown = this.state.showDropDown ? " ficon-up-open-big" : " ficon-down-open-big";
		const textAndIcon = (
			<>
				{this.props.icon && this.props.isIconLeftAlign && (
					<Icon className={styles.iconAlignLeft} name={this.props.icon} />
				)}
				<span>{this.props.primaryText}</span>
				{this.props.icon && !this.props.isIconLeftAlign && <Icon className={styles.icon} name={this.props.icon} />}
			</>
		);

		const className = mergeClassNames(
			styles.dropdownOnly,
			ficonShown,
			this.props.className,
			this.props.noIconLeft && styles.noIconLeft,
			this.props.appearance === "primaryDark" && styles.primaryDark,
			this.props.appearance === "destructive" && styles.destructive,
			this.props.appearance === "secondary" && styles.secondary,
		);

		return (
			<button
				data-testid={this.getTriggerTestId()}
				disabled={this.props.disabled}
				ref={secondary => {
					this.secondary = secondary;
				}}
				className={className}
				onClick={this.toggleDropDown}
				onBlur={this.handleBlur}
				type="button"
			>
				{textAndIcon}
			</button>
		);
	};

	render() {
		const ficonShown = this.state.showDropDown ? " ficon-up-open-big" : " ficon-down-open-big";

		return (
			<div
				className={mergeClassNames(
					this.props.className,
					this.props.stretch && styles.stretch,
					this.props.margin && getMarginClassName(this.props.margin),
				)}
				ref={this.props.innerRef}
			>
				<div className={styles.buttonContainer}>
					{!this.props.onlyDropdownMode && (
						<>
							{this.renderButton()}
							<button
								data-testid={this.getTriggerTestId()}
								disabled={this.props.disabled}
								ref={secondary => {
									this.secondary = secondary;
								}}
								className={styles.secondary + ficonShown}
								onClick={this.toggleDropDown}
								onBlur={this.handleBlur}
								type="button"
							/>
						</>
					)}
					{this.props.onlyDropdownMode && <>{this.renderDropDownModeButton()}</>}
				</div>
				<div className={styles.dropDownContainer}>
					<div className={this.state.showDropDown ? styles.dropDown : ""} onMouseDown={this.handleContainerMouseDown}>
						{this.state.showDropDown && (
							<React.Fragment>
								{this.renderChildren(this.props.children)}
								{this.props.menuContent && this.props.menuContent({ onClose: this.toggleDropDown })}
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		);
	}

	renderChildren(children: React.ReactNode) {
		return React.Children.map(children, child => {
			const c = child as JSX.Element;
			if (!c) {
				return null;
			} // If conditionally rendering this.props.children, react will read {false && <></> as a child}
			if (c.props.onClick) {
				return React.cloneElement(c);
			}

			return React.cloneElement(c, {
				...c.props,
				onClick: this.handleClick,
			});
		});
	}

	handleContainerMouseDown = () => {
		this.clickedInside = true;
		// The input's onBlur method is called from a queue right after onMouseDown event.
		// setTimeout adds another callback in the queue, but is called later than onBlur event
		this.clickTimeout = window.setTimeout(() => {
			this.clickedInside = false;
		}, 0);
	};

	toggleDropDown = () => {
		this.props.onClickSecondary && this.props.onClickSecondary();
		this.setState(state => ({ showDropDown: !state.showDropDown }));
	};

	handleClick = (e: Event, value: string) => {
		const { onClick } = this.props;
		onClick && onClick(e, value);
		this.toggleDropDown();
	};

	handleBlur = () => {
		this.setState({
			showDropDown: this.clickedInside,
		});
		// Force input's focus if blur event was caused
		// by clicking inside the overlay
		if (this.clickedInside && this.secondary) {
			this.secondary.focus();
		}
	};
}
