import { usePricePlanContext } from "@bokio/contexts/PricePlanContext/PricePlanContext";
import { usePricePlanFeatureContext } from "@bokio/contexts/PricePlanFeatureContext/PricePlanFeatureContext";
import { Paragraph, Strong } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Badge } from "@bokio/elements/Badge/Badge";
import { Tooltip } from "@bokio/elements/Tooltip";
import { GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { useCompanyUser } from "@bokio/shared/state/requests";
import { formatMessage } from "@bokio/shared/utils/format";
import { getPlanName } from "@bokio/utils/priceplanUtils";

import type * as React from "react";

import BokioPlan = m.Entities.BokioPlan;
import PricePlanFeature = m.Bokio.Common.Contract.ViewData.PricePlanFeature;
export { BokioPlan, PricePlanFeature };

export interface BokioPlanLockBadgeOverrideProps {
	overrideBadgeText?: (isInTrialPeriod: boolean | undefined) => string;
	overrideBadgeToolTipText?: (isInTrialPeriod: boolean | undefined) => React.ReactNode;
}

interface BokioPlanLockBadgeProps extends BokioPlanLockBadgeOverrideProps {
	feature: PricePlanFeature;
	iconOnly?: boolean;
	showPlanInfo?: boolean;
	/**
	 * Default to true. When false, it will become unclickable and won't show any tooltips on hover.
	 */
	allowInteraction?: boolean;
}

export interface BokioPlanLockBadgeHookProps {
	feature: PricePlanFeature;
}

export interface BokioPlanLockBadgeHookHandle {
	isFeatureLocked?: boolean;
	isInTrialPeriod?: boolean;
	daysUntilTrialEnded?: number;
	minimumPlan: BokioPlan;
	hasUpgradePermisson: boolean;
	/**
	 * Nullish when user don't have permission
	 */
	onCtaClick?: () => void;
}

/**
 * @returns The hook for rendering the correct price plan lock information. `undefined` if the feature shouldn't be blocked.
 */
export const useBokioPlanLockBadge = ({
	feature,
}: BokioPlanLockBadgeHookProps): BokioPlanLockBadgeHookHandle | undefined => {
	const router = useRouter();
	const { companyInfo } = useCompanyInfo();
	const { companyUserPermissions } = useCompanyUser();
	const { daysUntilTrialEnded } = usePricePlanContext();
	const { isPricePlanFeatureEnabled, isPricePlanFeatureInTrial, getLowestPlanNeededForPricePlanFeature } =
		usePricePlanFeatureContext();

	const isFeatureLocked = !isPricePlanFeatureEnabled(feature);
	const isInTrialPeriod = isPricePlanFeatureInTrial(feature);
	const minimumPlan = getLowestPlanNeededForPricePlanFeature(feature);

	const hasUpgradePermisson = !!companyUserPermissions?.CompanySettings;

	const onCtaClick = hasUpgradePermisson
		? () => {
				// TODO: Take minumum plan into consideration when we switch this with modal
				router.redirect(getRoute("billing", { company: companyInfo.Id }));
			}
		: undefined;

	const shouldRender = isFeatureLocked || isInTrialPeriod;

	return shouldRender
		? {
				isFeatureLocked,
				isInTrialPeriod,
				daysUntilTrialEnded,
				minimumPlan,
				hasUpgradePermisson,
				onCtaClick,
			}
		: undefined;
};

// Exported just for storybook, banner, MenuCurrentPlan
export const BokioPlanLockBadgeInner: React.FC<
	Omit<BokioPlanLockBadgeProps & BokioPlanLockBadgeHookHandle, "feature">
> = ({
	isInTrialPeriod,
	isFeatureLocked,
	iconOnly,
	allowInteraction = true,
	overrideBadgeText,
	overrideBadgeToolTipText,
	onCtaClick,
	hasUpgradePermisson,

	// Not used during grace period:
	// showPlanInfo,
	minimumPlan,
	// daysUntilTrialEnded,
}) => {
	const generalLang = GeneralLangFactory();

	if (!isInTrialPeriod && !isFeatureLocked) {
		return null;
	}

	const paidPlans = [BokioPlan.Premium, BokioPlan.Pro];
	if (!paidPlans.includes(minimumPlan)) {
		return null;
	}

	const minimumPlanName = getPlanName(minimumPlan);

	//ME: The text below will show Business/Balance after the priceplan change if we don't clear them from our mappings. We can feature toggle that here or serverside.
	const text = iconOnly
		? ""
		: overrideBadgeText
			? overrideBadgeText(isInTrialPeriod)
			: isInTrialPeriod
				? generalLang.BokioPlanLockBadge_Text_Trial
				: formatMessage(generalLang.BokioPlanLockBadge_Text_RequiresPlanX, <Strong>{minimumPlanName}</Strong>);

	const toolTipText = overrideBadgeToolTipText
		? overrideBadgeToolTipText(isInTrialPeriod)
		: isInTrialPeriod
			? formatMessage(generalLang.BokioPlanLockBadge_ToolTip_RequiresPlanX_Trial, <Strong>{minimumPlanName}</Strong>)
			: formatMessage(generalLang.BokioPlanLockBadge_ToolTip_RequiresPlanX_Locked, <Strong>{minimumPlanName}</Strong>);

	const toolTipContent = (
		<>
			<Paragraph>{toolTipText}</Paragraph>
			{hasUpgradePermisson && <Paragraph>{generalLang.BokioPlanLockBadge_ToolTip_NoPermission}</Paragraph>}
		</>
	);

	const badge = (
		<Badge
			color={isInTrialPeriod ? "attention" : "locked"}
			icon={isInTrialPeriod ? "lock-open-alt" : "lock"}
			iconPosition="left"
			name={text}
			noMargin
			onClick={allowInteraction ? onCtaClick : undefined}
			testId="BokioPlanLockBadge_Status"
		/>
	);

	return (
		// Wrapper span to deal with legacy style issues in Status that causes wrong width/height
		<span>
			{allowInteraction ? (
				// TODO: Known issue that this doesn't really work well with screen right edge at the moment, shouldn't be too hard to fix but takes some time.
				<span>
					<Tooltip showArrow={false} contentGenerator={() => toolTipContent}>
						{badge}
					</Tooltip>
				</span>
			) : (
				badge
			)}
		</span>
	);
};

export const BokioPlanLockBadge: React.FC<BokioPlanLockBadgeProps> = props => {
	const hook = useBokioPlanLockBadge(props);

	return hook ? <BokioPlanLockBadgeInner {...hook} {...props} /> : null;
};
