import * as React from "react";

import { Modal } from "@bokio/components/Modal";
import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { noop } from "@bokio/shared/utils";

import { BbaToPremiumPlus } from "./BbaToPremiumPlus/BbaToPremiumPlus";
import { FreeToPaidPlan } from "./FreeToPaidPlan/FreeToPaidPlan";
import { HighDepositFree } from "./HighDepositFree/HighDepositFree";
import { LowDepositFree } from "./LowDepositFree/LowDepositFree";
import { PremiumToBba } from "./PremiumToBba/PremiumToBba";
import { TrialToPaidPlan } from "./TrialToPaidPlan/TrialToPaidPlan";

import * as styles from "./promotionModal.scss";

import SupportMessageType = m.Entities.Support.SupportMessageType;
export interface PromotionModalProps {
	visible?: boolean;
	type: m.Entities.Support.SupportMessageType;
	resolve: () => void;
	dismiss: () => void;
	testId?: string;
}

export const PromotionModal: React.FC<PromotionModalProps> = ({
	type,
	resolve,
	dismiss,
	testId = "PromotionModalTest",
}) => {
	const { companyInfo } = useCompanyInfo();
	const [visible, setVisible] = React.useState<boolean>(true);

	const localDismiss = () => {
		setVisible(false);
		dismiss();
	};

	const disallowNoneActionClose = [SupportMessageType.LowDepositFree, SupportMessageType.HighDepositFree];
	const allowNoneActionClose = !disallowNoneActionClose.includes(type);

	return (
		<Modal visible={visible} onClose={noop} width="extraWide" disableBackgroundClick={true} testId={`${testId}_modal`}>
			{allowNoneActionClose && (
				<Link className={styles.closePromotionModal} onClick={dismiss} testId="PromotionModal_cancel">
					<Icon name="cancel" />
				</Link>
			)}

			{type === SupportMessageType.TrialToPaidPlan && (
				<TrialToPaidPlan companyId={companyInfo.Id} resolve={resolve} dismiss={localDismiss} />
			)}
			{type === SupportMessageType.FreeToPaidPlan && (
				<FreeToPaidPlan companyId={companyInfo.Id} resolve={resolve} dismiss={localDismiss} />
			)}
			{type === SupportMessageType.PremiumToBba && (
				<PremiumToBba companyId={companyInfo.Id} resolve={resolve} dismiss={localDismiss} />
			)}
			{type === SupportMessageType.BbaToPremiumPlus && (
				<BbaToPremiumPlus companyId={companyInfo.Id} resolve={resolve} dismiss={localDismiss} />
			)}
			{type === SupportMessageType.LowDepositFree && (
				<LowDepositFree companyId={companyInfo.Id} resolve={resolve} dismiss={localDismiss} />
			)}
			{type === SupportMessageType.HighDepositFree && (
				<HighDepositFree companyId={companyInfo.Id} resolve={resolve} dismiss={localDismiss} />
			)}
		</Modal>
	);
};
