import { Link } from "@bokio/elements/Link/Link";
import { GeneralLangFactory } from "@bokio/lang";

import type * as React from "react";

interface BbaOnboardingPopoverNotInterestedLinkProps {
	onClick?: () => void;
}

export const BbaOnboardingPopoverNotInterestedLink: React.FC<BbaOnboardingPopoverNotInterestedLinkProps> = ({
	onClick,
}) => {
	const generalLang = GeneralLangFactory();

	return (
		<Link onClick={onClick} testId="BBAOnboardingPopover_NotInterested">
			{generalLang.Todo_Action_NotInterested}
		</Link>
	);
};
