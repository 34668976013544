import * as React from "react";

import { HelpWindowMode } from "@bokio/components/Help/helpSupportUtils";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { noop } from "@bokio/shared/utils";

import type { HelpPageModel } from "@bokio/shared/models/HelpPageModel";

export type HelpContextValue = {
	visible: boolean;
	toggleVisible: () => void;
	show: () => void;
	view: HelpWindowMode;
	onNavigate: (view: HelpWindowMode) => void;
	onClearHistory: () => void;
	onGoBack: () => void;
	openHelpArticle: (id: string) => void;
	openArticleIsLoading: boolean;
	openArticleIsError: boolean;
	resetHelpArticle: () => void;
	activeHelpArticle?: HelpPageModel;
	searchString: string;
	setSearchString: (value: string) => void;
	getCachedHelpPageModel: (id: string) => HelpPageModel | undefined;
	areaPrefilled: m.Contracts.SupportFormArea;
	setAreaPrefilled: (value: m.Contracts.SupportFormArea) => void;
	messagePrefilled?: string;
	setMessagePrefilled: (value: string) => void;
	referenceId?: string;
	setReferenceId: (value: string) => void;
	modalMode: boolean;
	setModalMode: (value: boolean) => void;
};

export const helpContext = React.createContext<HelpContextValue>({
	visible: false,
	toggleVisible: noop,
	show: noop,
	view: HelpWindowMode.Default,
	onNavigate: noop,
	onClearHistory: noop,
	onGoBack: noop,
	openHelpArticle: noop,
	openArticleIsLoading: false,
	openArticleIsError: false,
	resetHelpArticle: noop,
	activeHelpArticle: undefined,
	searchString: "",
	setSearchString: noop,
	getCachedHelpPageModel: noop,
	areaPrefilled: m.Contracts.SupportFormArea.NotSet,
	setAreaPrefilled: noop,
	messagePrefilled: undefined,
	setMessagePrefilled: noop,
	referenceId: undefined,
	setReferenceId: noop,
	modalMode: false,
	setModalMode: noop,
});

export const useHelpContext = () => React.useContext(helpContext);
