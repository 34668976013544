import * as React from "react";

import { fromJson, toQueryString, withData } from "./client/helpers";

import type { HelpPageModel } from "@bokio/shared/models/HelpPageModel";
import type { HelpSearchResultModel } from "@bokio/shared/models/HelpSearchResultModel";
import type { UmbracoReleaseNotesModel } from "@bokio/shared/models/UmbracoReleaseNotesModel";

class HttpClient {
	constructor(public baseUrl: string) {}

	public get<T>(path: string, data: Record<string, unknown> = {}): Promise<T> {
		let fullPath = path;
		if (Object.keys(data).length > 0) {
			fullPath += "?" + toQueryString(data);
		}
		return this.request<T>("GET", fullPath);
	}

	public request<T>(method: string, path: string, data: Record<string, unknown> = {}, isFormData = false): Promise<T> {
		const options = withData(method, data, isFormData);

		// make IE stop caching stuff like crazy
		options.headers["If-Modified-Since"] = "Mon, 26 Jul 1997 05:00:00 GMT";
		// extra
		options.headers["Cache-Control"] = "no-cache";
		options.headers.Pragma = "no-cache";

		return fetch(this.baseUrl + path, options).then(fromJson);
	}
}

const createClient = () => {
	const umbracoBaseUrl = window.config.websiteUrl;
	const client = new HttpClient(umbracoBaseUrl);
	return client;
};

const helpPageModelRequestCache: Record<string, Promise<HelpPageModel>> = {};
let helpPageModelCache: Record<string, HelpPageModel> = {};

export const useUmbracoApi = () => {
	const [preloadedHelpPageModels, setPreloadedHelpPageModels] = React.useState(helpPageModelCache);

	const search = (searchString: string, maxCount: number): Promise<HelpSearchResultModel> => {
		return createClient().get<HelpSearchResultModel>(
			`umbraco/surface/FaqListSurface/Search?q=${searchString}&maxCount=${maxCount}`,
		);
	};

	const getHelpCacheId = (pageId: string) => `${window.config.websiteUrl}/${pageId}`;

	const getHelp = async (pageId: string): Promise<HelpPageModel> => {
		const cacheId = getHelpCacheId(pageId);
		const existing = helpPageModelRequestCache[cacheId];

		if (existing) {
			return existing;
		}

		try {
			helpPageModelRequestCache[cacheId] = createClient().get<HelpPageModel>(
				`umbraco/surface/FaqListSurface/Get?id=${pageId}`,
			);
			helpPageModelCache[cacheId] = await helpPageModelRequestCache[cacheId];
			helpPageModelCache = { ...helpPageModelCache };
			setPreloadedHelpPageModels(helpPageModelCache);
		} catch {
			delete helpPageModelRequestCache[cacheId];
			delete helpPageModelCache[cacheId];
		}

		return helpPageModelRequestCache[cacheId];
	};

	const getCachedHelpPageModel = (pageId: string): HelpPageModel | undefined => {
		// No need to wait here because the get will set state on success -> trigger rerender -> re-run this function -> then finally return the actual HelpPageModel
		getHelp(pageId);
		return preloadedHelpPageModels[getHelpCacheId(pageId)];
	};

	const getWhatsNew = React.useCallback(() => {
		return createClient().get<UmbracoReleaseNotesModel>("umbraco/surface/ReleaseNotesSurface/List");
	}, []);

	return { search, getHelp, getCachedHelpPageModel, getWhatsNew };
};
