import * as React from "react";
import { useHistory } from "react-router";

import { trackException } from "@bokio/utils/t";
import { withCurrentReturnUrl } from "@bokio/utils/url";

import type { Location } from "history";

// Explicitly declared route state types.
// Prefer optional types so we don't have to explicitly declare everything when we only care about one of the props.
export interface BokioRouterLocationState {
	isRedirectedFromNotification?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	signUpSceneProps?: any;
}

export type RouterProps = {
	push: (path: string, state?: BokioRouterLocationState) => void;
	replace: (path: string, state?: BokioRouterLocationState) => void;
	goBack: () => void;
	goForward: () => void;
	location: Location<BokioRouterLocationState>;
	redirect: (path: string, state?: BokioRouterLocationState) => void;
	redirectHard: (path: string) => void;

	currentCompanyId: string | undefined;
	currentAgencyId: string | undefined;
};

/**
 * Tips for unit testing:
 * See the `history` prop from `renderApp(...)` for example of testing.
 */
export const useRouter = (): RouterProps => {
	const history = useHistory<BokioRouterLocationState>();

	const routerRef = React.useRef({} as unknown as RouterProps);
	routerRef.current.goBack = history.goBack.bind(history);
	routerRef.current.goForward = history.goForward.bind(history);
	routerRef.current.location = history.location;
	routerRef.current.push = (path: string, state?: BokioRouterLocationState) => history.push(path, state);
	routerRef.current.redirect = (path: string, state?: BokioRouterLocationState) => {
		history.push(withCurrentReturnUrl(history.location, path), state);
	};
	routerRef.current.redirectHard = (path: string) => {
		window.location.href = withCurrentReturnUrl(history.location, path);
	};
	routerRef.current.replace = (path: string, state?: BokioRouterLocationState) => {
		try {
			history.replace(path, state);
		} catch (err) {
			trackException(err, "withRouterControls", { location });
		}
	};
	routerRef.current.currentCompanyId = history.location.pathname.match(
		/^\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/,
	)?.[1];
	routerRef.current.currentAgencyId = history.location.pathname.match(
		/^\/(?:backoffice|agencies)\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/,
	)?.[1];

	return routerRef.current;
};
