import * as React from "react";

import { empty } from "@bokio/mobile-web-shared/services/api/requestState";
import { noop } from "@bokio/shared/utils";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

type UserPaymentPermissionDto = m.Bokio.Bank.Contract.Dtos.UserPaymentPermissionDto;
type CountryInfoDto = m.Bokio.Bank.Contract.Dtos.CountryInfoDto;
type CurrencyInfoDto = m.Bokio.Bank.Contract.Dtos.CurrencyInfoDto;

type OnboardingStatusViewModel = m.Bokio.Bank.Contract.Mir.Onboarding.OnboardingStatusViewModel;
type PaymentValidationRulesDto = m.Bokio.Bank.Contract.Dtos.PaymentValidationRulesDto;

export type { CountryInfoDto, CurrencyInfoDto };

export type PaymentContextValue = {
	currentUserPermission: UserPaymentPermissionDto;
	refreshPaymentPermission: () => void;
	countryListRequest: RequestState<m.Envelope<m.Bokio.Bank.Contract.Dtos.CountryInfoDto[], m.SimpleError>>;
	currencyListRequest: RequestState<m.Envelope<CurrencyInfoDto[], m.SimpleError>>;
	signablePaymentCount: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	refreshSignablePaymentCount: () => Promise<any> | undefined;
	mirOnboardingStatus: OnboardingStatusViewModel | undefined;
	mirOnboardingStatusRequest: RequestState<
		m.Envelope<m.Bokio.Bank.Contract.Mir.Onboarding.OnboardingStatusViewModel, m.SimpleError>
	>;
	refreshMirOnboardingStatus: () => void;
	paymentValidationRules: PaymentValidationRulesDto | undefined;
	paymentValidationRulesRequest: RequestState<m.Envelope<PaymentValidationRulesDto, m.SimpleError>>;
	getPermissionOfCurrentUserRequest: RequestState<m.Envelope<UserPaymentPermissionDto, m.SimpleError>>;
};

export const paymentContext = React.createContext<PaymentContextValue>({
	currentUserPermission: {
		CanSignPayment: false,
	},
	refreshPaymentPermission: noop,
	countryListRequest: {
		error: false,
		isLoading: true,
	},
	currencyListRequest: {
		error: false,
		isLoading: true,
	},
	signablePaymentCount: 0,
	refreshSignablePaymentCount: noop,
	mirOnboardingStatus: undefined,
	mirOnboardingStatusRequest: empty(),
	refreshMirOnboardingStatus: noop,
	paymentValidationRules: undefined,
	paymentValidationRulesRequest: empty(),
	getPermissionOfCurrentUserRequest: empty(),
});

export const usePaymentContext = () => React.useContext(paymentContext);
