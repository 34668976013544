import * as React from "react";

import { AnalyticsEventCategory, useMetric } from "@bokio/hooks/useMetric/useMetric";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { useApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { mergeClassNames } from "@bokio/utils/classes";

import { TopBarButton } from "../TopBar/TopBarButton";
import { AccountantPanelFlyout } from "./AccountantPanelFlyout/AccountantPanelFlyout";
import { AccountantPanelIcon } from "./AccountantPanelIcon";

import * as styles from "../MenuTopBarNotificationButton/menuTopBarNotificationButton.scss";

interface MenuAccountantTopBarButtonProps {
	backOfficeMode?: boolean;
}
export const MenuAccountantTopBarButton: React.FC<MenuAccountantTopBarButtonProps> = ({ backOfficeMode }) => {
	const { companyInfo } = useCompanyInfo();
	const [addMetric] = useMetric();

	const [isAccountantPanelOpen, setIsAccountantPanelOpen] = React.useState(false);
	const [executeBadgeCountRequest] = useApi(proxy.Common.AccountantPanelController.NotesCount.Get, [companyInfo.Id]);

	const badgeCount = executeBadgeCountRequest.data?.Data ?? 0;

	const [renderedBadgeCount, setRenderedBadgeCount] = React.useState(!isAccountantPanelOpen ? badgeCount : 0);

	React.useEffect(() => {
		if (isAccountantPanelOpen) {
			setRenderedBadgeCount(0);
			return;
		}
		setRenderedBadgeCount(badgeCount);
	}, [isAccountantPanelOpen, badgeCount]);

	const OnClickAccountantPanelIcon = () => {
		setIsAccountantPanelOpen(!isAccountantPanelOpen);
		if (!!isAccountantPanelOpen) {
			addMetric(AnalyticsEventCategory.AccountantPanel, "Click");
		}
	};
	return (
		<>
			<TopBarButton
				className={mergeClassNames(styles.button, styles.lastIcon)}
				onClick={OnClickAccountantPanelIcon}
				testId="AccountantPanelIcon"
			>
				<AccountantPanelIcon
					fillClassName={styles.buttonIconFill}
					badgeCount={renderedBadgeCount}
					backOfficeMode={backOfficeMode}
				/>
			</TopBarButton>
			<AccountantPanelFlyout
				companyId={companyInfo.Id}
				isOpen={isAccountantPanelOpen}
				onClose={() => setIsAccountantPanelOpen(false)}
			/>
		</>
	);
};
