import { Badge } from "@bokio/elements/Badge/Badge";

import type { BadgeColor } from "@bokio/elements/Badge/Badge";

export interface StatusOption {
	statusId: BadgeColor;
	label: string;
}

interface StatusIndicatorProps<T extends string> {
	status: T;
	className?: string;
	getStatusOption: (v: T) => StatusOption;
}

export const StatusIndicator = <T extends string>({ status, className, getStatusOption }: StatusIndicatorProps<T>) => {
	const option = getStatusOption(status);

	return <Badge color={option.statusId} name={option.label} className={className} />;
};
