import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./floatingTutorialButton.scss";

export interface FloatingTutorialButtonProps {
	isOpened: boolean;
	onClick: () => void;
}

export const FloatingTutorialButton: React.FC<FloatingTutorialButtonProps> = props => {
	const classNames = mergeClassNames("ficon-plus", styles.tutorialButton, props.isOpened && styles.tutorialButtonOpen);

	return <div className={classNames} onClick={props.onClick} data-testid="BS_Lessons_GuideButton" />;
};
