import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./modal.scss";

interface ModalFooterProps {
	centered?: boolean;
	children?: React.ReactNode;
	className?: string;
}

export const ModalFooter = (props: ModalFooterProps) => {
	const modalClassName = mergeClassNames(props.centered ? styles.footerCentered : styles.footer, props.className);
	return <div className={modalClassName}>{props.children}</div>;
};
