import * as React from "react";

import { useLoader } from "../useLoader/useLoader";

import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

interface UseOptimisticSetterProps<T> {
	currentValue: T;
	setter: (value: T) => Promise<void>;
	onChanged?: (value: T) => void;
	onError?: (error: unknown) => void;
}

interface UseOptimisticSetterHandle<T> {
	value: T;
	setValue: (t: T) => void;
	request: RequestState<T>;
}

export const useOptimisticSetter = <V>(props: UseOptimisticSetterProps<V>): UseOptimisticSetterHandle<V> => {
	const [pendingValue, setPendingValue] = React.useState<V | undefined>(undefined);

	const { load: post, request } = useLoader<V, V>({
		endpoint: async v => {
			setPendingValue(v);
			await props.setter(v);
			return v;
		},
		onSuccess: v => {
			if (props.onChanged) {
				props.onChanged(v);
			}
		},
		onError: e => {
			setPendingValue(undefined);
			if (props.onError) {
				props.onError(e);
			}
		},
	});

	return { value: pendingValue ?? props.currentValue, setValue: (newValue: V) => post(newValue), request };
};
