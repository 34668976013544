import * as React from "react";
import { useHistory, useLocation } from "react-router";

export const returnedFromBankIdHash = "#returnedFromBankId";

export const useReturnedFromBankId = ({ onReturnedFromBankId }: { onReturnedFromBankId: () => void }) => {
	const { hash } = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		const queryParams = new URLSearchParams(hash);
		const isReturnedFromBankId = queryParams.has(returnedFromBankIdHash);

		if (isReturnedFromBankId) {
			onReturnedFromBankId();
		}
		//We only want to do the check if the hash has changed. We're not interested if the onReturnedFromBankId changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hash]);

	return {
		clearReturnedFromBankId: () => {
			const queryParams = new URLSearchParams(hash);
			if (queryParams.has(returnedFromBankIdHash)) {
				queryParams.delete(returnedFromBankIdHash);

				history.replace({ hash: queryParams.toString() });
			}
		},
	};
};
