import * as React from "react";

import { FileUploader } from "@bokio/elements/FileUploader/FileUploader";
import { useDragDropEventTracker } from "@bokio/hooks/useDragDropEventTracker/useDragDropEventTracker";

import type { DndEvent } from "@bokio/hooks/useDragDropEventTracker/useDragDropEventTracker";

import * as styles from "./globalFileDropZone.scss";

interface GlobalFileDropZoneProps {
	disabled?: boolean;
	handleUploadFiles: (files: File[]) => void;
}

const checkIsFileTransferEvent = (event: DndEvent) => {
	return !!event.dataTransfer?.types.includes("Files");
};

export const GlobalFileDropZone: React.FC<GlobalFileDropZoneProps> = ({ disabled, handleUploadFiles }) => {
	const rootRef = React.useRef<HTMLDivElement>(null);
	const [isUserDraggingInElement, isDragStartedInElement, onDescendantDropHandled] = useDragDropEventTracker({
		baseElement: document.body,
		dragEnterLeaveEventFilter: checkIsFileTransferEvent,
		descendantDropHandlingElementRef: rootRef,
		disabled,
	});

	if (disabled || isDragStartedInElement || !isUserDraggingInElement) {
		return null;
	}

	return (
		<div data-testid="GlobalFileDropZone" ref={rootRef} className={styles.base}>
			<FileUploader
				testId="GlobalFileDropZone_FileUploader"
				fullscreen
				transparentButton
				noMargin
				dropOnly
				rejectAllOnAnyRejection
				allowMultiple
				acceptedFileTypes={["pdf", "jpg", "jpeg", "png"]}
				onDropHandled={onDescendantDropHandled}
				onFileUpload={handleUploadFiles}
				disablePreview
			/>
		</div>
	);
};
