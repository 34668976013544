// extracted by mini-css-extract-plugin
export var loaderAnimation = "Jr";
export var __loadingSpinner_scss__loaderAnimation = "Jr";
export var loader = "Hr";
export var circle = "Ir";
export var color = "Vy";
export var blue = "cb";
export var green = "Wy";
export var white = "Xy";
export var dark = "WL";
export var center = "qFa";
export var message = "rFa";
export var paddingTop = "Kr";
export var paddingBottom = "Lr";