import { companyInfoContext } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";

import { useTopLevelUser } from "../TopLevelUserContext/useTopLevelUser";

import type * as React from "react";

export const CompanyInfoContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { companyInfo, reloadCompanyInfo } = useTopLevelUser();

	return (
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		<companyInfoContext.Provider value={{ companyInfo: companyInfo!, reloadCompanyInfo }}>
			{children}
		</companyInfoContext.Provider>
	);
};
