import * as React from "react";

import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { GeneralLangFactory } from "@bokio/lang";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

import Markdown from "../Markdown/Markdown";

import * as styles from "./cookieWarning.scss";

interface CookieWarningProps {
	onChange: (val: "Track" | "DoNotTrack") => void;
}

export const CookieWarning: React.FC<CookieWarningProps> = props => {
	const valueRef = React.useRef<"Track" | "DoNotTrack">();
	const [setTracking, request] = useLazyApi(proxy.Settings.PrivacySettingsController.SetTrackingPreference.Post, {
		onSuccess: () => {
			if (valueRef.current) {
				props.onChange(valueRef.current);
			}
		},
	});

	const generalLang = GeneralLangFactory();
	const save = (value: "Track" | "DoNotTrack") => {
		valueRef.current = value;
		setTracking(value);
	};

	return (
		<div className={styles.wrapper}>
			<Markdown markdownContent={generalLang.CookieWarning} />
			<ButtonGroup align="center">
				<Button type="button" appearance="secondary" loading={request.isLoading} onClick={() => save("DoNotTrack")}>
					{generalLang.No}
				</Button>
				<Button type="button" appearance="primary" loading={request.isLoading} onClick={() => save("Track")}>
					{generalLang.CookieWarning_Accept}
				</Button>
			</ButtonGroup>
		</div>
	);
};
