import SimpleTable, { SimpleTableSpec } from "./SimpleTable";
import Table from "./Table";
import TBody from "./TBody";
import Td from "./Td";
import TFoot from "./TFoot";
import Th from "./Th";
import THead from "./THead";
import Tr from "./Tr";

export { Table, THead, TBody, TFoot, Tr, Td, Th, SimpleTable, SimpleTableSpec };
