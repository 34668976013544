import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import ExpandableSectionContent from "./ExpandableSectionContent";
import ExpandableSectionHeader from "./ExpandableSectionHeader";

import * as styles from "./expandableSection.scss";

export interface ExpandableSectionProps {
	id?: string;
	expanded: boolean;
	onChange?: (section: ExpandableSection) => void;
	className?: string;
	testId?: string;
	withBorder?: boolean;
}

export class ExpandableSection extends React.Component<React.PropsWithChildren<ExpandableSectionProps>> {
	render() {
		return (
			<div className={mergeClassNames(this.props.className, this.props.withBorder && styles.border)}>
				{this.renderChildren()}
			</div>
		);
	}

	renderChildren() {
		return React.Children.map(this.props.children, child => {
			const c = child as JSX.Element;
			if (!c) {
				return null;
			} // If conditionally rendering this.props.children, react will read {false && <></> as a child}

			if (c.type !== ExpandableSectionHeader && c.type !== ExpandableSectionContent) {
				return c;
			}

			return React.cloneElement(c, {
				...c.props,
				expanded: this.props.expanded,
				onClick: () => this.props.onChange && this.props.onChange(this),
			});
		});
	}
}
