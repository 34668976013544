import * as React from "react";

import { fastMapBackward } from "@bokio/shared/utils/arrayUtils";
import { flattenChildren } from "@bokio/shared/utils/reactUtils";

import { SGChildClassNameContext, useSGChildClassNameContext } from "./SGChildClassNameContext";
import { SGIgnore } from "./SGIgnore";

import type { ComponentType } from "react";

// prettier-ignore
import * as styles from "./spacingGroup.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

// prettier-ignore
import * as legacyMarginPropStyles from "../../infrastructure/legacyComponentInterop/legacyMarginProp.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

export type ChildrenIterationInfoPointer = {
	foundFragmentUsage: boolean;
};

const isUsingSGFragmentBranding = Symbol("IsUsingSGFragment");

/**
 * Internal function for spacing group that checks the branding of a component constructor,
 * so {@link renderSGChild} knows when to wrap a children or not.
 *
 * SS 2021-09-17
 * Without this it's impossible to tell what component shouldn't be wrapped
 * because we can't figure out what's coming in the child component's return
 * when React is resolving the tree from top to bottom and haven't run the actual component function.
 */
const isUsingSGFragment = <TProps, TComponent extends ComponentType<TProps>>(
	reactComponent: TComponent | undefined | string,
) => {
	return reactComponent && reactComponent[isUsingSGFragmentBranding];
};

const renderSGChild = (
	child: React.ReactChild,
	key: React.Key,
	classNameFromContext: string | undefined,
	fromFragment: boolean,
	childrenIterationInfoPointer: ChildrenIterationInfoPointer | undefined,
) => {
	// SS 2021-09-17
	// This was originally a "SGChild" component,
	// but since we are in full control of this,
	// and we don't run any hooks here,
	// it's fine to use render function instead of real component to speed up rendering.

	// React.isValidElement(child) should lead you to the same type but `any` should be fine here,
	// plus that we want to save a function call for an obvious thing on the hot path.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const childType = (child as any)?.type as undefined | string | React.JSXElementConstructor<unknown>;
	const isSGFragmentUsed = isUsingSGFragment(childType);
	if (isSGFragmentUsed && childrenIterationInfoPointer) {
		childrenIterationInfoPointer.foundFragmentUsage = true;
	}
	const shouldWrap = classNameFromContext && !isSGFragmentUsed;

	const wrapped = shouldWrap && (
		<span
			className={
				styles.item +
				" " +
				legacyMarginPropStyles.cancelLegacyOutwardMargin +
				" " +
				(childType === SGIgnore ? styles.itemIgnored : classNameFromContext)
			}
		>
			{child}
		</span>
	);

	return (
		<React.Fragment key={key}>
			{wrapped ? (
				// Terminate the context to prevent the descendant from incorrectly
				// taking class name from somewhere higher up we have no idea of.
				// This is mainly for the case where someone accidently moved a component using SGFragment outisde of a SG.
				fromFragment ? (
					<SGChildClassNameContext.Provider value="">{wrapped}</SGChildClassNameContext.Provider>
				) : (
					wrapped
				)
			) : (
				child
			)}
		</React.Fragment>
	);
};

export const wrapChildrenWithSGChild = (
	children: React.ReactNode,
	classNameFromContext: string | undefined,
	fromFragment: boolean,
	childrenIterationInfoPointer: ChildrenIterationInfoPointer | undefined,
) => {
	return fastMapBackward(flattenChildren(children), (child, index) =>
		renderSGChild(
			child,
			// flattenChildren doesn't inject key on string and number children by intention.
			// The "|| index" part should only happen on string and number.
			// We need a key here because we later wrap the child with an extra fragment.
			typeof child["key"] === "undefined" ? index : child["key"],
			classNameFromContext,
			fromFragment,
			childrenIterationInfoPointer,
		),
	);
};

/**
 * Tells the parent spacing group (SG) that there is a {@link SGFragment} inside the component can pick up the parent gap and apply it.
 *
 * SS 2021-09-17
 * This isn't made as a higher-order function
 * because it's more hassle to maintain the component name in React DevTools if we do it that way,
 * e.g. you'll need to assign displayName and make sure function are coded in
 * the way where JS engine can pick names up.
 *
 * @example
 * const Component: React.FC = () => {
 *   <SGFragment>
 *     <p>1</p>
 *     <p>2</p>
 *     <p>3</p>
 *   </SGFragment>
 * }
 * markAsUsingSGFragment(Component);
 *
 * // Then when rendered like this, p 1 to 4 will have gap 16 in between.
 * <SG gap="16">
 *   <Component />
 *   <p>4</p>
 * </SG>
 */
export const markAsUsingSGFragment = <P,>(reactComponent: ComponentType<P>): void => {
	reactComponent[isUsingSGFragmentBranding] = true;
};

/**
 * Pick up the gap from parent spacing group (SG).
 * Need to be combined with {@link markAsUsingSGFragment} to get it working.
 *
 * @example
 * const Component: React.FC = () => {
 *   <SGFragment>
 *     <p>1</p>
 *     <p>2</p>
 *     <p>3</p>
 *   </SGFragment>
 * }
 * markAsUsingSGFragment(Component);
 *
 * // Then when rendered like this, p 1 to 4 will have gap 16 in between.
 * <SG gap="16">
 *   <Component />
 *   <p>4</p>
 * </SG>
 */
export const SGFragment = (props: React.PropsWithChildren<unknown>) => {
	const classNameFromContext = useSGChildClassNameContext();
	return <>{wrapChildrenWithSGChild(props.children, classNameFromContext, true, undefined)}</>;
};
