export enum Grade {
	VeryBad = 1,
	Bad = 2,
	Okay = 3,
	Good = 4,
	Excellent = 5,
}

export enum Steps {
	Rating,
	Feedback,
	Complete,
}
