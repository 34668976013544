// extracted by mini-css-extract-plugin
export var button = "Xka";
export var disabled = "Yka";
export var primary = "Zka";
export var disabledPrimary = "ya";
export var secondary = "ala";
export var disabledSecondary = "za";
export var destructive = "cla";
export var disabledDestructive = "Aa";
export var ghost = "f";
export var disabledGhost = "M";
export var normal = "ela";
export var small = "fla";
export var icon = "ila";
export var loading = "jla";
export var alignSelfBaseline = "kv";
export var alignSelfCenter = "lv";
export var alignSelfFlexEnd = "By";
export var alignSelfFlexStart = "Gy";
export var marginTop = "qE";
export var marginRight = "OK";
export var marginBottom = "LT";
export var marginLeft = "MT";
export var margin = "OW";