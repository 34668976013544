import { OnboardingLangFactory } from "@bokio/lang";

import type { PromotionListItemProps } from "../promotionModalModels";

export const trialAndFreeChecklist = (props: PromotionListItemProps) => {
	const onboardingLang = OnboardingLangFactory();

	return [
		onboardingLang.Onboarding_PromotionChecklist_bookkeeping,
		props.bba && onboardingLang.Onboarding_PromotionChecklist_bba,
		props.bba && props.plus && onboardingLang.Onboarding_PromotionChecklist_cashback,
		props.bba && props.plus && onboardingLang.Onboarding_PromotionChecklist_support,
	].filter(item => item);
};
