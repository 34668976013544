import debounce from "lodash-es/debounce";
import * as React from "react";

interface DebounceOptions {
	delay: number;
}

// NOTE: MQ 2019-08-19 Explain why 250 is the median reaction time https://stackoverflow.com/a/44755058/1349340
export const REACTION_TIME = 250;

export const useDebounce = <T extends unknown[]>(
	func: (...args: T) => void,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	deps: ReadonlyArray<any>,
	{ delay }: DebounceOptions = { delay: REACTION_TIME },
	// eslint-disable-next-line react-hooks/exhaustive-deps
) => React.useCallback(debounce(func, delay), deps);

export function useDebouncedValue<T>(value: T, options: DebounceOptions = { delay: REACTION_TIME }) {
	const [debounced, setDebounced] = React.useState<T>(value);
	const setValue = useDebounce((value: T) => setDebounced(value), [], options);
	React.useEffect(() => {
		setValue(value);
	}, [value, setValue]);
	return debounced;
}
