import * as React from "react";

import { InputField } from "@bokio/elements/Form/InputField";
import { parseNumber, parseString } from "@bokio/mobile-web-shared/core/utils/numberUtils";
import { formatWithDecimalCount } from "@bokio/shared/utils/format";

import type { InputFieldProps, InputFieldPropsBase } from "@bokio/elements/Form/InputField";

export interface FloatFieldProps extends Partial<InputFieldPropsBase<number | undefined>> {
	zeroOnEmpty?: boolean;
	allowMathematicalExpression?: boolean;
	setAmountInvalid?: (isValid: boolean) => void;
	setMathematicalExpression?: (rawValue: string) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	innerRef?: React.MutableRefObject<HTMLInputElement | null>;
}

/**
 * A field which parses and provides floating point numbers.
 *
 * In either case, onChange() will be called with the parsed float value and optionally
 * the raw string currently in the input field.
 */

export const FloatField: React.FC<FloatFieldProps> = props => {
	const [value, setValue] = React.useState<number>();
	const [rawValue, setRawValue] = React.useState<string>(props.value == null ? "" : props.value.toString());

	React.useEffect(() => {
		if (props.value !== value && props.value !== value) {
			const rawValue = props.value ? formatWithDecimalCount(props.value, 0, 20) : "";
			setRawValue(rawValue);
			setValue(props.value);
		}
	}, [props.value, value]);

	const notify = (val: number | undefined) => {
		if (props.onChange) {
			props.onChange(val);
		}
	};
	const onChange = (value: string) => {
		if (!!props.allowMathematicalExpression) {
			const num = props.zeroOnEmpty && value === "" ? 0 : parseString(value);
			props.setMathematicalExpression && props.setMathematicalExpression(value);
			if (typeof num === "string") {
				// this is the stage where the user is in middle of writing mathematical expression
				setRawValue(value);
				setValue(0);
				notify(undefined);
				props.setAmountInvalid && props.setAmountInvalid(true);
			} else if (isNaN(num)) {
				setRawValue(value);
				setValue(undefined);
				notify(undefined);
				props.setAmountInvalid && props.setAmountInvalid(true);
			} else {
				props.setAmountInvalid && props.setAmountInvalid(false);
				setRawValue(value);
				setValue(num);
				notify(num);
			}
		} else {
			const num = props.zeroOnEmpty && value === "" ? 0 : parseNumber(value);
			if (isNaN(num)) {
				setRawValue(value);
				setValue(undefined);
				notify(undefined);
			} else {
				setRawValue(value);
				setValue(num);
				notify(num);
			}
		}
	};

	return (
		<InputField
			innerRef={props.innerRef}
			{...(props as InputFieldProps)}
			value={rawValue}
			onChange={onChange}
			onKeyDown={props.onKeyDown}
		/>
	);

	// We want this to run any time the value of the input field is changed:
	//  - When props.value changes.
	//  - When the user types in the underlying InputField.
	//
	// The reason for this is that we want to give the parsed value to the parent component,
	// even when the first value is provided.
	// onChange(rawValue: string) {

	//   if (parentOnChange) {
	//     if (rawValue === null || rawValue === undefined) {
	//       parentOnChange(NaN, rawValue);
	//       return;
	//     }

	//     const numerics = rawValue.trim();

	//     // TODO: could be "static", and as objects with predicate & transform
	//     const formats: [[(s: string) => boolean, (s: string) => string]] = [
	//       [ // 1234.56
	//         s => /^-?[0-9]+(\.[0-9]+)?$/.test(s),
	//         s => s
	//       ],
	//       [ // 1234,56
	//         s => /^-?[0-9]+(,[0-9]+)?$/.test(s),
	//         s => s.replace(',', '.')
	//       ],
	//       [ // 1,234.56 or 1 234.56
	//         s => /^-?([0-9]+,?)+(\.[0-9]+)?$/.test(s) || /^-?([0-9]+\s?)+(\.[0-9]+)?$/.test(s),
	//         s => s.replace(/[,\s]/g, '')
	//       ],
	//       [ // 1.234,56 or 1 234,56
	//         s => /^-?([0-9]+\.?)+(,[0-9]+)?$/.test(s) || /^-?([0-9]+\s?)+(,[0-9]+)?$/.test(s),
	//         s => s.replace(/[\.\s]/g, '').replace(',', '.')
	//       ],
	//     ];

	//     for (var i = 0; i < formats.length; i++) {
	//       if (formats[i][0](numerics)) {
	//         const candidate = formats[i][1](numerics);
	//         if (this.notifyValidValue(candidate, rawValue, parentOnChange)) {
	//           return;
	//         }
	//       }
	//     }

	//     parentOnChange(NaN, rawValue);
	//   }
	// }
};
