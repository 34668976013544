// extracted by mini-css-extract-plugin
export var menuLogo = "rl";
export var logo = "tl";
export var closeIcon = "ul";
export var menuCounter = "vl";
export var menuSettings = "wl";
export var menuItemsWrapper = "xl";
export var bottomNavMobile = "yl";
export var divider = "Rma";
export var header = "kE";
export var headerTitle = "BE";
export var headerRow = "TVa";