import * as React from "react";

import CompanyLink from "@bokio/components/CompanyLink/CompanyLink";
import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { mergeClassNames } from "@bokio/utils/classes";

import { useDeviceQuery } from "../DeviceQuery/useDeviceQuery";

import * as styles from "./boxHeader.scss";

export interface HeaderActionGroup {
	primary: React.ReactNode;
	secondary?: React.ReactNode;
}

export interface BoxHeaderActions {
	/**
	 * Fixed to the right, regardless of media.
	 */
	fixed?: React.ReactNode;
	/**
	 * Placed to the right when there is space.
	 * Otherwise stacked under the box title, with 100% width.
	 */
	flow?: React.ReactElement | HeaderActionGroup[];
}

interface BoxHeaderProps {
	title?: React.ReactNode | string;
	titleClassName?: string;
	backRoute?: string;
	backRouteWithCompany?: string;
	withPadding?: boolean;
	postTitleContent?: React.ReactNode;
	className?: string;
	actions?: BoxHeaderActions;
}

const BoxHeader = (props: BoxHeaderProps) => {
	const { backRoute, titleClassName, backRouteWithCompany, withPadding, postTitleContent, title, className, actions } =
		props;
	const hasBack = backRoute || backRouteWithCompany;
	const LinkComponent = props.backRouteWithCompany ? CompanyLink : Link;
	const device = useDeviceQuery();
	return (
		<div
			className={mergeClassNames(
				styles.header,
				withPadding && styles.headerWithPadding,
				className,
				postTitleContent && styles.withPostTitle,
			)}
		>
			<div className={mergeClassNames(styles.titleRow, postTitleContent && styles.withPostTitle)}>
				{hasBack && (
					<div className={styles.backButton}>
						<LinkComponent route={props.backRouteWithCompany || props.backRoute} style="none" testId="Page_BackRoute">
							<Icon name="left-open-big" size="24" />
						</LinkComponent>
					</div>
				)}
				<h1 className={mergeClassNames(styles.title, titleClassName)}>{title}</h1>
				{postTitleContent && (
					<span className={styles.postTitleWrapper}>
						<span className={styles.postTitle}>{postTitleContent}</span>
					</span>
				)}
				{!device.isMobile &&
					actions &&
					actions.flow &&
					(React.isValidElement(actions.flow)
						? actions.flow
						: (actions.flow as HeaderActionGroup[]).flatMap((a, i) => [
								<div className={styles.action} key={`PrimaryAction_${i}`}>
									{a.primary}
								</div>,
								a.secondary && (
									<div className={styles.action} key={`SecondaryAction_${i}`}>
										{a.secondary}
									</div>
								),
							]))}
				{actions && actions.fixed}
			</div>

			{device.isMobile &&
				actions &&
				actions.flow &&
				(React.isValidElement(actions.flow)
					? actions.flow
					: (actions.flow as HeaderActionGroup[]).map((a, i) => (
							<div className={styles.stackedActionGroup} key={`Action_${i}`}>
								<div className={styles.stackedPrimaryAction}>{a.primary}</div>
								{a.secondary && <div className={styles.stackedSecondaryAction}>{a.secondary}</div>}
							</div>
						)))}
		</div>
	);
};

export default BoxHeader;
