import { ProcessingBlock } from "@bokio/bank/src/scenes/Svea/Onboarding/components/ProcessingBlock";
import { Button } from "@bokio/designsystem/components/Button";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { RenderRequestError } from "@bokio/elements/Loading";
import { GeneralLangFactory } from "@bokio/lang";

import { BankIdSecureStart } from "../BankIdSecureStart/BankIdSecureStart";

import type { BankIdState } from "./useSveaBankIdState";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";
import type * as React from "react";

type SveaBankIdSignButtonProps = {
	children?: React.ReactNode;
	bankIdProps: BankIdState;
	pollingRequest?: RequestState<unknown>;
	onRetry?: () => void;
};

export function SveaBankIdFragment(props: SveaBankIdSignButtonProps) {
	const generalLang = GeneralLangFactory();
	return (
		<SG gap="16">
			{props.children}
			{props.bankIdProps.autoStartToken &&
				(props.bankIdProps.bankIdQrCode ? <BankIdSecureStart {...props.bankIdProps} /> : <ProcessingBlock />)}

			{props.pollingRequest && (
				<RenderRequestError
					request={props.pollingRequest}
					// AP 2024-05: eventually if we decide to implement some retry logic, we can use this code!
					appendToError={
						props.onRetry && (
							<Button margin={["top"]} appearance="secondary" onClick={() => props.onRetry?.()}>
								{generalLang.TryAgain}
							</Button>
						)
					}
				/>
			)}
		</SG>
	);
}
