import { Link } from "@bokio/elements/Link/Link";
import withActiveUserInfo from "@bokio/shared/containers/user/withActiveUserInfo";

import type { LinkProps } from "@bokio/elements/Link/Link";
import type { ActiveUserProps } from "@bokio/shared/containers/user/withActiveUserInfo";

type CompanyLinkProps = LinkProps;

const CompanyLink = (props: CompanyLinkProps & ActiveUserProps) => {
	const { companyId, route, external, ...otherProps } = props;

	const routeWithCompany = route ? `/${companyId}${route}` : undefined;
	const externalWithCompany = external ? `/${companyId}${external}` : undefined;

	return <Link {...otherProps} route={routeWithCompany} external={externalWithCompany} />;
};

export default withActiveUserInfo(CompanyLink);
