import * as React from "react";

import bankidSvg from "@bokio/assets/images/bankid.svg";
import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Div, Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { Link } from "@bokio/elements/Link/Link";
import { useDeviceUserAgent } from "@bokio/hooks/useDeviceUserAgent/useDeviceUserAgent";
import { returnedFromBankIdHash } from "@bokio/hooks/useReturnedFromBankId/useReturnedFromBankId";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import { BankIdHintCode } from "../BankIdHintCode/BankIdHintCode";
import { QrCode } from "../QrCode/QrCode";

import * as styles from "./bankIdSecureStart.scss";

import BankIdHintCodeEnum = m.Bokio.Common.Contract.BankId.BankIdHintCode;
import BankIdCollectionStatus = m.Bokio.Common.Contract.BankId.BankIdCollectionStatus;

interface BankIdSecureStartProps {
	autoStartToken?: string;
	bankIdQrCode?: string;
	collectionStatus?: BankIdCollectionStatus;
	hintCode?: BankIdHintCodeEnum;

	onStartPollingQrCode?: () => void;
	onStopPollingQrCode?: () => void;
	/**
	 * BokioPay login flow polls the collect endpoint separately,
	 * so {@link onStartPollingQrCode} doesn't need to run in auto start view (=the default view in mobile) for BokioPay;
	 * but for Svea flow the collect polliing is the same call for getting the QR code,
	 * we use this flag for temporarily separating the flow before we clean up the code between branches.
	 */
	isBokioPayLogin?: boolean;
}

export type BankIdSecureStartLoginView = "autostart" | "qr";

export const BankIdSecureStart = ({
	autoStartToken,
	bankIdQrCode,
	collectionStatus,
	hintCode,
	onStartPollingQrCode,
	onStopPollingQrCode,
	isBokioPayLogin,
}: BankIdSecureStartProps) => {
	const generalLang = GeneralLangFactory();
	const { isMobile } = useDeviceQuery();
	const [loginView, setLoginView] = React.useState<BankIdSecureStartLoginView>(isMobile ? "autostart" : "qr");
	const [appLaunchedAutomatically, setAppLaunchedAutomatically] = React.useState(false);
	const { isIOS, isChromeOnIOs, isFirefoxOnIOs } = useDeviceUserAgent();

	const getAutostartUrl = (bankIdAutoStartToken: string) => {
		if (!isIOS) {
			return `bankid:///?autostarttoken=${bankIdAutoStartToken}&redirect=null`;
		}

		if (isChromeOnIOs) {
			return `bankid:///?autostarttoken=${bankIdAutoStartToken}&redirect=${encodeURIComponent("googlechromes://")}`;
		}

		if (isFirefoxOnIOs) {
			return `bankid:///?autostarttoken=${bankIdAutoStartToken}&redirect=${encodeURIComponent("firefox://")}`;
		}

		//Safari on IOS deallocates the tab and restarts it when going from and to the BankID app.
		//Thats why we need to signal to ourselves that we're coming back with the query param so that we can start polling again
		const redirect = `${encodeURIComponent(location.href.replace(returnedFromBankIdHash, ""))}${returnedFromBankIdHash}`; //Replace if we already have one in the URL to prevent weird state
		return `bankid:///?autostarttoken=${bankIdAutoStartToken}&redirect=${redirect}`;
	};

	const launchBankIdApp = (openedAutomatically: boolean, bankIdAutoStartToken: string) => {
		setAppLaunchedAutomatically(openedAutomatically);

		const autostartUrl = getAutostartUrl(bankIdAutoStartToken);

		window.location.href = autostartUrl;
	};

	React.useEffect(() => {
		if (autoStartToken && loginView === "autostart") {
			launchBankIdApp(true, autoStartToken);
		}

		// TODO: Fix functions in the hook to make sure they are proper useCallback
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoStartToken, loginView]);

	React.useEffect(() => {
		if (!autoStartToken) {
			return;
		}

		if (isBokioPayLogin && loginView === "autostart") {
			// BokioPay polls the collect endpoint separately,
			// so we don't need to poll the QR code when we don't need to show the QR code.
			return;
		}

		// For BokioPay: Poll QR code
		// For Svea: Poll collect & QR code
		onStartPollingQrCode?.();

		return () => {
			onStopPollingQrCode?.();
		};

		// TODO: Fix functions in the hook to make sure they are proper useCallback
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoStartToken, isBokioPayLogin, loginView]);

	return (
		<SG align="center" gap="16">
			<Div>
				<img src={bankidSvg} className={styles.bankIdLogo} alt={generalLang.BankID} />
			</Div>
			{hintCode && collectionStatus && (
				<BankIdHintCode
					bankIdSecureStartLoginView={loginView}
					hintCode={hintCode}
					collectionStatus={collectionStatus}
					triedToLaunchAppAutomatically={appLaunchedAutomatically}
				/>
			)}

			{loginView == "qr" ? (
				<Div>
					{bankIdQrCode && <QrCode data={bankIdQrCode} />}
					<Div>
						<Link onClick={() => setLoginView("autostart")}>{generalLang.BankID_OpenOnThisDevice}</Link>
					</Div>
				</Div>
			) : (
				<Div>
					<SG gap="8">
						<Paragraph textCenter>{generalLang.BankId_NotOpening}</Paragraph>
						<ButtonGroup direction="column">
							<Button
								type="button"
								icon="bankid-solid"
								iconAlign="right"
								margin={["top", "bottom"]}
								onClick={() => autoStartToken && launchBankIdApp(false, autoStartToken)}
								appearance="primary"
							>
								{generalLang.BankId_Open}
							</Button>
							<Button
								type="button"
								icon="bankid-solid"
								iconAlign="right"
								margin={["top", "bottom"]}
								onClick={() => setLoginView("qr")}
								appearance="secondary"
							>
								{generalLang.BankID_OpenOnOtherDevice}
							</Button>
						</ButtonGroup>
					</SG>
				</Div>
			)}
		</SG>
	);
};
