import * as React from "react";

import { AppMessageType, useAppContext } from "@bokio/contexts/AppContext/AppContext";
import { AccountingLangFactory, GeneralLangFactory } from "@bokio/lang";
import { toEnvelope } from "@bokio/mobile-web-shared/core/utils/loaderHelpers";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { formatMessage } from "@bokio/shared/utils/format";
import { resizeImageToBlob } from "@bokio/shared/utils/ImageResizer";
import { readerResultToString } from "@bokio/shared/utils/stream";

//4MiB * 1024 * 1024 is actually 4,194,304 but we hit the limitation of the server with exact 4MiB and get a 500 error.
//With 4_100_000 the user can upload a file of size 3 998 KB (in the windows filesystem interpretation) without triggering the server error
export const MAX_PDF_FILE_SIZE = 4_100_000;
export const MAX_ADDITIONAL_FILE_SIZE = 2_097_152; //2MiB
export const ADDITIONAL_FILES_ALLOWED_FILE_TYPES =
	"image/jpeg, image/png, application/pdf, text/plain, .csv, .xls, .xlsx";

export interface BlobFile {
	Data: Blob;
	Name: string;
	Type: string;
}

const prepareImageForUploadAsDataUrl = (file: File): Promise<ReturnType<typeof resizeImageToBlob>> => {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.onload = () => {
			const imageData = reader.result;
			const imageElement = new Image();
			imageElement.onload = () => {
				const newSize = file.type === "image/png" ? 1024 : 2048;
				resolve(resizeImageToBlob(imageElement, newSize, newSize, file.type, 0.8));
			};
			imageElement.src = readerResultToString(imageData);
		};
		reader.readAsDataURL(file);
	});
};

export const prepareImageForUpload = async (file: File): Promise<BlobFile> => {
	const blob = (await prepareImageForUploadAsDataUrl(file)).toBlob();

	return {
		Data: blob,
		Name: file.name,
		Type: file.type,
	};
};

export const prepareImageForUploadAsBlob = async (file: File): Promise<Blob> => {
	return (await prepareImageForUploadAsDataUrl(file)).toBlob();
};

export const uploadImageRequest = async ({
	companyId,
	image,
	startReceiptPredictionNow,
	autoVerificationRowId,
	verificationId,
}: {
	companyId: string;
	image: BlobFile;
	startReceiptPredictionNow: boolean;
	autoVerificationRowId?: string;
	verificationId?: string;
}) => {
	const data = await proxy.Accounting.ReceiptController.UploadReceiptWithFile.Post(
		companyId,
		new File([image.Data], image.Name, { type: image.Type }),
		"uncategorized",
		undefined,
		verificationId,
		autoVerificationRowId,
		undefined,
		startReceiptPredictionNow,
	);
	return toEnvelope(data);
};

export const uploadPdfRequest = async ({
	companyId,
	pdf,
	startReceiptPredictionNow,
	autoVerificationRowId,
	verificationId,
}: {
	companyId: string;
	pdf: File;
	startReceiptPredictionNow: boolean;
	autoVerificationRowId?: string;
	verificationId?: string;
}) => {
	const generalLang = GeneralLangFactory();
	if (pdf.size > MAX_PDF_FILE_SIZE) {
		const data = await Promise.reject({ message: generalLang.ReceiptUpload_PDFTooBig });
		return toEnvelope(data);
	}
	return proxy.Accounting.ReceiptController.UploadPdf.Post(
		companyId,
		pdf,
		"uncategorized",
		undefined,
		verificationId,
		autoVerificationRowId,
		startReceiptPredictionNow,
	).then(toEnvelope);
};

export const fileTypeAllowedTest = (allowedFileTypes: string, file: File) => {
	return (
		allowedFileTypes.split(", ").some(y => y === file.type) ||
		allowedFileTypes.split(", ").some(y => y === file.name.substring(file.name.lastIndexOf(".")))
	);
};

export const useUploadAdditionalFile = (allUploadingFilesCompleted: () => void) => {
	const activeRequests = React.useRef(0);
	const { addMessage } = useAppContext();

	const [uploadAdditionalFile] = useLazyApi((companyId: string, file: File) => {
		if (file.size > MAX_ADDITIONAL_FILE_SIZE || !fileTypeAllowedTest(ADDITIONAL_FILES_ALLOWED_FILE_TYPES, file)) {
			return Promise.reject();
		}
		activeRequests.current++;
		return proxy.Bokio.Accounting.Web.AdditionalFiles.AdditionalFilesController.UploadAdditionalFile.Post(
			companyId,
			file,
		).finally(() => {
			activeRequests.current--;
			activeRequests.current === 0 && allUploadingFilesCompleted();
		});
	});

	const uploadMultipleFiles = React.useCallback(
		(companyId: string, files: File[]) => {
			const accountingLang = AccountingLangFactory();
			const errors = { fileSizeError: false, fileTypeError: false };
			errors.fileSizeError = files.some(x => x.size > MAX_ADDITIONAL_FILE_SIZE);
			errors.fileTypeError = files.some(x => !fileTypeAllowedTest(ADDITIONAL_FILES_ALLOWED_FILE_TYPES, x));
			files.forEach(f => uploadAdditionalFile(companyId, f));

			errors.fileSizeError &&
				addMessage({
					message: formatMessage(
						accountingLang.AdditionalFileToBigNotice,
						(MAX_ADDITIONAL_FILE_SIZE / 1000000).toFixed(),
					),
					persist: false,
					type: AppMessageType.WarningMessage,
				});
			errors.fileTypeError &&
				addMessage({
					message: accountingLang.AdditionalFileUpload_FileTypeNotSupported,
					persist: false,
					type: AppMessageType.WarningMessage,
				});

			return errors;
		},
		[uploadAdditionalFile, addMessage],
	);

	return uploadMultipleFiles;
};

export const connectReceiptEndpoint = (params: {
	org: string;
	receiptId: string;
	autoVerificationRowId?: string;
	verificationId?: string;
	supplierInvoiceId?: string;
	manualPaymentId?: string;
}) => {
	const { org, receiptId, autoVerificationRowId, verificationId, supplierInvoiceId, manualPaymentId } = params;

	if (autoVerificationRowId) {
		return proxy.Bank.AutoVerificationController.ConnectToReceipt.Post(org, {
			ReceiptId: receiptId,
			AutoVerificationRowId: autoVerificationRowId,
		});
	} else if (verificationId) {
		return proxy.Accounting.ReceiptController.ConnectToVerification.Post(org, {
			ReceiptId: receiptId,
			VerificationId: verificationId,
		});
	} else if (supplierInvoiceId) {
		return proxy.Accounting.SupplierInvoiceController.ConnectToReceipt.Post(org, {
			ReceiptId: receiptId,
			SupplierInvoiceId: supplierInvoiceId,
		});
	} else if (manualPaymentId) {
		return proxy.Bank.PaymentController.ConnectManualPaymentToReceipt.Post(org, {
			ReceiptId: receiptId,
			ManualPaymentId: manualPaymentId,
		});
	}
	return Promise.reject();
};
