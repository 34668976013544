import * as React from "react";

import CheckBox from "@bokio/elements/CheckBox/CheckBox";
import Icon from "@bokio/elements/Icon/Icon";
import classes from "@bokio/utils/classes";

import { ListColumn } from "../ListColumn/ListColumn";

import * as styles from "./listItem.scss";

export interface ListItemProps {
	onClick?: () => void;
	clickable?: boolean;
	check?: boolean;
	checked?: boolean;
	children?: React.ReactNode;
	onChange?: (checked: boolean) => void;
}

export class ListItem extends React.Component<React.PropsWithChildren<ListItemProps>> {
	onChange(checked: boolean) {
		if (this.props.onChange) {
			this.props.onChange(checked);
		}
	}

	render() {
		const clickable = this.props.clickable || this.props.onClick;

		const classNames = classes(styles, "item", { clickable });

		return (
			<div className={classNames} onClick={this.onClick}>
				{this.props.check && (
					<CheckBox
						onChange={(checked: boolean) => this.onChange(checked)}
						checked={this.props.checked}
						rightMargin={true}
					/>
				)}
				{this.props.children}
				{clickable && this.renderArrow()}
			</div>
		);
	}

	renderArrow() {
		return (
			<ListColumn key="list-action" width="40px">
				<Icon name="right-open-big" />
			</ListColumn>
		);
	}

	onClick = () => {
		if (this.props.onClick) {
			this.props.onClick();
		}
	};
}
