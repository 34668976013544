import * as React from "react";

import EnquiryModal from "@bokio/components/EnquiryModal/EnquiryModal";
import RenderRequest from "@bokio/components/RenderRequest/RenderRequest";
import { AppMessageType, useAppContext } from "@bokio/contexts/AppContext/AppContext";
import { Button } from "@bokio/designsystem/components/Button";
import { Badge } from "@bokio/elements/Badge/Badge";
import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingContent } from "@bokio/elements/Loading";
import { useOptimisticSetter } from "@bokio/hooks/useOptimisticSetter/useOptimisticSetter";
import { useAgencyUserPreference } from "@bokio/hooks/useUserPreference/useAgencyUserPreference";
import { GeneralLangFactory, SettingsLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

import { getTiersInfo } from "./loyaltyProgramUtils";

import * as styles from "./MenuLoyaltyProgram.scss";

import ContractStatus = m.Entities.ContractStatus;
interface LoyaltyProgramContentUKProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	agencyStatus: any;
	onLinkClick: () => void;
}

export const LoyaltyProgramContentUK: React.FC<LoyaltyProgramContentUKProps> = ({ agencyStatus, onLinkClick }) => {
	const generalLang = GeneralLangFactory();
	const settingsLang = SettingsLangFactory();
	const [tierInfo] = useApi(proxy.BackOffice.AgencyController.GetTiersInfo.Get, [agencyStatus?.Id]);
	const [showFeedbackModal, setShowFeedbackModal] = React.useState<boolean>(false);
	const app = useAppContext();
	const userPreferences = useAgencyUserPreference(
		agencyStatus.Id,
		m.Entities.AgencyUserPreferenceType.MenuLoyaltyPlanCollapsedPreference,
	);

	const collapsed = useOptimisticSetter({
		currentValue: !!userPreferences.preferenceValue?.MenuLoyaltyPlanCollapsedPreference?.Value,
		setter: async state => {
			await userPreferences.setPreference({
				value: {
					MenuLoyaltyPlanCollapsedPreference: { Value: state },
				},
			});
		},
	});

	const addSuccessMessage = () => {
		app.addMessage({
			type: AppMessageType.SuccessMessage,
			title: generalLang.LoyaltyProgram_RegisterInterest_SuccessHeading,
			content: generalLang.LoyaltyProgram_RegisterInterest_SuccessMessage,
			persist: false,
		});
	};
	const handleSuccess = () => {
		collapsed.setValue(true);
		addSuccessMessage();
	};

	const StatusId = () => {
		return agencyStatus.ContractStatus === ContractStatus.NotSigned ? "draft" : "active";
	};

	return (
		<RenderRequest request={tierInfo} whenLoading={<LoadingContent lines={1} padding={true} />}>
			{tiersNumber => {
				const StatusInfo = () => {
					switch (agencyStatus.ContractStatus) {
						case ContractStatus.Signed:
							return getTiersInfo(tiersNumber, generalLang)?.label;
						case ContractStatus.Pending:
							return generalLang.LoyaltyProgram_StatusPending;
						case ContractStatus.NotSigned:
						default:
							return generalLang.LoyaltyProgram_StatusInactive;
					}
				};
				const renderStatusInfo = () => {
					return (
						<div className={styles.statusInfo}>
							{agencyStatus.ContractStatus === ContractStatus.Signed ? (
								<div data-testid={`MenuLoyaltyProgram_${StatusInfo()}`}>{StatusInfo()}</div>
							) : (
								<Badge
									color={StatusId()}
									name={StatusInfo()}
									testId={`MenuLoyaltyProgram_Status_${agencyStatus.ContractStatus}`}
								/>
							)}
						</div>
					);
				};

				return (
					<div className={styles.plan}>
						<>
							<div>
								<Link
									style="none"
									onClick={() => collapsed.setValue(!collapsed.value)}
									className={styles.titleAndToggle}
								>
									<div>
										<div className={styles.title}>{generalLang.LoyaltyProgram_LoyaltyProgram}</div>
										{renderStatusInfo()}
									</div>
									<div className={styles.tierAndToggle}>
										{agencyStatus.ContractStatus == ContractStatus.Signed &&
											getTiersInfo(tiersNumber, generalLang)?.image && (
												<img className={styles.tierIcon} src={getTiersInfo(tiersNumber, generalLang)?.image} />
											)}
										<Icon name={collapsed.value ? "up-open-big" : "down-open-big"} className={styles.arrow} />
									</div>
								</Link>
							</div>
							{!collapsed.value && (
								<>
									<div className={styles.buttonContainer}>
										{agencyStatus.ContractStatus == ContractStatus.NotSigned ? (
											<Button
												testId="MenuLoyaltyProgram_RegisterInterest"
												appearance="primary"
												stretch
												size="small"
												onClick={() => {
													setShowFeedbackModal(true);
												}}
											>
												{generalLang.LoyaltyProgram_RegisterInterest}
											</Button>
										) : (
											<Button
												route={generalLang.LoyaltyProgram_ViewTiersLink}
												type="link"
												external
												target="_blank"
												appearance="secondary"
												stretch
												testId="MenuLoyaltyProgram_ViewTiers"
												size="small"
												onNavigation={onLinkClick}
											>
												{generalLang.LoyaltyProgram_ViewTiers}
											</Button>
										)}
									</div>
								</>
							)}
							<EnquiryModal
								title={settingsLang.Integrations_OpenApi_RegisterInterest}
								visible={showFeedbackModal}
								subject={generalLang.LoyaltyProgram_RegisterInterestEnquiry}
								area={m.Contracts.SupportFormArea.BackOffice}
								category={m.Contracts.SupportFormCategory.LoyaltyProgram}
								sub={m.Contracts.SupportFormSubCategory.LoyaltyProgramEnquiry}
								messagePlaceholder={generalLang.GeneralFeedback_WriteYourMessageHere_text}
								onClose={() => setShowFeedbackModal(false)}
								onSuccess={handleSuccess}
							/>
						</>
					</div>
				);
			}}
		</RenderRequest>
	);
};
