import compact from "lodash-es/compact";

import { BankLangFactory, GeneralLangFactory, InvoicesLangFactory } from "@bokio/lang";

import type { DefinitionListTableRow } from "@bokio/components/DefinitionListTable/DefinitionListTable";
import type * as m from "@bokio/mobile-web-shared/core/model/model";

type TellerPaymentInformationUnion = m.Classes.Teller.TellerPaymentInformationUnion;
export const getRecipientName = (union: TellerPaymentInformationUnion): string => {
	return (
		union.BankgiroPaymentInfo?.RecipientName ||
		union.PlusgiroPaymentInfo?.RecipientName ||
		union.SekClearingPaymentInformation?.RecipientName ||
		union.SepaPaymentInformation?.RecipientName ||
		union.SwiftPaymentInfo?.RecipientName ||
		""
	);
};

export const getDisplayedPaymentFor = (senderReference: string, union: TellerPaymentInformationUnion): string =>
	senderReference ? `${senderReference} - ${getRecipientName(union)}` : getRecipientName(union);

/**
 * @param union This is made optional to allow you to just `...getDefinitionListTableRows(union)` instead of `...(!!union ? getDefinitionListTableRows(union) : [])`
 */
export const getDefinitionListTableRows = (
	union: TellerPaymentInformationUnion | undefined,
): DefinitionListTableRow[] => {
	const generalLang = GeneralLangFactory();
	const bankLang = BankLangFactory();
	const invoiceLang = InvoicesLangFactory();

	return compact([
		...((): DefinitionListTableRow[] => {
			const giro = union?.BankgiroPaymentInfo || union?.PlusgiroPaymentInfo;
			return compact([
				giro?.RecipientName && [bankLang.RecipientName, giro.RecipientName],
				!!union?.BankgiroPaymentInfo && [bankLang.BankgiroNumber, union?.BankgiroPaymentInfo.BankgiroNumber],
				!!union?.PlusgiroPaymentInfo && [bankLang.PlusgiroNumber, union?.PlusgiroPaymentInfo.PlusgiroNumber],
				giro?.Ocr !== undefined && [invoiceLang.OcrNumberFullText, giro.Ocr],
				giro?.Message !== undefined && [generalLang.Message, giro.Message],
			]);
		})(),
		...((): DefinitionListTableRow[] => {
			const clearing = union?.SekClearingPaymentInformation;

			return !clearing
				? []
				: [
						[bankLang.RecipientName, clearing.RecipientName],
						[generalLang.ClearingNumber, clearing.ClearingNumber],
						[invoiceLang.PaymentMethod_AccountNumber, clearing.AccountNumber],
						[generalLang.Message, clearing.Message],
					];
		})(),
		...((): DefinitionListTableRow[] => {
			const sepa = union?.SepaPaymentInformation;

			return !sepa
				? []
				: [
						[bankLang.RecipientName, sepa.RecipientName],
						[invoiceLang.PaymentMethod_IBAN, sepa.AccountNumber],
						[generalLang.Message, sepa.Message],
					];
		})(),

		...((): DefinitionListTableRow[] => {
			const swift = union?.SwiftPaymentInfo;

			return !swift
				? []
				: [
						[bankLang.RecipientName, swift.RecipientName],
						[invoiceLang.PaymentMethod_IBAN, swift.AccountNumber],
						[invoiceLang.PaymentMethod_BIC, swift.BicCode],
						[generalLang.Message, swift.Message],
					];
		})(),
	]);
};
