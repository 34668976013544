import * as React from "react";
import { Link } from "react-router-dom";

import Icon from "@bokio/elements/Icon/Icon";
import { LoadingSpinner } from "@bokio/elements/Loading";
import { mergeClassNames } from "@bokio/utils/classes";

import {
	getAlignSelfClassName,
	getButtonDisabledClassName,
	getIconAlignClassName,
	getIconSize,
	getLoadingSpinnerColor,
	getMarginClassName,
} from "./button.helper";

import type {
	Appearance,
	CommonButtonHtmlProps,
	CommonButtonProps,
	ExternalLinkButtonProps,
	IconAlign,
	LinkButtonProps,
	NormalButtonProps,
	SubmitButtonProps,
} from "./button.types";
import type { FontelloIcons } from "@bokio/assets/fontello";

// prettier-ignore
import * as styles from "./button.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

// prettier-ignore
import * as baseStyles from "./button.base.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

interface ButtonIconProps extends CommonButtonProps {
	icon: FontelloIcons | React.ReactElement;
	children?: React.ReactText | React.ReactText[];
}

interface ButtonTextProps extends CommonButtonProps {
	icon?: FontelloIcons | React.ReactElement;
	children?: React.ReactText | React.ReactText[];
}

export type ButtonProps = (ButtonIconProps | ButtonTextProps) & {
	appearance?: Appearance;
	iconAlign?: IconAlign;
	stretch?: boolean;
};

export type ButtonPropsUnion = ButtonProps &
	(NormalButtonProps | LinkButtonProps | ExternalLinkButtonProps | SubmitButtonProps);

export const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonPropsUnion>(
	(
		{
			children,
			icon,
			disabled,
			loading,
			title,
			testId,
			id,
			autoFocus,
			appearance = "primary",
			size = "normal",
			iconAlign = "left",
			stretch,
			align,
			margin,
			...props
		},
		ref,
	) => {
		const isDisabled = disabled || loading;

		const showIconLeft = iconAlign === "left";
		const showIconRight = iconAlign !== "left";

		const classNames = mergeClassNames(
			baseStyles.button,
			styles.button,
			baseStyles[appearance],
			baseStyles[size],
			stretch === true && styles.stretch,
			// Only activate autoResize if explicitly receive stretch as false
			stretch === false && styles.autoResize,
			loading && baseStyles.loading,
			disabled && baseStyles.disabled,
			disabled && getButtonDisabledClassName(appearance),
			align && getAlignSelfClassName(align),
			margin && getMarginClassName(margin),
		);

		const iconClassNames = mergeClassNames(
			baseStyles.icon,
			getIconSize(size),
			getIconAlignClassName(iconAlign),
			loading && styles.hide,
		);

		const commonProps: CommonButtonHtmlProps = {
			id,
			title: title ?? (typeof children === "string" || typeof children === "number" ? children.toString() : undefined),
			className: classNames,
			"data-testid": testId,
		};

		const renderIcon = () =>
			React.isValidElement(icon) ? (
				<span className={iconClassNames}>{icon}</span>
			) : (
				<Icon className={iconClassNames} name={icon as FontelloIcons} size={getIconSize(size)} />
			);

		const inner = (
			<>
				{icon && showIconLeft && renderIcon()}
				<span className={loading ? styles.hide : undefined}>{children ?? <wbr />}</span>
				{icon && showIconRight && renderIcon()}
			</>
		);

		if (props.type === "link" && disabled) {
			return (
				<a {...commonProps} ref={ref as React.Ref<HTMLAnchorElement>}>
					{inner}
				</a>
			); // should be a span but wanted the ref to stay the same
		}

		if (props.type === "link" && !props.external) {
			return (
				<Link
					{...commonProps}
					onClick={e => (disabled ? e.preventDefault() : props.onNavigation?.())}
					to={props.route}
					innerRef={ref as React.Ref<HTMLAnchorElement>}
				>
					{inner}
				</Link>
			);
		}

		if (props.type === "link" && props.external) {
			return (
				<a
					{...commonProps}
					onClick={e => (disabled ? e.preventDefault() : props.onNavigation?.())}
					href={props.route}
					download={props.download}
					target={props.target}
					ref={ref as React.Ref<HTMLAnchorElement>}
				>
					{inner}
				</a>
			);
		}

		if (props.type === "submit") {
			return (
				<button
					{...commonProps}
					disabled={isDisabled}
					onClick={isDisabled ? undefined : props.onClick}
					autoFocus={autoFocus}
					type="submit"
					ref={ref as React.Ref<HTMLButtonElement>}
				>
					{loading && (
						<LoadingSpinner color={getLoadingSpinnerColor(appearance)} className={styles.loadingSpinner} size={20} />
					)}
					{inner}
				</button>
			);
		}

		return (
			<button
				{...commonProps}
				onClick={isDisabled ? undefined : props.onClick}
				disabled={isDisabled}
				autoFocus={autoFocus}
				type="button"
				ref={ref as React.Ref<HTMLButtonElement>}
			>
				{loading && (
					<LoadingSpinner color={getLoadingSpinnerColor(appearance)} className={styles.loadingSpinner} size={20} />
				)}
				{inner}
			</button>
		);
	},
);
