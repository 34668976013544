const getResizedCanvas = (
	image: HTMLImageElement,
	maxWidth: number,
	maxHeight: number,
	autoRotate = true,
): HTMLCanvasElement => {
	const h = image.height;
	const w = image.width;
	const ratioW = w / maxWidth;
	const ratioH = h / maxHeight;
	const maxRatio = Math.max(1, ratioW, ratioH);

	const newW = Math.round(w / maxRatio);
	const newH = Math.round(h / maxRatio);

	const canvas = document.createElement("canvas");
	canvas.width = newW;
	canvas.height = newH;
	const context = canvas.getContext("2d");

	if (!context) {
		throw new Error("Failed getting canvas context.");
	}

	// ** This fixes an auto-rotate bug only on mobile devices when uploading an image from a camera.
	// ** This should be refactored in the future.
	if (newW > newH && typeof window.orientation !== "undefined" && autoRotate) {
		canvas.width = newH;
		canvas.height = newW;
		context.translate(newH, 0);
		context.rotate(Math.PI / 2);
	}
	context.drawImage(image, 0, 0, newW, newH);

	return canvas;
};

const dataURItoBlob = (dataURI: string, type: string) => {
	const byteString = atob(dataURI.split(",")[1]);
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type });
};

export const resizeImageToBlob = (
	image: HTMLImageElement,
	maxWidth: number,
	maxHeight: number,
	type: string,
	quality: number,
	autoRotate = true,
): { toBlob: () => Blob } => {
	const result = getResizedCanvas(image, maxWidth, maxHeight, autoRotate).toDataURL(type, quality);

	return {
		/**
		 * SS 2021-05-11
		 * Not using `HTMLCanvasElement.toBlob` because it doesn't support quality arg on mobile so far,
		 * although `HTMLCanvasElement.toBlob` should be more memory-efficient.
		 */
		toBlob: () => dataURItoBlob(result, type),
	};
};
