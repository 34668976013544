import * as React from "react";

import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { Heading, Paragraph, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { TextAreaField } from "@bokio/elements/Form";
import { GeneralLangFactory } from "@bokio/lang";

import { getRatingLabel, getRatingMessage } from "../../NPSFeedbackHelper";

import * as styles from "./ratingMessageStep.scss";

interface RatingMessageStepProps {
	score: number;
	onSubmit: (message: string) => void;
	loading: boolean;
}

export const RatingMessageStep: React.FC<RatingMessageStepProps> = ({ score, onSubmit, loading }) => {
	const [message, setMessage] = React.useState("");
	const generalLang = GeneralLangFactory();

	const handleRatingWithMessage = () => onSubmit(message);

	return (
		<Section bumpHeadingLevel>
			<Heading textCenter>{getRatingMessage(score)}</Heading>
			<Paragraph>
				<div className={styles.textareaWrapper}>
					<TextAreaField
						label={getRatingLabel(score)}
						value={message}
						onChange={setMessage}
						testId="RatingMessageStep_TextArea"
					/>
				</div>
			</Paragraph>
			<Paragraph>
				<ButtonGroup direction="row" align="center">
					<Button
						appearance="secondary"
						onClick={handleRatingWithMessage}
						disabled={loading}
						testId="RatingMessageStep_NotThisTime"
					>
						{generalLang.NPS_NotThisTime}
					</Button>
					<Button onClick={handleRatingWithMessage} disabled={loading || !message} testId="RatingMessageStep_Submit">
						{generalLang.NPS_SendFeedback}
					</Button>
				</ButtonGroup>
			</Paragraph>
		</Section>
	);
};
