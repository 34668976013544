import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { EmailField, FormGroup, FormWithValidation } from "@bokio/elements/Form";
import { asValidatorFunction } from "@bokio/elements/Form/FormWithValidation/FormWithValidation";
import { RenderRequestError } from "@bokio/elements/Loading";
import { GeneralLangFactory, SignUpLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { withErrorMessageFromEnvelope } from "@bokio/mobile-web-shared/core/utils/loaderHelpers";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { filterByField } from "@bokio/shared/validation/entityValidator";

import UserNameValidator from "./validators/UserNameValidator";

import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";
import type { ValidatorResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./loginForm.scss";

import LoginMethod = m.Bokio.Common.Contract.LoginMethod;
interface LoginEmailStepProps {
	onSubmit: (email: string) => void;
	defaultValue: string;
	getLoginMethodRequest: RequestState<m.Envelope<LoginMethod[], m.SimpleError>>;
}

const LoginEmailStep = (props: LoginEmailStepProps) => {
	const { isMobile } = useDeviceQuery();
	const lang = GeneralLangFactory();
	const signupLang = SignUpLangFactory();

	const [currentUserEmail, setUserEmail] = React.useState(props.defaultValue);

	const router = useRouter();
	const isAccountant = router.location?.pathname.startsWith(getRoute("connectAgency")) ?? false;

	const initialState = { userName: currentUserEmail };
	return (
		<FormWithValidation<typeof initialState, ValidatorResult>
			initialState={initialState}
			onChange={formData => {
				setUserEmail(formData.userName);
			}}
			onSubmit={() => props.onSubmit(currentUserEmail)}
			validator={asValidatorFunction(new UserNameValidator(lang))}
		>
			{({ formData, setValue, validation }) => (
				<React.Fragment>
					<p className={styles.intro}>
						{isAccountant ? signupLang.Login_EmailDescriptionAgency : signupLang.Login_EmailDescription}
					</p>
					<FormGroup>
						<EmailField
							nameForAutofill={"userName"}
							onChange={setValue("userName")}
							label={lang.Email}
							value={formData.userName}
							disabled={props.getLoginMethodRequest.isLoading}
							autoFocus={!isMobile}
							errors={filterByField("userName", validation.errors)}
							testId="LoginEmailStep_Email"
						/>
					</FormGroup>

					<FormGroup align="center">
						<Button type="submit" loading={props.getLoginMethodRequest.isLoading} testId="LoginEmailStep_Continue">
							{lang.Continue}
						</Button>
					</FormGroup>
					<RenderRequestError request={withErrorMessageFromEnvelope(props.getLoginMethodRequest)} />
				</React.Fragment>
			)}
		</FormWithValidation>
	);
};

export default LoginEmailStep;
