import { readFromLocalStorage, writeToLocalStorage } from "@bokio/hooks/useLocalStorage/useLocalStorage";

export type PaymentSigningProgressInfo = {
	hasInitiated: boolean;
	isPolling: boolean;
	paymentGroupIds: string[] | undefined;
	SuccessfulPaymentIds: string[] | undefined;
	signingToken: string | undefined;
};

enum SveaBankIdSigningLocalStorageSavedStates {
	AuthProgressInfo = "sveaAuthToken",
	PaymentSigningProgressInfo = "sveaPaymentSigningInfo",
}

/**
 * Save the status polling token when a Svea authentication is in progress. This token value is used to resume the polling process
 * if the polling is interuppted due to a page refresh while the polling is in progress.
 * @param token
 */
export function saveSveaAuthPollingTokenToLocalStorage(token: string) {
	writeToLocalStorage(SveaBankIdSigningLocalStorageSavedStates.AuthProgressInfo, token);
}
export function readSveaAuthPollingTokenFromLocalStorage(): string | undefined {
	return readFromLocalStorage<string>(SveaBankIdSigningLocalStorageSavedStates.AuthProgressInfo);
}
export function clearSveaAuthPollingTokenFromLocalStorage() {
	writeToLocalStorage(SveaBankIdSigningLocalStorageSavedStates.AuthProgressInfo, undefined);
}

/**
 * Save the status when a Svea payment signing is started & in progress. This is used to resume the polling with the same token
 * if the polling is interuppted due to a page refresh. Sometimes Safari in IOS deallocate the memory when it navigate to
 * BankId app. When the BankId app redirect the user back to Safari browser, if the memory is deallocated, it will trigger a refreshe.
 * In those scenario we need to resume the same polling session to continue the payment signing.
 * @param paymentSigningProgressInfo
 */
export function savePaymentSigningProgressInfoToLocalStorage(paymentSigningProgressInfo: PaymentSigningProgressInfo) {
	writeToLocalStorage<PaymentSigningProgressInfo>(
		SveaBankIdSigningLocalStorageSavedStates.PaymentSigningProgressInfo,
		paymentSigningProgressInfo,
	);
}
export function readPaymentSigningProgressInfoFromLocalStorage(): PaymentSigningProgressInfo | undefined {
	return readFromLocalStorage<PaymentSigningProgressInfo>(
		SveaBankIdSigningLocalStorageSavedStates.PaymentSigningProgressInfo,
	);
}
export function clearPaymentSigningProgressInfoFromLocalStorage() {
	writeToLocalStorage(SveaBankIdSigningLocalStorageSavedStates.PaymentSigningProgressInfo, undefined);
}
