import type {
	CommonButtonProps,
	ExternalLinkButtonProps,
	LinkButtonProps,
	Margin,
	NormalButtonProps,
} from "../Button/button.types";
import type { PopoverCommonProps } from "../Popover/Popover.types";
import type { FontelloIcons } from "@bokio/assets/fontello";
import type * as React from "react";

export type DropdownItem = (NormalButtonProps | LinkButtonProps | ExternalLinkButtonProps) & {
	key: React.Key;
	label: React.ReactNode;
	active?: boolean;
	disabled?: boolean;
	appearance?: "normal" | "destructive";
	testId?: string;
	tooltip?: string;
};

export interface DropdownItemGroup {
	label: string;
	items: DropdownItem[];
}

export type DropdownMenuItemProps = {
	item: DropdownItem;
	onClick?: () => void;
	setItemRef?: (ref: HTMLElement | null) => void;
	focused?: boolean;
};

export interface DropdownMenuProps {
	focusedItemKey?: DropdownItem["key"];
	setItemRef?: (key: React.Key) => (ref: HTMLElement | null) => void;
	testId?: string;
	items?: DropdownItem[];
	groups?: DropdownItemGroup[];
	onItemClick?: (item: DropdownItem) => void;
}

interface TriggerProps {
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	ref: React.RefObject<HTMLButtonElement>;
	disabled: boolean;
}

interface TriggerState {
	isOpen: boolean;
}

interface DropdownDefaultTriggerProps {
	type?: "default";
	margin?: Margin;
	trigger: (props: TriggerProps, state: TriggerState) => React.ReactNode;
}

interface DropdownButtonTriggerProps {
	type?: "default";
	margin?: Margin;
	trigger: string;
	size?: CommonButtonProps["size"];
}

interface DropdownIconTriggerProps {
	type: "icon";
	margin?: Margin;
	icon?: FontelloIcons;
	size?: CommonButtonProps["size"];
}

type DropdownTriggerProps = DropdownDefaultTriggerProps | DropdownButtonTriggerProps | DropdownIconTriggerProps;

export const isDropdownTriggerPropsForDefault = (x: DropdownTriggerProps): x is DropdownDefaultTriggerProps => {
	return "trigger" in x && typeof x.trigger === "function";
};

export const isDropdownTriggerPropsForButton = (x: DropdownTriggerProps): x is DropdownButtonTriggerProps => {
	return "trigger" in x && typeof x.trigger === "string";
};

export type DropdownProps = PopoverCommonProps &
	DropdownTriggerProps &
	Pick<DropdownMenuProps, "items" | "groups"> & {
		disabled?: boolean;
	};
