import { produce } from "immer";
import * as React from "react";

import { languageNotifier } from "@bokio/lang/languageNotifier";
import { toEnvelopeEndpoint, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { Config } from "@bokio/shared/config";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { signalRStart } from "@bokio/shared/services/api/signalRStart";
import { trackException, trackTrace } from "@bokio/utils/t";

import { calculatePermissions, TopLevelUserContext } from "./TopLevelUserContext";

import type { SavedLoginState } from "./TopLevelUserContext";

/**
 * SS 2024-09-23
 * Code here is mostly to implement what we had while we still had a global Redux store so we can easier remove Redux,
 * therefore even if some of the props/actions can be separated into different contexts,
 * I chose to just put all of them into one big context.
 */
export const TopLevelUserProvider: React.FC<React.PropsWithChildren> = props => {
	const router = useRouter();

	const [loginsState, setLoginsState] = React.useState<SavedLoginState>(() => {
		const loadLoginState = (): SavedLoginState => {
			const defaultValue: SavedLoginState = {
				currentUserEmail: "",
				logins: {},
			};

			if (!localStorage) {
				trackTrace("localstorage is not defined", {}, "warn");
				return defaultValue;
			}
			try {
				const serializedState = localStorage.getItem("state");
				if (serializedState === null) {
					return defaultValue;
				}
				return JSON.parse(serializedState)?.logins ?? defaultValue;
			} catch (err) {
				return defaultValue;
			}
		};

		return loadLoginState();
	});

	React.useEffect(() => {
		if (!localStorage) {
			trackTrace("localstorage is not defined", {}, "warn");
			return;
		}
		try {
			const serializedState = JSON.stringify({
				logins: loginsState,
			});
			localStorage.setItem("state", serializedState);
		} catch (err) {
			trackException(err, "persist.ts", {});
		}
	}, [loginsState]);

	const [showLogin, setShowLogin] = React.useState(false);

	const [getCompanyUser, getCompanyUserRequest] = useLazyApi(
		toEnvelopeEndpoint(proxy.Settings.StatusController.Get.Get),
		{
			onSuccess: data => {
				languageNotifier.load(data.UserConfiguration.Lang);
			},
		},
	);

	const [getUser, getUserRequest] = useLazyApi(toEnvelopeEndpoint(proxy.Settings.UserController.Index.Get), {
		onSuccess: data => {
			languageNotifier.load(data.Language);
		},
	});

	const [getMemberships, getMembershipsRequest] = useLazyApi(
		toEnvelopeEndpoint(async (companyIdInit: string | undefined) => {
			let companyId = companyIdInit;

			const partners = await proxy.Settings.StatusController.PartnerMemberships.Get();

			if (!companyId) {
				const user = await proxy.Settings.UserController.Index.Get();

				if (user && user.CompanyMemberships[0]) {
					companyId = user.CompanyMemberships[0].CompanyId;
				} else {
					return { partners, companies: [] };
				}
			}

			if (!companyId) {
				return {
					companies: [],
					partners,
				};
			}

			const companies = await proxy.Settings.StatusController.Memberships.Get(companyId);

			return {
				companies,
				partners,
			};
		}),
	);

	const [getAgencyStatus, getAgencyStatusRequest] = useLazyApi(
		proxy.BackOffice.PartnerViewerController.AgencyStatus.Get,
	);

	const [getAgencyCount, getAgencyCountRequest] = useLazyApi(proxy.BackOffice.PartnerViewerController.GetCount.Get);

	// TODO: Check if this cache busting counter is really needed, feels strange
	const [todoCacheBuster, setTodoCacheBuster] = React.useState(0);
	const [getTodoCount, getTodoCountRequest] = useLazyApi(proxy.Common.ToDoController.Count.Get, {
		onSuccess: () => setTodoCacheBuster(x => x + 1),
		onError: () => setTodoCacheBuster(x => x + 1),
		onApiError: () => setTodoCacheBuster(x => x + 1),
	});

	const reloadAgencyStatus = React.useCallback(async () => {
		router.currentAgencyId && (await getAgencyStatus(router.currentAgencyId));
	}, [getAgencyStatus, router]);

	const reloadAgencyCount = React.useCallback(async () => {
		router.currentAgencyId && (await getAgencyCount(router.currentAgencyId));
	}, [getAgencyCount, router]);

	const updateTodoStatus = React.useCallback(async () => {
		router.currentCompanyId && getTodoCount(router.currentCompanyId);
	}, [getTodoCount, router]);

	const reloadCompanyInfo = React.useCallback(async () => {
		router.currentCompanyId && getCompanyUser(router.currentCompanyId);
	}, [getCompanyUser, router]);

	const reloadMemberships = React.useCallback(async () => {
		getMemberships(router.currentCompanyId);
	}, [getMemberships, router]);

	const clearUser = React.useCallback(async () => {
		await Promise.all([getUser(), getMemberships(router.currentCompanyId)]);
	}, [
		// Using router in the dependency array so the function identity doesn't change based on ID changes
		router,
		getMemberships,
		getUser,
	]);

	const clearAll = React.useCallback(async () => {
		await Promise.all([
			clearUser(),
			router.currentCompanyId && getCompanyUser(router.currentCompanyId),
			router.currentAgencyId && getAgencyStatus(router.currentAgencyId),
			router.currentAgencyId && getAgencyCount(router.currentAgencyId),
			router.currentCompanyId && getTodoCount(router.currentCompanyId),
		]);
	}, [
		// Using router in the dependency array so the function identity doesn't change based on ID changes
		router,
		clearUser,
		getAgencyCount,
		getAgencyStatus,
		getCompanyUser,
		getTodoCount,
	]);

	React.useEffect(() => {
		const companyIdFromApiResponse = getCompanyUserRequest.data?.Data?.Company.Id;

		const agencyIdFromApiResponse = getAgencyStatusRequest.data?.Data?.Id;

		if (router.currentCompanyId !== companyIdFromApiResponse || router.currentAgencyId !== agencyIdFromApiResponse) {
			clearAll();
		}
	}, [
		clearAll,
		router.currentAgencyId,
		router.currentCompanyId,
		getAgencyStatusRequest.data?.Data?.Id,
		getCompanyUserRequest.data?.Data?.Company.Id,
	]);

	const currentUserId = getUserRequest.data?.Data?.Id ?? getCompanyUserRequest.data?.Data?.UserId;

	const signOut = React.useCallback(async () => {
		try {
			await fetch(Config.env.apiUrl + "/Account/LogOff", {
				method: "post",
				redirect: "manual",
				credentials: "same-origin",
			});
			router.redirectHard(getRoute("login"));
		} catch {
			router.redirectHard(getRoute("login"));
		}
	}, [router]);

	const canStartSignalR = !!getUserRequest.data?.Data;
	React.useEffect(() => {
		if (canStartSignalR) {
			signalRStart();
		}
	}, [canStartSignalR]);

	return (
		<TopLevelUserContext.Provider
			value={{
				currentUserId,
				currentCompanyId: router.currentCompanyId,
				currentAgencyId: router.currentAgencyId,
				currentEmployeeId: getCompanyUserRequest.data?.Data?.EmployeeId,
				currentLang: getCompanyUserRequest.data?.Data?.UserConfiguration.Lang,

				companyUser: getCompanyUserRequest.data?.Data,
				hasErrorGettingCompanyUser: getCompanyUserRequest.error,
				isLoadingCompany: getCompanyUserRequest.isLoading,
				reloadCompanyUser: async () => {
					if (!router.currentCompanyId) {
						return;
					}

					await getCompanyUser(router.currentCompanyId);
					await getUser();
				},

				user: getUserRequest.data?.Data,
				isLoadingUser: getUserRequest.isLoading,
				reloadUser: async () => {
					getUser();
				},

				agencyStatus: getAgencyStatusRequest.data?.Data,
				isLoadingAgencyStatus: getAgencyStatusRequest.isLoading,
				reloadAgencyStatus,

				agencyCount: getAgencyCountRequest.data?.Data ?? {
					ClientRequestCount: 0,
					QuoteRequestCount: 0,
					TotalCount: 0,
				},
				isLoadingAgencyCount: getAgencyCountRequest.isLoading,
				reloadAgencyCount: reloadAgencyCount,

				todoCount: getTodoCountRequest.data?.Data,
				isLoadingTodo: getTodoCountRequest.isLoading,
				todoCacheBuster: todoCacheBuster,
				updateTodoStatus,

				companyInfo: getCompanyUserRequest.data?.Data?.Company,
				reloadCompanyInfo,
				companyUserPermissions: calculatePermissions(getCompanyUserRequest?.data?.Data),

				memberships: getMembershipsRequest.data?.Data,
				isLoadingMemberships: getMembershipsRequest.isLoading,
				reloadMemberships,

				clearUser,
				clearAll,
				signOut,

				showLogin,
				setShowLogin,
				loginsControls: {
					currentUserEmail: loginsState.currentUserEmail,
					logins: loginsState.logins,
					setUserEmail: email =>
						setLoginsState(
							produce(draft => {
								draft.currentUserEmail = email;

								if (!draft.logins[email]) {
									draft.logins[email] = {
										userEmail: email,
										userFirstName: "",
										userLastName: "",
										userLoginMethod: undefined,
										userPersonalNumber: "",
									};
								}
							}),
						),
					setUserFirstName: v =>
						setLoginsState(
							produce(draft => {
								draft.logins[draft.currentUserEmail].userFirstName = v;
							}),
						),
					setUserLastName: v =>
						setLoginsState(
							produce(draft => {
								draft.logins[draft.currentUserEmail].userLastName = v;
							}),
						),
					setUserLoginMethod: v =>
						setLoginsState(
							produce(draft => {
								draft.logins[draft.currentUserEmail].userLoginMethod = v;
							}),
						),
					setUserPersonalNumber: v =>
						setLoginsState(
							produce(draft => {
								draft.logins[draft.currentUserEmail].userPersonalNumber = v;
							}),
						),
				},
			}}
		>
			{props.children}
		</TopLevelUserContext.Provider>
	);
};
