import { AnimatedFailFeedbackGraphic, AnimatedFeedbackGraphic } from "./AnimatedFeedbackGraphic";

import type * as React from "react";

import * as styles from "./animatedFeedbackGraphicModalContent.scss";

interface AnimatedFeedbackGraphicModalContentProps {
	type: "success" | "fail";
	heading: string;
	description: React.ReactNode;
	className?: string;
}

export const AnimatedFeedbackGraphicModalContent: React.FC<AnimatedFeedbackGraphicModalContentProps> = ({
	type,
	heading,
	description,
	className,
}) => {
	return (
		<div className={className}>
			<div className={styles.successFeedbackBlock}>
				{type === "success" ? <AnimatedFeedbackGraphic /> : <AnimatedFailFeedbackGraphic />}
				<h2 className={styles.feedbackHeading}>{heading}</h2>
				<p className={styles.feedbackDescription}>{description}</p>
			</div>
		</div>
	);
};
