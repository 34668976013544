import color from "@bokio/mobile-web-shared/color";

import { generateBreakpoints } from "./theme.helper";

const breakpoint = generateBreakpoints({
	mobile: 480,
	tablet: {
		portrait: 768,
		landscape: 1024,
	},
	desktop: 1280,
	desktopLarge: 1600,
});

const typography = {
	// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
	weight: {
		regular: 400,
		medium: 500,
		semibold: 600,
		bold: 700,
	},
} as const;

// SS 2021-09-09
// If you want to migrate these values, do it in multiple steps to ensure things are mapped correctly:
// 1. Rename the spacing names to like `${readable name}-${actual value}`, e.g. medium-16px.
// 2. Migrate spacing based on the new mapping of readable name.
// 3. Rename values back to the simple actual value form so we don't introduce new noise in the code.
const spacing = {
	// 0x, this is an utility rather than real "spacing".
	0: "0px",
	// 0.5x
	4: "4px",
	// 0.75x
	6: "6px",
	// 1x
	8: "8px",
	// 1.5x
	12: "12px",
	// 2x
	16: "16px",
	// 3x
	24: "24px",
	// 4x
	32: "32px",
	// 5x
	40: "40px",
	// 6x
	48: "48px",
} as const;

export type StandardSpacingNumbers = keyof typeof spacing;
export type StandardSpacingNames = `${StandardSpacingNumbers}`;

const modal = {
	"padding-top": "50px", // spacing from modal to top of the screen
	"padding-bottom": "90px", // spacing from modal to bottom of the screen, need larger because we have chatlio at the bottom
	"content-padding": "16px", // padding in content (the part between header and footer)
	"content-max-height": "calc(100vh - #{$modal-padding-top} - #{$modal-padding-bottom} - 57px - 69px)", // 57 = modal header, 69 = modal footer
};

export const theme = {
	color,
	breakpoint,
	typography,
	spacing,
	modal,
};

type NestedKeyOf<ObjectType extends object> = {
	[Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
		? `${Key}` | `${Key}-${NestedKeyOf<ObjectType[Key]>}`
		: `${Key}`;
}[keyof ObjectType & (string | number)];

export type CssVariableColorNames = Exclude<`${NestedKeyOf<typeof color>}`, keyof typeof color>;
