import * as React from "react";

import { ModalStackContext } from "@bokio/utils/UseModalStack/ModalStackContext";

/**
 * @deprecated Directly using Modal2 component should be preferred
 * over {@link useModalStack} and {@link useModalsAsync} because modal hooks doesn't pass prop updates like
 * the normal React Portal behaviour, which is causing confusion.
 * This hook is kept mainly for backward compatibility.
 */
export const useModalStack = () => React.useContext(ModalStackContext);
