import * as React from "react";

import { noop } from "@bokio/shared/utils";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export type BbaMigrationContext = {
	bbaMigrationState: m.Bokio.Bank.Contract.BbaMigration.BbaMigrationStateDto | undefined;
	bbaMigrationStateIsLoading: boolean;
	refreshBbaMigrationState: () => void;
};

export const bbaMigrationContext = React.createContext<BbaMigrationContext>({
	bbaMigrationState: undefined,
	bbaMigrationStateIsLoading: true,
	refreshBbaMigrationState: noop,
});

export const useBbaMigrationContext = () => React.useContext(bbaMigrationContext);
