import { GeneralLangFactory } from "@bokio/lang";

import { StepContainer } from "./StepContainer";

import type * as React from "react";

import * as styles from "./ratingToast.scss";

interface FeedbackStepProps {
	canProceed: boolean;
	description: string;
	title: string;
	onChange: (feedback: string) => void;
	handleClose: () => void;
	handleSubmit: () => Promise<void>;
}

export const FeedbackStep: React.FC<FeedbackStepProps> = ({
	canProceed,
	description,
	title,
	handleClose,
	handleSubmit,
	onChange,
}) => {
	const lang = GeneralLangFactory();

	return (
		<StepContainer
			canProceed={canProceed}
			description={description}
			title={title}
			handleClose={handleClose}
			handleSubmit={handleSubmit}
		>
			{/* VM 2020-02-04: I'm using a regular textarea instead of TextFieldField because it behaves weirdly
			and resizes the height of the input which breaks this component. */}
			<textarea
				rows={3}
				placeholder={lang.BookkeepingRating_Feedback_Placeholder}
				className={styles.feedbackTextarea}
				onChange={event => onChange(event.target.value)}
				data-testid="Rating_FeedbackField"
			/>
		</StepContainer>
	);
};
