import * as React from "react";

import { createEventHub } from "@bokio/shared/services/api/signalRHub";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export interface NotificationActivityState {
	badgeCount: number;
	/**
	 * Used to be able to rerender pages when a notification of
	 * a specific type comes in.
	 */
	activityCounter: { eInvoices: number };
}

export const defaultContextValue: NotificationActivityState = {
	badgeCount: 0,
	activityCounter: { eInvoices: 0 },
};

export const NotificationActivityContext = React.createContext(defaultContextValue);

export const notificationActivityHub = createEventHub<m.Core.Services.Notifications.Dtos.NotificationWebActivityDto>();
