import BokioLogo from "@bokio/components/BokioLogo/BokioLogo";
import { cssVariableForBgColor } from "@bokio/designsystem/theme";
import { mergeClassNames } from "@bokio/utils/classes";

import { FloatingHelp } from "../FloatingHelp/FloatingHelp";

import type { CssVariableColorNames } from "@bokio/designsystem/theme/theme";
import type * as React from "react";

import * as styles from "./layoutFull.scss";

export type LayoutFullType = "legacy" | "plain";

interface LayoutFullProps {
	noTopBarMode: boolean;
	noIconMode?: boolean;

	backgroundColor?: CssVariableColorNames;
	type?: LayoutFullType;
}

const getLayoutFullTypeClass = (type: LayoutFullType = "legacy"): string => {
	// PA, 2024-09-18: It might feel like overkill to do a switch when there are only two options (and one of them is the default)
	// I put this here to make it easier to add new variations in the future.
	// I also put the default value on the argument side of things rather than use a default statement in the switch.
	// This is more of a personal prefrence than anything else, but I really like this way of doing defaults (in this case anyway).

	switch (type) {
		case "plain": {
			return styles.plainLayout;
		}
		case "legacy": {
			return styles.legacyLayout;
		}
	}
};

export const LayoutFull: React.FC<React.PropsWithChildren<LayoutFullProps>> = props => {
	const layoutFullTypeClass = getLayoutFullTypeClass(props.type);

	return (
		<div
			className={mergeClassNames(styles.wrapper, layoutFullTypeClass)}
			style={cssVariableForBgColor(props.backgroundColor, "--bgColor")}
		>
			{!props.noIconMode && (
				<div className={styles.logo}>
					<BokioLogo size="48" inverted={false} imageClassName={styles.logoImage} />
				</div>
			)}

			{props.children}
			{props.noTopBarMode && <FloatingHelp />}
		</div>
	);
};
