import * as React from "react";

import { AppContext, AppMessageType, useAppContext } from "@bokio/contexts/AppContext/AppContext";

import type { AppActions, AppMessage, AppState } from "@bokio/contexts/AppContext/AppContext";

export interface ErrorMessageProps {
	setErrorMessage: (message: string) => void;
	clearErrorMessage: () => void;
	currentMessage: AppMessage | undefined;
}

// KO 12-9-23: This is exported to enable mocking it in tests
export const getErrorMessageProps = (app: AppState & AppActions): ErrorMessageProps => {
	return {
		setErrorMessage: message => app.addMessage({ type: AppMessageType.ErrorMessage, message, persist: false }),
		clearErrorMessage: () => {
			const errorMessages = app.messages.filter(m => m.type === AppMessageType.ErrorMessage);
			errorMessages.forEach(m => app.dismissMessage(m.key));
		},
		currentMessage: app.messages.length > 0 ? app.messages[0] : undefined,
	};
};

const withErrorMessage = <T,>(Component: React.ComponentType<T & ErrorMessageProps>) =>
	class extends React.Component<T> {
		render() {
			return (
				<AppContext.Consumer>{app => <Component {...this.props} {...getErrorMessageProps(app)} />}</AppContext.Consumer>
			);
		}
	};

export const useErrorMessage = () => getErrorMessageProps(useAppContext());

export default withErrorMessage;
