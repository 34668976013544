import { Link } from "react-router-dom";

import { mergeClassNames } from "@bokio/utils/classes";

import type { CommonButtonHtmlProps } from "../../Button/button.types";
import type { DropdownMenuItemProps } from "../Dropdown.types";
import type * as React from "react";

import * as styles from "./dropdownMenuItem.scss";

export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
	item: { label, active, disabled, appearance, testId, tooltip, ...props },
	onClick,
	focused,
	setItemRef,
}) => {
	const commonProps: CommonButtonHtmlProps = {
		className: mergeClassNames(
			styles.item,
			active && styles.active,
			disabled && styles.disabled,
			appearance === "destructive" && styles.destructive,
			focused && styles.focused,
		),
		title: typeof label === "string" ? label : undefined,
		"data-testid": testId,
		tabIndex: -1,
		role: "menuItem",
		"aria-selected": active,
	};

	const handleClick = (fn: undefined | (() => void)): void => {
		onClick?.();
		fn?.();
	};

	if (props.type === "link" && !props.external) {
		return (
			<Link
				ref={(ref: unknown) => setItemRef?.(ref as HTMLElement)}
				{...commonProps}
				onClick={e => (disabled ? e.preventDefault() : handleClick(props.onNavigation))}
				to={props.route}
				aria-disabled={disabled}
				title={tooltip}
			>
				{label}
			</Link>
		);
	}

	if (props.type === "link" && props.external) {
		return (
			<a
				ref={(ref: unknown) => setItemRef?.(ref as HTMLElement)}
				{...commonProps}
				onClick={e => (disabled ? e.preventDefault() : handleClick(props.onNavigation))}
				href={props.route}
				download={props.download}
				target={props.target}
				aria-disabled={disabled}
				title={tooltip}
			>
				{label}
			</a>
		);
	}
	return (
		<button
			ref={(ref: unknown) => setItemRef?.(ref as HTMLElement)}
			{...commonProps}
			onClick={disabled ? undefined : e => handleClick(() => props.onClick(e))}
			disabled={disabled}
			type="button"
			title={tooltip}
		>
			{label}
		</button>
	);
};
