import { usePaymentContext } from "@bokio/contexts/PaymentContext/PaymentContext";
import { Link } from "@bokio/elements/Link/Link";
import { Notice } from "@bokio/elements/Notice/Notice";
import { BankLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { getRoute } from "@bokio/shared/route";
import { formatMessage } from "@bokio/shared/utils/format";

import type { LegacyMarginProp } from "@bokio/designsystem/infrastructure/legacyComponentInterop/legacyMarginProp";
import type * as React from "react";

interface SveaKycBlockServicesNoticeProps {
	testId?: string;
	margin?: LegacyMarginProp;
}

export const SveaKycBlockServicesNotice: React.FC<SveaKycBlockServicesNoticeProps> = ({ testId, margin }) => {
	const bankLang = BankLangFactory();
	const {
		companyInfo: { Id: companyId },
	} = useCompanyInfo();
	const { currentUserPermission } = usePaymentContext();

	if (!currentUserPermission.CanSignPayment) {
		return null;
	}

	function toKycScene(message: string) {
		return <Link route={getRoute("sveaKycScene", { company: companyId })}>{message}</Link>;
	}
	return (
		<Notice margin={margin ?? ["bottom", "top"]} testId={testId} color={"error"} collapsible={false}>
			{formatMessage(bankLang.SveaKyc_BlockServices_Notice, toKycScene)}
		</Notice>
	);
};
