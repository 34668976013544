import isEmpty from "lodash-es/isEmpty";
import without from "lodash-es/without";
import * as React from "react";

import { isPastDate } from "@bokio/bank/src/utils/paymentUtils";
import RenderRequest from "@bokio/components/RenderRequest/RenderRequest";
import { SignAndPayContent } from "@bokio/components/SignAndPay/SignAndPayModal/SignAndPayContent/SignAndPayContent";
import {
	clearPaymentSigningProgressInfoFromLocalStorage,
	readPaymentSigningProgressInfoFromLocalStorage,
	savePaymentSigningProgressInfoToLocalStorage,
} from "@bokio/components/SveaBankIdAuthModal/sveaPollingUtils";
import { SveaBankIdFragment } from "@bokio/components/SveaBankIdFragment/SveaBankIdFragment";
import { useSveaBankIdState } from "@bokio/components/SveaBankIdFragment/useSveaBankIdState";
import { AppMessageType, useAppContext } from "@bokio/contexts/AppContext/AppContext";
import { usePaymentContext } from "@bokio/contexts/PaymentContext/PaymentContext";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { LoadingContent } from "@bokio/elements/Loading";
import { BankLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useActiveBankConnection } from "@bokio/mobile-web-shared/hooks/useActiveBankConnection/useActiveBankConnection";
import { apiPollingTimebasedStopCheck, useApiPolling, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { formatNumberCurrency } from "@bokio/shared/utils/format";

import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

type PaymentValidationRulesDto = m.Bokio.Bank.Contract.Dtos.PaymentValidationRulesDto;

type FailedPaymentGroups = m.Bokio.Bank.Contract.Dtos.FailedPaymentGroup;
type PaymentGroupForAuthorization = m.Bokio.Bank.Contract.Dtos.PaymentGroupForAuthorization;
type PaymentGroupForListDto = m.Bokio.Bank.Contract.Dtos.PaymentGroupForListDto;
const enum ErrorTypes {
	Cancelled = 1,
	Rejected = 2,
	UnHandledPollStatus = 3,
	AuthError = 4,
	RowErrors = 5,
	UserCanNotSign = 6,
}
import BankIdCollectionStatus = m.Bokio.Common.Contract.BankId.BankIdCollectionStatus;

type SignAndPayError =
	| { type: ErrorTypes.AuthError; message: string }
	| { type: Exclude<ErrorTypes, ErrorTypes.AuthError> };

const getSelectedPaymentsTotal = (
	paymentGroups: PaymentGroupForListDto[],
	selectedPaymentGroupIds: string[],
): { total: number; multipleCurrencies: boolean } => {
	const selectedPayments = paymentGroups.filter(x => selectedPaymentGroupIds.includes(x.Id)).flatMap(g => g.Payments);
	const currencies = new Set(selectedPayments.map(p => p.Currency));

	const selectedApproximateTotal = selectedPayments.reduce((sum, payment) => sum + payment.ApproximateDomesticSum, 0);

	return {
		total: selectedApproximateTotal,
		multipleCurrencies: currencies.size > 1,
	};
};

const getPaymentGroupForAuth = (
	defaultPaymentDate: m.Day,
	paymentGroup: m.Bokio.Bank.Contract.Dtos.PaymentGroupForListDto,
): PaymentGroupForAuthorization => {
	// TODO: Better way to do this for groups??
	// TODO move isPastDate to core
	return {
		Id: paymentGroup.Id,
		Date: isPastDate(paymentGroup.Payments[0]) ? defaultPaymentDate : paymentGroup.Date,
	};
};

export interface useSignAndPayReturn {
	paymentListRequest: RequestState<m.Envelope<m.Bokio.Bank.Contract.Dtos.PaymentsForListDto, m.SimpleError>>;
	refreshPaymentListAndSummary: () => void;
	availableBalance: string;
	failedPaymentGroups: Record<string, FailedPaymentGroups>;
	error: string | undefined;
	stopPollingAndCancelSigning: () => void;
	isPolling: boolean;
	authorizeRequest: RequestState<
		m.Envelope<m.Bokio.Bank.Contract.Dtos.SendAndAuthorizePaymentsResultDto, m.Bokio.Bank.Contract.Dtos.BankError>
	>;
	togglePaymentGroupSelection: (id: string, selected: boolean) => void;
	availablePaymentGroupIds: string[];
	availablePaymentGroups: PaymentGroupForListDto[];
	totalSum: number;
	multipleCurrencies: boolean;
	selectedPaymentGroupIds: string[];
	setSelectedPaymentGroupIds: React.Dispatch<React.SetStateAction<string[]>>;
	isAnyRequestLoading: boolean;
	disableSign: boolean;
	paymentValidationRules: PaymentValidationRulesDto;
	executeAuthorize: () => Promise<void>;
	renderSignAndPayContent: (onClose: () => void) => React.ReactNode;
}

interface useSignAndPayProps {
	paymentValidationRules: PaymentValidationRulesDto;
	initiallySelectedPaymentGroupIds?: string[];
	onSubmit: () => void;
	onAuthorizationFailed?: () => void;
	/**
	 * Used when you want to create a payment and directly sign in
	 * Used in BBA migration.
	 */
	createPaymentAndexecuteAuthorizeEndpoint?: (
		org: string,
		paymentGroups: PaymentGroupForAuthorization[],
	) => Promise<
		m.Envelope<m.Bokio.Bank.Contract.Dtos.SendAndAuthorizePaymentsResultDto, m.Bokio.Bank.Contract.Dtos.BankError>
	>;
}

export const useSignAndPay = ({
	paymentValidationRules,
	initiallySelectedPaymentGroupIds,
	onSubmit,
	onAuthorizationFailed,
	createPaymentAndexecuteAuthorizeEndpoint,
}: useSignAndPayProps): useSignAndPayReturn => {
	const bankLang = BankLangFactory();
	const { companyInfo } = useCompanyInfo();
	const { activeBusinessAccountExternalSystem, activeBusinessAccount } = useActiveBankConnection();
	const { currentUserPermission, refreshSignablePaymentCount } = usePaymentContext();

	const [selectedPaymentGroupIds, setSelectedPaymentGroupIds] = React.useState<string[]>(
		initiallySelectedPaymentGroupIds ?? [],
	);
	// This is a ref to make sure the value is changed before the polling is started
	const [failedPaymentGroups, setFailedPaymentGroups] = React.useState<Record<string, FailedPaymentGroups>>({});
	const [error, setError] = React.useState<SignAndPayError>();
	const app = useAppContext();
	const addMessage = app.addMessage;

	const { bankIdState, resetBankIdState, setBankIdState } = useSveaBankIdState();

	const getError = (error: SignAndPayError | undefined) => {
		if (error === undefined) {
			return;
		}
		let message = bankLang.SignAndPayModalError;
		switch (error.type) {
			case ErrorTypes.AuthError:
				message = error.message;
				break;
			case ErrorTypes.Cancelled:
				message = bankLang.BankIdPolling_CancelledByUser;
				break;
			case ErrorTypes.Rejected:
				message = bankLang.BankIdPolling_Rejected;
				break;
			case ErrorTypes.UnHandledPollStatus:
				message = bankLang.BankIdPolling_UnHandledPollStatus;
				break;
			case ErrorTypes.RowErrors:
				message = bankLang.SignAndPayModalError_RowErrors;
				break;
			case ErrorTypes.UserCanNotSign:
				message = bankLang.SignAndPayModal_NoRights;
				break;
		}
		return message;
	};

	const [refreshPaymentCentralSummaryRequest, paymentCentralSummaryRequest] = useLazyApi(
		proxy.Bank.PaymentController.GetPaymentCentralSummary.Get,
	);

	const domesticCurrency = companyInfo.CountryMetadata.Currency;
	const availableAmount = paymentCentralSummaryRequest.data?.Data?.AvailableAmount;
	const availableBalance =
		availableAmount !== undefined ? formatNumberCurrency(availableAmount, domesticCurrency) : "-";

	const [refreshPaymentList, paymentListRequest] = useLazyApi(proxy.Bank.PaymentController.ListSignablePayments.Get, {
		onSuccess: data => {
			const availablePaymentGroupIds = data.PaymentGroups.map(x => x.Id);

			const hasInprogressPaymentSigning = readPaymentSigningProgressInfoFromLocalStorage();
			if (hasInprogressPaymentSigning && hasInprogressPaymentSigning.paymentGroupIds) {
				setSelectedPaymentGroupIds(hasInprogressPaymentSigning.paymentGroupIds);
				return;
			}

			if (selectedPaymentGroupIds.length === 0) {
				setSelectedPaymentGroupIds([...availablePaymentGroupIds]);
				return;
			}

			const selectedButUnavailableIds = without(selectedPaymentGroupIds, ...availablePaymentGroupIds);
			// Remove unavailable ids from selection
			setSelectedPaymentGroupIds(prev => without(prev, ...selectedButUnavailableIds));
		},
	});

	if (!activeBusinessAccountExternalSystem) {
		throw new Error("At least one business account must be active");
	}

	const refreshPaymentListAndSummary = async () => {
		await refreshPaymentCentralSummaryRequest(companyInfo.Id);
		await refreshPaymentList(companyInfo.Id);
	};

	const [cancelConfirmPayment] = useLazyApi(proxy.Bank.PaymentController.CancelConfirmPayment.Post);

	const { startPolling, stopPolling, isPolling } = useApiPolling(proxy.Bank.PaymentController.ConfirmPayment.Post, {
		stopCondition: context =>
			apiPollingTimebasedStopCheck()(context) ||
			context.apiError ||
			context.error !== null ||
			(context.data !== null && context.data.CollectionStatus !== BankIdCollectionStatus.Pending),
		effect: async (data, error) => {
			if (error) {
				clearPaymentSigningProgressInfoFromLocalStorage();
				setError({ type: ErrorTypes.AuthError, message: error.Message });
				resetBankIdState();
				return;
			}

			switch (data?.CollectionStatus) {
				case BankIdCollectionStatus.Complete:
					clearPaymentSigningProgressInfoFromLocalStorage();
					resetBankIdState();
					await refreshPaymentListAndSummary();
					await refreshSignablePaymentCount();
					addMessage({
						type: AppMessageType.InfoMessage,
						message: bankLang.SignAndPayModalSuccess,
						persist: false,
					});

					onSubmit();
					return;
				case BankIdCollectionStatus.Pending:
					setBankIdState({ bankIdQrCode: data.QrCode, collectionStatus: data.CollectionStatus });
					return;
				case BankIdCollectionStatus.Failed:
				case BankIdCollectionStatus.Invalid:
					clearPaymentSigningProgressInfoFromLocalStorage();
					setError({ type: ErrorTypes.UnHandledPollStatus });
					return;
			}
		},
	});

	const [executeAuthorizeRequest, authorizeRequest] = useLazyApi(
		createPaymentAndexecuteAuthorizeEndpoint || proxy.Bank.PaymentController.SendAndAuthorizePayments.Post,
		{
			onSuccess: async data => {
				if (createPaymentAndexecuteAuthorizeEndpoint) {
					// Refresh the payments because the creation of payment is done inside executeAuthorizeEndpoint
					await refreshPaymentListAndSummary();
					await refreshSignablePaymentCount();
				}
				setFailedPaymentGroups(data.FailedPaymentGroups);

				if (!isEmpty(data.FailedPaymentGroups)) {
					onAuthorizationFailed?.();
					setError({ type: ErrorTypes.RowErrors });
					clearPaymentSigningProgressInfoFromLocalStorage();
					return;
				}

				const savedProgress = readPaymentSigningProgressInfoFromLocalStorage();
				if (savedProgress) {
					savePaymentSigningProgressInfoToLocalStorage({
						...savedProgress,
						isPolling: true,
						SuccessfulPaymentIds: data.SuccessfulPaymentIds,
						signingToken: data.AutostartToken,
					});
				}

				setBankIdState(
					{
						autoStartToken: data?.AutostartToken,
						bankIdQrCode: data?.QrCode,
						hintCode: m.Bokio.Common.Contract.BankId.BankIdHintCode.OutstandingTransaction,
					},
					{ lockQrCode: true },
				);
				startPolling([companyInfo.Id, data.AutostartToken, data.SuccessfulPaymentIds]);
			},
			onError: data => setError({ type: ErrorTypes.AuthError, message: data.Message }),
		},
	);

	const availablePaymentGroups = React.useMemo(
		() => paymentListRequest.data?.Data?.PaymentGroups ?? [],
		[paymentListRequest.data],
	);
	const availablePaymentGroupIds = availablePaymentGroups.map(x => x.Id);

	const executeAuthorize = React.useCallback(async () => {
		if (!currentUserPermission.CanSignPayment) {
			setError({ type: ErrorTypes.UserCanNotSign });
			clearPaymentSigningProgressInfoFromLocalStorage();
			return;
		}

		setError(undefined);
		await executeAuthorizeRequest(
			companyInfo.Id,
			availablePaymentGroups
				.filter(ap => selectedPaymentGroupIds.includes(ap.Id))
				.map(p => getPaymentGroupForAuth(paymentValidationRules.NextPossiblePaymentDateIncludingToday, p)),
		);
	}, [
		availablePaymentGroups,
		companyInfo.Id,
		selectedPaymentGroupIds,
		currentUserPermission.CanSignPayment,
		executeAuthorizeRequest,
		paymentValidationRules.NextPossiblePaymentDateIncludingToday,
	]);

	const togglePaymentGroupSelection = (id: string, selected: boolean) => {
		if (selected) {
			setSelectedPaymentGroupIds(selectedPaymentGroupIds.concat(id));
			return;
		}

		setSelectedPaymentGroupIds(selectedPaymentGroupIds.filter(x => x !== id));
	};

	const { total, multipleCurrencies } = getSelectedPaymentsTotal(availablePaymentGroups, selectedPaymentGroupIds);
	const isAnyRequestLoading = paymentListRequest.isLoading || authorizeRequest.isLoading || isPolling;

	const disableSign =
		isAnyRequestLoading ||
		selectedPaymentGroupIds.length < 1 ||
		selectedPaymentGroupIds.some(sp => failedPaymentGroups[sp] !== undefined) ||
		activeBusinessAccount?.KycInformation?.RequiredAction === m.Bokio.Common.Helpers.SveaKycAction.BlockPayments;

	const stopPollingAndCancelSigning = () => {
		if (bankIdState.autoStartToken && !error) {
			cancelConfirmPayment(companyInfo.Id, activeBusinessAccountExternalSystem, bankIdState.autoStartToken);
		}

		stopPolling();
		resetBankIdState();
	};

	const renderSignAndPayContent = (onClose: () => void) => (
		<RenderRequest request={paymentListRequest} whenLoading={<LoadingContent />}>
			{() => (
				<SignAndPayContent
					selectedPaymentGroupIds={selectedPaymentGroupIds}
					isPolling={isPolling}
					onGlobalCheckClick={checked => setSelectedPaymentGroupIds(checked ? [...availablePaymentGroupIds] : [])}
					availablePaymentGroups={availablePaymentGroups}
					failedPaymentGroups={failedPaymentGroups}
					disabled={isAnyRequestLoading}
					error={getError(error)}
					togglePaymentGroupSelection={togglePaymentGroupSelection}
					onClose={onClose}
					paymentValidationRules={paymentValidationRules}
				>
					<SG padding="16">
						<SveaBankIdFragment bankIdProps={bankIdState} />
					</SG>
				</SignAndPayContent>
			)}
		</RenderRequest>
	);

	return {
		paymentListRequest,
		refreshPaymentListAndSummary,
		availableBalance: availableBalance,
		failedPaymentGroups,
		error: getError(error),
		stopPollingAndCancelSigning,
		isPolling,
		authorizeRequest,
		togglePaymentGroupSelection,
		availablePaymentGroupIds,
		availablePaymentGroups,
		totalSum: total,
		multipleCurrencies,
		selectedPaymentGroupIds,
		setSelectedPaymentGroupIds,
		isAnyRequestLoading,
		disableSign,
		paymentValidationRules,
		executeAuthorize: async () => {
			const activeSveaPaymentSigningToken = readPaymentSigningProgressInfoFromLocalStorage();
			if (activeSveaPaymentSigningToken && !activeSveaPaymentSigningToken.isPolling) {
				await executeAuthorize();
			} else {
				if (!isPolling) {
					stopPolling();
				}
				if (
					activeSveaPaymentSigningToken &&
					activeSveaPaymentSigningToken.signingToken &&
					activeSveaPaymentSigningToken.SuccessfulPaymentIds
				) {
					setBankIdState(
						{
							autoStartToken: activeSveaPaymentSigningToken.signingToken,
							bankIdQrCode: undefined,
							hintCode: m.Bokio.Common.Contract.BankId.BankIdHintCode.OutstandingTransaction,
						},
						{ lockQrCode: false },
					);
					startPolling([
						companyInfo.Id,
						activeSveaPaymentSigningToken.signingToken,
						activeSveaPaymentSigningToken.SuccessfulPaymentIds,
					]);
				}
			}
		},
		renderSignAndPayContent,
	};
};
