import * as React from "react";

import withRouterControls from "@bokio/shared/containers/router/withRouterControls";
import { mergeClassNames } from "@bokio/utils/classes";

import type { RouterControlsProps } from "@bokio/shared/containers/router/withRouterControls";

import * as styles from "./table.scss";

export interface TrProps {
	className?: string;
	level?: number;
	visible?: boolean;
	isActive?: boolean; // shows green highlight when active subRow
	isSum?: boolean;
	isSubRow?: boolean;
	route?: string;
	testId?: string;
	id?: string;
	/**
	 * Show in screen reader only. We just put the text out of viewport to keep accessibility.
	 * Default is false
	 */
	srOnly?: boolean;
	onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void;
}

class Tr extends React.Component<React.PropsWithChildren<TrProps & RouterControlsProps>> {
	render() {
		const { className, srOnly, level, visible, isActive, isSum, onClick, route, testId, id } = this.props;
		const mergedClassNames =
			visible === false
				? styles.hidden
				: mergeClassNames(
						level === 2 ? styles.level2 : styles.tr,
						isSum && styles.sum,
						(onClick || route) && !(level === 2) && styles.hover,
						className,
						isActive && styles.activeTr,
						srOnly ? "srOnly" : "",
					);
		return (
			<tr
				className={mergedClassNames}
				onClick={this.handleOnClick}
				onMouseDown={this.handleMouseDown}
				data-testid={testId}
				id={id}
			>
				{this.props.children}
			</tr>
		);
	}

	handleOnClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
		const { route, onClick } = this.props;

		// When the target is or is inside a link or button element, it implies that there is an action there and Tr should ignore the click
		if (e.target instanceof Element) {
			let current: Element | null = e.target;
			while (current !== null && current !== e.currentTarget) {
				if (current.tagName === "A" || current.tagName === "BUTTON") {
					return;
				}

				current = current.parentElement;
			}
		}

		if (route) {
			this.goToRoute(e.ctrlKey);
		} else if (onClick) {
			onClick(e);
		}
	};

	handleMouseDown = (e: React.MouseEvent<HTMLTableRowElement>) => {
		if (e.button === 1) {
			this.goToRoute(true);
		}
	};

	goToRoute = (newTab: boolean) => {
		const { route, redirect } = this.props;
		const selection = window.getSelection();
		if ((!selection || selection.toString() === "") && route) {
			if (newTab) {
				window.open(route);
			} else {
				redirect(route);
			}
		}
	};
}

export default withRouterControls(Tr);
