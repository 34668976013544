import * as React from "react";

import { Link } from "@bokio/elements/Link/Link";
import { LoadingProgress } from "@bokio/elements/Loading";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./dropdownItem.scss";

interface DropdownItemLink {
	route?: string;
	external?: string;
	target?: string;
}

interface DropdownItemVisualisationProps {
	children?: React.ReactNode;
	type?: "normal" | "separator";
	loadable?: boolean;
	loading?: boolean;
	setActive?: (v: boolean) => void;
	link?: DropdownItemLink;
	appearance?: "destructive";
}

export interface DropdownItemProps extends DropdownItemVisualisationProps {
	onClick?: (a?: (v: boolean) => void) => void;
	className?: string;
	testId?: string;
}

export class DropdownItem extends React.Component<DropdownItemProps> {
	static displayName = "DropdownItem";

	render() {
		const { children, type, loadable, loading, className, link, testId, appearance } = this.props;
		const itemClass = mergeClassNames(
			styles.dropdownItem,
			type === "separator" && styles.separator,
			appearance === "destructive" && styles.destructive,
			className,
		);

		return (
			<React.Fragment>
				{loadable && loading ? (
					<div className={styles.loading}>
						<LoadingProgress />
					</div>
				) : link ? (
					<Link
						className={itemClass}
						route={link.route}
						external={link.external}
						target={link.target}
						testId={testId}
						onClick={this.handleClick}
					>
						{children}
					</Link>
				) : (
					<div className={itemClass} onClick={this.handleClick} data-testid={testId}>
						{children}
					</div>
				)}
			</React.Fragment>
		);
	}
	handleClick = () => {
		const { setActive, onClick, loadable } = this.props;

		if (loadable) {
			onClick && onClick(setActive);
		} else {
			setActive && setActive(false);
			onClick && onClick();
		}
	};
}
