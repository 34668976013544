export type Listener<T> = (e: T) => void;

export const createEventHub = <T>() => {
	let listeners: Listener<T>[] = [];

	return {
		add: (listener: Listener<T>) => {
			listeners = [...listeners, listener];
		},
		remove: (listener: Listener<T>) => {
			listeners = listeners.filter(keep => keep !== listener);
		},
		dispatch: (e: T) => {
			listeners.forEach(listener => listener(e));
		},
	};
};
