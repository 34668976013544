import * as React from "react";

import { AuthenticationChecker } from "@bokio/components/AuthenticationChecker/AuthenticationChecker";
import { NPSFeedback } from "@bokio/components/NPSFeedback/NPSFeedback";
import { Box } from "@bokio/elements/Box";
import { LoadingBox } from "@bokio/elements/Loading";
import { useDeviceUserAgent } from "@bokio/hooks/useDeviceUserAgent/useDeviceUserAgent";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { mergeClassNames } from "@bokio/utils/classes";
import { isBookkeepingRoute } from "@bokio/utils/routeUtils";

import { ScrollArea } from "./ScrollArea";

import * as styles from "./layout.scss";

interface MenuHandle {
	expanded: boolean;
	closeMenu: () => void;
	toggleMenu: () => void;
}

type LayoutProps = {
	menu: (handle: MenuHandle) => JSX.Element;
	content: JSX.Element | undefined; // use undefined to indicate that the content is loading
	isDemo?: boolean;
	noSideBar?: boolean;
};

interface LayoutState {
	menuExpanded: boolean;
}

const Layout = (props: LayoutProps) => {
	// MQ: When user/guest accesses auth routes, it always makes api's call to get user and company's information
	// It is better if we store token or something similar on frontend to check whethere there is user's session or not
	// Based on that, we can decide to make api's call
	// It will be much more easy if we refactor after completely migrating to React
	// Note: Update logic on `components/Route` to check based on token
	const router = useRouter();
	const [state, setState] = React.useState<LayoutState>({ menuExpanded: false });

	const { isBokioMobileAppUserAgent } = useDeviceUserAgent();

	const closeMenu = () => {
		setState({ menuExpanded: false });
	};

	const toggleMenu = () => {
		setState(s => ({ menuExpanded: !s.menuExpanded }));
	};

	const noPaddingBottom = isBookkeepingRoute(router.location?.pathname ?? "");

	return (
		<AuthenticationChecker>
			<div className={mergeClassNames(styles.partnerWrapper)}>
				<div
					className={mergeClassNames(
						styles.layout,
						isBokioMobileAppUserAgent ? styles.layoutForMobileWebView : "",
						props.isDemo && styles.demoLayout,
					)}
				>
					{isBokioMobileAppUserAgent ? null : props.menu({ expanded: state.menuExpanded, closeMenu, toggleMenu })}
					<ScrollArea
						className={mergeClassNames(
							styles.content,
							noPaddingBottom && styles.noPaddingBottom,
							props.noSideBar && styles.contentNoSideMenu,
						)}
						touchMoveEnabled={!state.menuExpanded}
					>
						{props.content || (
							<Box>
								<LoadingBox spacingTop={true} />
							</Box>
						)}
					</ScrollArea>
					<NPSFeedback />
				</div>
			</div>
		</AuthenticationChecker>
	);
};

export default Layout;
