import { Day } from "@bokio/mobile-web-shared/core/model/model";
import * as m from "@bokio/mobile-web-shared/core/model/model";

type PaymentForListDto = m.Bokio.Bank.Contract.Dtos.PaymentForListDto;
import TellerPaymentStatus = m.Entities.Teller.TellerPaymentStatus;

export const isPastDate = (payment: PaymentForListDto) => {
	switch (payment.Status) {
		case TellerPaymentStatus.AwaitingApproval:
		case TellerPaymentStatus.DraftInExternalSystem:
		case TellerPaymentStatus.WaitingForAuthorization:
			const today = Day.today();
			return payment.Date < today;
		default:
			return false;
	}
};
