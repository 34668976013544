import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";
import { createConnectHoc } from "@bokio/utils/createConnectHoc";

export interface ActiveUserProps {
	activeUser?: {
		userId: string;
		lang: string;
		employeeId?: string;
	};
	/**
	 * @deprecated If this is empty in unit test, use `companyInfo` from `useCompanyInfo()` or `withCompanyInfo` instead.
	 */
	companyId: string;
}

/**
 * @deprecated use `useCompanyInfo`, `useCompanyUser` or `useUser` hooks.
 */
export const useActiveUserInfo = (): ActiveUserProps => {
	const { currentCompanyId, currentUserId, currentLang, currentEmployeeId } = useTopLevelUser();

	return {
		companyId: currentCompanyId || "",
		activeUser:
			currentUserId === undefined
				? undefined
				: {
						userId: currentUserId,
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						lang: currentLang!,
						employeeId: currentEmployeeId,
					},
	};
};

/**
 * @deprecated use `useCompanyUser` or `useUser` hooks.
 */
const withActiveUserInfo = createConnectHoc(() => {
	return useActiveUserInfo();
});

export default withActiveUserInfo;
