import { useScript } from "@bokio/hooks/useScript/useScript";

interface GoogleConsentProps {
	onScriptsLoaded?: () => void;
}

export const GoogleAnalyticsConsent = (props: GoogleConsentProps) => {
	useScript("scripts/gtagConsent.js", props.onScriptsLoaded);

	return null;
};
