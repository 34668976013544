import { LinkButton } from "@bokio/elements/LinkButton/LinkButton";
import { GeneralLangFactory } from "@bokio/lang";
import { toEnvelopeEndpoint, useApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

import { LoginForm } from "../LoginForm";
import { Modal } from "../Modal";

import type * as React from "react";

import * as styles from "./recentLoginModal.scss";

interface RecentLoginModalProps {
	title?: string;
	description?: string;
	buttonText?: string;
	onLogin: () => void;
	onCancel: () => void;
	visible: boolean;
	baseTestId?: string; // Optional to separate multiple active login forms test ids
	alwaysMounted?: boolean;
}

export const RecentLoginModal: React.FC<RecentLoginModalProps> = props => {
	// ME: Makes unneeded API call but it's because "useUser()" doesn't work on user routes. See https://bokio.slack.com/archives/CFN9GKYV8/p1610436202001100
	const [userRequest] = useApi(toEnvelopeEndpoint(proxy.Settings.UserController.Index.Get), []);

	const lang = GeneralLangFactory();
	const { alwaysMounted = true } = props;
	return (
		<Modal
			alwaysMounted={alwaysMounted}
			visible={props.visible}
			disableBackgroundClick={true}
			onClose={props.onCancel}
			disabled={true}
			noHeader={true}
		>
			<div className={styles.login}>
				<div className={styles.header}>
					<h1>{props.title ?? lang.RecentLogin_Header}</h1>
					<p>{props.description ?? lang.RecentLogin_Description}</p>
				</div>
				{!!userRequest.data?.Data && (
					<LoginForm
						username={userRequest.data.Data.Email}
						loginButtonText={lang.RecentLogin_Action}
						loginSuccess={async () => {
							props.onLogin();
						}}
						baseTestId={props.baseTestId}
					/>
				)}
				<p>
					<LinkButton onClick={props.onCancel}>{lang.GoBack}</LinkButton>
				</p>
			</div>
		</Modal>
	);
};
