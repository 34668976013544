import * as React from "react";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export interface Memberships {
	partners: m.Settings.Controllers.PartnerMembershipDto[];
	companies: m.SettingsNoConflict.ViewModels.CompanyMembershipDto[];
}

export interface SavedLoginState {
	currentUserEmail: string;
	logins: { [key: string]: UserState };
}

export interface LoginsControls extends SavedLoginState {
	setUserEmail: (v: string) => void;
	setUserFirstName: (v: string) => void;
	setUserLastName: (v: string) => void;
	setUserLoginMethod: (v: m.Bokio.Common.Contract.LoginMethod) => void;
	setUserPersonalNumber: (v: string) => void;
}

export interface UserState {
	userEmail: string;
	userFirstName: string;
	userLastName: string;
	userLoginMethod: m.Bokio.Common.Contract.LoginMethod | undefined;
	userPersonalNumber: string;
}

export interface TopLevelUserState {
	currentUserId: string | undefined;
	currentCompanyId: string | undefined;
	currentAgencyId: string | undefined;
	currentLang: string | undefined;
	currentEmployeeId: string | undefined;

	companyUser: m.Settings.Controllers.StatusDto | undefined;
	hasErrorGettingCompanyUser: boolean;
	isLoadingCompany: boolean;
	reloadCompanyUser: () => Promise<void>;

	user: m.Bokio.Backbone.Web.Controllers.Settings.Models.UserViewModel | undefined;
	isLoadingUser: boolean;
	reloadUser: () => Promise<void>;

	agencyStatus: m.Bokio.BackOffice.PartnerStatusDto | undefined;
	isLoadingAgencyStatus: boolean;
	reloadAgencyStatus: () => Promise<void>;

	agencyCount: m.Bokio.BackOffice.AgencyCountViewModel;
	isLoadingAgencyCount: boolean;
	reloadAgencyCount: () => Promise<void>;

	todoCount: m.Common.ViewModels.ToDoCountViewModel | undefined;
	isLoadingTodo: boolean;
	todoCacheBuster: number;
	updateTodoStatus: () => Promise<void>;

	companyInfo: m.Core.CompanyInfo | undefined;
	reloadCompanyInfo: () => Promise<void>;
	companyUserPermissions: m.Settings.Controllers.UserAccess;

	memberships: Memberships | undefined;
	isLoadingMemberships: boolean;
	reloadMemberships: () => Promise<void>;

	showLogin: boolean;
	setShowLogin: (value: boolean) => void;
	loginsControls: LoginsControls;

	clearUser: () => Promise<void>;
	clearAll: () => Promise<void>;
	signOut: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const defaultTopLevelUserContextValue: TopLevelUserState = {} as TopLevelUserState;

export const calculatePermissions = (
	companyUser?: m.Settings.Controllers.StatusDto,
): m.Settings.Controllers.UserAccess => {
	if (!companyUser) {
		return {
			ViewBookkeeping: false,
			Bookkeep: false,
			UploadReceipt: false,
			UploadBank: false,
			Closures: false,
			Partner: false,
			Reports: false,
			SupportUser: false,
			Attest: false,
			Disbursements: false,
			UploadExpense: false,
			ImportantDates: false,
			Salaries: false,
			Invoices: false,
			AccountingSettings: false,
			InvoiceSettings: false,
			SalarySettings: false,
			CompanySettings: false,
			Inbox: false,
			AdminUsers: false,
			SupplierInvoices: false,
		};
	}

	return companyUser.Access;
};

export const TopLevelUserContext = React.createContext(defaultTopLevelUserContextValue);
