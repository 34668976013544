import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";

export function distanceInWords(datetime: Date, datetimeBase: Date = new Date()) {
	const generalLang = GeneralLangFactory();
	const locales = {
		ago: generalLang.TIMEAGO_AGO,
		in: generalLang.TIMEAGO_IN,
		default: "%d",

		seconds: generalLang.TIMEAGO_SECONDS,
		minute: generalLang.TIMEAGO_MINUTE,
		minutes: generalLang.TIMEAGO_MINUTES,
		hour: generalLang.TIMEAGO_HOUR,
		hours: generalLang.TIMEAGO_HOURS,
		day: generalLang.TIMEAGO_DAY,
		days: generalLang.TIMEAGO_DAYS,
		month: generalLang.TIMEAGO_MONTH,
		months: generalLang.TIMEAGO_MONTHS,
		year: generalLang.TIMEAGO_YEAR,
		years: generalLang.TIMEAGO_YEARS,
	};
	const seconds = Math.floor((datetime.getTime() - datetimeBase.getTime()) / 1000);
	let interval = 0;
	const intervals = {
		year: seconds / 31536000,
		month: seconds / 2592000,
		day: seconds / 86400,
		hour: seconds / 3600,
		minute: seconds / 60,
		second: seconds,
	};
	let distance = "";

	Object.keys(intervals).some(key => {
		interval = intervals[key];

		if (key === "day" && interval > 1) {
			interval = Math.ceil(interval);
		} else {
			interval = Math.round(interval);
		}

		if (Math.abs(interval) > 1) {
			distance = locales[key + "s"];
		} else if (Math.abs(interval) === 1) {
			distance = locales[key];
		}
		return !!distance;
	});

	distance = (distance || locales.default).replace(/%d/i, Math.abs(interval).toString()).trim();

	if (interval > 0) {
		return `${locales.in} ${distance}`;
	} else {
		return `${distance} ${locales.ago}`;
	}
}
