import type * as React from "react";

import * as styles from "./progressBar.scss";

interface ProgressBarProps {
	percentage: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = props => {
	const percentage = Math.min(props.percentage, 100);
	return (
		<div className={styles.container}>
			<div className={styles.progressBackground}>
				<div className={styles.progressBar} style={{ width: `${percentage}%` }} />
			</div>
			<div className={styles.progressText}>{percentage}%</div>
		</div>
	);
};
