import type { Size } from "../Button/button.types";

import * as styles from "./iconButton.scss";

export function getSizeClassName(size: Size): string {
	switch (size) {
		case "normal":
			return styles.sizeNormal;
		case "small":
			return styles.sizeSmall;
		default:
			return "";
	}
}
