import * as React from "react";

import type { ReceiptUploadRequest } from "@bokio/components/UploadReceiptProvider/UploadReceiptProvider";
import type { Listener } from "@bokio/shared/services/api/signalRHub";

export type UploadMessage = { historic?: boolean } & (
	| { type: "started"; file: File[] }
	| { type: "done"; request: ReceiptUploadRequest }
	| { type: "change" | "allDone"; requests: ReceiptUploadRequest[] }
);

export interface UploadContext {
	invokeUpload: (rerouteToBookkeepingOnSingleUpload?: boolean) => void;
	requests?: ReceiptUploadRequest[];
	addListener: (listener: Listener<UploadMessage>) => void;
	removeListener: (listener: Listener<UploadMessage>) => void;
	setGlobalFileDropZoneBlockerCounter: (stateUpdater: (counter: number) => number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadContext = React.createContext<UploadContext>({} as any);

export const useUploadContext = () => React.useContext(uploadContext);
