import { createConnectHoc } from "@bokio/utils/createConnectHoc";

import { useRouter } from "./useRouter";

import type { RouterProps } from "./useRouter";

export interface RouterControlsProps extends RouterProps {
	currentCompanyId: string | undefined;
	currentPath: string | undefined;
}

/**
 * @deprecated This is just a wrapper around the useRouter hook. Use the hook directly instead.
 */
const withRouterControls = createConnectHoc((): RouterControlsProps => {
	const routerProps = useRouter();
	const routeInfo = {
		currentCompanyId: routerProps.currentCompanyId,
		currentPath: routerProps.location.pathname,
	};
	return { ...routerProps, ...routeInfo };
});

export default withRouterControls;
