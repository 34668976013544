import * as React from "react";

import { ModalFooter } from "@bokio/components/Modal";
import RenderRequest from "@bokio/components/RenderRequest/RenderRequest";
import { Button } from "@bokio/designsystem/components/Button";
import { LoadingProgress, RenderRequestError } from "@bokio/elements/Loading";
import { GeneralLangFactory, SettingsLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { toEnvelopeEndpoint, useApi, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

import { AddFiscalYearModalView } from "../../AddFiscalYearModalViews/AddFiscalYearModalView";

import type { FiscalYearFormData } from "@bokio/settings/src/scenes/FiscalYears/components/FiscalYearForm";

import CreateFiscalMode = m.Bokio.Services.FiscalYears.CreateFiscalMode;
type FiscalYearsDto = m.Bokio.Services.FiscalYears.FiscalYearsDto;
import FiscalYearStatus = m.Core.FiscalYearStatus;

const postFiscalYear = (parameters: {
	companyId: string;
	createMode: CreateFiscalMode;
	formData: FiscalYearFormData;
}) => {
	if (parameters.createMode === CreateFiscalMode.Next) {
		return proxy.Bokio.Accounting.Web.Settings.FiscalYearsController.CreateNext.Post(parameters.companyId, {
			...parameters.formData,
		});
	} else {
		return proxy.Bokio.Accounting.Web.Settings.FiscalYearsController.CreatePrevious.Post(parameters.companyId, {
			...parameters.formData,
		});
	}
};

const getCreateMode = (fiscals: FiscalYearsDto, fiscalStatus: FiscalYearStatus) => {
	const shouldCreateNext =
		m.Day.today() >= fiscals.NextYear.StartDate || fiscalStatus === FiscalYearStatus.PromptToCreateNextYear;
	return shouldCreateNext ? CreateFiscalMode.Next : CreateFiscalMode.Previous;
};

interface CreateFiscalYearFormProps {
	fiscalYears: FiscalYearsDto;
	belongsToMultipleCompanies: boolean;
	onFiscalCreated: () => void;
}

export const CreateFiscalYearForm: React.FC<CreateFiscalYearFormProps> = props => {
	const { belongsToMultipleCompanies, fiscalYears, onFiscalCreated } = props;
	const { companyInfo } = useCompanyInfo();
	const createMode = getCreateMode(fiscalYears, companyInfo.FiscalYearStatus);
	const lang = SettingsLangFactory();
	const generalLang = GeneralLangFactory();
	const [fiscalForm, setFiscalForm] = React.useState<FiscalYearFormData>({
		AccountingForm: companyInfo.ActiveAccountingMethod,
		VatSetting: companyInfo.ActiveVatSetting,
		StartDate: undefined,
		EndDate: undefined,
		ExpectedTurnover: 0,
	});
	const [postFiscal, postFiscalRequest] = useLazyApi(postFiscalYear, { onSuccess: onFiscalCreated });
	const [getFiscalRequest] = useApi(
		toEnvelopeEndpoint(proxy.Bokio.Accounting.Web.Settings.FiscalYearsController.CreateFiscal.Get),
		[companyInfo.Id, CreateFiscalMode.Next],
		{
			onSuccess: data =>
				setFiscalForm({
					AccountingForm: data.AccountingForm,
					EndDate: data.EndDate,
					ExpectedTurnover: data.ExpectedTurnover,
					StartDate: data.StartDate,
					VatSetting: data.VatSetting,
				}),
		},
	);

	const cta =
		companyInfo.FiscalYearStatus === FiscalYearStatus.PromptToCreateNextYear
			? generalLang.Confirm
			: lang.FiscalYears_Create_Title;

	return (
		<RenderRequest request={getFiscalRequest} whenError={"useDefault"} whenLoading={<LoadingProgress />}>
			{() => {
				return (
					<React.Fragment>
						<AddFiscalYearModalView
							formData={fiscalForm}
							companyInfo={companyInfo}
							onUpdate={setFiscalForm}
							showSelectCompanyLink={belongsToMultipleCompanies}
						/>
						<RenderRequestError request={postFiscalRequest} />
						<ModalFooter>
							<Button
								appearance="primary"
								loading={postFiscalRequest.isLoading}
								onClick={() =>
									postFiscal({
										companyId: companyInfo.Id,
										createMode,
										formData: fiscalForm,
									})
								}
								testId="AddFiscalYearModal_Action"
							>
								{cta}
							</Button>
						</ModalFooter>
					</React.Fragment>
				);
			}}
		</RenderRequest>
	);
};
