import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";
import { createConnectHoc } from "@bokio/utils/createConnectHoc";

export enum ActiveOrganisationType {
	Company,
	Agency,
	User, //When the user is on personal pages
}

export interface ActiveOrganisationProps {
	activeOrganisationType: ActiveOrganisationType;
	organisationId?: string;
	organisationName?: string;
}

export const useOrganisationInfo = (): ActiveOrganisationProps => {
	const { currentCompanyId, currentAgencyId, companyUser, agencyStatus } = useTopLevelUser();

	if (currentCompanyId) {
		return {
			activeOrganisationType: ActiveOrganisationType.Company,
			organisationId: currentCompanyId,
			organisationName: companyUser?.Company.Name,
		};
	}

	if (currentAgencyId) {
		return {
			activeOrganisationType: ActiveOrganisationType.Agency,
			organisationId: currentAgencyId,
			organisationName: agencyStatus?.Name,
		};
	}

	return {
		activeOrganisationType: ActiveOrganisationType.User,
	};
};

const withActiveOrganisationInfo = createConnectHoc((): ActiveOrganisationProps => {
	return useOrganisationInfo();
});

export default withActiveOrganisationInfo;
