import * as React from "react";
import BoldRenderer from "simple-commonmark-react/dist/renderers/BoldRenderer";
import HeaderRenderer from "simple-commonmark-react/dist/renderers/HeaderRenderer";
import ListRenderer from "simple-commonmark-react/dist/renderers/ListRenderer";
import ParagraphRenderer from "simple-commonmark-react/dist/renderers/ParagraphRenderer";

import {
	Div,
	Heading,
	Paragraph,
	Strong,
	TextSpan,
} from "@bokio/designsystem/components/TypographicElements/TypographicElements";

type UseTypographicElementsPropsInternal = { compact: boolean };
export type UseTypographicElementsProps = boolean | UseTypographicElementsPropsInternal;

const defaultProps: UseTypographicElementsPropsInternal = { compact: false };
const ctx = React.createContext(defaultProps);

export const MarkdownInteropContextProvider = ctx.Provider;

export class TypographicElementsParagraphRenderer extends ParagraphRenderer {
	override renderNodeWithProps(props: object) {
		// Respect parent behaviour where it doesn't render within a tight list
		const proxy = super.renderNodeWithProps(props);
		if (!proxy?.type) {
			return proxy;
		}
		return React.createElement(Paragraph, props, []);
	}
}

export class TypographicElementsTextSpanRenderer extends ParagraphRenderer {
	override renderNodeWithProps(props: object) {
		// Respect parent behaviour where it doesn't render within a tight list
		const proxy = super.renderNodeWithProps(props);
		if (!proxy?.type) {
			return proxy;
		}
		return React.createElement(TextSpan, props, []);
	}
}

export class TypographicElementsHeadingRenderer extends HeaderRenderer {
	override renderNodeWithProps(props: object) {
		// SS 2022-01-27
		// We should take the node level into account but I'm unsure how to do this in a smart way,
		// perhaps some sort of magical counting React Context in <Markdown> level is needed.
		// Mostly that sometimes people use ### to style text while it should actually be decoupled from styling.

		// TODO: Do something with this.node.level
		return React.createElement(Heading, props, []);
	}
}

export class TypographicElementsStrongRenderer extends BoldRenderer {
	override renderNodeWithProps(props: object) {
		return React.createElement(Strong, props, []);
	}
}

/**
 * The renderer seems to push to component children directly in runtime,
 * therefore a wrapper component is needed to forward the list item correctly into the list instead of {@link Div}.
 */
const ListItemForwarder: React.FC<
	React.PropsWithChildren<unknown & { forwardingListType: Parameters<typeof React.createElement>[0] }>
> = ({ children, forwardingListType, ...props }) => {
	const context = React.useContext(ctx);
	const nested = React.createElement(forwardingListType, props, [children]);
	return context.compact ? nested : <Div>{nested}</Div>;
};

export class TypographicElementsListRenderer extends ListRenderer {
	override renderNodeWithProps(props: object) {
		const list = super.renderNodeWithProps(props);
		return React.createElement(ListItemForwarder, { forwardingListType: list.type, ...props }, []);
	}
}
