import * as React from "react";

import { ModalFooter } from "@bokio/components/Modal";
import { Button } from "@bokio/designsystem/components/Button";
import { Link } from "@bokio/elements/Link/Link";
import { SettingsLangFactory } from "@bokio/lang";
import { getRoute } from "@bokio/shared/route";
import { noop } from "@bokio/shared/utils";

import * as styles from "../addFiscalYearModal.scss";

interface InvalidPermissionsViewProps {
	showSelectCompanyLink: boolean;
}

export const InvalidPermissionsView = ({ showSelectCompanyLink }: InvalidPermissionsViewProps) => {
	const lang = SettingsLangFactory();
	return (
		<React.Fragment>
			<div className={styles.container} data-testid="AddFiscalYear_InvalidPermissionsView">
				{lang.FiscalYears_CreateFiscalYear_InvalidPermissions}
				{showSelectCompanyLink && (
					<React.Fragment>
						<br />
						<br />
						<Link route={getRoute("selectCompany")}> {lang.AddFiscalYearModal_SwitchCompanies} </Link>
					</React.Fragment>
				)}
			</div>
			<ModalFooter>
				<Button appearance="primary" onClick={noop} disabled={true}>
					{lang.FiscalYears_Create_Title}
				</Button>
			</ModalFooter>
		</React.Fragment>
	);
};
