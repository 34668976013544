import * as React from "react";

import CheckBox from "@bokio/elements/CheckBox/CheckBox";
import { FormGroup } from "@bokio/elements/Form";
import { Notice } from "@bokio/elements/Notice/Notice";
import { InfoTooltip } from "@bokio/elements/Tooltip";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { useCompanyUser } from "@bokio/shared/state/requests";
import { formatMessage } from "@bokio/shared/utils/format";

import type { BankLang } from "@bokio/lang/BankLangFactory";

import * as styles from "./onboardingCheckForms.scss";

interface OnboardingChecksFormProps {
	personalId: string;
	bankLang: BankLang;
	setCheckedState: (ok: boolean) => void;
}

interface FormState {
	isOwnerOfEmail: boolean;
	isRealCompany: boolean;
	isNotSharedAccount: boolean;
}

const initialFormState: FormState = { isNotSharedAccount: false, isOwnerOfEmail: false, isRealCompany: false };

export function OnboardingChecksForm({ personalId, bankLang, setCheckedState }: OnboardingChecksFormProps) {
	const { companyInfo } = useCompanyInfo();
	const { userStatus } = useCompanyUser();

	const [state, update] = React.useReducer((current: FormState, update: Partial<FormState>) => {
		return { ...current, ...update };
	}, initialFormState);

	React.useEffect(() => {
		if (state.isNotSharedAccount && state.isOwnerOfEmail && state.isRealCompany) {
			setCheckedState(true);
		}
	}, [setCheckedState, state]);

	return (
		<>
			<Notice dismissible={false}>{bankLang.ActivateBusinessAccount_Modal_AdditionalChecksNotice}</Notice>
			<FormGroup checkBoxOrRadio>
				<CheckBox
					checked={state.isOwnerOfEmail}
					onChange={checked => update({ isOwnerOfEmail: checked })}
					testId="BankOnboardingPrompt_OwnerOfEmail"
					label={
						<span>
							{formatMessage(
								bankLang.ActivateBusinessAccount_Modal_OwnerOfEmail,
								() => (
									<strong className={styles.emphasizedText}>{userStatus?.Email}</strong>
								),
								() => (
									<strong className={styles.emphasizedText}>{personalId}</strong>
								),
							)}
						</span>
					}
				/>
			</FormGroup>
			<FormGroup checkBoxOrRadio>
				<CheckBox
					checked={state.isRealCompany}
					onChange={checked => update({ isRealCompany: checked })}
					testId="BankOnboardingPrompt_RealCompany"
					label={
						<span>
							{formatMessage(
								bankLang.ActivateBusinessAccount_Modal_RealCompany,
								() => (
									<strong className={styles.emphasizedText}>{companyInfo.Name}</strong>
								),
								() => (
									<strong className={styles.emphasizedText}>{companyInfo.OrgNumber}</strong>
								),
							)}
							<InfoTooltip
								contentGenerator={() =>
									formatMessage(bankLang.ActivateBusinessAccount_Modal_RealCompany_Tooltip, () => companyInfo.OrgNumber)
								}
							/>
						</span>
					}
				/>
			</FormGroup>
			<FormGroup checkBoxOrRadio>
				<CheckBox
					checked={state.isNotSharedAccount}
					onChange={checked => update({ isNotSharedAccount: checked })}
					testId="BankOnboardingPrompt_NotShared"
					label={
						<span>
							{formatMessage(bankLang.ActivateBusinessAccount_Modal_NotShared, () => (
								<strong className={styles.emphasizedText}>{userStatus?.Email}</strong>
							))}
						</span>
					}
				/>
			</FormGroup>
		</>
	);
}
