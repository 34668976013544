import * as React from "react";

import { GlobalFileDropZone } from "@bokio/components/GlobalFileDropZone/GlobalFileDropZone";
import { UploadReceiptProvider } from "@bokio/components/UploadReceiptProvider/UploadReceiptProvider";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { useCompanyUser, useTodoStatus } from "@bokio/shared/state/requests";

import { useTopLevelUser } from "../TopLevelUserContext/useTopLevelUser";
import { uploadContext } from "./UploadContext";

import type { UploadContext, UploadMessage } from "./UploadContext";
import type { Listener } from "@bokio/shared/services/api/signalRHub";

export const UploadContextProvider: React.FC<React.PropsWithChildren> = props => {
	const { company } = useCompanyUser();
	const { memberships } = useTopLevelUser();

	const { redirect } = useRouter();
	const listeners = React.useRef<Listener<UploadMessage>[]>([]);
	const { updateTodoStatus } = useTodoStatus();

	const [globalFileDropZoneBlockerCounter, setGlobalFileDropZoneBlockerCounter] = React.useState(0);
	const globalFileDropZoneDisabled = globalFileDropZoneBlockerCounter !== 0;

	const messageQueue = React.useRef<UploadMessage[]>([]);
	const rerouteToBookkeepingOnSingleUpload = React.useRef<boolean | undefined>(false);

	const addListener = (listener: Listener<UploadMessage>) => {
		listeners.current = [...listeners.current, listener];
		messageQueue.current.forEach(e => listener(e));
	};

	const removeListener = (listener: Listener<UploadMessage>) => {
		listeners.current = listeners.current.filter(keep => keep !== listener);
	};

	const dispatchMessage = (e: UploadMessage) => {
		messageQueue.current = [...messageQueue.current, { ...e, historic: true }];
		listeners.current.forEach(listener => listener(e));
	};

	const isPartnerAccountant = memberships?.partners.some(p => p.PartnerId === company?.PartnerStatus?.PartnerId);

	const companyId = company?.Id;

	return (
		<UploadReceiptProvider
			companyId={companyId}
			allowMultiUpload
			onUploadStarted={file => {
				if (!company) {
					throw new Error("Invalid Company");
				}
				if (
					file.length > 1 ||
					!rerouteToBookkeepingOnSingleUpload.current ||
					(!isPartnerAccountant &&
						company.HasActivePartner &&
						company.PartnerStatus?.PartnerCompanyType === m.Entities.PartnerCompanyType.FullPartner)
				) {
					redirect(getRoute("uploads", { company: company.Id }));
				}
				dispatchMessage({ type: "started", file });
			}}
			onUploadChange={requests => dispatchMessage({ type: "change", requests })}
			onUploadDone={request => dispatchMessage({ request: request, type: "done" })}
			onSingleReceiptUpload={request => {
				if (!company) {
					throw new Error("Invalid Company");
				}

				rerouteToBookkeepingOnSingleUpload.current &&
					request.Data &&
					redirect(getRoute("preBookkeep", { company: company.Id, receiptId: request.Data.Id }));
			}}
			onAllUploadsDone={requests => {
				updateTodoStatus();
				dispatchMessage({ type: "allDone", requests });
			}}
		>
			{uploader => {
				const context: UploadContext = {
					requests: uploader.requests,
					invokeUpload: (routeToPreBookkeep?: boolean) => {
						uploader.setImmediatelyDoReceiptPrediction(!routeToPreBookkeep);
						uploader.invokeUpload();
						rerouteToBookkeepingOnSingleUpload.current = routeToPreBookkeep;
					},
					// TODO Support multiple uploads
					addListener,
					removeListener,
					setGlobalFileDropZoneBlockerCounter,
				};
				return (
					<uploadContext.Provider value={context}>
						{props.children}
						{/* Placing GlobalFileDropZone as the next sibling of children, so the drop zone can be shown above anything in the app baseLayer without using z-index. */}
						<GlobalFileDropZone
							disabled={globalFileDropZoneDisabled}
							handleUploadFiles={files => {
								uploader.setImmediatelyDoReceiptPrediction(true);
								uploader.handleUploadFiles(files);
								rerouteToBookkeepingOnSingleUpload.current = false;
							}}
						/>
					</uploadContext.Provider>
				);
			}}
		</UploadReceiptProvider>
	);
};
