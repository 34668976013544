import * as React from "react";

import { useDeviceQuery } from "./useDeviceQuery";

import type { DeviceQueryParams, DeviceQueryResult } from "./useDeviceQuery";

interface DeviceQueryProps extends DeviceQueryParams {
	children: (props: DeviceQueryResult) => React.ReactNode;
}

/**
 * @deprecated Use the hook version instead: useDeviceQuery. You might need to convert your component to a functional component first.
 */
const DeviceQuery: React.FunctionComponent<DeviceQueryProps> = (props: DeviceQueryProps) => {
	const { children, ...rest } = props;
	const deviceQuery = useDeviceQuery(rest);
	return <React.Fragment>{children(deviceQuery)}</React.Fragment>;
};

export default DeviceQuery;
