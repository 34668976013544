// extracted by mini-css-extract-plugin
export var placeholder = "zq";
export var runningProgress = "__loading_scss__runningProgress";
export var __loading_scss__placeholder = "Aq";
export var loadingShimmer = "qh zq";
export var placeholderInline = "Bq";
export var progressLine = "Cq";
export var __loading_scss__runningProgress = "Dq";
export var paddingTop = "Eq";
export var paddingBottom = "Fq";
export var padding = "Gq Eq Fq";
export var loadingProgressText = "Hq";
export var text = "EK";
export var emphasizedText = "Ce";