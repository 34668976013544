import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button";
import { Form, FormGroup, InputField } from "@bokio/elements/Form";
import { LinkButton } from "@bokio/elements/LinkButton/LinkButton";
import { useLoginBokioPayBankIdAuth } from "@bokio/hooks/useBokioPayBankIdAuth/useBokioPayBankIdAuth";
import { GeneralLangFactory, SignUpLangFactory } from "@bokio/lang";

import type { LoginUserResult } from "@bokio/guest/src/scenes/SignUp/signUpUtils";

import * as styles from "./loginForm.scss";

interface LoginBokioPayStepProps {
	onLoginSuccess: (loginuserStatus: LoginUserResult) => void;
	emailAddress: string;
	onChangeEmail: () => void;
	disableChangeEmail?: boolean;
	baseTestId?: string; // Optional to separate multiple active login forms test ids
}

export const LoginBokioPayStep: React.FC<LoginBokioPayStepProps> = ({
	onLoginSuccess,
	emailAddress,
	onChangeEmail,
	disableChangeEmail,
	baseTestId,
}) => {
	const lang = GeneralLangFactory();
	const signupLang = SignUpLangFactory();

	const { startAuth, startAuthRequest, isPolling, renderAuthModal, renderErrorMessage } = useLoginBokioPayBankIdAuth(
		emailAddress,
		onLoginSuccess,
	);

	return (
		<Form>
			<React.Fragment>
				<p className={styles.intro}>{signupLang.Login_BankIdDescription}</p>
				<React.Fragment>
					<FormGroup>
						<InputField
							value={emailAddress}
							disabled={true}
							inlineControls={
								disableChangeEmail
									? undefined
									: () => (
											<div className={styles.changeEmailLink}>
												<LinkButton onClick={onChangeEmail} testId="login_changeEmail" type="button">
													{lang.Change}
												</LinkButton>
											</div>
										)
							}
						/>
					</FormGroup>

					<FormGroup align="center">
						<Button
							testId={`${baseTestId ?? ""}LoginBokioPayStep_Submit`}
							type="button"
							onClick={startAuth}
							loading={startAuthRequest.isLoading || isPolling}
							icon="bankid-solid"
							iconAlign="right"
						>
							{signupLang.Login_BankIdButton}
						</Button>
					</FormGroup>
					{renderErrorMessage()}
					{renderAuthModal()}
				</React.Fragment>
			</React.Fragment>
		</Form>
	);
};
