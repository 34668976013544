// Implementation from https://github.com/tolbon/luhn-ts/blob/master/src/luhn.ts (no license file) and then applied some suggested fixes

function checkLuhn(strToTest: string, multiple = 10): number {
	let digit = 0;
	let sum = 0;
	const length: number = strToTest.length;
	let odd = false;

	for (let i: number = length - 1; i >= 0; i--) {
		digit = parseInt(strToTest[i], 10) || 0;

		if (odd) {
			digit = digit * 2 || 0;
		}
		if (digit > 9) {
			digit = digit - 9;
		}
		odd = !odd;
		sum += digit;
	}
	const res: number = sum % multiple;
	if (res === 0) {
		return 0;
	}
	return multiple - res;
}

function isLuhnValid(strToTest: string, multiple = 10): boolean {
	if (strToTest.length === 0) {
		return false;
	}

	const ret: boolean = checkLuhn(strToTest, multiple) === 0;
	return ret;
}

/**
 * return a clean copy of strTotest
 * @param {string} strToTest
 * @return {string}
 */
function cleanProposed(strToTest: string): string {
	const cleanStr: string = String(strToTest).replace(/[^\d]/g, "");

	return cleanStr;
}

/**
 * Return false if strToTest is empty or doesn't pass the checksum validation
 * @param {string} strToTest A string with at least one digit - non-digits will be ignored
 * @return {boolean} true if the checksum validation succeeded
 */
export function validateNumberWithLuhnChecksum(strToTest: string): boolean {
	return isLuhnValid(cleanProposed(strToTest));
}

export function correctLuhn(strToTest: string): string {
	if (strToTest.length === 0) {
		throw new Error("input is empty");
	}

	if (validateNumberWithLuhnChecksum(strToTest)) {
		return strToTest;
	}

	const prefix = (parseInt(cleanProposed(strToTest).slice(0, -1), 10) || 0) * 10;

	for (let i = 0; i <= 9; ++i) {
		const str = (prefix + i).toString();
		if (validateNumberWithLuhnChecksum(str)) {
			return str;
		}
	}
	throw new Error("input is not valid format");
}
