import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import Field from "../Field/Field";
import { FormContext } from "../Form";
import { Label } from "../LabelFor/Label";
import { Validation } from "../Validation/Validation";

import type { SelectFieldOption } from "../SelectField";
import type { InputFieldProps } from "@bokio/elements/Form/InputField";

import * as styles from "./radioField.scss";

export interface RadioFieldProps extends InputFieldProps {
	name: string;
	label?: React.ReactNode;
	hint?: string;
	options: SelectFieldOption[];
	layout?: "vertical" | "horizontal";
	radioButtonClass?: string;
	/**
	 * The class name that targets the <span> inside of each item's <label> DOM element.
	 *
	 * If you are looking for the per-item <label> class name, use the className in `options` property.
	 */
	labelClassName?: string;
	optionClassName?: string;
	/**
	 * The class name that applies to <Label className={...}> component.
	 */
	topLabelClassName?: string;
}

export const RadioField = ({
	label,
	hint,
	options,
	wrapperClassName,
	labelClassName,
	errors,
	layout,
	testId,
	onChange,
	radioButtonClass,
	disabled,
	value,
	name,
	optionClassName,
	topLabelClassName,
}: RadioFieldProps) => {
	const { disableAllFields } = React.useContext(FormContext);
	const isVerticalLayout = layout === "vertical";
	return (
		<Field className={mergeClassNames(styles.field, wrapperClassName)}>
			<div className={styles.label}>
				{label && <Label label={label} hint={hint} className={mergeClassNames(styles.innerLabel, topLabelClassName)} />}
				<div className={mergeClassNames(styles.options, isVerticalLayout && styles.verticalOptions, optionClassName)}>
					{options.map(item => {
						const itemDisabled = disableAllFields || disabled || item.disabled;
						return (
							<label
								key={item.value}
								className={mergeClassNames(
									isVerticalLayout ? styles.verticalRadio : styles.radio,
									itemDisabled && styles.radioDisabled,
									item.className,
								)}
								data-testid={item.testId}
								data-value={item.value}
								data-name={name}
							>
								<input
									type="radio"
									name={name}
									value={item.value}
									data-testid={testId}
									onChange={e => onChange && onChange(e.target.value)}
									checked={value === item.value}
									disabled={itemDisabled}
									className={mergeClassNames(
										radioButtonClass,
										itemDisabled ? styles.checkmarkDisabled : styles.checkmarkEnabled,
										styles.nativeRadio,
									)}
								/>
								<span className={mergeClassNames(styles.radioLabel, labelClassName)}>{item.label}</span>
							</label>
						);
					})}
				</div>
				<Validation testId={`Validation_Inline_${testId}`} errors={errors && errors.errors} />
			</div>
		</Field>
	);
};
