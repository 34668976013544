import * as React from "react";
import { useState } from "react";

import { isPastDate } from "@bokio/bank/src/utils/paymentUtils";
import { getWarningsFromPaymentGroup, PaymentWarnings } from "@bokio/components/PaymentWarnings/PaymentWarnings";
import { Button } from "@bokio/designsystem/components/Button";
import CheckBox from "@bokio/elements/CheckBox/CheckBox";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import Icon from "@bokio/elements/Icon/Icon";
import { Notice } from "@bokio/elements/Notice/Notice";
import { Td, Tr } from "@bokio/elements/Table";
import { BankLangFactory, GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { getDisplayedPaymentFor } from "@bokio/shared/utils/Bank/renderTellerPaymentInformationUnion";
import { formatDate, formatNumberCurrency } from "@bokio/shared/utils/format";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

import * as styles from "./signAndPayRow.scss";

type PaymentValidationRulesDto = m.Bokio.Bank.Contract.Dtos.PaymentValidationRulesDto;
type PaymentGroup = m.Bokio.Bank.Contract.Dtos.PaymentGroupForListDto;
type FailedPaymentInfo = m.Bokio.Bank.Contract.Dtos.FailedPaymentInfo;
interface ErrorNoticeProps {
	testId?: string;
	errorMessage?: string;
}

const ErrorNotice: React.FC<ErrorNoticeProps> = props => (
	<Tr>
		<Td colSpan={6}>
			<Notice margin={[]} color="error" testId={props.testId}>
				{props.errorMessage || GeneralLangFactory().SomethingWentWrongWeAreSorry}
			</Notice>
		</Td>
	</Tr>
);

interface SignAndPayRowProps {
	paymentGroup: PaymentGroup;
	toggle: (id: string, checked: boolean) => void;
	disabled: boolean;
	selected?: boolean;
	failedPayments: Record<string, FailedPaymentInfo>;
	paymentValidationRules: PaymentValidationRulesDto;
}

export const SignAndPayRow: React.FC<SignAndPayRowProps> = ({
	paymentGroup,
	toggle,
	disabled,
	selected,
	failedPayments,
	paymentValidationRules,
}) => {
	const generalLang = GeneralLangFactory();
	const bankLang = BankLangFactory();
	const { isMobile } = useDeviceQuery();
	const { companyInfo } = useCompanyInfo();
	const domesticCurrency = companyInfo.CountryMetadata.Currency;

	const [open, setOpen] = useState(false);

	const toggleOpen = () => {
		setOpen(!open);
	};

	const payment = paymentGroup.Payments[0];
	const hasErrors = paymentGroup.Payments.some(p => failedPayments[p.Id]);
	const isForeignCurrency = payment.Currency !== domesticCurrency;
	const groupDomesticTotal = paymentGroup.Payments.reduce((sum, p) => sum + p.ApproximateDomesticSum, 0);

	return (
		<>
			<Tr
				className={mergeClassNames(styles.tr, hasErrors && styles.error, paymentGroup.IsBatch && styles.batch)}
				testId="SignAndPay_Payment_Row"
			>
				<Td className={styles.expand}>
					{paymentGroup.IsBatch && (
						<Icon
							onClick={toggleOpen}
							name={open ? "down-open-big" : "right-open-big"}
							testId="PaymentGroupRow_ToggleOpen"
							className={styles.originalColor}
						/>
					)}
				</Td>
				{!isMobile && (
					<Td shrink align="center" verticalAlign="middle">
						<CheckBox
							testId={`SelectPayment_${paymentGroup.Id}`}
							disabled={disabled}
							className={styles.margin0}
							checked={selected}
							onChange={selected => toggle(paymentGroup.Id, selected)}
						/>
					</Td>
				)}
				<Td className={mergeClassNames(styles.paymentFor, !selected && styles.linethrough)}>
					{paymentGroup.IsBatch
						? paymentGroup.GroupReference
						: getDisplayedPaymentFor(payment.SenderReference, payment.PaymentInfoUnion)}
				</Td>
				<Td className={styles.date}>
					<span className={styles.mobileHeader}>{generalLang.Date}</span>
					<span className={mergeClassNames(!selected && styles.linethrough)}>
						{isPastDate(payment) && selected
							? formatDate(paymentValidationRules.NextPossiblePaymentDateIncludingToday)
							: formatDate(paymentGroup.Date)}
					</span>
				</Td>
				<Td className={styles.paymentWarning} testId="SignAndPayRow_PaymentWarnings">
					<PaymentWarnings
						isGroup={paymentGroup.IsBatch}
						warnings={getWarningsFromPaymentGroup(paymentGroup)}
						isSigning
					/>
				</Td>
				<Td className={styles.sum} align="right">
					<span className={styles.mobileHeader}>{generalLang.Sum}</span>
					<span className={mergeClassNames(!selected && styles.linethrough)}>
						{isForeignCurrency
							? `~${formatNumberCurrency(groupDomesticTotal, domesticCurrency)} (${formatNumberCurrency(
									paymentGroup.Sum,
									payment.Currency,
								)})`
							: formatNumberCurrency(paymentGroup.Sum, payment.Currency)}
					</span>
				</Td>
				{isMobile && (
					<Td className={styles.buttons} align="right">
						<Button
							disabled={disabled}
							appearance="secondary"
							icon={selected ? undefined : "plus"}
							onClick={() => toggle(paymentGroup.Id, !selected)}
						>
							{selected ? bankLang.SignAndPayRow_MoveForLater_Button : bankLang.SignAndPayRow_AddToPay_Button}
						</Button>
					</Td>
				)}
			</Tr>

			{!paymentGroup.IsBatch && failedPayments[payment.Id] && (
				<ErrorNotice testId="PaymentGroupRow_ErrorNotice" errorMessage={failedPayments[payment.Id].ErrorMessage} />
			)}
			{paymentGroup.IsBatch &&
				!isMobile &&
				open &&
				paymentGroup.Payments.map(p => (
					<React.Fragment key={p.Id}>
						<Tr className={mergeClassNames(styles.batchRow, failedPayments[p.Id] && styles.error)}>
							<Td colSpan={2} />
							<Td className={styles.paymentFor}>{getDisplayedPaymentFor(p.SenderReference, p.PaymentInfoUnion)}</Td>
							<Td />
							<Td>
								<PaymentWarnings isGroup={false} warnings={p.Warnings} isSigning />
							</Td>
							<Td className={styles.sum} align="right">
								<span className={styles.mobileHeader}>{generalLang.Sum}</span>
								{formatNumberCurrency(p.Sum, p.Currency)}
							</Td>
						</Tr>
						{failedPayments[p.Id] && (
							<ErrorNotice testId="PaymentRow_ErrorNotice" errorMessage={failedPayments[p.Id].ErrorMessage} />
						)}
					</React.Fragment>
				))}
		</>
	);
};
