import * as React from "react";

import type { EntityValidator, FieldValidationResult, ValidatorResult } from "@bokio/shared/validation/entityValidator";

type ValidatorFunction<FormData, Result> = (
	formData: FormData,
	event: "change" | "submit",
	prevValidation?: Result & { isValid: boolean; errors?: FieldValidationResult[] },
) => Result & { isValid: boolean; errors?: FieldValidationResult[] };

export const asValidatorFunction =
	<FormData,>(oldStyleValidator: EntityValidator<FormData>): ValidatorFunction<FormData, ValidatorResult> =>
	(formData, event): ValidatorResult & { isValid: boolean } => {
		const result = oldStyleValidator.validate(formData, false, event === "submit");
		return { isValid: result.errors.length === 0, ...result };
	};

interface FormWithValidationProps<FormData, Result> {
	initialState: FormData;
	className?: string;
	validator: ValidatorFunction<FormData, Result>;
	children: (state: FormHandle<FormData, Result>) => React.ReactNode;
	onChange?: (formData: FormData, result: Result | undefined) => void;
	onSubmit: (formData: FormData, result: Result) => void;
	stopSubmitPropagation?: boolean;
}

interface FormWithValidationState<FormData, Result> {
	formData: FormData;
	submitCount: number;
	isPristine: boolean;
	validation: Result & { isValid: boolean; errors?: FieldValidationResult[] };
}

export interface FormHandle<FormData, Result> extends FormWithValidationState<FormData, Result> {
	setValue: (key: keyof FormData) => (val: unknown) => void;
	setFormData: (reducer: (oldFormData: FormData) => FormData) => void;
}

export const DefaultFormValidator = () => ({ isValid: true });

export class FormWithValidation<FormData, Result> extends React.Component<
	FormWithValidationProps<FormData, Result>,
	FormWithValidationState<FormData, Result>
> {
	state: FormWithValidationState<FormData, Result> = {
		formData: this.props.initialState,
		submitCount: 0,
		isPristine: true,
		validation: this.props.validator(this.props.initialState, "change"),
	};

	render() {
		const parameters: FormHandle<FormData, Result> = {
			...this.state,

			setValue: this.setValue,
			setFormData: this.setFormData,
		};
		const content = this.props.children(parameters);
		return (
			<form onSubmit={this.onSubmit} className={this.props.className}>
				{content}
			</form>
		);
	}

	setValue = (key: string | number | symbol) => (newValue: unknown) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.setFormData(formData => ({ ...(formData as any), [key]: newValue }));
	};

	setFormData = (reducer: (oldData: FormData) => FormData) => {
		const { onChange, validator } = this.props;
		this.setState(
			state => {
				const formData = reducer(state.formData);
				const validation = validator(formData, "change", state.validation);
				const isPristine = false;
				return { ...state, formData, isPristine, validation };
			},
			() => {
				if (onChange) {
					onChange(this.state.formData, this.state.validation);
				}
			},
		);
	};

	onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const { formData } = this.state;
		const { validator, onSubmit, stopSubmitPropagation = false } = this.props;

		event.preventDefault();

		// TODO: try another approach by stop even bubbling in the modal instead
		// https://dev.azure.com/bokiodev/Voder/_workitems/edit/29236
		if (stopSubmitPropagation && typeof event.stopPropagation === "function") {
			// prevent any outer forms receiving submit event
			event.stopPropagation();
		}

		const validation = validator(formData, "submit");

		this.setState(state => ({ ...state, validation }));
		if (validation.isValid) {
			onSubmit(formData, validation);
		}
	};
}
