
/**
 * ┌────────────────────────────────────────────────────────────────────────┐
 * │ This file has been generated by themeGenerator                         │
 * │                                                                        │
 * │ The theme is defined in theme.ts                                   	│
 * │ Please do not edit this file manually.                                 │
 * └────────────────────────────────────────────────────────────────────────┘
**/

import type { StandardSpacingNames } from "./theme";
import type { MarginAway } from "../components/TypographicElements/TypographicElements.types";

export const flattenedCssVariables = {
    "color-primary-100": "#EEF6FF",
    "color-primary-200": "#DAEBFF",
    "color-primary-300": "#80C5FF",
    "color-primary-500": "#1259EF",
    "color-primary-700": "#000E3B",
    "color-success-100": "#F3FFF9",
    "color-success-200": "#D4FFEB",
    "color-success-300": "#7FDBA7",
    "color-success-500": "#16B47C",
    "color-success-700": "#154D3D",
    "color-warning-100": "#FFFAF0",
    "color-warning-200": "#FFE8BB",
    "color-warning-300": "#FFCD6D",
    "color-warning-500": "#FFAE10",
    "color-warning-700": "#704B03",
    "color-destructive-100": "#FFF0EF",
    "color-destructive-200": "#FFD8D7",
    "color-destructive-300": "#FFA5A6",
    "color-destructive-500": "#E7373B",
    "color-destructive-700": "#7F0000",
    "color-warm-100": "#FBF9F7",
    "color-warm-200": "#F5F0EA",
    "color-warm-300": "#E9E2D9",
    "color-warm-500": "#66615B",
    "color-burgundy-100": "#FFF3FB",
    "color-burgundy-200": "#FFE3F5",
    "color-burgundy-300": "#D97CB2",
    "color-burgundy-500": "#9C0034",
    "color-burgundy-700": "#620024",
    "color-action-focus": "#80C5FF",
    "color-reserved-transparent": "rgba(0,0,0,0)",
    "color-ui-1": "#1E2127",
    "color-ui-2": "#5C6B78",
    "color-ui-3": "#90989E",
    "color-ui-4": "#BBC2C7",
    "color-ui-5": "#E1E5E8",
    "color-ui-6": "#F5F7F9",
    "color-ui-7": "#FAFBFC",
    "color-ui-8": "#FFF",
    "color-base": "#fff",
    "breakpoint-mobile": "480px",
    "breakpoint-mobile-max": "767px",
    "breakpoint-tablet-portrait": "768px",
    "breakpoint-tablet-portrait-max": "1023px",
    "breakpoint-tablet-landscape": "1024px",
    "breakpoint-tablet-landscape-max": "1279px",
    "breakpoint-desktop": "1280px",
    "breakpoint-desktop-max": "1599px",
    "breakpoint-desktop-large": "1600px",
    "typography-weight-regular": "400",
    "typography-weight-medium": "500",
    "typography-weight-semibold": "600",
    "typography-weight-bold": "700",
    "spacing-0": "0px",
    "spacing-4": "4px",
    "spacing-6": "6px",
    "spacing-8": "8px",
    "spacing-12": "12px",
    "spacing-16": "16px",
    "spacing-24": "24px",
    "spacing-32": "32px",
    "spacing-40": "40px",
    "spacing-48": "48px",
    "modal-padding-top": "50px",
    "modal-padding-bottom": "90px",
    "modal-content-padding": "16px",
    "modal-content-max-height": "calc(100vh - #{$modal-padding-top} - #{$modal-padding-bottom} - 57px - 69px)",
} as const;

export const themeColors = {
    "color-primary-100": "#EEF6FF",
    "color-primary-200": "#DAEBFF",
    "color-primary-300": "#80C5FF",
    "color-primary-500": "#1259EF",
    "color-primary-700": "#000E3B",
    "color-success-100": "#F3FFF9",
    "color-success-200": "#D4FFEB",
    "color-success-300": "#7FDBA7",
    "color-success-500": "#16B47C",
    "color-success-700": "#154D3D",
    "color-warning-100": "#FFFAF0",
    "color-warning-200": "#FFE8BB",
    "color-warning-300": "#FFCD6D",
    "color-warning-500": "#FFAE10",
    "color-warning-700": "#704B03",
    "color-destructive-100": "#FFF0EF",
    "color-destructive-200": "#FFD8D7",
    "color-destructive-300": "#FFA5A6",
    "color-destructive-500": "#E7373B",
    "color-destructive-700": "#7F0000",
    "color-warm-100": "#FBF9F7",
    "color-warm-200": "#F5F0EA",
    "color-warm-300": "#E9E2D9",
    "color-warm-500": "#66615B",
    "color-burgundy-100": "#FFF3FB",
    "color-burgundy-200": "#FFE3F5",
    "color-burgundy-300": "#D97CB2",
    "color-burgundy-500": "#9C0034",
    "color-burgundy-700": "#620024",
    "color-action-focus": "#80C5FF",
    "color-reserved-transparent": "rgba(0,0,0,0)",
    "color-ui-1": "#1E2127",
    "color-ui-2": "#5C6B78",
    "color-ui-3": "#90989E",
    "color-ui-4": "#BBC2C7",
    "color-ui-5": "#E1E5E8",
    "color-ui-6": "#F5F7F9",
    "color-ui-7": "#FAFBFC",
    "color-ui-8": "#FFF",
    "color-base": "#fff",
} as const;

export const themeSpacings: StandardSpacingNames[] = ["0", "4", "6", "8", "12", "16", "24", "32", "40", "48"];
export const marginAway: MarginAway[] = ["0", "4", "6", "8", "12", "16", "24", "32", "40", "48", "24t16m"];