// extracted by mini-css-extract-plugin
export var modalInAnimation = "No";
export var modalOutAnimation = "Zx";
export var __modal_scss__modalInAnimation = "No";
export var __modal_scss__modalOutAnimation = "Zx";
export var modal = "Op";
export var animateIn = "Zy";
export var animateOut = "_y";
export var modalVisible = "Sp Op";
export var modalVisibleWide = "Tp Sp Op";
export var modalVisibleExtraWide = "XL Sp Op";
export var modalVisibleMedium = "Up Sp Op";
export var modalVisibleSmall = "Vp Sp Op";
export var modalVisibleVerySmall = "YL Sp Op";
export var wrapper = "Wp";
export var wrapperVisible = "Xp Wp";
export var wrapperContent = "Yp";
export var background = "Zp";
export var header = "_p";
export var heading = "bM";
export var closeContainer = "cM";
export var close = "ZY";
export var content = "aq";
export var contentCentered = "bq aq";
export var footer = "cq";
export var footerCentered = "dq cq";