import * as React from "react";

import { useUploadContext } from "@bokio/contexts/UploadContext/UploadContext";

// Tests of use case covered in UploadContextProvider

/**
 * Disable global file drop zone as long as the calling component is still mounted
 */
export const useDisableGlobalFileDropZone = () => {
	const { setGlobalFileDropZoneBlockerCounter } = useUploadContext();
	React.useEffect(() => {
		if (!setGlobalFileDropZoneBlockerCounter) {
			return;
		}
		setGlobalFileDropZoneBlockerCounter(counter => counter + 1);
		return () => setGlobalFileDropZoneBlockerCounter(counter => counter - 1);
	}, [setGlobalFileDropZoneBlockerCounter]);
};

/**
 * Dummy component for disabling global file drop zone in a class component
 */
export const DisableGlobalFileDropZone: React.FC = () => {
	useDisableGlobalFileDropZone();
	return <></>;
};
