import * as React from "react";

import imgDot from "@bokio/assets/images/dot.svg";
import imgNail from "@bokio/assets/images/nail.svg";
import SingleFormPage from "@bokio/components/SingleFormPage/SingleFormPage";
import { Button } from "@bokio/designsystem/components/Button";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { formatMessage } from "@bokio/shared/utils/format";
import { mergeClassNames } from "@bokio/utils/classes";
import { trackException } from "@bokio/utils/t";

import { ContactSupportLink } from "../ContactSupportLink/ContactSupportLink";

import * as styles from "./errorBoundary.scss";

interface ErrorBoundaryProps {
	source: "App" | "Route";
}

interface ErrorBoundaryState {
	hasError: boolean;
}

const onReload = () => {
	window.location.href = "/";
};

export default class ErrorBoundary extends React.Component<
	React.PropsWithChildren<ErrorBoundaryProps>,
	ErrorBoundaryState
> {
	state: ErrorBoundaryState = { hasError: false };

	componentDidCatch(error: Error, info: object) {
		const { source } = this.props;
		trackException(error, "app.errorBoundary", { ...info, source });
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	render() {
		const lang = GeneralLangFactory();
		if (this.state.hasError) {
			return (
				<SingleFormPage noTopBarMode={false} title={lang.ErrorPage_Title} className={styles.container}>
					<img className={mergeClassNames(styles.topRight, styles.showOnDesktop)} src={imgNail} alt="Corner" />
					<img className={mergeClassNames(styles.topLeft, styles.showOnDesktop)} src={imgDot} alt="Corner" />
					<section className={styles.contentContainer}>
						<Markdown markdownContent={lang.ErrorPage_Message} className={styles.markdownContent} />
						<Button appearance="primary" align="center" onClick={onReload}>
							{lang.ErrorPage_CTA_Reload}
						</Button>
						<div className={styles.contactSupport}>
							{formatMessage(lang.ErrorPage_Footer, link => (
								<ContactSupportLink area={m.Contracts.SupportFormArea.NotSet}>{link}</ContactSupportLink>
							))}
						</div>
					</section>
				</SingleFormPage>
			);
		}

		return this.props.children;
	}
}
