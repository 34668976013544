import { Link } from "@bokio/elements/Link/Link";
import { AnalyticsEventCategory, useMetric } from "@bokio/hooks/useMetric/useMetric";
import { GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { useActiveBankConnection } from "@bokio/mobile-web-shared/hooks/useActiveBankConnection/useActiveBankConnection";
import { getRoute } from "@bokio/shared/route";

import * as styles from "./signAndPayHeader.scss";

export interface SignAndPayHeaderProps {
	showTitleAndBalance?: boolean;
	availableBalanceString?: string;
	onClose: () => void;
}

export const SignAndPayHeader = (props: SignAndPayHeaderProps) => {
	const generalLang = GeneralLangFactory();
	const { companyInfo } = useCompanyInfo();
	// BBA multiple accounts refactoring:
	// Only used to go to BBA transactions list, will be refactored away in multiple accounts design.
	const { activeBusinessAccount } = useActiveBankConnection();
	const [addMetric] = useMetric();

	return (
		<div className={styles.container}>
			{props.showTitleAndBalance && (
				<div className={styles.header}>
					<div className={styles.title}>{generalLang.BBA_PaymentsToSign}</div>
					<div className={styles.balance}>{`${generalLang.BBA_AvailableBalance}: ${
						props.availableBalanceString ?? "-"
					}`}</div>
				</div>
			)}
			<div className={styles.linksContainer}>
				{activeBusinessAccount && (
					<Link
						className={styles.link}
						route={getRoute("bankAccount", {
							company: companyInfo.Id,
							account: `${activeBusinessAccount.BookkeepingAccount}`,
						})}
						onClick={() => {
							props.onClose();
							addMetric(AnalyticsEventCategory.Bba, "GoToBankAccount", "SignAndPayPopover_TransactionsLink");
						}}
					>
						{generalLang.BBA_ViewTransactions}
					</Link>
				)}
				<div className={styles.linkSeparator} />
				<Link
					className={styles.link}
					route={getRoute("bankPayments", { company: companyInfo.Id })}
					onClick={() => {
						props.onClose();
						addMetric(AnalyticsEventCategory.Bba, "GoToPayments", "SignAndPayPopover_PaymentsLink");
					}}
				>
					{generalLang.BBA_ViewPayments}
				</Link>
			</div>
			<div className={styles.modalGap} />
		</div>
	);
};
