import * as React from "react";
import { Key } from "w3c-keys";

import { useClickOutside } from "@bokio/hooks/useClickOutside/useClickOutside";

import { useAccessibleDropdown } from "../../hooks/useAccessibleKeyboard/useAccessibleDropdown";

import type { SelectOption } from "./SearchableSelectOption";
import type { CustomKeyboardBinding } from "@bokio/hooks/useAccessibleKeyboard/useAccessibleKeyboardbinding";

interface UseSearchableSelectProps<T> {
	selected: SelectOption<T> | undefined;
	onChange: (selected: SelectOption<T> | undefined) => void;
	options: SelectOption<T>[];
	searchFn: (option: SelectOption<T>, term: string) => boolean;
	innerRef?: React.MutableRefObject<HTMLInputElement | null>;
	focusOnSelect?: boolean;
	autoSelectWhenClosingMenu: boolean;
	onScrollEnd?: () => void;
}

export function useSearchableSelect<T>({
	selected,
	onChange,
	options,
	searchFn,
	innerRef,
	onScrollEnd,
	focusOnSelect = true,
	autoSelectWhenClosingMenu = true,
}: UseSearchableSelectProps<T>) {
	const [selectedOption, setSelectedOption] = React.useState<SelectOption<T> | undefined>(selected);
	const [isOpen, setIsOpen] = React.useState(false);
	const [searchTerm, setSearchTerm] = React.useState("");
	const [displaySelected, setDisplaySelected] = React.useState(!!selectedOption);

	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const dropdownRef = React.useRef<HTMLDivElement>(null);
	const inputRef = React.useRef<HTMLInputElement | null>(null);
	const scrollRef = React.useRef<HTMLDivElement>(null);

	const filteredOptions = (options || []).filter(option => searchFn(option, searchTerm));

	React.useEffect(() => {
		setSelectedOption(selected);
		setDisplaySelected(true);
	}, [selected]);

	const handleScroll = React.useCallback(() => {
		if (scrollRef.current) {
			const bottom = scrollRef.current.scrollTop + scrollRef.current.clientHeight + 50 > scrollRef.current.scrollHeight;
			bottom && onScrollEnd && onScrollEnd();
		}
	}, [onScrollEnd, scrollRef]);

	React.useEffect(() => {
		const scroll = scrollRef.current;
		scroll && scroll.addEventListener("scroll", handleScroll);

		return () => {
			scroll && scroll.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll, isOpen]);

	const focusInput = () => {
		inputRef.current && document.activeElement !== inputRef.current && inputRef.current.focus();
	};

	const toggleOpen = () => setIsOpen(open => !open);
	const close = () => {
		setIsOpen(false);
	};

	const clearSelection = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (e) {
			// Stop click event from bubbling up to parent div which would cause `toggleOpen` to be invoked
			e.stopPropagation();
		}
		setSelectedOption(undefined);
		setSearchTerm("");
		onChange(undefined);
		setDisplaySelected(false);
		focusInput();
	};

	const select = (option: SelectOption<T>) => {
		if (option.disabled) {
			return;
		}
		setSelectedOption(option);
		setDisplaySelected(true);
		close();
		onChange(option);
		if (focusOnSelect) {
			focusInput();
		}
	};

	const handleSearchInputBlur = () => {
		setDisplaySelected(selectedOption ? true : false);
	};

	const customKeyBindings: CustomKeyboardBinding<SelectOption<T>>[] = [
		[Key.Tab, selectOrClose],
		[
			Key.Enter,
			(_, options, focusedIndex) => {
				if (!isOpen) {
					setIsOpen(true);
					return;
				}

				const focusedOption = options[focusedIndex];
				if (focusedOption) {
					select(focusedOption);
					focusInput();
				}
			},
		],
		[Key.ArrowUp, () => !isOpen && setIsOpen(true)],
		[Key.ArrowDown, () => !isOpen && setIsOpen(true)],
		[Key.Escape, () => close()],
		[Key.Delete, () => clearSelection()],
	];

	const { handleKeyDown, setOptionRef, resetFocusedIndex, focusedIndex } = useAccessibleDropdown<SelectOption<T>>({
		options: filteredOptions,
		customBindings: customKeyBindings,
	});

	function selectOrClose() {
		if (selectedOption || !autoSelectWhenClosingMenu) {
			close();
			return;
		}

		if (filteredOptions.length === 1) {
			select(filteredOptions[0]);
			return;
		}

		const focusedOption = filteredOptions[focusedIndex];
		if (focusedOption) {
			select(focusedOption);
			return;
		}

		if (searchTerm === "") {
			setSelectedOption(undefined);
			onChange(undefined);
			close();
			return;
		}

		const matches = filteredOptions.filter(o => searchFn(o, searchTerm));

		if (matches.length > 0) {
			select(matches[0]);
			return;
		}

		close();
	}

	const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
		resetFocusedIndex();
		setDisplaySelected(false);
		setIsOpen(true);
		if (selectedOption) {
			setSelectedOption(undefined);
			onChange(undefined);
		}
	};

	useClickOutside({
		ref: wrapperRef,
		condition: isOpen,
		onClickOutside: selectOrClose,
	});

	const setInputRef = (ref: HTMLInputElement) => {
		if (!ref) {
			return;
		}
		inputRef.current = ref;
		if (innerRef) {
			innerRef.current = ref;
		}
	};

	return {
		actions: {
			handleSearchInputChange,
			handleSearchInputBlur,
			handleKeyDown,
			toggleOpen,
			select,
			setOptionRef,
			setInputRef,
			focusInput,
			clearSelection,
		},
		refs: {
			dropdown: dropdownRef,
			wrapper: wrapperRef,
			input: inputRef,
			scroll: scrollRef,
		},
		searchTerm,
		selectedOption,
		displaySelected,
		filteredOptions,
		focusedIndex,
		isOpen,
	};
}
