export const useDeviceUserAgent = () => {
	const isMobileOrTabletUserAgent = /(iPhone|iPad|Android)/i.test(navigator.userAgent);
	const isBokioMobileAppUserAgent = /(mobileapp__version)/i.test(navigator.userAgent);
	const isAndroidUserAgent = /(Android)/i.test(navigator.userAgent);
	const isIOS = /(iPhone|iPad)/i.test(navigator.userAgent);
	const isChromeOnIOs = /CriOS/i.test(navigator.userAgent);
	const isFirefoxOnIOs = /FxiOS/i.test(navigator.userAgent);

	return {
		isMobileOrTabletUserAgent,
		isBokioMobileAppUserAgent,
		isAndroidUserAgent,
		isIOS,
		isChromeOnIOs,
		isFirefoxOnIOs,
	};
};
