import groupBy from "lodash-es/groupBy";
import upperFirst from "lodash-es/upperFirst";
import * as React from "react";

import { Flyout } from "@bokio/components/Flyout/Flyout";
import { AppMessageType, useAppContext } from "@bokio/contexts/AppContext/AppContext";
import { CloseButton } from "@bokio/designsystem/components/CloseButton/CloseButton";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Heading, Paragraph, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { GeneralLangFactory } from "@bokio/lang";
import { Day } from "@bokio/mobile-web-shared/core/model/types";
import { useAgencyStatus } from "@bokio/shared/state/requests";
import { formatDate } from "@bokio/shared/utils/format";
import htmlDecode from "@bokio/shared/utils/htmlDecode";

import type { UmbracoReleaseNoteModel } from "@bokio/shared/models/UmbracoReleaseNotesModel";

import * as styles from "./whatsNewFlyout.scss";

interface WhatsNewFlyoutProps {
	whatsNewData: UmbracoReleaseNoteModel[];
	isOpen: boolean;
	onClose: () => void;
}

export const WhatsNewFlyout = ({ isOpen, onClose, whatsNewData }: WhatsNewFlyoutProps) => {
	const generalLang = GeneralLangFactory();
	const app = useAppContext();
	const { agencyStatus } = useAgencyStatus();

	const monthAndYearGroups =
		whatsNewData && groupBy(whatsNewData, r => Day.fromDate(new Date(r.ReleaseDate)).format("MMMM yyyy"));

	const MenuWhatsNew_ReleaseNotesLink = () => {
		if (agencyStatus !== undefined) {
			if (whatsNewData.length === 0) {
				return generalLang.MenuWhatsNew_ReleaseNotesLink;
			}
			return generalLang.MenuWhatsNew_ReleaseNotesLink_Agency;
		}
		return generalLang.MenuWhatsNew_ReleaseNotesLink_Company;
	};

	React.useEffect(() => {
		if (isOpen && app && app.messages.length > 0) {
			const agencyReleaseUpdates = app.messages.filter(m => m.type === AppMessageType.ReleaseNoteUpdatesInAgency);
			if (agencyReleaseUpdates && agencyReleaseUpdates.length > 0) {
				app.dismissMessage(agencyReleaseUpdates[0].key);
			}
		}
	}, [app, isOpen]);

	return (
		<Flyout
			asideClassName={styles.flyoutAside}
			backgroundClassName={styles.flyoutBackground}
			isOpen={isOpen}
			onClickOutside={onClose}
		>
			<Section>
				<div className={styles.header}>
					<CloseButton onClick={onClose} />
					<div className={styles.headerText}>
						<Heading>{generalLang.MenuWhatsNew_label}</Heading>
					</div>
					<Link external={MenuWhatsNew_ReleaseNotesLink()} target="_blank">
						<Icon name="external-link" size="18" />
					</Link>
				</div>
			</Section>

			{monthAndYearGroups && (
				<div className={styles.scrollable}>
					<SG section gap="16">
						{Object.entries(monthAndYearGroups).map(([date, releaseNotes], i) => (
							<SG section gap="16" key={i}>
								<Heading>{upperFirst(date)}</Heading>
								{releaseNotes.map((r, i) => (
									<div key={i} className={styles.item}>
										<SG section gap="4">
											<Heading>{r.Title}</Heading>
											<Paragraph style="subheading-default">{formatDate(new Date(r.ReleaseDate))}</Paragraph>
										</SG>

										{/* TODO find a "safe" way to render html */}
										{/* eslint-disable-next-line react/no-danger */}
										<div dangerouslySetInnerHTML={{ __html: htmlDecode(r.WhatsNew) }} />
									</div>
								))}
							</SG>
						))}
					</SG>
				</div>
			)}
		</Flyout>
	);
};
