/**
 * A null propagator function
 */
export default function NP<T, TOut>(item: T | undefined, onValue: (item: T) => TOut) {
	if (item === undefined) {
		return undefined;
	}
	return onValue(item);
}

class NullPropagator<T> {
	constructor(private item: T | undefined) {}

	NP = <TOut>(selector: (item: T) => TOut | undefined) => {
		return new NullPropagator<TOut>(this.item === undefined ? undefined : selector(this.item));
	};

	value = <TOut>(selector: (item: T) => TOut) => {
		return this.item === undefined ? undefined : selector(this.item);
	};
}

export function NullHelper<T>(item: T | undefined) {
	return new NullPropagator<T>(item);
}
