import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button";
import { OnboardingLangFactory } from "@bokio/lang";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "../promotionModal.scss";

interface PromotionModalButtonsProps {
	ctaButtonText: string;
	cancelButtonText?: string;
	resolve: () => void;
	dismiss: () => void;
	horizontal?: boolean;
}

export const PromotionModalButtons: React.FC<PromotionModalButtonsProps> = ({
	ctaButtonText,
	cancelButtonText,
	resolve,
	dismiss,
	horizontal = false,
}) => {
	const onboardingLang = OnboardingLangFactory();

	const cancelBtnText = cancelButtonText || onboardingLang.Onboarding_PromotionModal_postpone_button;

	const [ctaClicked, setCtaClicked] = React.useState<boolean>(false);

	const cta = () => {
		setCtaClicked(true);
		resolve();
	};

	const fullClassName = horizontal
		? mergeClassNames(styles.promotionModalButtonsHorizontal, styles.promotionModalButtons)
		: styles.promotionModalButtons;

	return (
		<div className={fullClassName} data-testid="PromotionModalButtons">
			<Button type="button" onClick={cta} loading={ctaClicked} testId="PromotionModalButtons_ctaButton">
				{ctaButtonText}
			</Button>
			<Button
				type="button"
				onClick={dismiss}
				disabled={ctaClicked}
				appearance="secondary"
				testId="PromotionModalButtons_cancelButton"
			>
				{cancelBtnText}
			</Button>
		</div>
	);
};
