// extracted by mini-css-extract-plugin
export var tooltipTrigger = "s3a";
export var tooltip = "_s";
export var tooltipContent = "t3a";
export var heading = "at";
export var arrow = "u3a";
export var wrapper = "jt";
export var paddingOff = "nt";
export var paddingOn = "ot";
export var black = "pt";
export var white = "qt";