import AccountingLangFactory from "@bokio/mobile-web-shared/core/lang/AccountingLangFactory";
import BackOfficeLangFactory from "@bokio/mobile-web-shared/core/lang/BackOfficeLangFactory";
import BankLangFactory from "@bokio/mobile-web-shared/core/lang/BankLangFactory";
import ClosuresLangFactory from "@bokio/mobile-web-shared/core/lang/ClosuresLangFactory";
import CountriesLangFactory from "@bokio/mobile-web-shared/core/lang/CountriesLangFactory";
import ExpensesLangFactory from "@bokio/mobile-web-shared/core/lang/ExpensesLangFactory";
import GeneralLangFactory from "@bokio/mobile-web-shared/core/lang/GeneralLangFactory";
import InvoicesLangFactory from "@bokio/mobile-web-shared/core/lang/InvoicesLangFactory";
import OnboardingLangFactory from "@bokio/mobile-web-shared/core/lang/OnboardingLangFactory";
import PartnersLangFactory from "@bokio/mobile-web-shared/core/lang/PartnersLangFactory";
import PayslipCalculationsLangFactory from "@bokio/mobile-web-shared/core/lang/PayslipCalculationsLangFactory";
import ReceipesLang from "@bokio/mobile-web-shared/core/lang/RecipesLangFactory";
import SalaryLangFactory from "@bokio/mobile-web-shared/core/lang/SalaryLangFactory";
import SettingsLangFactory from "@bokio/mobile-web-shared/core/lang/SettingsLangFactory";
import SignUpLangFactory from "@bokio/mobile-web-shared/core/lang/SignUpLangFactory";

export {
  BackOfficeLangFactory,
  BankLangFactory,
  CountriesLangFactory,
  GeneralLangFactory,
  AccountingLangFactory,
  ClosuresLangFactory,
  ExpensesLangFactory,
  InvoicesLangFactory,
  PartnersLangFactory,
  PayslipCalculationsLangFactory,
  ReceipesLang,
  SalaryLangFactory,
  SettingsLangFactory,
  SignUpLangFactory,
  OnboardingLangFactory,
};
