import * as React from "react";

export type UseHiddenFileInputProps = {
	allowMultiple?: boolean;
	/**
	 * HTMLInputElement.prototype.accept used by the actual input element
	 *
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
	 */
	acceptFileType?: string;
	/**
	 * Be ware that the user can unselect from file input (e.g. by clicking cancel in Windows file picker dialog),
	 * therefore the number of files could be 0.
	 */
	onSelectedFilesChange: (files: File[]) => void;
	testIdOfHiddenFileInput?: string;
};

/**
 * @example
 * const { renderHiddenFileInput, triggerFileDialog } = useHiddenFileInput({ });
 *
 * return (
 *   <Div>
 *      {renderHiddenFileInput()}
 *      <Button onClick={triggerFileDialog} />
 *   </Div>
 * );
 */
export const useHiddenFileInput = ({
	allowMultiple,
	acceptFileType,
	onSelectedFilesChange,
	testIdOfHiddenFileInput,
}: UseHiddenFileInputProps) => {
	const inputRef = React.useRef<HTMLInputElement>(null);

	const renderHiddenFileInput = () => (
		<input
			data-testid={testIdOfHiddenFileInput}
			ref={inputRef}
			type="file"
			hidden
			accept={acceptFileType}
			multiple={allowMultiple}
			onChange={event => {
				if (event.target.files) {
					onSelectedFilesChange([...event.target.files]);
				}
			}}
		/>
	);

	const triggerFileDialog = () => inputRef.current?.click();

	return { renderHiddenFileInput, triggerFileDialog };
};
