import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { Div, Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { BankLangFactory, GeneralLangFactory } from "@bokio/lang";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { millisecondsToTime } from "@bokio/mobile-web-shared/utils/formatting/millisecondsToTime";
import { formatMessage } from "@bokio/shared/utils/format";

interface SveaLogoutNoticeModalProps {
	visible: boolean;
	onClose: () => void;
	onContinue: () => void;
	onError: () => void;
	onLogout: () => void;
	companyId: string;
	/**
	 * Time in milliseconds
	 */
	timeUntilLogoutNeeded: number;
}

export const SveaLogoutNoticeModal = ({
	onClose,
	onContinue,
	visible,
	companyId,
	onError,
	onLogout,
	timeUntilLogoutNeeded,
}: SveaLogoutNoticeModalProps) => {
	const bankLang = BankLangFactory();
	const generalLang = GeneralLangFactory();

	const [ping, pingRequest] = useLazyApi(proxy.Bank.SveaAuthController.GetUserInfo.Get, {
		onSuccess: () => {
			onContinue();
		},
		onError,
	});

	return (
		<Modal visible={visible} onClose={onClose} title={bankLang.LogoutModal_Title} disableBackgroundClick disabled>
			{visible && (
				<>
					<ModalContent testId="SveaLogoutNoticeModal_Content">
						<Div>
							<Markdown markdownContent={bankLang.LogoutModal_Description} useTypographicElements />
						</Div>
						<Paragraph>
							{formatMessage(bankLang.LogoutModal_TimeRemaining, millisecondsToTime(timeUntilLogoutNeeded, true))}
						</Paragraph>
					</ModalContent>
					<ModalFooter>
						<ButtonGroup align="space-between" stretch>
							<Button
								onClick={onLogout}
								appearance="secondary"
								disabled={pingRequest.isLoading}
								testId="SveaLogoutNoticeModal_LogoutButton"
							>
								{generalLang.LogOut}
							</Button>
							<Button
								onClick={async () => {
									await ping(companyId);
								}}
								appearance="primary"
								disabled={pingRequest.isLoading}
								testId="SveaLogoutNoticeModal_KeepMeLoggedInButton"
							>
								{generalLang.KeepMeLoggedIn}
							</Button>
						</ButtonGroup>
					</ModalFooter>
				</>
			)}
		</Modal>
	);
};
