// extracted by mini-css-extract-plugin
export var header = "L5";
export var postTitleWrapper = "M5";
export var postTitle = "N5";
export var headerWithPadding = "O5";
export var withPostTitle = "P5";
export var titleRow = "Q5";
export var title = "R5";
export var backButton = "S5";
export var action = "iza";
export var stackedActionGroup = "jza";
export var stackedPrimaryAction = "kza";
export var stackedSecondaryAction = "lza";