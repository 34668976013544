import { Link as RouterLink } from "react-router-dom";

import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./link.scss";

export type StyleTypes = "normal" | "plain" | "none" | "native";

export interface LinkVisualisationProps {
	/**
	 * Default is normal
	 * normal - is colored with underline
	 * plain - standard text color (inherited from parent) with underline
	 * none - standard text color with no underline
	 * native - link color with no underline
	 */
	style?: StyleTypes;
	/**
	 * use this to set a custom classnames that will be added to the other built-in
	 */
	className?: string;
}

export interface LinkProps extends LinkVisualisationProps {
	testId?: string;
	/**
	 * Set this to link within the react application.
	 */
	route?: string;
	/**
	 * Set this to link outside the react application. Both to other
	 * urls on the same domain and external url
	 */
	external?: string;
	onClick?: React.EventHandler<React.MouseEvent<HTMLAnchorElement>>;
	/**
	 * Only use onMouseDown when you know why. This will cause problems
	 * on standard links. E.g. you can't click on a link and the cancel the click
	 * by dragging the mouse outside the link. This works by default if you use
	 * onClick.
	 */
	onMouseDown?: React.EventHandler<React.MouseEvent<HTMLAnchorElement>>;
	children?: React.ReactNode;
	target?: string;
	innerRef?: (element: HTMLAnchorElement) => void;
	download?: string | boolean;
	tabIndex?: number;
}

export const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({
	route,
	external,
	onClick,
	onMouseDown,
	style,
	className,
	testId,
	children,
	tabIndex,
	innerRef,
	target,
	download,
}) => {
	const classNames = mergeClassNames(styles[style || "normal"], className);

	if (route) {
		return (
			<RouterLink to={route} data-testid={testId} onClick={onClick} onMouseDown={onMouseDown} className={classNames}>
				{children}
			</RouterLink>
		);
	}

	if (external) {
		return (
			<a
				tabIndex={tabIndex}
				data-testid={testId}
				href={external}
				onClick={onClick}
				onMouseDown={onMouseDown}
				className={classNames}
				ref={innerRef}
				target={target}
				download={download}
				rel="noreferrer noopener"
			>
				{children}
			</a>
		);
	}

	if (onClick || onMouseDown) {
		return (
			<a
				data-testid={testId}
				tabIndex={tabIndex}
				href="#"
				onClick={e => {
					e.preventDefault();
					onClick && onClick(e);
				}}
				onMouseDown={onMouseDown}
				className={classNames}
			>
				{children}
			</a>
		);
	}

	return <span className={classNames}>{children}</span>;
};
