import * as React from "react";

import { BokioBusinessAccountButton } from "@bokio/elements/BokioBusinessAccountButton/BokioBusinessAccountButton";

import { useBokioPlanFeatureButton } from "./useBokioPlanFeatureButton";

import type { BokioPlanFeatureButtonProps } from "./useBokioPlanFeatureButton";
import type { BokioBusinessAccountButtonProps } from "@bokio/elements/BokioBusinessAccountButton/BokioBusinessAccountButton";

type Props = BokioBusinessAccountButtonProps & BokioPlanFeatureButtonProps;

export const BokioPlanBokioBusinessAccountButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
	(props, ref) => {
		const { renderButton } = useBokioPlanFeatureButton({
			featureButtonProps: props,
			lockButtonProps: props,
			lockButtonRef: ref,
			renderActualButton: () => <BokioBusinessAccountButton {...props} />,
		});

		return renderButton();
	},
);
