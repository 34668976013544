import * as React from "react";

import { useCashbackContext } from "@bokio/contexts/CashbackContext/CashbackContext";
import { Button } from "@bokio/designsystem/components/Button";
import { Heading, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { NumberField } from "@bokio/elements/Form";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory, OnboardingLangFactory, SettingsLangFactory } from "@bokio/lang";
import { CURRENCY } from "@bokio/mobile-web-shared/SharedRedLibrary/data/src/currency";
import { formatCurrencySymbol, formatMessage, formatNumberCurrency } from "@bokio/shared/utils/format";
import { mergeClassNames } from "@bokio/utils/classes";
import { calculateCashback, formatPriceplanPercentage, getNrOfDecimals } from "@bokio/utils/priceplanUtils";

import { PromotionModalButtons } from "../PromotionModalButtons/PromotionModalButtons";

import type { PromotionModalInnerProps } from "../promotionModalModels";
import type * as m from "@bokio/mobile-web-shared/core/model/model";

import * as styles from "../promotionModal.scss";

type CashbackContract = m.Bokio.Common.Billing.Model.CashbackContract;
export const BbaToPremiumPlus: React.FC<PromotionModalInnerProps> = ({ resolve, dismiss }) => {
	const generalLang = GeneralLangFactory();
	const settingsLang = SettingsLangFactory();
	const onboardingLang = OnboardingLangFactory();
	const currency = CURRENCY.SEK;
	const cashbacks = useCashbackContext();
	const useBBACashbackValues = cashbacks.estimatedCashbackBasedOnBBA > cashbacks.estimatedCashbackBasedOnBookkeeping;
	const defaultCashbackValues = useBBACashbackValues ? cashbacks.bbaValues : cashbacks.bookkeepingValues;

	const [capitalInput, setCapitalInput] = React.useState(defaultCashbackValues?.DepositedCapital || 0);
	const [cardspendInput, setCardspendInput] = React.useState(defaultCashbackValues?.CardSpend || 0);

	const [cashbackDeposited, setCashbackDeposited] = React.useState(0);
	const [cashbackCardSpend, setCashbackCardSpend] = React.useState(0);

	if (!cashbacks.initialized || !cashbacks.defaultContract) {
		return null;
	}

	const cashbackContract = cashbacks.defaultContract;
	const recalculate = (capital: number, cardspend: number, contract: CashbackContract) => {
		const { cardSpendCashback, depositedCapitalCashback } = calculateCashback(contract, capital, cardspend);
		setCashbackDeposited(depositedCapitalCashback);
		setCashbackCardSpend(cardSpendCashback);
	};

	const formattedPreamble = () => {
		const translation = onboardingLang.Onboarding_BbaToPremiumPlus_PromotionModalHeading_preamble;
		const formattedNumber = formatNumberCurrency(cashbacks.estimatedCashback, "SEK", 0);

		return formatMessage(translation, formattedNumber);
	};

	const highlightPlus = () => <span className={styles.highlightPlus}>Plus</span>;

	return (
		<div
			className={mergeClassNames(styles.promotionModal, styles.bbaToPremiumPlus)}
			data-testid="PromotionModal_BbaToPremiumPlus"
		>
			<Section resetHeadingLevelTo={2}>
				<Heading>
					{formatMessage(onboardingLang.Onboarding_BbaToPremiumPlus_PromotionModalHeading, highlightPlus)}
				</Heading>
				<Markdown markdownContent={formattedPreamble()}></Markdown>
			</Section>

			<div className={styles.kickbackCalculatorContainer}>
				<Section resetHeadingLevelTo={3}>
					<Heading>
						{formatMessage(onboardingLang.Onboarding_BbaToPremiumPlus_PromotionModal_calculator_heading, highlightPlus)}
					</Heading>
				</Section>
				<div className={styles.kickbackCalculator}>
					<Section>
						<NumberField
							label={settingsLang.KickbackCalculator_Label_DepositedCapital}
							value={capitalInput}
							onChange={setCapitalInput}
							postfix={formatCurrencySymbol(currency)}
						/>
						<NumberField
							label={settingsLang.KickbackCalculator_Label_CardSpend}
							value={cardspendInput}
							onChange={setCardspendInput}
							postfix={formatCurrencySymbol(currency)}
						/>
						<Button
							appearance="secondary"
							margin={["top"]}
							stretch
							onClick={() => recalculate(capitalInput, cardspendInput, cashbackContract)}
						>
							{settingsLang.KickbackCalculator_Recalculate}
						</Button>
					</Section>
					<Section resetHeadingLevelTo={4}>
						<section className={styles.kickbackCalculatorTotal}>
							<Heading>{onboardingLang.Onboarding_BbaToPremiumPlus_PromotionModal_Calculation}</Heading>
							<ul>
								<li>
									<span>
										{formatMessage(
											onboardingLang.Onboarding_BbaToPremiumPlus_PromotionModal_Calculation_account,
											formatPriceplanPercentage(cashbackContract.DepositedCapitalCashbackPercentage),
										)}
									</span>
									<span>{formatNumberCurrency(cashbackDeposited, currency, getNrOfDecimals(currency))}</span>
								</li>
								<li>
									<span>
										{formatMessage(
											onboardingLang.Onboarding_BbaToPremiumPlus_PromotionModal_Calculation_card,
											formatPriceplanPercentage(cashbackContract.CardSpendCashbackPercentage),
										)}
									</span>
									<span>{formatNumberCurrency(cashbackCardSpend, currency, getNrOfDecimals(currency))}</span>
								</li>
								<li className={styles.total}>
									<span>{generalLang.Total}:</span>
									<span>
										{formatNumberCurrency(cashbackDeposited + cashbackCardSpend, currency, getNrOfDecimals(currency))} /{" "}
										{generalLang.Month}
									</span>
								</li>
							</ul>
						</section>
					</Section>
				</div>
			</div>

			<PromotionModalButtons
				ctaButtonText={onboardingLang.Onboarding_PromotionModal_CTA_button}
				resolve={resolve}
				dismiss={dismiss}
			/>
		</div>
	);
};
