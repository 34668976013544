import { SignUpLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import { BankIdSecureStart } from "../BankIdSecureStart/BankIdSecureStart";
import { Modal, ModalContent } from "../Modal";

import BankIdCollectionStatus = m.Bokio.Common.Contract.BankId.BankIdCollectionStatus;
import BankIdHintCode = m.Bokio.Common.Contract.BankId.BankIdHintCode;

type BokioPayBankIdAuthModalProps = {
	autoStartToken?: string;
	qrStartToken?: string;
	qrCode?: string;
	collectionStatus?: BankIdCollectionStatus;
	hintCode?: BankIdHintCode;
	isPolling: boolean;

	onClose: () => void;

	onStartPollingQrCode: () => void;
	onStopPollingQrCode: () => void;
};

export function BokioPayBankIdAuthModal(props: BokioPayBankIdAuthModalProps) {
	const signupLang = SignUpLangFactory();

	const shouldShowBankIdModal = () => {
		return props.isPolling || (props.autoStartToken && props.qrStartToken);
	};

	return (
		<Modal
			alwaysMounted={true}
			visible={props.isPolling || props.collectionStatus == BankIdCollectionStatus.Failed}
			title={signupLang.Login_LoginWithBankId}
			onClose={props.onClose}
			disableBackgroundClick={true}
		>
			<ModalContent>
				{shouldShowBankIdModal() && (
					<BankIdSecureStart
						isBokioPayLogin
						autoStartToken={props.autoStartToken}
						bankIdQrCode={props.qrCode}
						hintCode={props.hintCode}
						collectionStatus={props.collectionStatus}
						onStartPollingQrCode={props.onStartPollingQrCode}
						onStopPollingQrCode={props.onStopPollingQrCode}
					/>
				)}
			</ModalContent>
		</Modal>
	);
}
