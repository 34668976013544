import imgAccessDenied from "@bokio/assets/images/ic_access_denied.svg";
import SingleFormPage from "@bokio/components/SingleFormPage/SingleFormPage";
import { Button } from "@bokio/designsystem/components/Button";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory } from "@bokio/lang";
import { getRoute } from "@bokio/shared/route";

import * as styles from "./companyAccessDeniedPage.scss";

export const CompanyAccessDeniedPage = () => {
	const lang = GeneralLangFactory();

	return (
		<SingleFormPage
			noTopBarMode={false}
			title={lang.Forbidden}
			illustration={<img src={imgAccessDenied} className={styles.heroImage} alt="" />}
		>
			<div className={styles.container}>
				<Markdown markdownContent={lang.CompanyAccessDeniedPage_Content} />

				<p className={styles.ctasection}>
					<Button
						appearance="primary"
						type="link"
						route={getRoute("selectCompany")}
						testId="CompanyAccessDeniedPage_ProceedLink"
					>
						{lang.ChooseAnotherCompany}
					</Button>
				</p>
			</div>
		</SingleFormPage>
	);
};
