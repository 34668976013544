import * as React from "react";

import { DotBadge } from "@bokio/designsystem/components/DotBadge/DotBadge";
import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import { TopBarButton } from "./TopBarButton";

import * as styles from "./topBarDropDownButton.scss";

interface TopBarDropDownButtonProps {
	open: boolean;
	text: React.ReactNode;
	disableBackgroundClick?: boolean;
	onClick: () => void;
	onBlur?: () => void;
	onBackgroundClose: () => void;
	testId?: string;
	badge?: boolean;
}

export const TopBarDropDownButton = ({
	text,
	onClick,
	onBlur,
	open,
	disableBackgroundClick,
	onBackgroundClose,
	testId,
	badge,
}: TopBarDropDownButtonProps) => {
	const onBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
		// Only close the modal if we clicked on the background
		if (e.target !== e.currentTarget) {
			return;
		}
		onBackgroundClose();
	};

	return (
		<React.Fragment>
			<TopBarButton onClick={onClick} onBlur={onBlur} active={open} testId={testId}>
				<span>{text}</span>
				<Icon name="down-open-big" size="16" className={styles.arrow} />
				{badge && (
					<div className={styles.badgeContainer}>
						<DotBadge testId="TopBarDropDownButton_Badge" />
					</div>
				)}
			</TopBarButton>
			<div
				className={mergeClassNames(styles.background, open && !disableBackgroundClick && styles.backgroundVisible)}
				onClick={e => !disableBackgroundClick && onBackgroundClick(e)}
				data-testid={`${testId}_Background`}
			/>
		</React.Fragment>
	);
};
