import * as React from "react";

import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import { useSveaAuthentication } from "@bokio/mobile-web-shared/hooks/useSveaAuthentication/useSveaAuthentication";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { noop } from "@bokio/shared/utils";
import { deleteCookie, readCookie, sveaLastActivityCookieName } from "@bokio/utils/cookie";

import { SveaLogoutNoticeModal } from "./components/SveaLogoutNoticeModal/SveaLogoutNoticeModal";

const getSveaActivityDate = async () => {
	const cookieValue = readCookie(sveaLastActivityCookieName);
	return !!cookieValue ? new Date(Date.parse(decodeURIComponent(cookieValue))) : undefined;
};

interface SveaAuthenticationCheckerProps {
	companyId: string;
}

export const SveaAuthenticationChecker: React.FC<React.PropsWithChildren<SveaAuthenticationCheckerProps>> = props => {
	const { redirect } = useRouter();
	const redirectRoute = getRoute("dashboard", { company: props.companyId });
	const [showTimerUntilLogoutNeeded, setShowTimerUntilLogoutNeeded] = React.useState<number | undefined>(undefined);

	const [logoutFromSvea] = useLazyApi(proxy.Bank.SveaAuthController.Logout.Post, {
		onSuccess: () => {
			redirect(redirectRoute); // TODO redirect to a Svea logout specific page with no Svea api calls
		},
	});

	const logout = React.useCallback(() => {
		deleteCookie(sveaLastActivityCookieName);
		logoutFromSvea();
	}, [logoutFromSvea]);

	const updateSecondsLeft = React.useCallback((secondsLeft: number) => {
		setShowTimerUntilLogoutNeeded(secondsLeft);
	}, []);

	const { startProtecting } = useSveaAuthentication(getSveaActivityDate, logout, updateSecondsLeft);

	React.useEffect(() => {
		startProtecting();
	}, [startProtecting]);

	const timeUntilLogoutNeeded = showTimerUntilLogoutNeeded ?? 0;
	const isModalVisible = (showTimerUntilLogoutNeeded ?? -1) > 0;

	return (
		<>
			{isModalVisible && (
				<SveaLogoutNoticeModal
					visible={isModalVisible}
					onClose={noop}
					companyId={props.companyId}
					onContinue={() => setShowTimerUntilLogoutNeeded(undefined)}
					onError={logout}
					onLogout={logout}
					timeUntilLogoutNeeded={timeUntilLogoutNeeded}
				/>
			)}
			{props.children}
		</>
	);
};
