import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./legacyMarginProp.scss";

type MarginHorizontal = "left" | "right";
type MarginVertical = "top" | "bottom";
type Margin = MarginHorizontal | MarginVertical;

export type LegacyMarginProp = (Margin | Falsy)[] | Falsy | true;

export const getLegacyMarginClassNames = (prop: LegacyMarginProp, px = 16): string => {
	if (!prop) {
		return "";
	}

	return prop === true
		? mergeClassNames(`top${px}`, `right${px}`, `bottom${px}`, `left${px}`)
		: mergeClassNames(prop.filter(x => x).map(x => styles[`${x}${px}`]));
};
