import * as React from "react";

import { Link } from "@bokio/elements/Link/Link";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";
import { formatMessage } from "@bokio/shared/utils/format";

import HelpSearchResultItem from "./HelpSearchResultItem";

import type { HelpSearchResultModel } from "@bokio/shared/models/HelpSearchResultModel";
import type { HelpSearchSuggestion } from "@bokio/shared/models/HelpSearchSuggestion";

import * as styles from "./helpStyle.scss";

export interface HelpSearchResultProps {
	searchResult: HelpSearchResultModel;
	itemClicked: (item: HelpSearchSuggestion) => void;
}

class HelpSearchResult extends React.Component<HelpSearchResultProps> {
	constructor(props: HelpSearchResultProps) {
		super(props);
	}

	render() {
		const lang = GeneralLangFactory();
		if (this.props.searchResult.suggestions.length === 0) {
			return (
				<React.Fragment>
					<p className={styles.noResult}>{lang.HelpEmptyResult1}</p>
					<p className={styles.noResult}>
						{formatMessage(lang.HelpEmptyResult2, name => (
							<Link target="_blank" external={"http://www.facebook.com/groups/240735629672293/"}>
								{name}
							</Link>
						))}
					</p>
				</React.Fragment>
			);
		}
		const items = this.props.searchResult.suggestions.map(i => (
			<HelpSearchResultItem key={i.Id} id={i.Id} searchItem={i} onClick={this.props.itemClicked} />
		));
		return <ul className={styles.helpResult}>{items}</ul>;
	}
}

export default HelpSearchResult;
