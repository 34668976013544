import Icon from "@bokio/elements/Icon/Icon";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory } from "@bokio/lang";
import { getRoute } from "@bokio/shared/route";
import { formatMessage } from "@bokio/shared/utils/format";

import type * as React from "react";

import * as styles from "./failedPaymentTopBar.scss";

interface FailedPaymentTopBarProps {
	company: string;
}

export const FailedPaymentTopBar: React.FC<FailedPaymentTopBarProps> = props => {
	const lang = GeneralLangFactory();
	return (
		<div className={styles.wrapper}>
			<Icon name="block" />
			<Markdown
				markdownContent={formatMessage(
					lang.FailedPayment_Bar_Text,
					getRoute("billing", { company: props.company }, { tab: "receipts" }),
				)}
			/>
		</div>
	);
};
