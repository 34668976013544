import type { MarginAway, TextStyles } from "./TypographicElements.types";

import * as styles from "./typographicElements.scss";

export const marginAwayToClassNameMapping: Record<MarginAway, string> = {
	"0": styles.sectionWrapperMarginAway_0,
	"4": styles.sectionWrapperMarginAway_4,
	"6": styles.sectionWrapperMarginAway_6,
	"8": styles.sectionWrapperMarginAway_8,
	"12": styles.sectionWrapperMarginAway_12,
	"16": styles.sectionWrapperMarginAway_16,
	"24": styles.sectionWrapperMarginAway_24,
	"32": styles.sectionWrapperMarginAway_32,
	"40": styles.sectionWrapperMarginAway_40,
	"48": styles.sectionWrapperMarginAway_48,
	"24t16m": styles.sectionWrapperMarginAway_24t16m,
};
export const marginAway = Object.keys(marginAwayToClassNameMapping);

export const textStylesToClassNameMapping: Record<TextStyles, string> = {
	"caption-default": styles.fontCaptionDefault,
	"caption-bold": styles.fontCaptionBold,
	"body-bold": styles.fontBodyBold,
	"body-default": styles.fontBodyDefault,
	"body-large": styles.fontBodyLargeDefault,
	"subheading-default": styles.fontSubheadingDefault,
	"heading-default": styles.fontHeadingDefault,
	"large-heading": styles.fontLargeHeadingDefault,
	"large-heading-bold": styles.fontLargeHeadingBold,
	"company-list-heading": styles.fontCompanyListHeading,
};
export const textStyles = Object.keys(textStylesToClassNameMapping);
