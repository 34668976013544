import * as React from "react";

import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import DeviceQuery from "@bokio/elements/DeviceQuery/DeviceQuery";
import { FormGroup, FormWithValidation, InputField } from "@bokio/elements/Form";
import { asValidatorFunction } from "@bokio/elements/Form/FormWithValidation/FormWithValidation";
import { RenderRequestError } from "@bokio/elements/Loading";
import { GeneralLangFactory, SignUpLangFactory } from "@bokio/lang";
import { fromEnvelope } from "@bokio/mobile-web-shared/core/utils/loaderHelpers";
import { toEnvelopeEndpoint, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { filterByField } from "@bokio/shared/validation/entityValidator";

import OtpFormValidator from "./validators/OTPFormValidator";

import type { LoginUserResult } from "@bokio/guest/src/scenes/SignUp/signUpUtils";
import type { ValidatorResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./loginForm.scss";

interface LoginTOTPStepProps {
	loginSuccess: (loginuserStatus: LoginUserResult) => void;
	abort2FA: "disable" | (() => void);
}

const endpoint = async (formData: { code: string }) => {
	return proxy.Bokio.AccountController.Login2FA.Post(formData.code).then(fromEnvelope);
};

const LoginTOTPStep = (props: LoginTOTPStepProps) => {
	const lang = GeneralLangFactory();
	const signupLang = SignUpLangFactory();

	const { loginSuccess, abort2FA } = props;

	const validator = asValidatorFunction(new OtpFormValidator(lang));

	const [verifyCall, verifyCallRequest] = useLazyApi(toEnvelopeEndpoint(endpoint), { onSuccess: loginSuccess });

	const initialState = { code: "" };
	return (
		<FormWithValidation<typeof initialState, ValidatorResult>
			initialState={initialState}
			onSubmit={verifyCall}
			validator={validator}
		>
			{({ formData, setValue, validation }) => (
				<React.Fragment>
					<p className={styles.intro}>{signupLang.Login_TOTP_Text}</p>
					<DeviceQuery>
						{({ isMobile }) => (
							<React.Fragment>
								<FormGroup>
									<InputField
										autoComplete="one-time-code"
										inputMode="numeric"
										label={lang.VerificationCode_TOTP}
										value={formData.code}
										onChange={setValue("code")}
										autoFocus={!isMobile} //Maybe disable on mobile
										errors={filterByField("code", validation.errors)}
									/>
								</FormGroup>

								<FormGroup>
									<ButtonGroup align="center">
										{abort2FA !== "disable" && (
											<Button appearance="secondary" type="button" onClick={abort2FA}>
												{lang.Cancel}
											</Button>
										)}
										<Button type="submit" loading={verifyCallRequest.isLoading}>
											{lang.TOTP_VerifyCode_Action}
										</Button>
									</ButtonGroup>
								</FormGroup>
								<RenderRequestError request={verifyCallRequest} />
							</React.Fragment>
						)}
					</DeviceQuery>
				</React.Fragment>
			)}
		</FormWithValidation>
	);
};

export default LoginTOTPStep;
