import { mergeClassNames } from "@bokio/utils/classes";

import Icon from "../Icon/Icon";
import { Tooltip } from "./Tooltip";

import type { IconColor, IconSize } from "../Icon/Icon";
import type * as React from "react";

export type TootlTipDirection = "Bottom" | "Top" | "Left" | "Right";

export interface InfoTooltipProps {
	contentGenerator: () => React.ReactNode;
	wrapperClassName?: string;
	tooltipClassName?: string;
	color?: "black" | "white";
	size?: IconSize;
	openDirection?: TootlTipDirection;
	heading?: string;
	className?: string;
	arrowOffsetClassName?: string;
	iconColor?: IconColor;
	type?: "filled" | "outline";
	testId?: string;
}

export const InfoTooltip = (props: InfoTooltipProps) => (
	<Tooltip {...props}>
		<Icon
			name={props.type === "outline" ? "ic_info" : "info"}
			className={mergeClassNames(!props.iconColor && props.className)}
			color={props.iconColor || "blue"}
			size={props.size || "16"}
			testId={props.testId}
		/>
	</Tooltip>
);
