import * as React from "react";

import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useAgencyStatus } from "@bokio/shared/state/requests";
import { mergeClassNames } from "@bokio/utils/classes";

import { CompleteStep } from "./CompleteStep";
import { FeedbackStep } from "./FeedbackStep";
import { RatingStep } from "./RatingStep";
import { Steps } from "./utils";

import type { Grade } from "./utils";

import * as styles from "./ratingToast.scss";

import PartnerRatingType = m.Entities.PartnerRatingType;
import RatingType = m.Entities.Feedback.RatingType;
type RatingDto = m.Bokio.Common.Contract.Metrics.Feedback.Dtos.RatingDto;
type AgencyRatingDto = m.Bokio.Common.Contract.Metrics.Feedback.Dtos.AgencyRatingDto;

interface RatingToastProps {
	/** Function that closes the toast. */
	onClose: () => void;
	/** Function that runs when user clicks to close the toast without submitting the feedback.
	 * The call is succeeded by `OnClose`. */
	onCancel?: () => void;
	/** This represents what is being rated. This property is defined in the entity `Rating.cs`. */
	type: RatingType;
	/** Function that fetches the current step's title. */
	renderStepTitle: (step: Steps) => string;
	/** Function that fetches the current step's description. */
	renderStepDescription: (step: Steps) => string;
	/** Additional data preferrably as a JSON string. E.g. `{ verificationId: "44c8ec86-2fbc-4d1f-85e4-35f1e40abed6" }` */
	additionalData: string;
	className?: string;
	testId?: string;
	partnerRatingType?: PartnerRatingType;
}

export const RatingToast: React.FC<RatingToastProps> = ({
	onClose,
	onCancel,
	renderStepTitle,
	renderStepDescription,
	type,
	testId,
	additionalData,
	className,
	partnerRatingType,
}) => {
	const [grade, setGrade] = React.useState<Grade>();
	const [feedback, setFeedback] = React.useState<string>("");
	const [step, setStep] = React.useState<Steps>(Steps.Rating);

	const { companyInfo } = useCompanyInfo();
	const { agencyStatus } = useAgencyStatus();

	const [rate, rateRequest] = useLazyApi(proxy.Feedback.RatingController.Rate.Post);
	const [agencyRate, agencyRateRequest] = useLazyApi(proxy.BackOffice.AgencyRatingController.Rate.Post);

	const handleSubmit = async () => {
		if (!grade) {
			return;
		}

		switch (step) {
			case Steps.Rating:
				setStep(Steps.Feedback);
				break;
			case Steps.Feedback:
				if (!partnerRatingType && !agencyStatus) {
					// company
					const request: RatingDto = {
						FeedbackText: feedback,
						Score: grade,
						Type: type,
						Cancelled: false,
						AdditionalData: additionalData,
					};
					await rate(companyInfo.Id, request);
				} else if (partnerRatingType && agencyStatus) {
					//agency
					const request: AgencyRatingDto = {
						FeedbackText: feedback,
						Score: grade,
						Type: partnerRatingType,
						Cancelled: false,
						AdditionalData: additionalData,
					};
					await agencyRate(agencyStatus.Id, request);
				}
				setStep(Steps.Complete);
				break;
		}
	};

	/** Function that runs when user closes the toast without rating.
	 *  Do not use for the Complete step, since it isn't a cancellation. */
	const cancelAndClose = async () => {
		if (companyInfo) {
			const request: RatingDto = {
				FeedbackText: "",
				Score: -1,
				Type: type,
				Cancelled: true,
				AdditionalData: additionalData,
			};
			await rate(companyInfo.Id, request);
		} else if (agencyStatus && partnerRatingType) {
			const request: AgencyRatingDto = {
				FeedbackText: "",
				Score: -1,
				Type: partnerRatingType,
				Cancelled: true,
				AdditionalData: additionalData,
			};
			await agencyRate(agencyStatus.Id, request);
		}
		onCancel && onCancel();
		onClose();
	};

	const renderCurrentStep = () => {
		const canProceed = grade !== undefined && !rateRequest.isLoading && !agencyRateRequest.isLoading;
		switch (step) {
			case Steps.Rating:
				return (
					<RatingStep
						title={renderStepTitle(step)}
						description={renderStepDescription(step)}
						canProceed={canProceed}
						setGrade={setGrade}
						handleClose={cancelAndClose}
						handleSubmit={handleSubmit}
						selectedGrade={grade}
					/>
				);
			case Steps.Feedback:
				return (
					<FeedbackStep
						title={renderStepTitle(step)}
						description={renderStepDescription(step)}
						canProceed={canProceed}
						onChange={setFeedback}
						handleClose={cancelAndClose}
						handleSubmit={handleSubmit}
					/>
				);
			case Steps.Complete:
				return (
					<CompleteStep title={renderStepTitle(step)} description={renderStepDescription(step)} onClose={onClose} />
				);
		}
	};

	return (
		<main data-testid={`RatingToast_${testId}`} className={mergeClassNames(styles.toast, className)}>
			{renderCurrentStep()}
		</main>
	);
};
