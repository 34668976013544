import map from "lodash-es/map";
import * as qs from "qs";
import { v4 as uuidv4 } from "uuid";

import { readFromLocalStorage, writeToLocalStorage } from "@bokio/hooks/useLocalStorage/useLocalStorage";

export async function getFingerprintHeaders() {
	const headers: { [h: string]: string } = {};
	const trySetHeader = (name: string, valueFunc: () => string | undefined) => {
		try {
			const value = valueFunc();
			if (value !== undefined) {
				headers[name] = value;
			}
		} catch (error) {}
	};

	trySetHeader("X-JS-User-Agent", () => window.navigator.userAgent);
	trySetHeader("X-JS-Do-Not-Track", getDoNotTrackFlag);
	trySetHeader("X-Client-UtcOffsetMinutes", getTimezoneOffsetInMinutes);
	trySetHeader("X-Client-Window-Size", getWindowSize);
	trySetHeader("X-Client-Screen", getScreen);
	trySetHeader("X-Client-Device-Id", getClientId);
	trySetHeader("X-Client-Timestamp", () => new Date().toISOString());
	trySetHeader("X-Client-Browser-Plugins", getClientBrowserPlugins);

	try {
		const localIP = await getClientLocalIP();
		trySetHeader("X-Client-Local-IPs", () => localIP);
	} catch (error) {}

	return headers;
}

export function getDoNotTrackFlag() {
	return window.navigator.doNotTrack === "1" || window.navigator.doNotTrack === "yes" ? "true" : undefined;
}

export function getTimezoneOffsetInMinutes() {
	// Note the negation: `getTimezoneOffset` returns `-60` for `UTC+01:00` (such as CET in winter)
	const offset = -new Date().getTimezoneOffset();
	return "" + offset;
}

export function getWindowSize() {
	const width = window.innerWidth;
	const height = window.innerHeight;
	if (typeof width !== "number" || typeof height !== "number") {
		return;
	}
	return qs.stringify({ width, height });
}

export function getScreen() {
	const width = window.screen.width;
	const height = window.screen.height;
	const scalingFactor = window.devicePixelRatio;
	const colorDepth = window.screen.colorDepth;
	if (typeof width !== "number" || typeof height !== "number") {
		return;
	}
	return qs.stringify({ width, height, scalingFactor, colorDepth });
}

const clientIdKey = "client_identifier";
export function getClientId() {
	const storedId = readFromLocalStorage<string>(clientIdKey);
	if (storedId === undefined) {
		const newId = uuidv4();
		writeToLocalStorage(clientIdKey, newId);
		return newId;
	}
	return storedId;
}

export function getClientBrowserPlugins() {
	if (window.navigator && window.navigator.plugins) {
		const browserPlugins = map(window.navigator.plugins, p => p.name && encodeURIComponent(p.name))
			.filter(x => x)
			.join(",");
		return browserPlugins;
	}
	return "";
}

export function getClientLocalIP() {
	const promiseForLocalIP = new Promise<string | undefined>((resolve, reject) => {
		try {
			const ips: string[] = [];
			const pc = new RTCPeerConnection({ iceServers: [] });
			const rejectionTimeout = setTimeout(() => {
				if (ips.length > 0) {
					const result = ips.join(",");
					resolve(result);
				} else {
					reject();
				}
				pc.close();
			}, 150);
			pc.createDataChannel("");
			// eslint-disable-next-line promise/catch-or-return
			pc.createOffer().then(offer => pc.setLocalDescription(offer));

			pc.onicecandidate = function (ice) {
				if (ice && ice.candidate && ice.candidate.candidate) {
					// Connection address is fifth space-separated element, see https://tools.ietf.org/html/rfc5245#section-15.1
					const localIp = ice.candidate.candidate.split(" ")[4];
					ips.push(encodeURIComponent(localIp));
					if (ips.length === 1) {
						clearTimeout(rejectionTimeout);
						setTimeout(() => {
							const result = ips.join(",");
							resolve(result);
							pc.close();
						}, 30);
					}
				}
			};
		} catch (e) {
			reject();
		}
	});
	return promiseForLocalIP;
}
