import { MenuCounter } from "@bokio/components/Menu/components/MenuCounter/MenuCounter";
import { useTodoStatus } from "@bokio/shared/state/requests";

import type { ToDoCounterType } from "@bokio/components/Menu/components/MenuCounter/MenuCounter";
import type * as React from "react";

interface TodoMenuCounterProps {
	companyId: string;
	type: ToDoCounterType;
	inactive?: boolean;
}

const TodoMenuCounter: React.FC<TodoMenuCounterProps> = ({ type, inactive }) => {
	const { todoCount } = useTodoStatus();
	if (!todoCount || todoCount.ToDoTotalCount === 0) {
		return null;
	}

	return (
		<MenuCounter
			color={inactive ? "subdued" : "highlight"}
			type={type}
			animateOnCountValueChange
			count={todoCount}
			testId="Menu_Todo_Counter"
		/>
	);
};

export default TodoMenuCounter;
