import * as React from "react";

import { Modal, ModalContent } from "@bokio/components/Modal";
import RenderRequest from "@bokio/components/RenderRequest/RenderRequest";
import {
	clearPaymentSigningProgressInfoFromLocalStorage,
	readPaymentSigningProgressInfoFromLocalStorage,
	savePaymentSigningProgressInfoToLocalStorage,
} from "@bokio/components/SveaBankIdAuthModal/sveaPollingUtils";
import { usePaymentContext } from "@bokio/contexts/PaymentContext/PaymentContext";
import { AnimatedFeedbackGraphicModal } from "@bokio/elements/Feedback/AnimatedFeedbackGraphicModal";
import { LoadingProgress } from "@bokio/elements/Loading";
import { AnalyticsEventCategory, useMetric } from "@bokio/hooks/useMetric/useMetric";
import { useSignAndPay } from "@bokio/hooks/useSignAndPay/useSignAndPay";
import { GeneralLangFactory } from "@bokio/lang";
import BankLangFactory from "@bokio/lang/BankLangFactory";
import { useCompanyUser } from "@bokio/shared/state/requests";

import { SignAndPayFooter } from "./SignAndPayFooter/SignAndPayFooter";
import { SignAndPayHeader } from "./SignAndPayHeader/SignAndPayHeader";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

import * as styles from "./signAndPayModal.scss";

type PaymentGroupForAuthorization = m.Bokio.Bank.Contract.Dtos.PaymentGroupForAuthorization;
type PaymentValidationRulesDto = m.Bokio.Bank.Contract.Dtos.PaymentValidationRulesDto;
interface SignAndPayModalInnerProps extends SignAndPayModalProps {
	paymentValidationRules: PaymentValidationRulesDto;
	showTransactionsAndPaymentsLinks?: boolean;
}

export const SignAndPayModalInner = ({
	initiallySelectedPaymentGroupIds,
	onSubmit,
	refreshPaymentCenter,
	visible,
	onClose,
	showTransactionsAndPaymentsLinks,
	paymentValidationRules,
	createPaymentAndexecuteAuthorizeEndpoint,
}: SignAndPayModalInnerProps) => {
	const generalLang = GeneralLangFactory();
	const bankLang = BankLangFactory();
	const permissions = useCompanyUser().companyUserPermissions;
	const { currentUserPermission } = usePaymentContext();

	const [addMetric] = useMetric();

	const signAndPayState = useSignAndPay({
		paymentValidationRules,
		initiallySelectedPaymentGroupIds,
		onSubmit: () => {
			addMetric(AnalyticsEventCategory.Bba, "SignedPayment", "FromSignAndPayModal");
			refreshPaymentCenter?.();
			onSubmit();
		},
		onAuthorizationFailed: refreshPaymentCenter,
		createPaymentAndexecuteAuthorizeEndpoint: createPaymentAndexecuteAuthorizeEndpoint,
	});

	React.useEffect(() => {
		if (visible) {
			signAndPayState.refreshPaymentListAndSummary();
		}
		const hasInprogressPaymentSigning = readPaymentSigningProgressInfoFromLocalStorage();

		// Directly start the authorize (BBA Migration)
		if (visible && createPaymentAndexecuteAuthorizeEndpoint) {
			signAndPayState.executeAuthorize();
		} else if (visible && hasInprogressPaymentSigning && hasInprogressPaymentSigning.paymentGroupIds) {
			signAndPayState.setSelectedPaymentGroupIds(hasInprogressPaymentSigning.paymentGroupIds);
			if (hasInprogressPaymentSigning.isPolling) {
				signAndPayState.executeAuthorize();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible, createPaymentAndexecuteAuthorizeEndpoint]);

	if (!currentUserPermission.CanSignPayment || permissions?.UploadBank === false) {
		return (
			<AnimatedFeedbackGraphicModal
				onClose={onClose}
				visible={visible}
				title={bankLang.SignAndPayModalTitle}
				type="fail"
				heading={generalLang.Forbidden}
				description={bankLang.SignAndPayModal_NoRights}
			/>
		);
	}

	const renderModalTitle = () => {
		return (
			<>
				<div className={styles.heading}>{generalLang.BBA_PaymentsToSign}</div>
				<div
					className={styles.subHeading}
				>{`${generalLang.BBA_AvailableBalance}: ${signAndPayState.availableBalance}`}</div>
			</>
		);
	};

	const handleClose = () => {
		signAndPayState.stopPollingAndCancelSigning();
		clearPaymentSigningProgressInfoFromLocalStorage();
		onClose();
	};

	const handleOnSignAndPlayClick = () => {
		savePaymentSigningProgressInfoToLocalStorage({
			hasInitiated: true,
			isPolling: false,
			SuccessfulPaymentIds: undefined,
			paymentGroupIds: signAndPayState.selectedPaymentGroupIds,
			signingToken: undefined,
		});
		signAndPayState.executeAuthorize();
	};
	return (
		<Modal
			// Defer auto focus to make ModalBase properly find all the focusable elements
			autoFocus={!signAndPayState.paymentListRequest.isLoading}
			onClose={handleClose}
			visible={visible}
			title={renderModalTitle()}
			testId="Payments_SignAndPayModal"
		>
			{showTransactionsAndPaymentsLinks && <SignAndPayHeader onClose={handleClose} />}
			<ModalContent className={styles.content}>{signAndPayState.renderSignAndPayContent(handleClose)}</ModalContent>
			<LoadingProgress request={signAndPayState.authorizeRequest} hideSoftErrors />
			<SignAndPayFooter
				disabled={signAndPayState.disableSign}
				isLoading={signAndPayState.isAnyRequestLoading}
				onSignAndPayClick={handleOnSignAndPlayClick}
				multipleCurrencies={signAndPayState.multipleCurrencies}
				totalSum={signAndPayState.totalSum}
				selectedPayments={signAndPayState.selectedPaymentGroupIds.length}
				style="modal"
			/>
		</Modal>
	);
};

export interface SignAndPayModalProps {
	visible: boolean;
	initiallySelectedPaymentGroupIds?: string[];
	onClose: () => void;
	onSubmit: () => void;
	refreshPaymentCenter?: () => void;
	createPaymentAndexecuteAuthorizeEndpoint?: (
		org: string,
		paymentGroups: PaymentGroupForAuthorization[],
	) => Promise<
		m.Envelope<m.Bokio.Bank.Contract.Dtos.SendAndAuthorizePaymentsResultDto, m.Bokio.Bank.Contract.Dtos.BankError>
	>;
}

export const SignAndPayModal: React.FC<SignAndPayModalProps> = props => {
	const { paymentValidationRulesRequest } = usePaymentContext();

	return (
		<RenderRequest request={paymentValidationRulesRequest} whenLoading={null}>
			{paymentValidationRules => <SignAndPayModalInner {...props} paymentValidationRules={paymentValidationRules} />}
		</RenderRequest>
	);
};
