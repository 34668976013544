import { useEffect, useState } from "react";

import * as requests from "@bokio/mobile-web-shared/services/api/requestState";
import { trackError } from "@bokio/utils/t";

type Listener = (newState: requests.RequestState<boolean>) => void;

class ScriptRequestStateContainer {
	#state: requests.RequestState<boolean>;
	#listeners = new Set<Listener>();

	constructor(initialState = requests.load<boolean>(undefined)) {
		this.#state = initialState;
	}

	get initialStateForComponent() {
		return this.#state;
	}

	subscribe(listener: Listener) {
		this.#listeners.add(listener);
	}

	unsubscribe(listener: Listener) {
		this.#listeners.delete(listener);
	}

	setState(newState: requests.RequestState<boolean>) {
		this.#state = newState;
		this.#listeners.forEach(listener => listener(newState));
	}
}

const scriptRequestStateMapping: Record<string, ScriptRequestStateContainer> = {};

/**
 * Run a <script src="src"> in the current document.
 * The script tag is always loaded at most once in the window no matter how many components are calling this & how many times this hook is called,
 * because most of the 3rd party script tags in the wild are not designed to be able to run twice.
 *
 * @param src <script>'s src, this is optional to ease async loading of the src string
 * @returns Request state like what you get from useApi
 * @example
 *
 * const request = useScript("https://example.com/script.js");
 *
 * React.useEffect(() => {
 *   if (request.data)
 *   {
 * 	   // run some effect after script loaded
 *   }
 * }, [request.data]);
 *
 * if (!request.data) {
 *    return <LoadingContent />
 * }
 * // Then do whatever that's only available after the script is loaded
 * window.Plaid...
 */
export const useScript = (src?: string | false, callback?: () => void) => {
	const scriptRequestState = scriptRequestStateMapping[src || ""] ?? new ScriptRequestStateContainer();

	if (src) {
		scriptRequestStateMapping[src] = scriptRequestState;
	}

	// The real state is maintained outside of component lifecycle,
	// this `useState` is mainly for:
	// - loading the real state back on component mount
	// - populating the changes to child component
	const [state, setState] = useState(scriptRequestState.initialStateForComponent);
	useEffect(() => {
		// Handle remounting with different src
		setState(scriptRequestState.initialStateForComponent);
		scriptRequestState.subscribe(setState);

		return () => {
			scriptRequestState.unsubscribe(setState);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [src]);

	useEffect(() => {
		const scriptAlreadyExists = Array.from(document.querySelectorAll("head > script[src]")).some(
			s => s.attributes.getNamedItem("src")?.value === src,
		);

		if (src && !scriptAlreadyExists) {
			const script = document.createElement("script");
			script.src = src;
			script.async = true;
			script.onload = () => {
				scriptRequestState.setState(requests.success(true));
			};
			script.onerror = err => {
				scriptRequestState.setState(requests.error(typeof err === "string" ? err : ""));

				// Derived from the query result of the `trackError` call below.
				// For example query, see: https://bokio.slack.com/archives/CFN9GKYV8/p1694438586239179?thread_ts=1694432902.078399&cid=CFN9GKYV8
				// This is a list of scripts that are potentially blocked by commonly used ad blockers.
				const potentiallyBlockedSrcs = [
					"https://static2.creative-serving.com/pixel_loader.js",
					"https://www.googletagmanager.com/gtag/js?id=G-WYJW7HY8TN",
					"scripts/gtag.js",
					"scripts/LinkedinAds.js",
					"scripts/metaPixel.js",
					"scripts/microsoftAds.js",
					"scripts/TaboolaPixel.js",
					"https://www.youtube.com/player_api",
				];

				// We don't log script load error for the scripts that are potentially blocked,
				// as we can't really take actions for those scripts,
				// and we don't really depend on these scripts to get our web app to work.
				if (potentiallyBlockedSrcs.includes(src)) {
					return;
				}

				// Otherwise log the script load error,
				// so we can decide later on whether to add it to the potentiallyBlockedSrcs above or take some other actions around it.
				trackError(err, "app.useScript", {
					src,
				});
			};

			// Careful here to insert the script correctly
			// otherwise Internet Explorer will cause an app crash.
			// https://humanwhocodes.com/blog/2008/03/17/the-dreaded-operation-aborted-error/
			document.head.appendChild(script);

			// JH 2024-02-15: We add a callback option incase some tracking depends on other tracking having to be loaded first
			if (callback && typeof callback === "function") {
				callback();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [src]);

	return state;
};
