import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./table.scss";

interface TableExpanderButtonProps {
	activeTrigger: boolean;
	onClick: () => void;
	orientation?: "top";
}

class TableExpanderButton extends React.Component<TableExpanderButtonProps> {
	render() {
		const { activeTrigger, onClick, orientation } = this.props;
		const topStyle = orientation === "top";
		const iconStateClass = activeTrigger ? (topStyle ? styles.topTrigger : styles.bottomTrigger) : "";
		return (
			<button onClick={onClick} className={styles.expanderButton}>
				<Icon name="down-open-big" size="18" className={mergeClassNames(styles.iconTrigger, iconStateClass)} />
			</button>
		);
	}
}

export default TableExpanderButton;
