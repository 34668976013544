import * as React from "react";

import { GeneralLangFactory } from "@bokio/lang";

import * as styles from "./detailedMessage.scss";

interface DetailedMessageProps {
	title: React.ReactNode;
	content: React.ReactNode;
	referenceId?: React.ReactNode | undefined;
}

const DetailedMessage = (message: DetailedMessageProps) => {
	return (
		<React.Fragment>
			<div className={styles.title}>{message.title}</div>
			<div className={styles.content}>{message.content}</div>
			{message.referenceId && (
				<div className={styles.referenceIdContainer}>
					<div className={styles.referenceId}>
						{GeneralLangFactory().Error_Notification_Reference}
						{message.referenceId}
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default DetailedMessage;
