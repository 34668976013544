import classes, { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./loading.scss";

interface LoadingTextProps {
	width?: string;
	height?: string;
	dark?: boolean;
	inline?: boolean;
	className?: string;
}

const LoadingText = (props: LoadingTextProps) => {
	const customStyle = {
		height: props.height || "20px",
		width: props.width || Math.round(Math.random() * 50 + 40) + "%",
		opacity: props.dark ? 0.5 : 0.2,
	};

	const classNames = classes(styles, "placeholder", {
		placeholderInline: props.inline,
	});

	return <div className={mergeClassNames(classNames, props.className)} style={customStyle} />;
};

export default LoadingText;
