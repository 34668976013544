import Icon from "../Icon/Icon";

import type * as React from "react";

import * as styles from "./animatedFeedback.scss";

interface AnimatedFeedbackGraphicProps {
	testId?: string;
}

const LINE_COUNT = 8;

export const AnimatedFeedbackGraphic: React.FC<AnimatedFeedbackGraphicProps> = ({ testId }) => {
	return (
		<div className={styles.animatedWrapper} data-testid={testId}>
			<div className={styles.animatedLines}>
				{Array.from({ length: LINE_COUNT }, (_, i) => (
					<div key={i} className={styles.animatedLineWrapper}>
						<div className={styles.animatedLine} />
					</div>
				))}
			</div>
			<div className={styles.animatedSuccessFeedbackCircle}>
				<Icon name="check" size="40" color="white" />
			</div>
		</div>
	);
};

export const AnimatedFailFeedbackGraphic: React.FC<AnimatedFeedbackGraphicProps> = ({ testId }) => {
	return (
		<div className={styles.animatedWrapper} data-testid={testId}>
			<div className={styles.animatedFailFeedbackCircle}>
				<strong className={styles.animatedFeedbackIcon}>!</strong>
			</div>
		</div>
	);
};
