// extracted by mini-css-extract-plugin
export var feedback = "as";
export var paragraph = "bs";
export var image = "cs";
export var imageComplete = "ds cs";
export var imageNoExpenses = "es cs";
export var UploadsReceiptsOrInvoices = "Qx cs";
export var UploadsAdditionalFiles = "QA cs";
export var ClosureJournals = "RA cs";
export var SupplierInvoices = "SA cs";
export var ExpenseClaimsPayments = "lH cs";
export var imageNoBookkeeping = "fs cs";
export var ExpensesMyExpensesClaims = "nH cs";
export var PurchasesSuppliers = "oH cs";
export var ExpenseClaimsPermissions = "pH cs";
export var ExpensesAllExpensesClailms = "KI cs";
export var StaffSalaryEmployees = "DL cs";
export var StaffSalaryVacation = "JR cs";
export var SettingsBankRules = "KR cs";
export var SettingsCustomBokkeping = "QS cs";
export var settingsImportPreviousBookkeeping = "XT cs";
export var imageExternalOnboarding = "gs cs";
export var imageFactoringOnboarding = "hs cs";
export var imageNoNotifications = "Al cs";
export var imagePayrollTaxes = "r cs";
export var imageVacation = "Jda cs";
export var imageEmptyState = "t5 cs";
export var imageEmptyStateAlt1 = "Gs cs";
export var imageEmptyStateAlt2 = "Hs cs";
export var imageEmptyStateAlt3 = "Is cs";
export var imageAgencyClientslist = "LU cs";
export var imageAgencyClientsRequests = "HX cs";
export var imageAgencyClientComments = "r8 cs";
export var imageAgencyDashboardComments = "Ge cs";
export var imageAgencyClientTasks = "s8 cs";
export var imageAgencyDashboardTasks = "He cs";
export var imageBankInboxMessage = "JX cs";
export var imageEmptyStateAccountantPanel = "E- cs";
export var imageEmptyStateCustomTodos = "t8 cs";
export var imageEmptyStateTodos = "u8 cs";
export var imageEtrEmptyStateConnectBank = "tOYZc6O-6WRuR2cLtTkdk cs";
export var imageEtrEmptyStateNoEvents = "_2evzprViEUFOjZqvTVEQWx cs";