import { Button } from "@bokio/designsystem/components/Button/Button";
import { useHiddenFileInput } from "@bokio/hooks/useHiddenFileInput/useHiddenFileInput";

import type { ButtonProps } from "@bokio/designsystem/components/Button/Button";
import type { UseHiddenFileInputProps } from "@bokio/hooks/useHiddenFileInput/useHiddenFileInput";
import type * as React from "react";

interface UploadFileButtonProps extends UseHiddenFileInputProps {
	buttonProps?: Omit<ButtonProps, "onClick" | "children">;
	children: ButtonProps["children"];
}

export const UploadFileButton: React.FC<UploadFileButtonProps> = ({
	onSelectedFilesChange,
	allowMultiple,
	acceptFileType = "image/jpeg,image/png,application/pdf",
	buttonProps,
	children,
}) => {
	const { renderHiddenFileInput, triggerFileDialog } = useHiddenFileInput({
		allowMultiple,
		acceptFileType,
		onSelectedFilesChange,
	});

	return (
		<div>
			{renderHiddenFileInput()}
			<Button {...buttonProps} onClick={triggerFileDialog}>
				{children}
			</Button>
		</div>
	);
};
