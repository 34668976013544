import * as PropTypes from "prop-types";
import * as React from "react";

import { noop } from "@bokio/shared/utils";

import type { ValidationState } from "./Validation";

export interface FormContextValue {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFieldChange: (name: string, value: any, validation: any) => void;
	getFieldValue: (name: string) => void;
	disableAllFields: boolean;
}

export const FormContext = React.createContext<FormContextValue>({
	onFieldChange: noop,
	getFieldValue: noop,
	disableAllFields: false,
});

export interface FormValidationState {
	hasError: boolean;
	hasWarning: boolean;
}

interface FormProps {
	onSubmit?: () => void;
	onValidation?: (v: FormValidationState) => void;
	allowInvalidSubmit?: boolean;
	disableAll?: boolean;
	className?: string;
}

interface FormState {
	(key: string): ValidationState;
}

export class Form extends React.Component<React.PropsWithChildren<FormProps>, FormState> {
	static childContextTypes = {
		onFieldChange: PropTypes.func,
		getFieldValue: PropTypes.func,
		disableAllFields: PropTypes.bool,
	};

	constructor(props: FormProps) {
		super(props);

		this.state = {};
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.getValue = this.getValue.bind(this);
		this.checkValidation = this.checkValidation.bind(this);
	}

	getChildContext() {
		return {
			onFieldChange: this.onChange,
			getFieldValue: this.getValue,
			disableAllFields: this.props.disableAll,
		};
	}

	getValue(name: string) {
		if (this.state[name]) {
			return this.state[name].value;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange(name: string, value: any, validation: any) {
		this.setState(
			{
				[name]: {
					value,
					validation,
				},
			},
			this.checkValidation,
		);
	}

	checkValidation() {
		if (this.props.onValidation) {
			this.props.onValidation({
				hasWarning: this.hasWarning(),
				hasError: this.hasError(),
			});
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSubmit(e: any) {
		e.preventDefault();

		if (!this.props.allowInvalidSubmit && this.hasError()) {
			return;
		}

		this.props.onSubmit && this.props.onSubmit();
	}

	hasWarning(): boolean {
		for (const i in this.state) {
			if (this.state[i].validation.hasWarning) {
				return true;
			}
		}

		return false;
	}

	hasError(): boolean {
		for (const i in this.state) {
			if (this.state[i].validation.hasError) {
				return true;
			}
		}

		return false;
	}

	render() {
		const value: FormContextValue = {
			onFieldChange: this.onChange,
			getFieldValue: this.getValue,
			disableAllFields: this.props.disableAll || false,
		};
		return (
			<FormContext.Provider value={value}>
				<form onSubmit={this.onSubmit} className={this.props.className}>
					{this.props.children}
				</form>
			</FormContext.Provider>
		);
	}
}
