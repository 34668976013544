import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingText } from "@bokio/elements/Loading";
import { GeneralLangFactory } from "@bokio/lang";
import { getRoute } from "@bokio/shared/route";
import { mergeClassNames } from "@bokio/utils/classes";

import { TopBarDropDownButton } from "../../../TopBar/TopBarDropDownButton";

// prettier-ignore
import * as styles from "./menuCurrentUser.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

// prettier-ignore
import * as flagStyles from "./languageSwitcherModal.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";

export interface MenuCurrentUserProps {
	openLangModal?: () => void;
}

const MenuCurrentUser: React.FC<MenuCurrentUserProps> = ({ openLangModal }) => {
	const { isLoadingUser, user, signOut } = useTopLevelUser();
	const [isOpen, setIsOpen] = React.useState(false);

	const handleBlur = () => {
		if (isOpen) {
			setTimeout(() => {
				// fixes blur firing before onClick
				setIsOpen(false);
			}, 200);
		}
	};

	const toggleuserSettings = () => {
		setIsOpen(x => !x);
	};

	const lang = GeneralLangFactory();

	if (isLoadingUser) {
		return (
			<div className={styles.dropdown}>
				<LoadingText width="70%" />
			</div>
		);
	}

	if (!user) {
		return <div className={styles.dropdown}>No user</div>;
	}

	return (
		<div className={styles.link}>
			<TopBarDropDownButton
				text={user.FirstName || lang.MyAccount}
				onClick={toggleuserSettings}
				onBlur={handleBlur}
				onBackgroundClose={handleBlur}
				open={isOpen}
				testId="MenuCurrentUser"
			/>
			<div className={mergeClassNames(styles.userSettings, isOpen && styles.showUserSettings)}>
				{
					<div className={styles.loggedInAs}>
						<p className={styles.name}>
							{user.FirstName && user.LastName
								? `${user.FirstName} ${user.LastName}`
								: user.FirstName
									? `${user.FirstName}`
									: lang.MyAccount}
						</p>
						<em className={styles.email}>{user.Email}</em>
					</div>
				}
				<Link
					style="none"
					className={styles.closeButton}
					onClick={() => {
						setIsOpen(false);
					}}
				>
					<Icon name="cancel" size="22" />
				</Link>
				<Link
					className={styles.linkItem}
					style="none"
					route={getRoute("personalSettings", { returnUrl: window.location.pathname })}
				>
					{lang.PersonalSettings}
				</Link>
				{user.Languages.length > 1 && (
					<Link
						className={mergeClassNames(
							styles.linkItem,
							flagStyles.flag,
							flagStyles[`flag${user.Language.substring(0, 2)}`],
						)}
						style="none"
						onClick={openLangModal}
					>
						{lang.ChangeLanguage_action}
					</Link>
				)}
				<Link className={styles.linkItem} style="none" route={getRoute("deleteUserAccount")}>
					{lang.DeleteAccount}
				</Link>
				<Link
					className={mergeClassNames(styles.linkItem, styles.logout)}
					testId="logoutButton"
					style="none"
					onClick={e => {
						e.preventDefault();
						signOut();
					}}
				>
					{lang.LogOut}
				</Link>
			</div>
		</div>
	);
};

export default MenuCurrentUser;
