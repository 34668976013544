import * as React from "react";

import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { Button } from "@bokio/designsystem/components/Button";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { TextAreaField } from "@bokio/elements/Form";
import { RenderRequestError } from "@bokio/elements/Loading";
import { GeneralLangFactory, SettingsLangFactory } from "@bokio/lang";

import type { Appearance } from "@bokio/designsystem/components/Button";
import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

import * as styles from "./feedbackModal.scss";

interface FeedbackModalProps {
	visible: boolean;
	onClose: () => void;
	onSendFeedback: (text: string) => void;
	request?: RequestState<m.Envelope<boolean | m.Settings.Controllers.HelpCreateResult, m.SimpleError>>;
	requests?: RequestState<m.Envelope<boolean | m.Settings.Controllers.HelpCreateResult, m.SimpleError>>[];
	text?: string;
	title?: string;
	textInputTitle?: string;
	primaryButtonText?: string;
	primaryButtonAppearance?: Appearance;
	allowEmptyMessage?: boolean;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
	visible,
	onClose,
	onSendFeedback,
	request,
	text,
	requests,
	title,
	textInputTitle,
	primaryButtonText,
	primaryButtonAppearance = "primary",
	allowEmptyMessage = false,
}) => {
	const [textValue, writeText] = React.useState("");
	const { isMobile } = useDeviceQuery();
	const generalLang = GeneralLangFactory();
	const settingsLang = SettingsLangFactory();
	const anyRequestLoading = request?.isLoading || requests?.some(x => x.isLoading);
	return (
		<Modal visible={visible} onClose={onClose} title={title ?? settingsLang.AddOns_Feedback_Modal_Title}>
			<ModalContent>
				{isMobile && <p className={styles.noTopMargin}>{text ?? generalLang.WantFeatureFeedback}</p>}
				<TextAreaField
					onChange={writeText}
					label={textInputTitle ?? settingsLang.AddOns_Feedback_Modal_Message_Label}
					value={textValue}
					placeholder={settingsLang.AddOns_Feedback_Modal_Message_Placeholder}
					autoFocus={!isMobile && true}
					rows={9}
					disabled={anyRequestLoading}
					testId="FeedbackModal_TextArea_input"
				/>
			</ModalContent>
			<RenderRequestError request={request} requests={requests} />
			<ModalFooter>
				<Button
					appearance="secondary"
					onClick={onClose}
					disabled={anyRequestLoading}
					testId="FeedbackModal_Cancel_button"
				>
					{generalLang.Cancel}
				</Button>
				<Button
					disabled={!allowEmptyMessage && !textValue}
					appearance={primaryButtonAppearance}
					onClick={() => onSendFeedback(textValue)}
					loading={anyRequestLoading}
					testId="FeedbackModal_send_button"
				>
					{primaryButtonText ?? generalLang.Submit}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
