import * as React from "react";

/**
 * This internal context is mainly for keeping the child class name
 * that is required by spacing group component
 * when we try to penetrate through the component tree with SGFragment.
 */
export const SGChildClassNameContext = React.createContext<string | undefined>(undefined);

export const useSGChildClassNameContext = () => {
	return React.useContext(SGChildClassNameContext);
};
