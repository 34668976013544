import "@bokio/assets/scss/global.scss";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";

import createHistory from "history/createBrowserHistory";
import { createRoot } from "react-dom/client";

import { languageNotifier } from "@bokio/lang/languageNotifier";
import { setup as dateUtilsSetup, initFormatter } from "@bokio/mobile-web-shared/core/model/types";
import { setup as numberUtilsSetup } from "@bokio/mobile-web-shared/core/utils/numberUtils";
import { Client } from "@bokio/mobile-web-shared/services/api/client";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { Config } from "@bokio/shared/config";
import { WebClient } from "@bokio/shared/services/api/client";
import { formatDate } from "@bokio/shared/utils/format";
import { trackTrace } from "@bokio/utils/t";

import { loadIntl } from "./loadPolyFills";

import type { App as TApp } from "./App"; // This needs to be a type-only import to ensure it's stripped away during compilation, otherwise the loading other can go wrong. App module's loading order should be after the polyfills.
import type { BokioRouterLocationState } from "@bokio/shared/containers/router/useRouter";

// This is overridden in vitePluginBokioWebpackInterop
const isWebpack = true;

// Setup environment like number, date regex ... based on our culture
Config.setup({
	lang: window.currentLang,
	apiUrl: process.env.API_URL || "",
});

window.setLang = function (lang: string) {
	languageNotifier.load(lang);
};

window.setDebugLang = function () {
	// This is Kyrgyzstan, we need to use a real culture because the time formats will be messed up otherwise.
	// Kyrgyzstan is random and can be changed, it was chosen because it's a language that we won't likely support
	// If you change this you need to look for all places this string is used.
	window.setLang("ky-KG");
};

window.toogleScreenshotMode = function () {
	const root = window.document.getElementById("root");
	if (root) {
		if (root.classList.contains("screenShotMode")) {
			root.classList.remove("screenShotMode");
		} else {
			root.classList.add("screenShotMode");
		}
	}
};

//Makes it so that we can call all of our proxy functions through, for example, the dev console by writing window.bokioProxy.module.function
window.bokioProxy = proxy;

initFormatter(formatDate);

const startApp = async () => {
	["location", "history", "localStorage", "sessionStorage", "setTimeout", "getSelection"].forEach(api => {
		try {
			if (!window[api]) {
				trackTrace(`Browser lacks support for window.${api}`);
			}
		} catch (e) {}
	});

	// Sheng 2024-09-27
	// It feels like this no longer work as it was supposed to,
	// index.tsx is importing other dependencies that might need polyfills.
	// Consider moving those dependencies down into another file in the future,
	// so our code can really load after polyfills are loaded.
	//
	// NOTE: Load App component and related libraries after polyfills are loaded
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const App: typeof TApp = require("./App").App;

	Client.innerClient = new WebClient();
	const history = createHistory<BokioRouterLocationState>();

	const container = document.getElementById("root") as HTMLElement;
	const root = createRoot(container);
	root.render(<App history={history} />);

	// vitePluginBokioWebpackInterop-uncomment viteBokioAheadOfTimeWarmup();
};

Promise.all([
	// eslint-disable-next-line promise/always-return
	loadIntl().then(() => {
		dateUtilsSetup({ lang: Config.env.lang });
		Config.subscribe(dateUtilsSetup);

		numberUtilsSetup({ lang: Config.env.lang });
		Config.subscribe(numberUtilsSetup);
	}),
	languageNotifier.load(Config.env.lang),
])
	.then(startApp)
	.catch(window.onerror);

if (isWebpack && module.hot) {
	module.hot.accept("./App", startApp);
}
