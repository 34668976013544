import * as React from "react";

import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { RenderApi } from "@bokio/components/RenderApi/RenderApi";
import { Button } from "@bokio/designsystem/components/Button";
import { FormGroup, FormWithValidation } from "@bokio/elements/Form";
import { RenderRequestError } from "@bokio/elements/Loading";
import Markdown from "@bokio/elements/Markdown/Markdown";
import MentionsTextArea from "@bokio/elements/MentionsTextArea/MentionsTextArea";
import { Notice } from "@bokio/elements/Notice/Notice";
import { SearchableSelect } from "@bokio/elements/SearchableSelect";
import { BackOfficeLangFactory, GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { formatMessage } from "@bokio/shared/utils/format";

import { getButtonText, getMentionableUsers, getTranslation } from "./helper";

import type {
	CustomSuggestionDataItem,
	MentionsOnChangeProps,
} from "@bokio/elements/MentionsTextArea/MentionsTextArea";
import type { SuggestionDataItem } from "react-mentions";

interface AddEditCommentModalProps {
	visible: boolean;
	commentStateDto?: m.Core.Services.Notes.CommentsStateDto;
	partnerId: string;
	noteId?: string;
	companyDetailsId?: string;
	onClose: () => void;
	onSuccess: () => void;
	companyDetailsName?: string;
	quoteRequestId?: string;
}

export const AddEditCommentModal = ({
	visible,
	commentStateDto,
	partnerId,
	noteId,
	companyDetailsId,
	onClose,
	onSuccess,
	quoteRequestId,
	companyDetailsName,
}: AddEditCommentModalProps) => {
	const lang = GeneralLangFactory();
	const backofficeLang = BackOfficeLangFactory();
	const descriptionLabel = backofficeLang.Comment_TextField_Label;
	const isEdit = !!commentStateDto;

	const getInitialState = () => {
		return {
			Text: commentStateDto ? commentStateDto.Text : "",
			MentionedAgencyGuids: [""],
			MentionedCompanyGuids: [""],
			ClientCompanyId: companyDetailsId ?? undefined,
			QuoteRequestId: quoteRequestId,
		};
	};

	const [client, setClient] = React.useState(companyDetailsId);

	const [mentionsUsersCompany, setMentionsUsersCompany] = React.useState<SuggestionDataItem[]>();
	const [getClientUser] = useLazyApi(proxy.BackOffice.CompanyDetailsController.GetClientCompanyUsers.Get);

	const [taskSuggestions, setTaskSuggestions] = React.useState<CustomSuggestionDataItem[]>([]);

	const [agencyTasks] = useLazyApi(proxy.BackOffice.CompanyDetailsController.AgencyTasks.Get, {
		onSuccess: tasks => {
			setTaskSuggestions(
				tasks.map(t => ({
					id: t.Id,
					key: t.Id,
					display: t.State.Title ?? (t.State.Text.length > 60 ? t.State.Text.substring(0, 60) : t.State.Text),
				})),
			);
		},
	});

	React.useEffect(() => {
		client && agencyTasks(partnerId, client);
	}, [client, agencyTasks, partnerId]);

	const getPreviousMentions = () => {
		const regex = new RegExp(/[a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}/g);

		return commentStateDto?.Text.match(regex) ?? [];
	};

	const [showWarning, setshowWarning] = React.useState<boolean>(false);

	const handleOnSuccess = () => {
		onSuccess();
		onClose();
	};
	const [addComment, addCommentRequest] = useLazyApi(proxy.BackOffice.CompanyDetailsController.AddComment.Post, {
		onSuccess: handleOnSuccess,
	});

	const [updateComment, updateCommentRequest] = useLazyApi(
		proxy.BackOffice.CompanyDetailsController.UpdateComment.Put,
		{
			onSuccess: handleOnSuccess,
		},
	);

	const handleCancel = () => {
		onClose();
	};

	const title = isEdit
		? formatMessage(
				backofficeLang.AddEditNote_ModalTitle_Edit,
				getTranslation(m.Entities.NoteKind.Comment).toLowerCase(),
			)
		: formatMessage(
				backofficeLang.AddEditNote_ModalTitle_CreateNew,
				getTranslation(m.Entities.NoteKind.Comment).toLowerCase(),
			);

	const validator = (formData: {
		Text: string;
		MentionedAgencyGuids: string[];
		MentionedCompanyGuids: string[];
		ClientCompanyId?: string;
	}) => {
		if (!formData.Text) {
			return { isValid: false };
		}
		if (!formData.ClientCompanyId) {
			return { isValid: false };
		}
		return { isValid: true };
	};

	const getAllMentionableUsers = async (client: string | undefined) => {
		if (client) {
			const companyOwner = await getClientUser(partnerId, client);
			if (companyOwner.Data && companyOwner.Data.length > 0) {
				const companyusers = companyOwner.Data?.map(a => ({
					display: a.Name,
					id: a.Id,
					postText: backofficeLang.Client,
					key: a.Id,
				}));
				setMentionsUsersCompany(companyusers);
			}
		}
	};

	React.useEffect(() => {
		//reset the old client details
		setMentionsUsersCompany([]);
		getAllMentionableUsers(client);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client]);

	return (
		<Modal
			disableBackgroundClick
			alwaysMounted={true}
			title={title}
			visible={visible}
			onClose={onClose}
			testId="AddEditCommentModal_Modal"
		>
			<FormWithValidation
				initialState={getInitialState()}
				onSubmit={async formData => {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { ClientCompanyId, ...CommentStateDto } = formData;

					//Remove duplicate GUID from the Mentioned
					const newArray = CommentStateDto.MentionedAgencyGuids.filter(function (elem, pos) {
						return CommentStateDto.MentionedAgencyGuids.indexOf(elem) == pos;
					});

					if (!!mentionsUsersCompany && mentionsUsersCompany.length > 0) {
						CommentStateDto.MentionedCompanyGuids = newArray.filter(s => s === mentionsUsersCompany[0].id.toString());
						CommentStateDto.MentionedAgencyGuids = newArray.filter(s => s !== mentionsUsersCompany[0].id.toString());
					}

					if (isEdit && ClientCompanyId) {
						noteId && (await updateComment(partnerId, ClientCompanyId, noteId, CommentStateDto, commentStateDto.Text));
					} else if (ClientCompanyId) {
						await addComment(partnerId, ClientCompanyId, CommentStateDto);
					}
				}}
				validator={validator}
			>
				{({ formData, setFormData, validation }) => {
					const setCommentDescription = (newValues: MentionsOnChangeProps) => {
						setFormData(formState => ({
							...formState,
							Text: newValues.dynamicTextValue
								.replace(backofficeLang.Accountant, "")
								.replace(backofficeLang.Client, ""),
							MentionedAgencyGuids: newValues.mentionedUsers,
						}));
						if (
							!!mentionsUsersCompany &&
							mentionsUsersCompany.length > 0 &&
							newValues.mentionedUsers.includes(mentionsUsersCompany[0].id.toString())
						) {
							setshowWarning(true);
						} else {
							setshowWarning(false);
						}
					};
					const setClientId = async (clientId: string | undefined) => {
						setClient(clientId);
						setshowWarning(false);
						if (!!clientId) {
							setFormData(formState => ({
								...formState,
								ClientCompanyId: clientId,
								Text: "",
							}));
						} else {
							setFormData(formState => ({
								...formState,
								ClientCompanyId: undefined,
								Text: "",
							}));
						}
					};

					return (
						<>
							<ModalContent>
								{companyDetailsName ? (
									<>
										<Markdown
											markdownContent={
												quoteRequestId
													? formatMessage(
															isEdit
																? backofficeLang.AddEditNote_ModalDescription_Edit_QuoteRequest
																: backofficeLang.AddEditNote_ModalDescription_Add_QuoteRequest,
															getTranslation(m.Entities.NoteKind.Comment).toLowerCase(),
															companyDetailsName,
														)
													: formatMessage(
															isEdit
																? backofficeLang.AddEditNote_ModalDescription_Edit
																: backofficeLang.AddEditNote_ModalDescription_Add,
															getTranslation(m.Entities.NoteKind.Comment).toLowerCase(),
															companyDetailsName,
														)
											}
										/>
									</>
								) : (
									<RenderApi
										endpoint={proxy.BackOffice.AgencyController.ListClients.Get}
										parameters={[partnerId]}
										whenLoading="content"
									>
										{({ data: clients }) => {
											return (
												<>
													<SearchableSelect
														testId="AddEditComment_SelectCompany"
														label={backofficeLang.ChooseClientCompany}
														selected={clients
															.map(c => ({
																value: c.CompanyId,
																key: c.CompanyId,
																label: c.Name,
															}))
															.find(x => x.key === client)}
														onChange={clientId => setClientId(clientId?.value)}
														options={clients
															.map(c => ({
																value: c.CompanyId,
																key: c.CompanyId,
																label: c.Name,
															}))
															.sort((a, b) => a.label.localeCompare(b.label))}
														placeholder={backofficeLang.ChooseClientCompany}
													/>
												</>
											);
										}}
									</RenderApi>
								)}
								<RenderApi
									endpoint={proxy.BackOffice.UsersController.MentionableAccountants.Get}
									parameters={[
										partnerId,
										quoteRequestId
											? m.Entities.PartnerMembershipType.ManageQuoteRequests
											: m.Entities.PartnerMembershipType.Viewer,
									]}
									whenLoading="content"
								>
									{({ data: users }) => {
										const accountants = users.Accountants;
										let mentionUsers = getMentionableUsers(accountants);
										mentionUsers.forEach(a => (a.postText = backofficeLang.Accountant));

										if (mentionsUsersCompany !== undefined && mentionsUsersCompany.length > 0 && !quoteRequestId) {
											mentionUsers = mentionUsers.concat(mentionsUsersCompany);
										}

										return (
											<FormGroup>
												<MentionsTextArea
													placeholder={backofficeLang.AddEditNoteModal_PlaceHolder}
													label={descriptionLabel}
													value={formData.Text}
													onChange={mentionsInputChangeValues => setCommentDescription(mentionsInputChangeValues)}
													users={mentionUsers ?? []}
													hideCaption={formData.ClientCompanyId === undefined}
													tasks={taskSuggestions}
													previousMentions={getPreviousMentions()}
												/>
												{showWarning && (
													<Notice color="warning" margin={["top", "bottom"]}>
														{formatMessage(backofficeLang.ExternalCommunication_Warning)}
													</Notice>
												)}
											</FormGroup>
										);
									}}
								</RenderApi>
							</ModalContent>
							<RenderRequestError requests={[addCommentRequest, updateCommentRequest]}></RenderRequestError>
							<ModalFooter>
								<Button appearance="secondary" onClick={handleCancel}>
									{lang.Cancel}
								</Button>
								<Button
									type="submit"
									testId="AddCommentCreateButton"
									disabled={!validation.isValid}
									loading={addCommentRequest.isLoading || updateCommentRequest.isLoading}
								>
									{getButtonText(isEdit, false, m.Entities.NoteKind.Comment)}
								</Button>
							</ModalFooter>
						</>
					);
				}}
			</FormWithValidation>
		</Modal>
	);
};
