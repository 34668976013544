import * as React from "react";

import CheckBox from "@bokio/elements/CheckBox/CheckBox";
import Icon from "@bokio/elements/Icon/Icon";
import classes, { mergeClassNames } from "@bokio/utils/classes";

import TableData from "./TableData";
import TableHeader from "./TableHeader";

import type { TableAutoProps } from "./TableAutoProps";
import type { TableDataProps } from "./TableData";
import type { TableHeaderProps } from "./TableHeader";

import * as styles from "./style.scss";

export interface TableProps {
	children?: React.ReactNode;
	checked?: boolean;
	onChange?: (selected: number[]) => void;
	defaultSelectAll?: boolean;
	page?: "myExpenses" | "expensePayments1" | "expensePayments2"; // Add other styles to cater for mobile structure on mobile. This should be optimised down the line as it's a bit hacky now.
	className?: string;
	testId?: string;
}

interface TableState {
	selected: { [index: number]: boolean };
	selectAll: boolean;
}

export class Table extends React.Component<React.PropsWithChildren<TableProps>, TableState> {
	constructor(props: TableProps) {
		super(props);
		let selected: { [index: number]: boolean } = {};
		if (this.props.defaultSelectAll) {
			selected = this.getAllSelected();
		}
		this.state = {
			selected,
			selectAll: !!this.props.defaultSelectAll,
		};
	}

	componentDidMount() {
		this.onChange();
	}

	onChange = () => {
		const selected: number[] = [];
		for (const i in this.state.selected) {
			if (this.state.selected[i]) {
				// -1 to index from 0 due to header checkbox
				selected.push(parseInt(i, 10) - 1);
			}
		}
		if (this.props.onChange) {
			this.props.onChange(selected);
		}
	};

	select(checked: boolean, index: number) {
		this.setState(
			{
				selectAll: false,
				selected: {
					...this.state.selected,
					[index]: checked,
				},
			},
			this.onChange,
		);
	}

	selectAll() {
		if (this.state.selectAll) {
			this.setState(
				{
					selected: {},
					selectAll: false,
				},
				this.onChange,
			);
		} else {
			this.setState(
				{
					selected: this.getAllSelected(),
					selectAll: true,
				},
				this.onChange,
			);
		}
	}

	getAllSelected() {
		const selected: { [index: number]: boolean } = {};
		React.Children.forEach(this.props.children, (child, index) => {
			const e = child as JSX.Element;
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			if (e.type === TableRow) {
				selected[index] = true;
			}
		});
		return selected;
	}

	renderChildren() {
		return React.Children.map(this.props.children, (child, index) => {
			const e = child as JSX.Element;
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			if (e.type === TableRow) {
				return React.cloneElement(e, {
					...e.props,
					onChange: (checked: boolean) => this.select(checked, index),
					checked: this.state.selected[index],
					check: true,
				});
			}
			if (e.type === TableHeader) {
				return React.cloneElement(e, {
					...e.props,
					onChange: () => this.selectAll(),
					checked: this.state.selectAll,
					check: true,
				});
			}
			return e;
		});
	}

	render() {
		const { className } = this.props;
		const classNames = classes(styles, "table", {
			[`table__${this.props.page}`]: this.props.page,
		});

		return (
			<table className={mergeClassNames(classNames, className)} data-testid={this.props.testId}>
				{this.props.checked && this.renderChildren()}
				{!this.props.checked && this.props.children}
			</table>
		);
	}
}

export interface SubTableProps {
	children?: React.ReactNode;
	checked?: boolean;
	onChange?: (selected: number[]) => void;
	defaultSelectAll?: boolean;
	isExpanded?: boolean;
	outerColspan: number;
	autoProps?: TableAutoProps;
	className?: string;
}

interface SubTableState {
	selected: { [index: number]: boolean };
	selectAll: boolean;
}

class SubTable extends React.Component<SubTableProps, SubTableState> {
	constructor(props: SubTableProps) {
		super(props);
		let selected: { [index: number]: boolean } = {};
		if (this.props.defaultSelectAll) {
			selected = this.getAllSelected();
		}
		this.state = {
			selected,
			selectAll: !!this.props.defaultSelectAll,
		};
	}

	componentDidMount() {
		this.onChange();
	}

	onChange() {
		const selected: number[] = [];
		for (const i in this.state.selected) {
			if (this.state.selected[i]) {
				// -1 to index from 0 due to header checkbox
				selected.push(parseInt(i, 10) - 1);
			}
		}
		if (this.props.onChange) {
			this.props.onChange(selected);
		}
	}

	select(checked: boolean, index: number) {
		this.setState(
			{
				selectAll: false,
				selected: {
					...this.state.selected,
					[index]: checked,
				},
			},
			() => this.onChange(),
		);
	}

	selectAll() {
		if (this.state.selectAll) {
			this.setState(
				{
					selected: {},
					selectAll: false,
				},
				() => this.onChange(),
			);
		} else {
			this.setState(
				{
					selected: this.getAllSelected(),
					selectAll: true,
				},
				() => this.onChange(),
			);
		}
	}

	getAllSelected() {
		const selected: { [index: number]: boolean } = {};
		React.Children.forEach(this.props.children, (child, index) => {
			const e = child as JSX.Element;
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			if (e.type === TableRow) {
				selected[index] = true;
			}
		});
		return selected;
	}

	renderChildren() {
		return React.Children.map(this.props.children, child => {
			const e = child as JSX.Element;
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			if (e.type === TableRow || e.type === TableHeader) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return React.cloneElement(e, {
					autoProps: {
						nestingLevel: this.props.autoProps ? this.props.autoProps.nestingLevel + 1 : 1,
					},
				} as TableRowProps | TableHeaderProps);
			}

			return e;
		});
	}

	render() {
		const active = this.props.isExpanded;
		const trClasses = classes(styles, "subtable__tr", "table__tr__wrapper");
		const tableClasses = classes(styles, "subtable", {
			subtable__active: active,
		});
		const divClasses = classes(styles, "", { active });

		return (
			<tr className={trClasses}>
				<TableData colspan={this.props.outerColspan}>
					<div className={divClasses}>
						<table className={mergeClassNames(tableClasses, this.props.className)}>{this.renderChildren()}</table>
					</div>
				</TableData>
			</tr>
		);
	}
}

export { SubTable };

//import SubTable, { SubTableProps } from "./SubTable";
export interface TableRowProps {
	onClick?: () => void;
	route?: string;
	external?: string;
	clickable?: boolean;
	check?: boolean;
	checked?: boolean;
	children?: React.ReactNode;
	onChange?: (checked: boolean) => void;
	autoProps?: TableAutoProps;
	openOnLoad?: boolean;
	visible?: boolean;
	rowIsDeletedLoading?: boolean;
	type?: "total";
	className?: string;
	expandClassName?: string;
	style?: React.CSSProperties;
	testId?: string;
}

interface TableRowState {
	childExpanded: boolean;
}

class TableRow extends React.Component<TableRowProps, TableRowState> {
	constructor(props: TableRowProps) {
		super(props);
		this.state = {
			childExpanded: false,
		};
		this.onClick = this.onClick.bind(this);
	}

	onChange(checked: boolean) {
		if (this.props.onChange) {
			this.props.onChange(checked);
		}
	}

	render() {
		const rawChildren = React.Children.toArray(this.props.children);
		const tableData = rawChildren
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.filter(c => (c as React.ReactElement<any>).type !== SubTable)
			.map(child => {
				const c = child as React.ReactElement<TableDataProps>;
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return React.cloneElement(c, {
					route: this.props.route,
					external: this.props.external,
				} as TableDataProps);
			});
		// React.Children.map(this.props.children, (child) => React.cloneElement(child as React.ReactElement<any>, { link: this.props.link}));
		const subTables = rawChildren.map(c => c as React.ReactElement<SubTableProps>).filter(c => c.type === SubTable);
		const hasChildren = subTables.length > 0;
		const tbodyClassNames = classes(styles, { hidden: this.props.visible === false });
		const trClassNames = this.getClasses(hasChildren) + (this.props.className ? " " + this.props.className : "");

		return (
			<tbody className={tbodyClassNames}>
				<tr
					data-testid={this.props.testId}
					className={trClassNames}
					style={this.props.style ? this.props.style : undefined}
					onClick={this.onClick}
				>
					{this.props.check && (
						<TableData isCheck={true}>
							<CheckBox
								onChange={(checked: boolean) => this.onChange(checked)}
								checked={this.props.checked}
								rightMargin={true}
							/>
						</TableData>
					)}
					{tableData}
					{subTables.length > 0 ? this.renderArrow() : null}
				</tr>

				{subTables.map(subTable => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					return React.cloneElement(subTable, {
						isExpanded: this.state.childExpanded,
						autoProps: {
							nestingLevel: this.props.autoProps ? this.props.autoProps.nestingLevel : 0,
						},
					} as Partial<SubTableProps>);
				})}
			</tbody>
		);
	}

	getClasses(hasChildren: boolean) {
		const open = this.state.childExpanded || this.props.openOnLoad;
		const clickable = this.props.clickable || this.props.onClick || this.props.openOnLoad;
		if (!this.props.autoProps || this.props.autoProps.nestingLevel === 0) {
			return classes(styles, "table__tr", {
				table__tr__hasCheck: this.props.check,
				table__hover: clickable || hasChildren,
				table__hover__active: open && clickable,
				table__tr__hidden: this.props.visible === false,
				table__tr__isDeleted: this.props.rowIsDeletedLoading,
				["table__tr__" + this.props.type]: this.props.type,
			});
		} else if (this.props.autoProps.nestingLevel === 1) {
			return classes(styles, "subtable__tr", {
				subtable__hover: clickable || hasChildren,
			});
		} else {
			return classes(styles, "subtable2__tr", {
				subtable2__tr__clickable: clickable || hasChildren,
			});
		}
	}

	renderArrow() {
		const open = this.state.childExpanded;
		const arrowOpen = open ? "up-open-big" : "down-open-big";
		return (
			<TableData isArrow={true} width="40px" className={this.props.expandClassName}>
				<Icon name={arrowOpen} />
			</TableData>
		);
	}

	onClick() {
		this.setState({
			childExpanded: !this.state.childExpanded,
		});
		if (this.props.onClick) {
			this.props.onClick();
		}
	}
}

export { TableRow };
