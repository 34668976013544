import * as React from "react";

import { List, ListColumn, ListHeader, ListItem } from "@bokio/shared/components/List";

import LoadingText from "./LoadingText";
import RenderRequestError from "./RenderRequestError";

import type { LoadingProps } from "./LoadingProps";

class LoadingList extends React.Component<LoadingProps> {
	render() {
		const { request, requests, children, hideSoftErrors } = this.props;
		return (
			<RenderRequestError
				request={request}
				requests={requests}
				showWhenLoaded={children}
				hideSoftErrors={hideSoftErrors}
			>
				<List>
					<ListHeader>
						<ListColumn>
							<LoadingText width="50%" />
						</ListColumn>
					</ListHeader>

					{this.renderItem()}
					{this.renderItem()}
					{this.renderItem()}
					{this.renderItem()}
					{this.renderItem()}
				</List>
			</RenderRequestError>
		);
	}

	renderItem() {
		const width = Math.random() * 100 + "%";
		return (
			<ListItem>
				<ListColumn>
					<LoadingText width={width} />
				</ListColumn>
			</ListItem>
		);
	}
}

export default LoadingList;
