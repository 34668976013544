import * as React from "react";

import * as styles from "./feedback.scss";

class Feedback extends React.Component<React.PropsWithChildren> {
	render() {
		return <div className={styles.feedback}>{this.props.children}</div>;
	}
}

export default Feedback;
