import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import Field from "./Field/Field";
import { FormContext } from "./Form";
import LabelFor from "./LabelFor/LabelFor";

import type { FormContextValue } from "./Form";
import type { LabelTooltip } from "./LabelFor/LabelFor";
import type { FieldValidationResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./form.scss";

interface TextAreaFieldProps {
	value?: string;
	onChange?: (value: string) => void;
	name?: string;
	label?: React.ReactNode;
	hint?: string;
	info?: React.ReactNode;
	disabled?: boolean;
	height?: string;
	rows?: number;
	errors?: FieldValidationResult;
	placeholder?: string;
	className?: string;
	autoFocus?: boolean;
	maxLength?: number;
	readonly?: boolean;
	/**
	 * Controls how the textarea resizes. Manual is when the user is allowed to drag it larger.
	 * auto will auto grow as the user types.
	 */
	resizeMode?: "none" | "manual" | "auto";
	testId?: string;
	labelTestId?: string;
	onHeightChange?: (height: number) => void;
	tooltip?: LabelTooltip;
}

export class TextAreaField extends React.Component<TextAreaFieldProps> {
	private field?: HTMLTextAreaElement;
	private borderHeight = 0;

	static contextType = FormContext;
	declare context: React.ContextType<React.Context<FormContextValue>>;

	componentDidUpdate(prevProps: TextAreaFieldProps) {
		// Make sure the component resizes back if the value props are cleared.
		if (this.props.value !== prevProps.value && !this.props.value) {
			this.autoGrow();
		}
	}

	render() {
		const {
			label,
			hint,
			info,
			height,
			name,
			value,
			rows,
			placeholder,
			errors,
			className,
			disabled,
			autoFocus,
			testId,
			maxLength,
			tooltip,
			labelTestId,
		} = this.props;
		const { disableAllFields } = this.context;
		const style = { height };

		return (
			<Field>
				<LabelFor
					label={label}
					hint={hint}
					info={info}
					errors={errors && errors.errors}
					tooltip={tooltip}
					testId={labelTestId}
				>
					<textarea
						data-testid={testId}
						ref={this.createRef}
						className={mergeClassNames(styles.field__input, styles.textArea, className)}
						style={style}
						name={name}
						value={value}
						maxLength={maxLength}
						rows={rows || 1}
						onChange={this.handleInputChanged}
						disabled={disableAllFields || disabled}
						placeholder={placeholder}
						autoFocus={autoFocus}
					/>
				</LabelFor>
			</Field>
		);
	}

	handleInputChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (this.props.onChange) {
			this.props.onChange(event.currentTarget.value);
		}
		this.autoGrow();
	};

	createRef = (elem: HTMLTextAreaElement) => {
		this.field = elem;
	};

	autoGrow = () => {
		if (this.field) {
			this.borderHeight = this.field.offsetHeight - this.field.clientHeight;
			this.field.style.minHeight = this.props.height || "auto";
			this.field.style.height = "auto";
			const height = this.field.scrollHeight + this.borderHeight;
			this.field.style.height = height + "px";
			if (this.props.onHeightChange !== undefined) {
				this.props.onHeightChange(height);
			}
		}
	};
}
