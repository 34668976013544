import * as React from "react";
import Measure from "react-measure";

import * as style from "./animations.scss";

interface SlideAnimationProps {
	isVisible?: boolean;
	children: React.ReactNode;
	targetWidth?: number;
	targetHeight?: number;
	inline?: boolean;
}

interface SlideAnimationState {
	realWidth?: number;
	realHeight?: number;
}

/**
 * Animate from hidden to an estimated size (width or height).
 * The width or height will be animated to the real size of the children
 * by measuring the children once it is rendered.
 * <SlideAnimation isVisible={true} targetHeight={100}>Content here</SlideAnimation>
 */
class SlideAnimation extends React.Component<SlideAnimationProps, SlideAnimationState> {
	constructor(props: SlideAnimationProps) {
		super(props);

		this.state = {};
	}

	hiddenState(): React.CSSProperties {
		return {
			opacity: 0,
			height: 0,
			width: 0,
			visibility: "hidden",
		};
	}

	visibleState(): React.CSSProperties {
		return {
			opacity: 1,
			height: this.props.targetHeight ? this.state.realHeight || this.props.targetHeight : "auto",
			width: this.props.targetWidth ? this.state.realWidth || this.props.targetWidth : "auto",
			visibility: "visible",
		};
	}

	render() {
		return (
			<div style={{ display: this.props.inline ? "inline-block" : "block", verticalAlign: "middle" }}>
				<div className={style.transitions} style={this.props.isVisible ? this.visibleState() : this.hiddenState()}>
					<Measure
						bounds={true}
						onResize={info => info && info.bounds && this.setRealSize(info.bounds.width, info.bounds.height)}
					>
						{args => <div ref={args.measureRef}>{this.props.children}</div>}
					</Measure>
				</div>
			</div>
		);
	}

	setRealSize(width: number, height: number) {
		if (!this.props.inline && (this.state.realWidth !== width || this.state.realHeight !== height)) {
			this.setState({
				realWidth: Math.round(width),
				realHeight: Math.round(height),
			});
		}
	}
}

export default SlideAnimation;
