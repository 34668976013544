import type * as React from "react";

let currentLang: "en-GB" | "sv-SE" | "en-SE" | undefined;


export default function inlineTranslate<T extends string | React.ReactNode>(render: (key: "en-GB" | "sv-SE" | "en-SE") => T): T | undefined {
  if (!currentLang) {
    return undefined;
  }
  return render(currentLang);
}

export function setInlineTranslateLang(newLang: string) {
  if (newLang === "en-GB" || newLang === "sv-SE" || newLang === "en-SE") {
      currentLang = newLang;
  } else {
    currentLang = undefined;
  }
}
