import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./searchableSelect.scss";

const defaultRenderer = <T,>(option: SelectOption<T>, { selected, focused }: OptionRendererProps) => (
	<span
		className={mergeClassNames(
			styles.defaultSelectOption,
			focused && styles.focusedOption,
			selected && styles.selectedOption,
		)}
		title={option.label}
	>
		{option.label}
	</span>
);

export interface OptionRendererProps {
	focused: boolean;
	selected: boolean;
}

/** Interface that represents the options of a SearchableSelect.
 * `T` is the type of the options' value. */
export interface SelectOption<TValue> {
	key: string | number;
	value: TValue;
	label: string;
	disabled?: boolean;
}

export type SelectOptionCategory<T> = {
	label: React.ReactNode;
	subCategories: SelectOptionCategory<T>[];
	options: SelectOption<T>[];
};

interface SearchableSelectOptionProps<T> {
	onSelect: (option: SelectOption<T>) => void;
	setRef: (ref: HTMLDivElement) => void;
	option: SelectOption<T>;
	selected: boolean;
	focused: boolean;
	renderer?: (option: SelectOption<T>, props: OptionRendererProps) => React.ReactNode;
}

export function SearchableSelectOption<T>({
	option,
	setRef,
	onSelect,
	selected,
	focused,
	renderer = defaultRenderer,
}: SearchableSelectOptionProps<T>) {
	return (
		<div
			tabIndex={-1}
			onClick={e => {
				e.preventDefault();
				e.stopPropagation();
				onSelect(option);
			}}
			ref={setRef}
			role="option"
			aria-selected={selected}
		>
			{renderer(option, { selected, focused })}
		</div>
	);
}
