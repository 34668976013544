import { mergeClassNames } from "@bokio/utils/classes";

import type { Appearance, ButtonAlign, IconAlign, Margin, MarginDirection, Size } from "./button.types";
import type { LoadingSpinnerColor } from "@bokio/elements/Loading/LoadingSpinner";

// prettier-ignore
import * as styles from "./button.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

// prettier-ignore
import * as baseStyles from "./button.base.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

import type { IconSize } from "@bokio/elements/Icon/Icon";

export function getLoadingSpinnerColor(appearance: Appearance): LoadingSpinnerColor {
	switch (appearance) {
		case "primary":
			return "white";
		case "destructive":
		case "secondary":
			return "dark";
		default:
			return "white";
	}
}

export function getIconSize(size: Size): IconSize {
	switch (size) {
		case "small":
			return "18";
		case "normal":
			return "20";
		default:
			return "20";
	}
}

export function getButtonDisabledClassName(appearance: Appearance): string {
	switch (appearance) {
		case "primary":
			return baseStyles.disabledPrimary;
		case "secondary":
			return baseStyles.disabledSecondary;
		case "destructive":
			return baseStyles.disabledDestructive;
		case "ghost":
			return baseStyles.disabledGhost;
		default:
			return "";
	}
}

export function getIconAlignClassName(iconAlign: IconAlign): string {
	switch (iconAlign) {
		case "left":
			return styles.iconAlignLeft;
		case "right":
			return styles.iconAlignRight;
		default:
			return "";
	}
}

export function getAlignSelfClassName(align: ButtonAlign): string {
	switch (align) {
		case "baseline":
			return baseStyles.alignSelfBaseline;
		case "center":
			return baseStyles.alignSelfCenter;
		case "flex-end":
			return baseStyles.alignSelfFlexEnd;
		case "flex-start":
			return baseStyles.alignSelfFlexStart;
	}
}

function getPositionalMarginClassName(margin: MarginDirection) {
	switch (margin) {
		case "top":
			return baseStyles.marginTop;
		case "right":
			return baseStyles.marginRight;
		case "bottom":
			return baseStyles.marginBottom;
		case "left":
			return baseStyles.marginLeft;
	}
}

export function getMarginClassName(margin: Margin): string {
	return Array.isArray(margin) ? mergeClassNames(margin.map(getPositionalMarginClassName)) : baseStyles.margin;
}
