import * as React from "react";

import { useDisableGlobalFileDropZone } from "@bokio/hooks/useDisableGlobalFileDropZone/useDisableGlobalFileDropZone";
import { noop } from "@bokio/shared/utils";

import type { ModalElement } from "./ModalElement";

const useModalTransition = (): [boolean, (callback: () => void) => void] => {
	const [state, setState] = React.useState({ visible: false });

	const onMount = () => {
		setTimeout(() => setState({ visible: true }));
	};

	React.useEffect(onMount, []);

	const onClose = (callback: () => void) => {
		setState({ visible: false });
		setTimeout(callback, 200);
	};

	return [state.visible, onClose];
};

const ModalTransitionWrapper = (props: { modal: ModalElement; onClose: () => void }) => {
	const [visible, closeModal] = useModalTransition();
	useDisableGlobalFileDropZone();

	return <props.modal onClose={() => closeModal(props.onClose)} visible={visible} />;
};

interface ModalStack {
	addModal: (modal: ModalElement) => void;
	removeModal: (id: number) => void;
}

interface ModalStackState {
	counter: number;
	modals: { id: number; modal: ModalElement }[];
}

/**
 * @deprecated This is only used in pair with deprecated modal hooks (`useModalStack`, `useModalsAsync`).
 */
export const ModalStackContext = React.createContext<ModalStack>({
	addModal: noop,
	removeModal: noop,
});

/**
 * @deprecated This is only used in pair with deprecated modal hooks (`useModalStack`, `useModalsAsync`).
 */
export const ModalStackContextProvider: React.FC<React.PropsWithChildren> = props => {
	const [state, setState] = React.useState<ModalStackState>({
		counter: 0,
		modals: [],
	});

	const stack: ModalStack = React.useMemo(
		() => ({
			addModal: modal =>
				setState(({ counter, modals }) => ({ counter: counter + 1, modals: [...modals, { id: counter, modal }] })),
			removeModal: id => setState(({ counter, modals }) => ({ counter, modals: modals.filter(m => m.id !== id) })),
		}),
		[],
	);

	return (
		<ModalStackContext.Provider value={stack}>
			{props.children}
			{state.modals.map(({ id, modal }) => (
				<ModalTransitionWrapper key={id} modal={modal} onClose={() => stack.removeModal(id)} />
			))}
		</ModalStackContext.Provider>
	);
};
