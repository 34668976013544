import { SalaryLangFactory } from "@bokio/lang";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";
import InvoiceLangFactory from "@bokio/lang/InvoicesLangFactory";

export const getOrdinalDayArray = () => {
	const invoiceLang = InvoiceLangFactory();
	return [
		invoiceLang.ORDINAL_FIRST,
		invoiceLang.ORDINAL_SECOND,
		invoiceLang.ORDINAL_THIRD,
		invoiceLang.ORDINAL_FOURTH,
		invoiceLang.ORDINAL_FIFTH,
		invoiceLang.ORDINAL_SIXTH,
		invoiceLang.ORDINAL_SEVENTH,
		invoiceLang.ORDINAL_EIGHTH,
		invoiceLang.ORDINAL_NINTH,
		invoiceLang.ORDINAL_TENTH,
		invoiceLang.ORDINAL_ELEVENTH,
		invoiceLang.ORDINAL_TWELFTH,
		invoiceLang.ORDINAL_THIRTEENTH,
		invoiceLang.ORDINAL_FOURTEENTH,
		invoiceLang.ORDINAL_FIFTEENTH,
		invoiceLang.ORDINAL_SIXTEENTH,
		invoiceLang.ORDINAL_SEVENTEENTH,
		invoiceLang.ORDINAL_EIGHTEENTH,
		invoiceLang.ORDINAL_NINETEENTH,
		invoiceLang.ORDINAL_TWENTIETH,
		invoiceLang.ORDINAL_TWENTY_FIRST,
		invoiceLang.ORDINAL_TWENTY_SECOND,
		invoiceLang.ORDINAL_TWENTY_THIRD,
		invoiceLang.ORDINAL_TWENTY_FOURTH,
		invoiceLang.ORDINAL_TWENTY_FIFTH,
		invoiceLang.ORDINAL_TWENTY_SIXTH,
		invoiceLang.ORDINAL_TWENTY_SEVENTH,
		invoiceLang.ORDINAL_TWENTY_EIGHTH,
		invoiceLang.ORDINAL_TWENTY_NINTH,
		invoiceLang.ORDINAL_THIRTIETH,
		invoiceLang.ORDINAL_THIRTY_FIRST,
	];
};

export const getShortMonthArray = () => {
	const salaryLang = SalaryLangFactory();
	return [
		salaryLang.MON_JAN_SHORT,
		salaryLang.MON_FEB_SHORT,
		salaryLang.MON_MAR_SHORT,
		salaryLang.MON_APR_SHORT,
		salaryLang.MON_MAY_SHORT,
		salaryLang.MON_JUN_SHORT,
		salaryLang.MON_JUL_SHORT,
		salaryLang.MON_AUG_SHORT,
		salaryLang.MON_SEP_SHORT,
		salaryLang.MON_OCT_SHORT,
		salaryLang.MON_NOV_SHORT,
		salaryLang.MON_DEC_SHORT,
	];
};

export const getMonthArray = (): [
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
] => {
	const generalLang = GeneralLangFactory();
	return [
		generalLang.January,
		generalLang.February,
		generalLang.March,
		generalLang.April,
		generalLang.May,
		generalLang.June,
		generalLang.July,
		generalLang.August,
		generalLang.September,
		generalLang.October,
		generalLang.November,
		generalLang.December,
	];
};

export const getMonthByNumber = (i: number, shortForm = false) => {
	const months = shortForm ? getShortMonthArray() : getMonthArray();
	return months[i];
};

export const getWeekDayArray = (): [string, string, string, string, string, string, string] => {
	const generalLang = GeneralLangFactory();
	return [
		generalLang.Sunday,
		generalLang.Monday,
		generalLang.Tuesday,
		generalLang.Wednesday,
		generalLang.Thursday,
		generalLang.Friday,
		generalLang.Saturday,
	];
};

export const getWeekDayByNumber = (i: number) => {
	const weekDays = getWeekDayArray();
	return weekDays[i];
};

export const getOrdinalDayByNumber = (day: number) => {
	const ordinalDays = getOrdinalDayArray();
	return ordinalDays.find(ordinalDay => day === parseInt(ordinalDay));
};
