import * as React from "react";

import * as requests from "@bokio/mobile-web-shared/services/api/requestState";

export interface LoaderProps<P, T> {
	endpoint: (params: P) => Promise<T>;
	onError?: (err: unknown, parameters: P) => void;
	onSuccess?: (result: T, parameters: P) => void;
	refreshBeforeLoading?: boolean;
}

export interface LoaderHandle<P, T> {
	request: requests.RequestState<T>;
	load: (params: P) => Promise<T>;
	reset: () => void;
}

export const useLoader = <P, T>(props: LoaderProps<P, T>): LoaderHandle<P, T> => {
	const { endpoint, onSuccess, onError, refreshBeforeLoading } = props;
	const [request, setRequestState] = React.useState(requests.empty<T>());

	const aborted = React.useRef(false);

	const abort = React.useCallback(() => {
		aborted.current = true;
	}, []);

	React.useEffect(() => abort, [abort]);

	const load = React.useCallback(
		async (parameters: P): Promise<T> => {
			if (!aborted.current) {
				setRequestState(s => requests.load(undefined, refreshBeforeLoading ? undefined : s.data));
			}
			try {
				const data = await endpoint(parameters);
				if (!aborted.current) {
					setRequestState(requests.success(data));
					onSuccess && onSuccess(data, parameters);
				}
				return data;
			} catch (error) {
				if (!aborted.current) {
					setRequestState(requests.error(error.message));
					onError && onError(error, parameters);
				}
				throw error;
			}
		},
		[endpoint, onSuccess, onError, refreshBeforeLoading],
	);

	const reset = React.useCallback(() => {
		if (!aborted.current) {
			setRequestState(requests.empty());
		}
	}, []);

	return { request, load, reset };
};
