import * as React from "react";

import * as styles from "./feedback.scss";

class FeedbackCopy extends React.Component<React.PropsWithChildren> {
	render() {
		return <p className={styles.paragraph}>{this.props.children}</p>;
	}
}

export default FeedbackCopy;
