import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { LoadingContent } from "@bokio/elements/Loading";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useAgencyStatus } from "@bokio/shared/state/requests";

import { LoyaltyProgramContent } from "./LoyaltyProgramContent";
import { LoyaltyProgramContentUK } from "./LoyaltyProgramContentUK";

interface MenuLoyaltyProgramProps {
	onLinkClick: () => void;
}

export const MenuLoyaltyProgram = ({ onLinkClick }: MenuLoyaltyProgramProps) => {
	const { isLoadingAgency, agencyStatus } = useAgencyStatus();
	const { isDesktop } = useDeviceQuery({ minDesktopWidth: 1024 }); // For now we just show the loyalty program on desktop
	if (isLoadingAgency || !agencyStatus) {
		return <LoadingContent lines={2} padding />;
	}
	const isUk = agencyStatus.Country === m.CountryCode.GB;

	if (agencyStatus?.Access?.ManageSettings && isDesktop) {
		return isUk ? (
			<LoyaltyProgramContentUK agencyStatus={agencyStatus} onLinkClick={onLinkClick} />
		) : (
			<LoyaltyProgramContent agencyStatus={agencyStatus} />
		);
	} else {
		return null;
	}
};
