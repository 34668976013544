import * as React from "react";

type onDescendantDropHandled = () => void;

/**
 * Beware that drag & drop related events are `MouseEvent` in Safari but `DragEvent` in other browsers.
 * Besides, Safari doesn't set `event.dataTransfer` for dragstart/dragend.
 */
export type DndEvent = DragEvent & MouseEvent;

export interface UseDragDropEventTrackerParams {
	/**
	 * Listen to drag events entering/leaving this element's boundary.
	 */
	baseElement: HTMLElement;
	/**
	 * Filter in the drag events you care e.g. check `event.dataTransfer.types`.
	 */
	dragEnterLeaveEventFilter?: (event: DndEvent) => boolean;
	/**
	 * If there's a element inside `baseElement` that handles drop event on its own, pass it's ref here.
	 * Remember to call `onDescendantDropHandled` callback after this element handled a drop event.
	 */
	descendantDropHandlingElementRef?: React.RefObject<HTMLElement>;
	/**
	 * Set this to true if you want to temporary disable the event listeners and reset `isUserDraggingInElement`.
	 */
	disabled?: boolean;
}

export type UseDragDropEventTrackerReturn = [boolean, boolean, onDescendantDropHandled];

/**
 * Listen to drag events entering/leaving an element's boundary.
 *
 * @returns {Array}
 * @returns {boolean} [0] isUserDraggingInElement
 * @returns {boolean} [1] isDragStartedInElement
 * @returns {Function} [2] onDescendantDropHandled - If the drop event is handled by another element which is a descendant in `baseElement`, call this after the drop event is handled. The original use case for this callback was to have GlobalFileDropZone stay mounted while FileUploader and react-dropzone is handling the drop event, and after that call this callback to unmount GlobalFileDropZone.
 */
export const useDragDropEventTracker = ({
	baseElement,
	dragEnterLeaveEventFilter = () => true,
	descendantDropHandlingElementRef,
	disabled = false,
}: UseDragDropEventTrackerParams): UseDragDropEventTrackerReturn => {
	/**
	 * SS 2020-04-15
	 * See the link below if it's hard to understand why the implementation here is using counters.
	 *  https://www.google.com/search?q=dragenter+dragleave+event+counter
	 *
	 * The TL;DR is that dragenter/dragleave fires every time you drag across an element's boundary.
	 * But there are some pitfalls around these events (also applies to dragover event):
	 * - It only fires at the deepest avaiable element.
	 *   In other words it never fires with event.target === event.currentTarget when the cursor is on top of an elemenet that's deeper than event.currentTarget.
	 * - Dragleave event doesn't fire when the user drops.
	 *
	 * Therefore one needs to count the events to check if the drag is still active within the `baseElement`, and reset counter on drop.
	 */
	const [activeDragEventsCounter, setActiveDragEventsCounter] = React.useState(0);
	const resetActiveDragEventsCounter = () => setActiveDragEventsCounter(0);

	const [isDragStartedInElement, setIsDragStartedInElement] = React.useState(false);
	const resetIsDragStartedInElement = () => setIsDragStartedInElement(false);

	const baseElementDragEnterCallback = React.useCallback<(event: DndEvent) => void>(
		event => {
			dragEnterLeaveEventFilter(event) && setActiveDragEventsCounter(x => x + 1);
		},
		[dragEnterLeaveEventFilter],
	);

	const baseElementDragLeaveCallback = React.useCallback<(event: DndEvent) => void>(
		event => {
			dragEnterLeaveEventFilter(event) && setActiveDragEventsCounter(x => x - 1);
		},
		[dragEnterLeaveEventFilter],
	);

	const baseElementDropCallback = React.useCallback<(event: DndEvent) => void>(
		event => {
			if (!dragEnterLeaveEventFilter(event)) {
				resetActiveDragEventsCounter();
				return;
			}
			const eventTargetIsDescendantOfRoot =
				event.target instanceof HTMLElement && descendantDropHandlingElementRef?.current?.contains(event.target);
			if (!eventTargetIsDescendantOfRoot) {
				resetActiveDragEventsCounter();
			}
		},
		[dragEnterLeaveEventFilter, descendantDropHandlingElementRef],
	);

	const baseElementDragStartCallback = React.useCallback<(event: DndEvent) => void>(() => {
		setIsDragStartedInElement(true);
	}, [setIsDragStartedInElement]);

	const baseElementDragEndCallback = React.useCallback<(event: DndEvent) => void>(() => {
		resetIsDragStartedInElement();
	}, []);

	React.useEffect(() => {
		if (disabled) {
			resetActiveDragEventsCounter();
			return;
		}
		baseElement.addEventListener("dragenter", baseElementDragEnterCallback);
		baseElement.addEventListener("dragleave", baseElementDragLeaveCallback);
		baseElement.addEventListener("drop", baseElementDropCallback);
		baseElement.addEventListener("dragstart", baseElementDragStartCallback);
		baseElement.addEventListener("dragend", baseElementDragEndCallback);
		/**
		 * Not that obvious but `dragend` event always fires from the drag source instead of where the drag really ends.
		 * Therefore it's attached on `baseElement` instead of document.
		 * https://html.spec.whatwg.org/multipage/dnd.html#dndevents
		 */
		return () => {
			baseElement.removeEventListener("dragenter", baseElementDragEnterCallback);
			baseElement.removeEventListener("dragleave", baseElementDragLeaveCallback);
			baseElement.removeEventListener("drop", baseElementDropCallback);
			baseElement.removeEventListener("dragstart", baseElementDragStartCallback);
			baseElement.removeEventListener("dragend", baseElementDragEndCallback);
		};
	}, [
		baseElement,
		disabled,
		baseElementDragEnterCallback,
		baseElementDragLeaveCallback,
		baseElementDropCallback,
		baseElementDragStartCallback,
		baseElementDragEndCallback,
	]);

	const isUserDraggingInElement = activeDragEventsCounter > 0;

	const onDescendantDropHandled = () => {
		resetActiveDragEventsCounter();
		return;
	};

	return [isUserDraggingInElement, isDragStartedInElement, onDescendantDropHandled];
};
