import * as React from "react";

import { BbaOnboardingPopover } from "@bokio/components/BbaOnboardingPopover/BbaOnboardingPopover";
import { CounterBadge } from "@bokio/components/CounterBadge/CounterBadge";
import { FeedbackModal } from "@bokio/components/FeedbackModal/FeedbackModal";
import RenderRequest from "@bokio/components/RenderRequest/RenderRequest";
import { SignAndPayPopover } from "@bokio/components/SignAndPay/SignAndPayPopover/SignAndPayPopover";
import { readPaymentSigningProgressInfoFromLocalStorage } from "@bokio/components/SveaBankIdAuthModal/sveaPollingUtils";
import { TopBarButton } from "@bokio/components/TopBar/TopBarButton";
import { usePaymentContext } from "@bokio/contexts/PaymentContext/PaymentContext";
import { NoticePopover } from "@bokio/designsystem/components/NoticePopover/NoticePopover";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import Icon from "@bokio/elements/Icon/Icon";
import { useFeatureAvailability } from "@bokio/hooks/useFeatureAvailability/useFeatureAvailability";
import { useFeatureToggle } from "@bokio/hooks/useFeatureToggle/useFeatureToggle";
import { AnalyticsEventCategory, useMetric } from "@bokio/hooks/useMetric/useMetric";
import { useUserFeedback } from "@bokio/hooks/useUserFeedback/useUserFeedback";
import { UserPreferenceType, useUserPreference } from "@bokio/hooks/useUserPreference/useUserPreference";
import { GeneralLangFactory } from "@bokio/lang";
import {
	canContinueBbaOnboarding,
	canStartBbaOnboarding,
} from "@bokio/mobile-web-shared/areas/bank/utils/bokioBusinessAccountUtils";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useActiveBankConnection } from "@bokio/mobile-web-shared/hooks/useActiveBankConnection/useActiveBankConnection";
import { useCompanyUser } from "@bokio/shared/state/requests";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./bbaTopBarButton.scss";

interface BbaTopBarButtonProps {
	backOfficeMode?: boolean;
}

interface BbaTopBarButtonInnerProps {
	shouldRenderSignAndPay: boolean;
	shouldRenderStartOnboarding: boolean;
	shouldRenderContinueOnboarding: boolean;
}

const BbaTopBarButtonInner: React.FC<BbaTopBarButtonInnerProps & BbaTopBarButtonProps> = ({
	shouldRenderSignAndPay,
	shouldRenderStartOnboarding,
	shouldRenderContinueOnboarding,
	backOfficeMode,
}) => {
	const generalLang = GeneralLangFactory();
	const { companyInfo } = useCompanyInfo();
	const companyId = companyInfo.Id;
	const { isMobile } = useDeviceQuery();
	const [addMetric] = useMetric();
	const { currentUserPermission, signablePaymentCount, mirOnboardingStatus } = usePaymentContext();

	const {
		setPreference: setQuickAccessNoticePopoverPreference,
		getPreferenceRequest: getQuickAccessNoticePopoverPreferenceRequest,
	} = useUserPreference(companyId, UserPreferenceType.BbaTopBarQuickAccessDismissed);
	const bbaQuickAccessNoticePopoverDismissedPref = getQuickAccessNoticePopoverPreferenceRequest.data?.Data;

	const { paymentValidationRulesRequest } = usePaymentContext();
	const { setPreference: setBbaOnboardingPreference, getPreferenceRequest: getBbaOnboardingPreferenceRequest } =
		useUserPreference(companyId, UserPreferenceType.BbaTopBarOnboardingNotInterested);
	const bbaNotInterestedPref = getBbaOnboardingPreferenceRequest.data?.Data;

	const [isQuickAccessNoticePopoverOpened, setIsQuickAccessNoticePopoverOpened] = React.useState(false);
	const iconRef = React.useRef<HTMLDivElement>(null);
	const popoverWrapperRef = React.useRef<HTMLDivElement>(null);

	const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);
	const { sendFeedback, sendFeedbackRequest } = useUserFeedback(() => {
		setShowFeedbackModal(false);
	});

	const [isPopoverOpened, setIsPopoverOpened] = React.useState(false);
	const closePopover = () => {
		setIsPopoverOpened(false);
	};

	const closeQuickAccessNoticePopover = () => {
		setIsQuickAccessNoticePopoverOpened(false);
		setQuickAccessNoticePopoverPreference({
			isCompanySpecific: true,
			value: { [UserPreferenceType.BbaTopBarQuickAccessDismissed]: { Value: true } },
		});
	};

	React.useEffect(() => {
		if (
			shouldRenderSignAndPay &&
			bbaQuickAccessNoticePopoverDismissedPref &&
			!bbaQuickAccessNoticePopoverDismissedPref.BbaTopBarQuickAccessDismissed?.Value
		) {
			setIsQuickAccessNoticePopoverOpened(true);
			return;
		}
	}, [shouldRenderSignAndPay, bbaQuickAccessNoticePopoverDismissedPref]);

	/**
	 * This is used to check if the user already has a payment signing in progress. (Read more about the methods in sveaPollingUtils.ts). If a user starts a payment signing,
	 * and during the polling phase if a user refresh the page, we use the SignAndPayPopover to continue the same payment signing, hence we open it automatically when a payment
	 * signing process gets interuppted due to a page refresh.
	 * More Info on Slack thread: https://bokio.slack.com/archives/C01NX3ZMSE4/p1717053838598069
	 *  */
	React.useEffect(() => {
		if (readPaymentSigningProgressInfoFromLocalStorage()) {
			setIsPopoverOpened(true);
		}
	}, []);

	const renderBadge = () => {
		const renderCounterBadge = (c: string | number) => (
			<span className={styles.badgeWrapper}>
				<CounterBadge size="xsmall" border>
					{c}
				</CounterBadge>
			</span>
		);

		switch (true) {
			case shouldRenderContinueOnboarding:
				return bbaNotInterestedPref && !bbaNotInterestedPref.BbaTopBarOnboardingNotInterested?.Value
					? renderCounterBadge(1)
					: null;
			case currentUserPermission.CanSignPayment && signablePaymentCount > 0:
				return renderCounterBadge(signablePaymentCount > 9 ? "9+" : `${signablePaymentCount}`);
			default:
				return null;
		}
	};

	const renderPopoverContent = () => {
		switch (true) {
			case shouldRenderStartOnboarding:
			case shouldRenderContinueOnboarding:
				return (
					!!mirOnboardingStatus && (
						<BbaOnboardingPopover
							refIncludingToggle={popoverWrapperRef}
							testId="BbaOnboardingPopover"
							visible={isPopoverOpened}
							arrowAnchor={iconRef.current}
							onboardingStatus={mirOnboardingStatus}
							onNotInterest={() => {
								closePopover();
								setShowFeedbackModal(true);
								setBbaOnboardingPreference({
									// This is not company specific just to avoid spamming user with multiple companies but all without BBA.
									isCompanySpecific: false,
									value: { [UserPreferenceType.BbaTopBarOnboardingNotInterested]: { Value: true } },
								});
							}}
							onClose={closePopover}
						/>
					)
				);
			case shouldRenderSignAndPay:
				return (
					<RenderRequest request={paymentValidationRulesRequest} whenLoading={null}>
						{paymentValidationRules => (
							<SignAndPayPopover
								refIncludingToggle={popoverWrapperRef}
								testId="BbaTopBarButton_SignAndPayPopover"
								visible={isPopoverOpened}
								arrowAnchor={iconRef.current}
								onClose={closePopover}
								paymentValidationRules={paymentValidationRules}
							/>
						)}
					</RenderRequest>
				);
			default:
				return null;
		}
	};

	const renderNoticePopover = () => {
		switch (true) {
			case shouldRenderSignAndPay:
				return (
					<NoticePopover
						refIncludingToggle={popoverWrapperRef}
						testIdOfCloseButton="BbaTopBarButton_NoticePopover"
						isOpen={isQuickAccessNoticePopoverOpened}
						message={generalLang.BBA_TopBarButton_NoticePopoverContent_QuickAccess}
						align={isMobile ? "middle" : "right"}
						direction={"down"}
						arrowAnchor={iconRef.current}
						onClose={reason => {
							if (reason === "close") {
								closeQuickAccessNoticePopover();
							}
						}}
					/>
				);
			default:
				return null;
		}
	};

	const shouldShowButton = shouldRenderSignAndPay || shouldRenderStartOnboarding || shouldRenderContinueOnboarding;

	if (!shouldShowButton) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div ref={popoverWrapperRef}>
				<TopBarButton
					className={mergeClassNames(styles.button)}
					testId="BBATopBarButton"
					onClick={() => {
						setIsPopoverOpened(isOpen => !isOpen);
						if (isQuickAccessNoticePopoverOpened) {
							closeQuickAccessNoticePopover();
						}
						addMetric(
							AnalyticsEventCategory.Bba,
							shouldRenderSignAndPay ? "ShowSignAndPayPopover" : "ShowBbaOnboardingPopover",
						);
					}}
				>
					<span ref={iconRef}>
						<Icon
							name="bba"
							size="28"
							color={backOfficeMode ? "lightGrey" : "darkGrey"}
							className={styles.buttonIcon}
						/>
					</span>
					{renderBadge()}
				</TopBarButton>
				{renderNoticePopover()}
			</div>

			{renderPopoverContent()}

			<FeedbackModal
				visible={showFeedbackModal}
				request={sendFeedbackRequest}
				onClose={() => setShowFeedbackModal(false)}
				onSendFeedback={text => {
					sendFeedback(text, "Feedback for Not Interested in BBA", "bba-onboarding");
				}}
			/>
		</div>
	);
};

export const BbaTopBarButton: React.FC<BbaTopBarButtonProps> = ({ backOfficeMode }) => {
	const { companyInfo } = useCompanyInfo();
	const featureAvailability = useFeatureAvailability();
	const { isFeatureEnabled } = useFeatureToggle();
	const { hasActiveBusinessAccount } = useActiveBankConnection();
	const { companyUserPermissions } = useCompanyUser();
	const { currentUserPermission, mirOnboardingStatus } = usePaymentContext();

	// CanSignPayment will be false if the user lacks UploadBank permission
	const shouldRenderSignAndPay = hasActiveBusinessAccount && currentUserPermission.CanSignPayment;

	const hasPermissionToOnboardBba = !!(
		companyUserPermissions?.CompanySettings &&
		// A user who lacks the UploadBank permission will get stuck in fourth step of onboarding
		companyUserPermissions?.UploadBank &&
		featureAvailability.BokioBusinessAccount
	);

	const shouldRenderStartOnboarding = !!(
		hasPermissionToOnboardBba &&
		mirOnboardingStatus &&
		canStartBbaOnboarding(mirOnboardingStatus.State) &&
		false && // False until support for Svea is built for this button
		isFeatureEnabled(m.Core.Features.LegacyBokioBusinessAccount)
	);

	const shouldRenderContinueOnboarding = !!(
		(hasPermissionToOnboardBba && mirOnboardingStatus && canContinueBbaOnboarding(mirOnboardingStatus.State) && false) // False until support for Svea is built for this button
	);

	const shouldRender =
		featureAvailability.BokioBusinessAccount &&
		companyInfo &&
		companyInfo.CompanySystem === m.Entities.CompanySystem.Default &&
		(shouldRenderStartOnboarding || shouldRenderContinueOnboarding || shouldRenderSignAndPay);

	if (!shouldRender) {
		return null;
	}

	return (
		<BbaTopBarButtonInner
			shouldRenderSignAndPay={shouldRenderSignAndPay}
			shouldRenderStartOnboarding={shouldRenderStartOnboarding}
			shouldRenderContinueOnboarding={shouldRenderContinueOnboarding}
			backOfficeMode={backOfficeMode}
		/>
	);
};
