// extracted by mini-css-extract-plugin
export var selectCompany = "ki";
export var companyListBox = "li";
export var title = "mi";
export var logo = "ni";
export var content = "oi";
export var input = "ti";
export var logOutBox = "ui";
export var user = "Js";
export var userName = "wi";
export var userEmail = "xi";