type ExpressionType = string | object | boolean | undefined | null | number;

export default function classes(styles: object, ...expressions: ExpressionType[]) {
	const result: string[] = [];

	expressions.forEach(expression => {
		if (!expression) {
			return;
		}

		if (typeof expression === "string") {
			result.push(expression);
			return;
		} else if (typeof expression === "object") {
			Object.keys(expression).forEach(name => {
				if (expression[name]) {
					result.push(name);
				}
			});
		}
	});

	return result.map(local => styles[local]).join(" ");
}

export function mergeClassNames(...expressions: ExpressionType[]) {
	const classnames: (string | number)[] = [];

	for (let i = 0; i < expressions.length; i++) {
		const expression = expressions[i];
		if (!expression) {
			continue;
		}

		if (typeof expression === "string" || typeof expression === "number") {
			classnames.push(expression);
		} else if (Array.isArray(expression) && expression.length) {
			const inner = mergeClassNames(...expression);
			if (inner) {
				classnames.push(inner);
			}
		} else if (typeof expression === "object") {
			Object.keys(expression).forEach(key => {
				classnames.push(key);
			});
		}
	}

	return classnames.join(" ");
}
