import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import { AnalyticsEventCategory, useMetric } from "../useMetric/useMetric";
import { useOnUnmount } from "../useOnUnmount/useOnUnmount";
import { useScript } from "../useScript/useScript";

/**
 * @returns Props meant to be passed to an `<iframe>` holding YouTube video, and the actual player JS object ref.
 */
export const useYoutubeApi = (
	youtubeEmbedLink: string,
	onStateChange?: YT.PlayerEventHandler<YT.OnStateChangeEvent>,
) => {
	useScript("https://www.youtube.com/player_api");
	const [addMetric] = useMetric();
	const player = React.useRef<YT.Player | undefined>();
	const endedAtTime = React.useRef<number | undefined>();

	const getIframeId = React.useCallback(() => {
		return `video-iframe_${uuidv4()}`;
	}, []);
	const iframeId = getIframeId();

	const getIframeSrc = React.useCallback(() => {
		const url = new URL(youtubeEmbedLink);

		// SS 2021-06-24:
		// The search params and player vars are duplicated
		// just to make it easier to follow for someone who
		// haven't come back to YouTube API for a long time.
		// There are just too many places where you can set things...
		// so let's set it everywhere 🤷‍♂️

		// Required for listening YouTube iframe events.
		// https://developers.google.com/youtube/player_parameters#enablejsapi
		url.searchParams.set("enablejsapi", "1");

		// Don't recommend videos that come from other channels.
		// https://developers.google.com/youtube/player_parameters#rel
		url.searchParams.set("rel", "0");

		return url.toString();
	}, [youtubeEmbedLink]);
	const iframeSrc = getIframeSrc();

	const createPlayer = () => {
		player.current = new window["YT"].Player(iframeId, {
			playerVars: { rel: 0, enablejsapi: 1 },
			events: {
				onStateChange: e => {
					// Stoping the video once it reaches the end state prevents related videos from showing
					if (e.data === YT.PlayerState.ENDED && player.current) {
						endedAtTime.current = player.current.getCurrentTime();
						player.current.stopVideo();
					}
					onStateChange?.(e);
				},
			},
		});
	};

	window["onYouTubeIframeAPIReady"] = () => createPlayer();
	React.useEffect(() => {
		// If the api is already loaded we should get the player reference from the window
		window["YT"] && createPlayer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useOnUnmount(player => {
		const timeWatched = player.current?.getCurrentTime?.() ?? 0;
		addMetric(
			AnalyticsEventCategory.Videos,
			// TODO: Consider stripping URL params before setting back to metrics or just use video id instead.
			youtubeEmbedLink,
			"TimeWatched",
			endedAtTime.current || timeWatched,
		);
	}, player);

	return { iframeId, iframeSrc, player: player.current };
};
