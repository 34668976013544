import { toDataURL } from "qrcode";
import * as React from "react";

interface QrCodeProps {
	data: string;
	width?: number;
	margin?: number;
	errorCorrectionLevel?: "L" | "M" | "Q" | "H";
}

export const QrCode = ({ data, width = 200, margin = 3, errorCorrectionLevel = "L" }: QrCodeProps) => {
	const [qrCodeImageSrc, setQrCodeImageSrc] = React.useState<string>();

	React.useEffect(() => {
		let stillMounted = true;
		toDataURL(data, { width, margin, errorCorrectionLevel })
			.then(url => stillMounted && setQrCodeImageSrc(url))
			.catch(() => stillMounted && setQrCodeImageSrc(undefined));

		return () => {
			stillMounted = false;
		};
	}, [data, width, margin, errorCorrectionLevel]);

	// Use Canvas in the future?
	return <img data-testid="bankIdQrCodeImg" src={qrCodeImageSrc}></img>;
};
