import * as React from "react";

import { useTutorialContext } from "@bokio/contexts/TutorialContext/TutorialContext";

import { FloatingTutorialButton } from "./FloatingTutorialButton/FloatinTutorialButton";
import { FloatingTutorialModal } from "./FloatingTutorialModal/FloatingTutorialModal";

import * as styles from "./floatingTutorial.scss";

export const FloatingTutorial = () => {
	const { currentTutorial } = useTutorialContext();
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	return currentTutorial ? (
		<div className={styles.help}>
			<FloatingTutorialButton isOpened={isOpen} onClick={() => setIsOpen(state => !state)} />
			<FloatingTutorialModal visible={isOpen} activeTutorial={currentTutorial} />
		</div>
	) : null;
};
