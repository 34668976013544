import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import Field from "./Field/Field";
import { FormContext } from "./Form";
import LabelFor from "./LabelFor/LabelFor";

import type { FormContextValue } from "./Form";
import type { InputFieldProps } from "@bokio/elements/Form/InputField";

import * as styles from "./form.scss";

export interface SelectFieldOption {
	hidden?: boolean;
	disabled?: boolean;
	value: string | number;
	label: string | number | React.ReactNode;
	className?: string;
	testId?: string;
}

interface SelectFieldProps extends Omit<InputFieldProps, "onClick"> {
	label?: string;
	hint?: string;
	options: SelectFieldOption[];
	predefinedDefaultLabel?: string;
	showBlankDefault?: boolean;
	labelTestId?: string;
	onClick?: (e: React.MouseEvent<HTMLSelectElement>) => void;
	mandatory?: boolean;
}

export class SelectField extends React.Component<SelectFieldProps> {
	static contextType = FormContext;
	declare context: React.ContextType<React.Context<FormContextValue>>;

	render() {
		const {
			value,
			onChange,
			options,
			disabled,
			label,
			labelClassName,
			hint,
			errors,
			showBlankDefault,
			autoFocus,
			wrapperClassName,
			testId,
			predefinedDefaultLabel,
			labelTestId,
			tooltip,
			onClick,
			placeholder,
			mandatory = false,
		} = this.props;
		// MQ 2018-10-05
		// We need to set value to empty if it is not in options because select tag will choose first option as default value
		// MQ 2018-10-10
		// We has to use == because we might use not-string value for option or selected value,
		// select/option will give us back string, string coercion below solves our problem
		const selectedValue = options.some(option => option.value == value) ? value : "";

		return (
			<Field className={wrapperClassName}>
				<LabelFor
					label={label}
					hint={hint}
					errors={errors && errors.errors}
					testId={labelTestId}
					tooltip={tooltip}
					className={labelClassName}
					mandatory={mandatory}
				>
					<div className={styles.selectWrapper}>
						<select
							data-testid={testId}
							autoFocus={autoFocus}
							onChange={e => onChange && onChange(e.currentTarget.value)}
							className={mergeClassNames(
								styles.field__input,
								styles.select,
								placeholder && !selectedValue && styles.placeholder,
							)}
							value={selectedValue}
							disabled={this.context.disableAllFields || disabled}
							onClick={onClick}
						>
							{showBlankDefault && <option key="empty" disabled hidden />}
							{placeholder && !showBlankDefault && (
								<option key="default" value="" hidden disabled>
									{placeholder}
								</option>
							)}
							{predefinedDefaultLabel && (
								<option key="default" hidden={true} disabled={true} value="">
									{predefinedDefaultLabel}
								</option>
							)}
							{options.map((item, index) => (
								<option
									key={`${item.value}-${index}`}
									value={item.value}
									disabled={item.disabled}
									hidden={item.hidden}
									className={mergeClassNames(
										item.hidden && styles.hidden,
										item.disabled && styles.disabled,
										placeholder && styles.option,
									)}
									data-testid={item.testId}
								>
									{item.label}
								</option>
							))}
						</select>
					</div>
				</LabelFor>
			</Field>
		);
	}
}
