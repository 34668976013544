import * as React from "react";

import { useLoader } from "@bokio/hooks/useLoader/useLoader";
import { toEnvelope } from "@bokio/mobile-web-shared/core/utils/loaderHelpers";
import { noop } from "@bokio/shared/utils";

import type { Envelope } from "@bokio/mobile-web-shared/core/model/model";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

type Endpoint<TParameters extends unknown[], TResult, TError> = (
	...parameters: TParameters
) => Promise<Envelope<TResult, TError>>;

export type ApiPollingStopConditionType<TResult, TError, TParameters> = (context: {
	data: TResult | null;
	error: TError | null;
	errorCounter: number;
	numberOfTries: number;
	startTime: Date;
	apiError: boolean;
	params: TParameters;
}) => boolean;

interface Options<TResult, TError, TParameters> {
	onSuccess?: (data: TResult, params: TParameters) => void;
	onError?: (error: TError, errorMessage: string, params: TParameters) => void;
	onApiError?: (error: unknown, params: TParameters) => void;
}

interface PollingOptions<TParameters, TResult, TError> {
	/**
	 *  Callback for checkout stop condition after you got a response from API, runs after `effect`, the poller will stop scheduling next poll if the callback returns true.
	 */
	stopCondition: ApiPollingStopConditionType<TResult, TError, TParameters> | "defaultTimeBasedCondition";
	/**
	 * Callback for handling API response, runs before stopCondition
	 */
	effect: (
		data: TResult | null,
		error: TError | null,
		errorCounter: number,
		apiError: boolean,
		params: TParameters,
	) => void;
	/**
	 * (Optional) Cleanup function run when either `stopPolling` is called or the stop condition fulfilled.
	 * The running order is `effect` -> `stopCondition` -> (if stop condition reached) onStopped.
	 */
	onStopped?: () => void;
	/**
	 * (Optional) The timeout that will be passed to `setTimeout`, default to 1000.
	 */
	interval?: number;
}

type UseLazyApi<TParameters extends unknown[], TResult, TError> = [
	(...parameters: TParameters) => Promise<Envelope<TResult, TError>>,
	RequestState<Envelope<TResult, TError>>,
	() => void,
];

let debuggingOrder = 0;
let debuggingFunction: (json: string) => Promise<void>;

export const debugApiCallsWith = (fn: (json: string) => Promise<void>) => {
	debuggingFunction = fn;
	debuggingOrder = 0;
};

/**
 * A hook for calling an api endpoint on an action
 *
 *@example
 *const [execute, request, reset] = useLazyApi(
 *	proxy.VerificationController.Create.Post,
 *	{
 *		onSuccess: data => console.log("data from success envelope");
 *		onError: error => console.log("error from error envelope")
 *	}
 *);
 *
 * <Button onClick={() => execute(companyId, verification)} />
 *
 * @param endpoint Enpoint from proxy, must return envelope. (Use toEnvelopeEndpoint if it doesn't).
 * @param options SideEffects which fire on success and on error with proper types from envelope.
 * @deprecated use useLazyApi from mobile-web-shared folder instead
 */
export function useLazyApi<TParameters extends unknown[], TResult, TError>(
	endpoint: Endpoint<TParameters, TResult, TError>,
	options?: Options<TResult, TError, TParameters>,
): UseLazyApi<TParameters, TResult, TError> {
	const optionsRef = React.useRef(options);
	// Hacky way to get the live version of options
	// Not technically correct for all kinds of closure
	// but should cover the most common use case
	optionsRef.current = options;
	// Do the same for the endpoint as well.
	// Since sometimes people assisgn a wrapper endpoint directly
	// where the function's identity changes on every render, unlike the majority of functions in proxy.ts.
	const endpointRef = React.useRef(endpoint);
	endpointRef.current = endpoint;

	const onSuccess = React.useCallback((data, params) => {
		data.Success && optionsRef.current?.onSuccess?.(data.Data, params);
		!data.Success && optionsRef.current?.onError?.(data.Error, data.ErrorMessage, params);
	}, []);
	const onError = React.useCallback((err, params) => {
		optionsRef.current?.onApiError?.(err, params);
	}, []);

	const endpointForLoader = React.useCallback(params => {
		return endpointRef.current(...params);
	}, []);

	const { request, load, reset } = useLoader<TParameters, Envelope<TResult, TError>>({
		endpoint: endpointForLoader,
		onSuccess,
		onError,
	});

	const execute = React.useCallback(
		async (...parameters: TParameters) => {
			let localOrder = 0;
			if (!!debuggingFunction) {
				debuggingOrder += 1;
				localOrder = debuggingOrder;
			}

			if (!!debuggingFunction) {
				await debuggingFunction(JSON.stringify({ debuggingOrder: localOrder, requestParameters: parameters }));
			}

			const response = await load(parameters);

			if (!!debuggingFunction) {
				await debuggingFunction(JSON.stringify({ debuggingOrder: localOrder, response }));
			}

			return response;
		},
		[load],
	);

	return [execute, request, reset];
}

/**
 * A hook for calling an api endpoint as an effect of mounting
 *
 *@example
 *const [request, refresh] = useApi(
 *	proxy.VerificationController.GetAllRows.Get,
 *	[companyInfo.Id],
 *	{
 *		onSuccess: data => console.log("data from success envelope"),
 *		onError: error => console.log("error from error envelope")
 *	}
 *);
 *
 * @param endpoint Endpoint from proxy, must return envelope. (Use toEnvelopeEndpoint if it doesn't).
 * @param parameters An array with the arguments to the enpoint in order of appearance.
 * @param options SideEffects which fire on success and on error with proper types from envelope.
 * @deprecated use useApi from mobile-web-shared folder instead
 */
export function useApi<TParameters extends unknown[], TResult, TError>(
	endpoint: Endpoint<TParameters, TResult, TError>,
	parameters: TParameters,
	options?: Options<TResult, TError, TParameters>,
): [RequestState<Envelope<TResult, TError>>, () => void] {
	const [execute, request] = useLazyApi(endpoint, options);

	const refresh = React.useCallback(() => {
		execute(...parameters).catch(noop);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [execute, JSON.stringify(parameters)]);

	React.useEffect(() => {
		refresh();
	}, [refresh]);

	return [request, refresh];
}

/**
 * @deprecated use toEnvelopeEndpoint from mobile-web-shared folder instead
 */
export function toEnvelopeEndpoint<P extends unknown[], R>(endpoint: (...parameters: P) => Promise<R>) {
	return (...parameters: P) => endpoint(...parameters).then(toEnvelope);
}

export type UseApiPollingReturn<TParameters, TResult, TError> = {
	startPolling: (params: TParameters) => void;
	stopPolling: () => void;
	isPolling: boolean;
	request: RequestState<Envelope<TResult, TError>>;
};

/**
 *
 * @param seconds After how many seconds we will stop the polling
 * @param maxErrors How many consecutive errors we allow before we stop polling
 */
export function apiPollingTimebasedStopCheck<TResult, TError, TParameters>(
	seconds = 120,
	maxErrors = 3,
): ApiPollingStopConditionType<TResult, TError, TParameters> {
	return context =>
		(!!(context.error || context.apiError) && context.errorCounter >= maxErrors) ||
		+Date.now() - +context.startTime > seconds * 1000;
}

/**
 * Hook for getting a poller that helps you poll an API until it reached some stop condition
 *
 * @example
 * 	const { startPolling, stopPolling, isPolling } = useApiPolling(
 *  proxy.Bank.PaymentController.ConfirmPayment2.Post,
 *  {
 *     stopCondition: (data, error, errorCounter, params) =>
 *       (!!error && errorCounter > 10) || (data !== null && data.RequestStatus !== RequestStatus.Pending),
 *     effect: (data, error, errorCounter, params) => {
 *       // Handle effects here
 *     }
 *  }
 * @deprecated use useApiPolling from mobile-web-shared folder instead
 */
export function useApiPolling<TParameters extends unknown[], TResult, TError>(
	/**
	 * Envelope endpoint as in {@link useLazyApi}'s endpoint
	 */
	endpoint: Endpoint<TParameters, TResult, TError>,
	options: PollingOptions<TParameters, TResult, TError>,
): UseApiPollingReturn<TParameters, TResult, TError> {
	const internalIsPollingRef = React.useRef(false);
	const [isPolling, setIsPollingState] = React.useState(false);
	const optionsRef = React.useRef(options);
	optionsRef.current = options;

	const isMounted = React.useRef(false);
	React.useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	/**
	 * We need to shadow the isPolling value between component state and ref here
	 * because the state one is mainly for rerendering component tree,
	 * while the ref one is for catching race conditions.
	 */
	const setIsPolling = (state: boolean) => {
		internalIsPollingRef.current = state;
		// The polling might resolve after component unmount and we only want to update state if the component is mounted.
		if (isMounted.current) {
			setIsPollingState(state);
		}
	};

	const timeoutRef = React.useRef<number>();
	const isAbortedRef = React.useRef(false);
	const errorCountRef = React.useRef(0);
	const triesRef = React.useRef(0);
	const startTimeRef = React.useRef<Date>();
	const interval = optionsRef.current.interval ?? 1000;

	const reset = React.useCallback(() => {
		setIsPolling(false);
		clearTimeout(timeoutRef.current);
		timeoutRef.current = undefined;
		isAbortedRef.current = false;
		errorCountRef.current = 0;
		triesRef.current = 0;
		startTimeRef.current = undefined;
	}, []);

	const stopPolling = React.useCallback(() => {
		setIsPolling(false);

		optionsRef.current.onStopped?.();

		clearTimeout(timeoutRef.current);
		timeoutRef.current = undefined;
		isAbortedRef.current = true;
	}, []);

	const [execute, request] = useLazyApi(endpoint, {
		onSuccess: (data, params) => {
			handleEffect(data, null, false, params);
		},
		onError: (error, errorMessage, params) => {
			errorCountRef.current += 1;
			handleEffect(null, error, false, params);
		},
		onApiError: (error, params) => {
			errorCountRef.current += 1;
			handleEffect(null, null, true, params);
		},
	});

	function handleEffect(data: TResult | null, error: TError | null, apiError: boolean, params: TParameters) {
		triesRef.current++;

		if (!isAbortedRef.current) {
			optionsRef.current.effect(data, error, errorCountRef.current, apiError, params);
		}

		const currentStopCondition =
			optionsRef.current.stopCondition === "defaultTimeBasedCondition"
				? apiPollingTimebasedStopCheck()
				: optionsRef.current.stopCondition;
		if (
			!currentStopCondition({
				data: data,
				error: error,
				errorCounter: errorCountRef.current,
				numberOfTries: triesRef.current,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				startTime: startTimeRef.current!,
				apiError: apiError,
				params: params,
			}) &&
			!isAbortedRef.current
		) {
			const handler: TimerHandler = () => {
				execute(...params);
			};
			timeoutRef.current = setTimeout(handler, interval);
			return;
		}

		// This ref check is for handling the race condition where the callback of API call runs after stop
		if (internalIsPollingRef.current) {
			optionsRef.current.onStopped?.();
		}
		reset();
	}

	const startPolling = React.useCallback(
		(parameters: TParameters) => {
			reset();

			startTimeRef.current = new Date();
			setIsPolling(true);
			execute(...parameters);
		},
		[execute, reset],
	);

	const poller = React.useMemo(() => {
		return { startPolling, stopPolling, isPolling, request };
	}, [isPolling, startPolling, stopPolling, request]);

	return poller;
}
