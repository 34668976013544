import { Link } from "@bokio/elements/Link/Link";

import type * as React from "react";

import * as styles from "./splitButton.scss";

export interface SplitButtonItemProps {
	testId?: string;
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>, value: string) => void;
	value: string;
	route?: string;
	external?: string;
	children?: React.ReactNode;
}

export const SplitButtonItem = (props: SplitButtonItemProps) => {
	const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		if (props.onClick) {
			props.onClick(e, props.value);
		}
	};
	return (
		<Link
			testId={props.testId}
			style="none"
			onClick={onClick}
			className={styles.link}
			route={props.route}
			external={props.external}
		>
			{props.children}
		</Link>
	);
};
