// extracted by mini-css-extract-plugin
export var popoverInUpAnimation = "kI";
export var popoverOutUpAnimation = "lI";
export var popoverInDownAnimation = "mI";
export var popoverOutDownAnimation = "pI";
export var __popover_scss__popoverInUpAnimation = "kI";
export var __popover_scss__popoverOutUpAnimation = "lI";
export var __popover_scss__popoverInDownAnimation = "mI";
export var __popover_scss__popoverOutDownAnimation = "pI";
export var popoverDirectionUp = "sY";
export var popoverDirectionDown = "GY";
export var popover = "HY";
export var popoverMinWidth = "RCa";
export var popoverInUp = "IY HY";
export var popoverOutUp = "JY HY";
export var popoverInDown = "RY HY";
export var popoverOutDown = "SY HY";
export var stretch = "EFa";
export var variantDefault = "TCa";
export var variantNotice = "iFa";
export var borderRadiusSmall = "jFa";
export var borderRadiusMedium = "kFa";
export var arrowColorDefault = "lFa";
export var arrowColorNotice = "mFa";
export var arrow = "nFa";
export var arrowUp = "oFa";
export var arrowDown = "pFa";