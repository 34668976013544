import * as React from "react";

import {
	clearPaymentSigningProgressInfoFromLocalStorage,
	readPaymentSigningProgressInfoFromLocalStorage,
	readSveaAuthPollingTokenFromLocalStorage,
	savePaymentSigningProgressInfoToLocalStorage,
} from "@bokio/components/SveaBankIdAuthModal/sveaPollingUtils";
import { useAppContext } from "@bokio/contexts/AppContext/AppContext";
import { Popover } from "@bokio/designsystem/components/Popover/Popover";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { AnalyticsEventCategory, useMetric } from "@bokio/hooks/useMetric/useMetric";
import { useSignAndPay } from "@bokio/hooks/useSignAndPay/useSignAndPay";
import { noop } from "@bokio/shared/utils";

import { SignAndPayFooter } from "../SignAndPayModal/SignAndPayFooter/SignAndPayFooter";
import { SignAndPayHeader } from "../SignAndPayModal/SignAndPayHeader/SignAndPayHeader";
import { SignAndPayModalInner } from "../SignAndPayModal/SignAndPayModal";

import type { useSignAndPayReturn } from "@bokio/hooks/useSignAndPay/useSignAndPay";
import type * as m from "@bokio/mobile-web-shared/core/model/model";

import * as styles from "./signAndPayPopover.scss";

type PaymentValidationRulesDto = m.Bokio.Bank.Contract.Dtos.PaymentValidationRulesDto;
export interface SignAndPayPopoverProps {
	refIncludingToggle: React.RefObject<HTMLDivElement> | null;
	visible: boolean;
	arrowAnchor?: HTMLElement | null;
	onClose: () => void;
	paymentValidationRules: PaymentValidationRulesDto;
	testId?: string;
}

type SignAndPayPopoverInnerProps = SignAndPayPopoverProps & useSignAndPayReturn;

const SignAndPayPopoverInner = (props: SignAndPayPopoverInnerProps) => {
	const { isBankAuthModalOpen } = useAppContext();
	const onClose = () => {
		props.stopPollingAndCancelSigning();
		clearPaymentSigningProgressInfoFromLocalStorage();
		props.onClose();
	};

	const handleOnSignAndPlayClick = async () => {
		savePaymentSigningProgressInfoToLocalStorage({
			hasInitiated: true,
			isPolling: false,
			SuccessfulPaymentIds: undefined,
			paymentGroupIds: props.selectedPaymentGroupIds,
			signingToken: undefined,
		});
		await props.executeAuthorize();
	};

	return (
		<Popover
			refIncludingToggle={props.refIncludingToggle}
			isOpen={props.visible}
			align={"middle"}
			direction={"down"}
			borderRadius={"medium"}
			showArrow
			arrowAnchor={props.arrowAnchor}
			onClose={onClose}
			testId={props.testId}
			clickOutsideCondition={() => !isBankAuthModalOpen}
		>
			<div className={styles.popoverContent}>
				<SignAndPayHeader
					showTitleAndBalance={true}
					availableBalanceString={props.availableBalance}
					onClose={onClose}
				/>
				<div className={styles.signAndPayContent}>{props.renderSignAndPayContent(onClose)}</div>
				<SignAndPayFooter
					style="popover"
					disabled={props.disableSign}
					onSignAndPayClick={handleOnSignAndPlayClick}
					multipleCurrencies={props.multipleCurrencies}
					totalSum={props.totalSum}
					selectedPayments={props.selectedPaymentGroupIds.length}
					isLoading={props.isAnyRequestLoading}
				/>
			</div>
		</Popover>
	);
};

export const SignAndPayPopover: React.FC<SignAndPayPopoverProps> = props => {
	const { isMobile } = useDeviceQuery();
	const [addMetric] = useMetric();
	const { openBankAuthenticationModal } = useAppContext();

	const signAndPayState = useSignAndPay({
		paymentValidationRules: props.paymentValidationRules,
		onSubmit: () => {
			addMetric(AnalyticsEventCategory.Bba, "SignedPayment", "FromSignAndPayPopover");
			props.onClose();
		},
	});

	const onClose = () => {
		if (!signAndPayState.isPolling && !signAndPayState.authorizeRequest.isLoading) {
			props.onClose();
		}
	};

	React.useEffect(() => {
		if (!props.visible) {
			return;
		}
		signAndPayState.refreshPaymentListAndSummary();

		const hasInprogressPaymentSigning = readPaymentSigningProgressInfoFromLocalStorage();
		if (readSveaAuthPollingTokenFromLocalStorage() && hasInprogressPaymentSigning?.hasInitiated) {
			openBankAuthenticationModal();
		} else if (hasInprogressPaymentSigning && hasInprogressPaymentSigning.paymentGroupIds) {
			signAndPayState.setSelectedPaymentGroupIds(hasInprogressPaymentSigning.paymentGroupIds);
			// If there is a interrupted polling session saved to local storage we resume it here.
			// we check for mobile because, in mobile we display the sign & pay modal instead of pop-over. In those two components we use the hook
			// useSignAndPay differently. Hence, we need to resume the polling process here if its not mobile.
			if (hasInprogressPaymentSigning.isPolling && !isMobile) {
				signAndPayState.executeAuthorize();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.visible]);

	return (
		<>
			{isMobile ? (
				<SignAndPayModalInner
					{...props}
					{...signAndPayState}
					showTransactionsAndPaymentsLinks
					onSubmit={noop}
					onClose={onClose}
				/>
			) : (
				<SignAndPayPopoverInner {...props} {...signAndPayState} onClose={onClose} />
			)}
		</>
	);
};
