import * as React from "react";

import { Article } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import KeyboardListener from "./KeyboardListener";

import * as styles from "./modal.scss";

interface ModalBaseProps {
	visible: boolean;
	width?: ModalWidth;
	className?: string;
	disableEasyClosing?: boolean;
	onBackgroundClick: () => void;
	onCloseButtonClick: () => void;
	onEscape: () => void;
	title?: React.ReactNode;
	children: React.ReactNode;
	testId?: string;
	autoFocus?: boolean | string;
	alwaysMounted?: boolean;
	noHeaderTitle?: boolean;
}

const getWidth = (width: ModalWidth = "medium") => {
	switch (width) {
		case "verySmall":
			return styles.modalVisibleVerySmall;
		case "small":
			return styles.modalVisibleSmall;
		case "medium":
			return styles.modalVisibleMedium;
		case "wide":
			return styles.modalVisibleWide;
		case "extraWide":
			return styles.modalVisibleExtraWide;
	}
};

export type ModalWidth = "verySmall" | "small" | "medium" | "wide" | "extraWide";
export type MultiFocusableElement = HTMLInputElement | HTMLAreaElement | HTMLButtonElement | HTMLSelectElement;

const getModalClassName = (width: ModalWidth | undefined, modalClassName?: string) => {
	const definedWidth = width && getWidth(width);
	const modalVisibleStyleName = width ? definedWidth : styles.modalVisible;
	return mergeClassNames(modalVisibleStyleName, modalClassName);
};

const getTabbableElements = (
	ref: HTMLDivElement | null,
	visible: boolean,
	prevElement: MultiFocusableElement | null,
	elementToFocus?: string,
) => {
	if (ref && visible) {
		const focusableElementsList = [
			...new Set([
				...ref.querySelectorAll<MultiFocusableElement>(elementToFocus || "__not_exist_element"),
				...ref.querySelectorAll<MultiFocusableElement>(
					'a[href]:not([disabled]), button:not(.modalCloseBtn), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
				),
			]),
		];
		if (focusableElementsList.length !== 0) {
			const firstFocusableEl = focusableElementsList[0];
			const lastFocusableEl = focusableElementsList[focusableElementsList.length - 1];

			/**
			 * NOTE: MQ 2020-10-15
			 * We cannot focus element which is currently `display: none` or `visibility: hidden`
			 * our modals are opened with 350ms transition -> we have to wait > 350ms before setting focus to any inside elements
			 */
			setTimeout(() => !ref.contains(document.activeElement) && firstFocusableEl.focus(), 350);

			// Traps focus within modal until close
			const trapTabs = (e: KeyboardEvent) => {
				if (e.key === "Tab" || e.keyCode === 9) {
					// SHIFT TAB
					if (e.shiftKey) {
						if (document.activeElement === firstFocusableEl) {
							lastFocusableEl.focus();
							e.preventDefault();
						}
						// TAB
					} else {
						if (document.activeElement === lastFocusableEl) {
							firstFocusableEl.focus();
							e.preventDefault();
						}
					}
				}
			};
			ref.addEventListener("keydown", trapTabs);
			return () => {
				ref && ref.removeEventListener("keydown", trapTabs);
				prevElement && prevElement.focus();
			};
		}
	}
	return;
};

const ModalBase = ({
	alwaysMounted,
	autoFocus = true,
	width,
	className,
	visible,
	disableEasyClosing,
	onBackgroundClick,
	onEscape,
	testId,
	title,
	noHeaderTitle,
	onCloseButtonClick,
	children,
}: ModalBaseProps) => {
	const modalVisibleStyleName = getModalClassName(width, className);
	const modalRef = React.useRef<HTMLDivElement | null>(null);
	const [previousFocusedElement, setPreviousFocusedElement] = React.useState<MultiFocusableElement | null>(null);

	const [shouldRender, setShouldRender] = React.useState(alwaysMounted);

	React.useEffect(() => {
		visible && setShouldRender(true);
	}, [visible]);

	const handleAnimationEnd = () => !visible && !alwaysMounted && setShouldRender(false);

	React.useEffect(() => {
		if (shouldRender && autoFocus) {
			const currentActive = document.activeElement as MultiFocusableElement;
			const elementToFocus = typeof autoFocus === "string" ? autoFocus : undefined;

			setPreviousFocusedElement(currentActive);
			return getTabbableElements(modalRef.current, visible, currentActive, elementToFocus);
		}
		return;
	}, [visible, autoFocus, shouldRender]);

	const handleClose = (handler: () => void, elementToFocus: MultiFocusableElement | null) => {
		handler();
		elementToFocus && elementToFocus.focus();
	};

	const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
		if (disableEasyClosing) {
			return;
		}
		// Only close the modal if we clicked on the background
		if (e.target !== e.currentTarget) {
			return;
		}
		// Dont close on right mouse button
		if (e.button === 2) {
			return;
		}
		handleClose(onBackgroundClick, previousFocusedElement);
	};

	if (!shouldRender) {
		return null;
	}

	return (
		<KeyboardListener onEscape={() => handleClose(onEscape, previousFocusedElement)}>
			<div className={visible ? styles.wrapperVisible : styles.wrapper} data-testid={testId}>
				<Article resetHeadingLevelTo={1}>
					<div className={styles.wrapperContent}>
						<div data-testid="Modal2Background" className={styles.background} onMouseDown={handleMouseDown} />
						<div
							onAnimationEnd={handleAnimationEnd}
							ref={ref => (modalRef.current = ref)}
							className={mergeClassNames(modalVisibleStyleName, visible ? styles.animateIn : styles.animateOut)}
							key="modal"
						>
							{title && (
								<div className={styles.header}>
									{noHeaderTitle ? (
										<div className={styles.heading}>{title}</div>
									) : (
										<h1 className={styles.heading}>{title}</h1>
									)}

									{!disableEasyClosing && (
										<div className={styles.closeContainer}>
											<button
												onClick={() => handleClose(onCloseButtonClick, previousFocusedElement)}
												className={mergeClassNames("modalCloseBtn", styles.close)}
												type="button"
												data-testid={testId ? `${testId}_Close` : undefined}
											>
												<Icon name="cancel" size="24" />
											</button>
										</div>
									)}
								</div>
							)}

							{children}
						</div>
					</div>
				</Article>
			</div>
		</KeyboardListener>
	);
};

export default ModalBase;
