import { useGoogleAnalyticsScript } from "@bokio/hooks/useGoogleAnalyticsScript/useGoogleAnalyticsScript";

interface GoogleAnalyticsProps {
	onScriptsLoaded?: () => void;
}

export const GoogleAnalytics = (props: GoogleAnalyticsProps) => {
	useGoogleAnalyticsScript(() => {
		props.onScriptsLoaded?.();
	});
	return null;
};
