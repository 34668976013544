import isNil from "lodash-es/isNil";
import * as React from "react";

import { parseNumber } from "@bokio/mobile-web-shared/core/utils/numberUtils";
import { formatNumber } from "@bokio/shared/utils/format";

import { InputField } from "./InputField";

import type { InputFieldPropsBase } from "./InputField";

interface PercentageFieldProps extends InputFieldPropsBase<number | undefined> {
	placeholder?: string;
	minValue?: number;
	maxValue?: number;
}

interface PercentageFieldState {
	rawValue: string;
	value?: number;
}

export class PercentageField extends React.Component<PercentageFieldProps, PercentageFieldState> {
	constructor(props: PercentageFieldProps) {
		super(props);
		this.state = { rawValue: "" };
	}

	componentDidMount() {
		this.updateInitialValue(this.props.value);
	}

	UNSAFE_componentWillReceiveProps(nextProps: PercentageFieldProps) {
		if (nextProps.value !== this.props.value && nextProps.value !== this.state.value) {
			this.updateInitialValue(nextProps.value);
		}
	}

	updateInitialValue = (value?: number) => {
		let val = "";
		if (!(isNil(value) || isNaN(value))) {
			val = formatNumber(Number((value * 100).toPrecision(7)));
		}
		this.setState({ value, rawValue: val });
	};

	clampValue = (min: number | undefined, val: number, max: number | undefined) => {
		const numMin = min ?? val;
		const numMax = max ?? val;
		return Math.max(numMin, Math.min(val, numMax));
	};

	render() {
		const props = {
			...this.props,
			value: this.state.rawValue,
			fieldProps: {
				placeholder: this.props.placeholder,
			},
		};

		return <InputField {...props} onChange={this.onChange} />;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange = (value: any) => {
		const numberFormat = parseNumber(value);
		if (isNaN(numberFormat)) {
			this.setState({ rawValue: value, value: undefined }, () => this.notify(undefined));
		} else {
			const num = this.clampValue(this.props.minValue, numberFormat, this.props.maxValue);
			const val = Number((num / 100).toPrecision(7));
			this.setState({ rawValue: num != numberFormat ? num + "" : value, value: val }, () => this.notify(val));
		}
	};

	notify = (val: number | undefined) => {
		if (this.props.onChange) {
			this.props.onChange(val);
		}
	};
}
