import * as React from "react";

import classes, { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./dropdownMenu.scss";

interface DropdownMenuProps {
	className?: string;
	isActive?: boolean;
	children: React.ReactNode;
	align: "left" | "right";
	setActive?: (v: boolean) => void;
}

export class DropdownMenu extends React.Component<DropdownMenuProps> {
	static defaultProps: Partial<DropdownMenuProps> = {
		align: "left",
	};

	render() {
		const { className, align, isActive, children } = this.props;
		const divClass = classes(styles, "dropdownMenu", {
			dropdownMenuActive: isActive,
			[align]: true,
		});

		return (
			<div data-testid="DropdownMenu_Container" className={mergeClassNames(divClass, className)}>
				{this.deepCloneChildren(children)}
			</div>
		);
	}

	deepCloneChildren(children: React.ReactNode, key?: number): React.ReactNode {
		const { setActive } = this.props;

		if (Array.isArray(children)) {
			return children.filter(child => child).map((child, index) => this.deepCloneChildren(child, index));
		} else if (
			React.isValidElement(children) &&
			(children.type as React.FunctionComponent).displayName === "DropdownItem"
		) {
			// TODO: MQ 2019-02-15 Using context instead
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return React.cloneElement(React.Children.only(children), { setActive, key } as any);
		} else {
			return children;
		}
	}
}
