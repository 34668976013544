import { cssVariableForSpacing } from "@bokio/designsystem/theme";
import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import type { StandardSpacingNames } from "@bokio/designsystem/theme/theme";
import type { IconProps } from "@bokio/elements/Icon/Icon";
import type * as React from "react";

import * as styles from "./unorderedList.scss";

export type UnorderedListItem = string | React.ReactNode;
export type UnorderedListProps = {
	className?: string;
	iconProps?: IconProps;
	items: UnorderedListItem[];
	gap?: StandardSpacingNames;
	hideBullets?: boolean;
};

export const UnorderedList: React.FC<UnorderedListProps> = ({
	iconProps,
	items,
	className,
	hideBullets = false,
	gap = "0",
}) => {
	const hideBulletsClass = hideBullets ? styles.hideBullets : "";
	const listClassName = className
		? mergeClassNames(
				styles.ul,
				className,
				iconProps && styles.removeListStyle,
				hideBulletsClass,
				styles.useVariableMarginBottom,
			)
		: mergeClassNames(styles.ul, iconProps && styles.removeListStyle, hideBulletsClass, styles.useVariableMarginBottom);

	return (
		<ul style={cssVariableForSpacing(gap, "--theme-spacing")} className={listClassName}>
			{items.map((content, index) =>
				!iconProps ? (
					<li key={index}>{content}</li>
				) : (
					<li key={index}>
						<Icon {...iconProps} noMargin />
						<span>{content}</span>
					</li>
				),
			)}
		</ul>
	);
};
