import * as React from "react";

import CompanyList from "@bokio/components/CompanyList/CompanyList";
import { TopBarDropDownButton } from "@bokio/components/TopBar/TopBarDropDownButton";
import { GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { Entities } from "@bokio/mobile-web-shared/core/model/model";
import { ActiveOrganisationType, useOrganisationInfo } from "@bokio/shared/containers/withActiveOrganisationInfo";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./menuCurrentCompany.scss";

interface MenuCurrentCompanyState {
	open: boolean;
}

export const MenuCurrentCompany = ({}) => {
	const { organisationId, organisationName, activeOrganisationType } = useOrganisationInfo();
	const companyInfo = useCompanyInfo();
	const isTestCompany =
		activeOrganisationType === ActiveOrganisationType.Company
			? companyInfo?.companyInfo?.CompanySystem === Entities.CompanySystem.TestCompany
			: false;
	const [state, setState] = React.useState<MenuCurrentCompanyState>({
		open: false,
	});

	const handleBlur = () => {
		if (state.open) {
			setTimeout(() => {
				setState({ open: false });
			}, 100);
		}
	};

	const toggleCompanySelector = () => {
		setState({
			open: !state.open,
		});
	};

	if (!organisationName) {
		return null;
	}

	const lang = GeneralLangFactory();

	return (
		<div className={styles.link}>
			<TopBarDropDownButton
				text={
					<>
						{organisationName} {isTestCompany && <span className={styles.test}>({lang.TestCompany_Label})</span>}{" "}
					</>
				}
				onClick={toggleCompanySelector}
				open={state.open}
				onBackgroundClose={handleBlur}
				testId="MenuCurrenctCompany_Dropdown"
			/>
			<div className={mergeClassNames(styles.companySelector, state.open && styles.showCompanySelector)}>
				<CompanyList currentOrganisationId={organisationId} isTopNav={true} />
			</div>
		</div>
	);
};
