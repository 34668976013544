import Icon from "@bokio/elements/Icon/Icon";

import { Popover } from "../Popover/Popover";
import { Paragraph } from "../TypographicElements/TypographicElements";

import type { PopoverProps } from "../Popover/Popover.types";
import type * as React from "react";

import * as styles from "./noticePopover.scss";

export interface NoticePopoverProps extends Omit<PopoverProps, "showArrow" | "onClose" | "fitContent"> {
	message: string;
	onClose: (reason: "close" | "outfocus") => void;
	testIdOfCloseButton?: string;
}

export const NoticePopover: React.FC<React.PropsWithChildren<NoticePopoverProps>> = ({
	message,
	arrowAnchor,
	onClose,
	...otherProps
}) => {
	return (
		<Popover
			variant={"notice"}
			borderRadius={"medium"}
			showArrow
			arrowAnchor={arrowAnchor}
			onClose={() => {
				onClose("outfocus");
			}}
			fitContent
			{...otherProps}
		>
			<div className={styles.content}>
				<Paragraph>{message}</Paragraph>
				<Icon
					className={styles.closeButton}
					name={"cancel"}
					onClick={() => {
						onClose("close");
					}}
				/>
			</div>
		</Popover>
	);
};
