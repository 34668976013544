import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button/Button";
import { useMetric } from "@bokio/hooks/useMetric/useMetric";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import { useBokioPlanLockBadge } from "../BokioPlanLockBadge/BokioPlanLockBadge";
import { UpgradePricePlanModal } from "../UpgradePricePlanModal/UpgradePricePlanModal";

import type { BokioPlanLockBadgeHookHandle, PricePlanFeature } from "../BokioPlanLockBadge/BokioPlanLockBadge";

import AnalyticsEventCategory = m.Bokio.Common.Contract.Metrics.Analytics.Requests.AnalyticsEventCategory;

export type BokioPlanFeatureButtonProps = {
	feature: PricePlanFeature;
	upgradeNudgeOverride?: string;
	planDescriptionOverride?: string;
	metricAlternative?: string;
	lockCondition?: (hook: BokioPlanLockBadgeHookHandle | undefined) => boolean;
	lockedTestId?: string;
};

export const useBokioPlanFeatureButton = <
	TActualButtonProps,
	TActualButtonRef extends React.Ref<HTMLButtonElement | HTMLAnchorElement>,
>({
	featureButtonProps: {
		feature,
		upgradeNudgeOverride,
		planDescriptionOverride,
		metricAlternative,
		lockCondition = hook => !!hook?.isFeatureLocked,
		lockedTestId,
	},
	lockButtonRef,
	lockButtonProps,
	renderActualButton,
}: {
	featureButtonProps: BokioPlanFeatureButtonProps;
	lockButtonRef: TActualButtonRef | undefined;
	lockButtonProps: TActualButtonProps;
	renderActualButton: () => React.ReactNode;
}): { renderButton: () => React.ReactElement } => {
	const hook = useBokioPlanLockBadge({ feature });
	const [addMetric] = useMetric();

	const [showPopup, setShowPopup] = React.useState(false);
	const { companyInfo } = useCompanyInfo();

	const checkIsLocked = (hook: BokioPlanLockBadgeHookHandle | undefined): hook is BokioPlanLockBadgeHookHandle => {
		return lockCondition(hook);
	};

	const renderButton = (): React.ReactElement => {
		if (!checkIsLocked(hook)) {
			return <>{renderActualButton()}</>;
		}

		return (
			<>
				<Button
					{...lockButtonProps}
					// This shouldn't fallback to test ID so it's easier to figure out if some configuration is wrong
					testId={lockedTestId}
					icon="lock"
					iconAlign="left"
					disabled={false}
					type="button"
					onClick={e => {
						e.preventDefault();
						addMetric(
							AnalyticsEventCategory.BokioPlans,
							`BlockedFeatureClicked${metricAlternative ? `_${metricAlternative}` : ""}`,
							feature,
						);
						setShowPopup(true);
					}}
					ref={lockButtonRef}
				/>
				<UpgradePricePlanModal
					upgradeToPlan={hook.minimumPlan}
					visible={showPopup}
					onClose={() => setShowPopup(false)}
					upgradeNudgeOverride={upgradeNudgeOverride}
					planDescriptionOverride={planDescriptionOverride}
					companyId={companyInfo.Id}
				/>
			</>
		);
	};

	return {
		renderButton,
	};
};
