export function importPixels() {
	const sendVerve: (trackId: "3163676" | "3163677") => void = trackId => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const tracker = (window as any).p161;
			if (tracker) {
				tracker.track(trackId);
				tracker.trackQueue(); //ME: This call is needed because we call track() after the scripts are fully loaded
			}
		} catch {}
	};

	return { sendVerve };
}
