/**
 * ┌────────────────────────────────────────────────────────────────────────┐
 * │ This file has been generated by routeGenerator                         │
 * │                                                                        │
 * │ The routes are defined in bundles.ts                                   │
 * │ Some routes may require changes in Rewrite.cs                          │
 * │ Please do not edit this file manually.                                 │
 * └────────────────────────────────────────────────────────────────────────┘
**/
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/ban-ts-comment */

import { compile } from "path-to-regexp";
import * as qs from "qs";

/* @ts-ignore */
import type * as m from "@bokio/mobile-web-shared/core/model/model";

/* eslint-enable @typescript-eslint/no-unused-vars, @typescript-eslint/ban-ts-comment */

export const routes = {
	
  /* Signup */
  signUp: "/sign-up/:step?/:subStep?",
  signUpAccountant: "/sign-up-accountant/:step?/:subStep?",
  login: "/login",
  connectAgency: "/connect-agency",
  forgotPassword: "/forgot-password/:step?",
  resetPassword: "/reset-password/:userId/:deprecatedCode?",
  acceptInvitation: "/accept-invitation/:inviteId?/:token?/:type?/",
  acceptCompanyOwnerInvitation: "/accept-company-owner-invitation/:inviteId?/:token?/",
  confirmEmail: "/confirm-email/:subStep?",
  integrationTests: "/integration-tests/:subPage?",
  voucherFlow: "/activate-discount/:token",

  /* Settings */
  createCompany: "/create-company/:id?/:step?",
  preCreateClient: "/pre-create-client/:source/:step",
  settingsImportCompany: "/:company/settings-r/import-company/:step?",
  settingsImportOverview: "/:company/settings-r/import-previous/:step?",
  fiscalList: "/:company/settings-r/fiscals",
  fiscalEdit: "/:company/settings-r/fiscals/:id",
  fiscalCreate: "/:company/settings-r/fiscals/create/:mode",
  accountPlan: "/:company/settings-r/account-plan",
  mapAccounts: "/:company/settings-r/account-plan/map-accounts",
  ecommerceSettings: "/:company/settings-r/ecommerce-settings",
  invoiceSettings: "/:company/settings-r/invoice-settings",
  eInvoiceSettings: "/:company/settings-r/configure-einvoice",
  automaticCheckingSettings: "/:company/settings-r/automatic-checking-settings",
  paymentMethodSettings: "/:company/settings-r/payment-method-settings",
  settingsOverview: "/:company/settings-r/overview",
  personalSettings: "/settings-r/personal-settings/:returnUrl",
  companyDataStorage: "/settings-r/company-data-storage",
  totpActivate: "/settings-r/totp-settings/activate/:returnUrl",
  totpDeactivate: "/settings-r/totp-settings/deactivate/:returnUrl",
  totpSettings: "/settings-r/totp-settings/:returnUrl",
  totpRecoveryDocument: "/settings/twofactorsettings/recoverypdf\?encryptedSecret=:encryptedSecret",
  recentLogins: "/settings-r/recent-logins:returnUrl",
  logOutEverywhere: "/settings-r/logout-everywhere:returnUrl",
  bankidSettings: "/settings-r/bankid-settings:returnUrl",
  bankidActivate: "/settings-r/bankid-settings/activate/:returnUrl",
  bankidDeactivate: "/settings-r/bankid-settings/deactivate/:returnUrl",
  bankIdRequired: "/settings-r/bankid-required/:returnUrl",
  updateUsername: "/settings-r/update-username",
  confirmUpdateUsername: "/settings-r/update-username-confirm/:id/:code?",
  moneyAccounts: "/:company/settings-r/money-accounts",
  tagTypes: "/:company/settings-r/tag-types",
  exportBookkeeping: "/:company/settings-r/export-bookkeeping",
  openingBalances: "/:company/settings-r/opening-balances",
  accountPayables: "/:company/settings-r/account-payables",
  accountReceivables: "/:company/settings-r/account-receivables",
  layoutLogoColor: "/:company/settings-r/layout",
  companySettings: "/:company/settings-r/company-settings",
  changeCompanySystem: "/:company/settings-r/convert-company",
  deleteCompany: "/:company/settings-r/delete-company",
  leaveCompany: "/:company/settings-r/leave-company",
  deleteUserAccount: "/settings-r/delete-user-account",
  featureToggles: "/:company/feature-toggles/:key?",
  agreements: "/legal/agreements",
  signTerms: "/legal/agreements/sign",
  settings: "/:company/Settings",
  companySetting: "/:company/Settings/Company",
  inbox: "/:company/settings-r/inbox",
  inboxHistory: "/:company/settings-r/inbox/history/:messageId?",
  inboxAttachmentDownload: "/:company/common/inboxmessages/previewfile\?messageId=:messageId&filename=:filename",
  exportData: "/:company/settings-r/export-data",
  users: "/:company/settings-r/users/:mode?/:id?",
  changePassword: "/settings-r/change-password",
  auditHistory: "/:company/settings-r/audit-history",
  exportAuditHistory: "/:company/Settings/AuditHistory/ExportAuditHistory?page=:page",
  companyVacationSettings: "/:company/settings-r/company-vacation",
  placeOfEmploymentSettings: "/:company/settings-r/place-of-employment",
  salarySettingUK: "/:company/settings-r/salarySettingUK",
  activateVoucher: "/:company/settings-r/billing/activatevoucher/:code",
  billing: "/:company/settings-r/billing",
  viewBill: "/:company/settings-r/billing/:billId",
  downloadBillingReceiptPdf: "/:company/CommonWeb/Subscription/DownloadPurchaseReceipt?receiptId=:receiptId",
  cardList: "/:company/bank/cards",
  card: "/:company/bank/cards/card/:cardId",
  orderCard: "/:company/bank/cards/order-card/:accountId/:userId",
  supportMessages: "/:company/settings-r/support-messages/:tab?",
  bokioBusinessAccountOffboarding: "/:company/settings-r/bokio-business-account-offboarding",
  sveaBankConsent: "/:company/settings-r/bank-consent",
  sveaKycScene: "/:company/settings-r/bokio-business-account/kyc",
  notificationSettings: "/:company/settings-r/notification-settings",
  notificationRedirect: "/:company/notifications-redirect/:area/:areaType/:itemType?/:itemId?",
  exportSIEFile: "/:company/Settings/ExportCompany/SieForYear?settingsId=:settingsId",
  customTemplates: "/:company/settings-r/custom-templates/:templateId?",
  automationRules: "/:company/settings-r/bank-rules",
  mirChangeSignatory: "/:company/settings-r/bokio-business-account-change-signatory",
  removeImport: "/:company/settings-r/remove-import",

  /* Bookkeeping */
  hmrcAuth: "/hmrc-auth",
  accounting: "/:company/Accounting",
  preBookkeep: "/:company/pre-bookkeep/:receiptId?",
  preBookkeepSupplierInvoice: "/:company/pre-bookkeep/:receiptId?\?category=supplier",
  bookkeepReceipt: "/:company/bookkeep/category/:category/:receiptId?",
  recordingCheckList: "/:company/bookkeep/checks/recordingcheck/list",
  recordingCheckCreate: "/:company/bookkeep/checks/recordingcheck/create",
  recordingCheckView: "/:company/bookkeep/checks/recordingcheck/:id",
  bookkeepExpense: "/:company/bookkeep/expense/:expenseId/:receiptId?",
  bookkeepSupplier: "/:company/bookkeep/supplier/:supplierInvoiceId/:receiptId?",
  bookkeepTransaction: "/:company/bookkeep/transaction/:transactionId/:receiptId?",
  editVerification: "/:company/edit-verification/:id/:returnUrl",
  attest: "/:company/attest/:id/:returnUrl",
  eventsToRecord: "/:company/bookkeep/events/:rowId?/:eventType?",
  receiptImage: "/:company/Accounting/Receipt/ShowReciept/:receiptId?",
  downloadReceipt: "/:company/Accounting/Receipt/DownloadReceipt/:receiptId",
  receiptThumbnail: "/receiptImage/:company/:receiptId?/:version??height=:imageSize&width=:imageSize&mode=crop",
  receiptThumbnailAlt: "/:company/Accounting/Receipt/ShowThumbnail/?receiptId=:receiptId&version=:version?&imageSize=:imageSize&cacheBuster=20220802",
  receiptExtraImage: "/:company/Accounting/Receipt/ShowReceiptExtraPage/:imageId?",
  loginToArsRedovisningOnline: "/:company/Accounting/Closure/LoginToArsRedovisningOnline",
  importSi: "/:company/import-si",

  /* Bank */
  bankImport: "/:company/bank/import",
  bankList: "/:company/bank",
  bokioBusinessAccountMigration: "/:company/bank/bokio-business-account-upgrade",
  sveaOnboarding: "/:company/bank/bokio-business-account-onboard",
  sveaOnboardingAgreementPdf: "/:company/BankApi/SveaOnboarding/GetPackageAgreement",
  sveaAddInternationalPaymentsScene: "/:company/bank/add-international-payments",
  sveaInternationalPayments: "/:company/bank/bokio-business-account/international-payments",
  sveaPreregistrationViewScene: "/:company/bank/bokio-business-account/preregistrations/:id",
  sveaMatchingScene: "/:company/bank/bokio-business-account/payment-matching",
  sveaAddSavingsAccount: "/:company/bank/bokio-business-account/add-bank-account",
  sveaSwishOverview: "/:company/bank/bokio-business-account/swish",
  sveaSwishOnboarding: "/:company/bank/bokio-business-account/add-swish",
  bankManage: "/:company/bank/manage",
  bankAccountEdit: "/:company/bank/accounts/:account/imports",
  bankAccount: "/:company/bank/accounts/:account/:tab?",
  bankInbox: "/:company/bank/inbox",
  sveaInboxAttachmentFile: "/:company/BankApi/SveaInbox/GetMessageAttachment",
  bankAccountQrCode: "/:company/BankApi/BankAccount/QrCode",
  bankPayments: "/:company/bank/payments",
  bankCreatePayment: "/:company/bank/payments/create",
  bankCreatePaymentSuccess: "/:company/bank/payments/createSuccess/:paymentId",
  bokioBusinessAccountOnboardingSteps: "/:company/bank/bokio-business-account-onboarding/:step?",
  mirOnboardingAgreementPdf: "/:company/BankApi/MirOnboarding/GetAgreementPdf?onboardingId=:onboardingId",
  bankStatementPdf: "/:company/BankApi/Transaction/BankStatementPdf",
  transactionDetailsPdf: "/:company/BankApi/Transaction/TransactionDetailsPdf",
  blockedBankList: "/:company/bank/bankinformation",

  /* Supplier invoices */
  suppliersInvoicesAll: "/:company/supplier-invoices",
  supplierInvoices: "/:company/supplier-invoices/suppliers/:supplierId",
  supplierInvoice: "/:company/supplier-invoices/view/:invoiceId",
  suppliers: "/:company/supplier-invoices/suppliers",
  suppliersImport: "/:company/suppliers/import",
  supplierInvoiceQrCode: "/:company/Accounting/SupplierInvoice/GetSwedishQrCode",

  /* Customer invoices */
  periodicReport: "/:company/invoicing/invoices/periodic-report(\\?fromDate=:fromDate&toDate=:toDate&includeNonEu=:includeNonEu)?",
  invoicesShow: "/:company/invoicing/invoices/view/:invoiceId",
  invoicesCreate: "/:company/invoicing/invoices/create/:invoiceType?(\\?companySettings=:companySettings&addCustomer=:addCustomer&editCustomer=:editCustomer)?",
  invoicesEdit: "/:company/invoicing/invoices/edit/:invoiceId/(\\?companySettings=:companySettings&addCustomer=:addCustomer&editCustomer=:editCustomer)?",
  invoices: "/:company/invoicing/invoices/:mode?",
  articles: "/:company/invoicing/articles",
  articlesImport: "/:company/invoicing/articles/import",
  rotRutErrands: "/:company/invoicing/rot-rut-errands/:mode?",
  logoImageUrl: "/:company/Invoices/companyimage/GetCompanyImage?version=:version",
  factoringActivation: "/:company/settings-r/add-ons/factoring",
  exportArticles: "/:company/Invoices/Article/Export",
  customerDownload: "/:company/Invoices/Customer/Get/:id",
  invoiceLatestPdf: "/:company/Invoices/Invoice/LatestPdf/:invoiceId/:invoiceSystem?",
  invoicePdf: "/:company/Invoices/Invoice/Pdf",
  rotXML: "/:company/Invoices/RotRut/DownloadRotErrands",
  rutXML: "/:company/Invoices/RotRut/DownloadRutErrands",
  supplierInvoiceImage: "/:company/Accounting/Receipt/ShowReciept/:receiptId??version=null",
  exportInvoices: "/:company/Invoices/Invoice/Export",
  customersImport: "/:company/invoicing/customers/import",
  customers: "/:company/invoicing/customers/:mode?",
  customerInvoices: "/:company/invoicing/customers/view/:customerId/:mode?",
  exportCustomers: "/:company/Invoices/Customer/Export",
  exportAccountsReceivable: "/:company/Invoices/Customer/ExportAccountsReceivable",
  exportAccountsPayable: "/:company/Accounting/Reports/ExportAccountsPayable",
  invoiceAttachment: "/:company/Invoices/InvoiceAttachment/InvoiceAttachment/:attachmentId",
  quotesCreate: "/:company/invoicing/quotes/create",
  quotesEdit: "/:company/invoicing/quotes/edit/:quoteId",
  quoteDetails: "/:company/invoicing/quotes/view/:quoteId",
  quotes: "/:company/invoicing/quotes/:mode?",
  quotePdf: "/:company/Invoices/Quote/Pdf/:quoteId",
  recurringInvoiceDetailsOverview: "/:company/invoicing/recurring-invoices/:recurringInvoiceId",
  recurringInvoices: "/:company/invoicing/recurring-invoices/:mode?",

  /* Expenses */
  expenses: "/:company/expenses",
  expensesUpload: "/:company/expenses/upload",
  expensesUploadShow: "/:company/expenses/upload/:id",
  expenseReceiptView: "/:company/expenses/view/:id",
  expensesPaymentsList: "/:company/expenses/payments/list",
  expensesPayments: "/:company/expenses/payments",
  showExpensePayment: "/:company/expenses/showpayment/:paymentId",
  expensesPermissions: "/:company/expenses/permissions",
  expensesOverview: "/:company/expenses/overview",
  expenseSettings: "/:company/settings-r/expenses/min-date",
  myExpenseAccounts: "/:company/expenses/my-accounts",
  companyCards: "/:company/settings-r/expenses/company-cards",
  convertToExpenses: "/:company/expenses/from-receipt/:receiptId",

  /* Salary */
  salarySettings: "/:company/salary/settings/:returnUrl",
  salaryPayrollPayslipShow: "/:company/salary/payroll/:year/:month/payslip/:id/:fromEmployee?",
  salaryPayrollTaxreport: "/:company/salary/payroll/:year/:month/taxreport",
  salaryTaxreportRedirect: "/:company/Salary/Api/v1/Payroll/Taxreportredirect/:reportId",
  myPayslipPdf: "/:company/Salary/api/v1/payslips/myPayslipPdf?id=:id",
  bulkpayslipPdf: "/:company/Salary/api/v1/payroll/pdf",
  p60Pdf: "/:company/Salary/api/v1/payroll/p60Pdf",
  P45Pdf: "/:company/Salary/api/v1/payroll/P45Pdf",
  salary2Payroll_old: "/:company/salary/payroll/:tab?",
  salaryPayrollDetails: "/:company/salary/payrolldetails/:paydate/:independentpayrollid?",
  salaryAGIdownload: "/:company/Salary/Api/v1/agi/download?year=:year&month=:month",
  salaryTaxPeriodList: "/:company/salary/periods/",
  salaryTaxPeriodView: "/:company/salary/periods/:year/:month",

  /* Salary/Absence */
  salaryAbsense: "/:company/salary/absence",
  salaryAGI: "/:company/salary/payroll/:year/:month/agi",

  /* Reports */
  balanceReport: "/:company/reports/balance(\\?fromDate=:fromDate&toDate=:toDate&dateMode=:dateMode)?",
  balanceReportAccountPage: "/:company/reports/balance/account/:accountNo",
  vatList: "/:company/reports/vat/:tab?",
  vatReport: "/:company/reports/vat/:year/:period",
  vatReport2: "/:company/reports/vat2/:year/:period",
  vatReportHmrc: "/:company/reports/vatreturns/:key",
  vatCheck: "/:company/reports/vat/:year/:period/check",
  hmrcVatCheck: "/:company/reports/vatreturns/:key/check",
  resultReport: "/:company/reports/result(\\?fromDate=:fromDate&toDate=:toDate&mode=:dateMode&key=:key)?",
  tagReport: "/:company/reports/tag(\\?fromDate=:fromDate&toDate=:toDate&mode=:dateMode&key=:key)?",
  drillDownReport: "/:company/reports/drilldown(\\?fromDate=:fromDate&toDate=:toDate&mode=:dateMode&key=:key)?",
  resultReportAccountPage: "/:company/reports/result/account/:accountNo",
  ledgerReconcilliation: "/:company/reports/ledger/reconcile",
  ledgerReport: "/:company/reports/ledger(\\?fromDate=:fromDate&toDate=:toDate&showDecimals=:showDecimals)?",
  accountedList: "/:company/reports/accounted(\\?fromDate=:fromDate&toDate=:toDate&showGrouped=:grouped&showHidden=:hidden&search=:search&verificate=:verificate&showSupport:=showSupport)?",
  accountingCheckShow: "/:company/reports/accountingcheck/show",
  checkVerifications: "/:company/reports/accountingcheck/checkverifications(\\?fromDate=:fromDate&toDate=:toDate)?",
  vatReportXmlDownload: "/:company/Accounting/VAT/SwedishVatXml?savedReportId=reportId",
  balanceReportPdf: "/:company/Accounting/Reports/BalancePdf?fromDate=:fromDate&toDate=:toDate",
  balanceReportExcel: "/:company/Accounting/Reports/BalanceExcel?fromDate=:fromDate&toDate=:toDate",
  resultReportPdf: "/:company/Accounting/Reports/ResultPdf?fromDate=:fromDate&toDate=:toDate&allowedTagTypeValueId=:allowedTagTypeValueId?",
  resultReportExcel: "/:company/Accounting/Reports/ResultExcel?fromDate=:fromDate&toDate=:toDate&allowedTagTypeValueId=:allowedTagTypeValueId?",
  ledgerPdf: "/:company/Accounting/Reports/LedgerPdf?fromDate=:fromDate&toDate=:toDate&showDeleted=:showDeleted",
  trialBalanceExcel: "/:company/Accounting/Reports/TrialBalanceReportExcel?fromDate=:fromDate&toDate=:toDate",
  ledgerAccountExcel: "/:company/Accounting/Reports/LedgerAccountExcel?fromDate=:fromDate&toDate=:toDate&account=:account",
  invoicesReport: "/:company/reports/invoices(\\?fromDate=:fromDate&toDate=:toDate)?",
  salaryReports: "/:company/reports/salary",
  salaryReportSalaryTotal: "/:company/reports/salary/salary-total",
  salaryReportVacationSummary: "/:company/reports/salary/vacation-summary",
  salaryReportSickDays: "/:company/reports/salary/sick-days",
  salaryReportHourlyPaid: "/:company/reports/salary/hourly-paid",
  salaryReportVacationDebt: "/:company/reports/salary/vacation-debt",
  agedDebtorsReport: "/:company/reports/agedDebtors",
  agedDebtorsReportPdf: "/:company/Invoices/InvoiceReports/AccountsReceivablePdf",
  agedCreditorsReport: "/:company/reports/agedCreditors",
  accountPayablePdf: "/:company/Accounting/Reports/AccountsPayablePdf",

  /* EmployeesWithContracts */
  employeeList: "/:company/employees/:tab?",
  employeeAdd: "/:company/employees/employee/onboard/:mode?/:id?/:periodSettingId?/:vacationSetting?",
  manageActiveEmployees: "/:company/employees/activation/manageactive/",
  employeeDetails: "/:company/employees/employee/details/:mode?/:id?/:periodSettingId?/:vacationSetting?",
  employeeVacationTrackingForm: "/:company/employees/employee/trackedvacation/:id?/:periodSettingId?",
  employeeVacationForm: "/:company/employees/employee/vacation/:id?/:periodSettingId?",
  vacationTracking: "/:company/employee/vacations",
  vacationTrackingSetup: "/:company/employee/vacations/vacationTrackingSetup",
  employeeView: "/:company/employees/employee/view/:id/:tab?",
  editDirector: "/:company/employees/director/edit/:id",
  contractView: "/:company/employees/employee/contractview/:id/:periodSettingId",
  contractAdd: "/:company/employees/employee/addcontract/:id/:periodSettingId",
  contractEdit: "/:company/employees/employee/editcontract/:id/:periodSettingId",
  myContractView: "/:company/employee/myDetails/contractView/:periodSettingId",
  myEmployee: "/:company/employees/employee/myDetails/:tab?",
  myEmployeeSalary: "/:company/employees/employee/myDetails/2",
  employeesImport: "/:company/employees/employee/import",
  exportEmployees: "/:company/Salary/api/v1/Employees/Export",
  employeeDownload: "/:company/Salary/api/v1/Employees/Get/:id",
  vacationYearEnd: "/:company/employee/vacations/yearend/:startDate",
  vacationYearEndEmployeeDetail: "/:company/employee/vacations/yearenddetail/:vacationYearEndEmployeeId",
  GetMyP45: "/:company/Salary/Api/v1/Employees/GetMyP45",
  GetMyP60: "/:company/Salary/Api/v1/Employees/GetMyP60",

  /* Development */
  developerTools: "/:company/dev",
  mockTools: "/:company/mocktools",
  stagingEnableBankingScene: "/:company/enablebankingtest",

  /* Closures */
  annualReportForm: "/:company/closures/annualreport/form/:reportId",
  closureNEAttachment: "/:company/closures/:year/:endYear/neattachment",
  closuresShow: "/:company/closures/:year/:endYear",
  annualReportArchive: "/:company/closures/:year/:endYear/annual-report-archive",
  FetchFiles: "/:company/Accounting/ClosureFile/FetchFiles",
  closures: "/:company/closures",

  /* Todo */
  todo: "/:company/todo/:page?/:item?",
  importantDates: "/:company/important-dates",
  preliminaryTaxes: "/:company/preliminary-taxes",
  preliminaryTaxesPaymentDetails: "/:company/preliminary-taxes/:paymentDetailsId",
  todoPromotionAvtal24: "/:company/todo/free-legal-consultation/:returnUrl",
  todoPromotionMynt: "/:company/todo/summer-financing/191/:returnUrl",

  /* Agencies */
  agencyIndex: "/agencies/:agencyId([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})",
  createAgency: "/create-agency/:id?/:step?",
  preCreateAgency: "/pre-create-agency",
  agencyOverview: "/agencies/:agencyId/overview",
  agencyPartnerInfo: "/agencies/:agencyId/partnerinfo",
  agencyClients: "/agencies/:agencyId/clients",
  agencyUsers: "/agencies/:agencyId/users",
  agencyNotes: "/agencies/:agencyId/notes/:tab?",
  agencyClientDetails: "/agencies/:agencyId/clients/:companyId/:tab",
  exportNotes: "/:agencyId/BackOffice/CompanyDetails/ExportNotes",
  AgencyPartnerContractPdf: "/:agencyId/BackOffice/AgencyPartnerOnboarding/AgencyPartnerContractPdf",
  agencySettings: "/agencies/:agencyId/settings",
  agencyImportClients: "/agencies/:agencyId/settings/import-clients",
  agencyClientRequests: "/agencies/:agencyId/client-requests",
  agencyDetailsScene: "/agencies/:agencyId/agency-details",
  agencyNotificationSettings: "/agencies/:agencyId/notification-settings",
  agencyExport: "/agencies/:agencyId/export",
  leaveAgency: "/agencies/:agencyId/leave-agency",
  deleteAgency: "/agencies/:agencyId/delete-agency",
  backofficeFeatureToggles: "/agencies/:agencyId/features",
  agencyPartnerProgram: "/agencies/:agencyId/partnerProgram",
  agencyQuoteRequestsList: "/agencies/:agencyId/client-quotes",
  agencyQuoteRequest: "/agencies/:agencyId/client-quotes/:company/application/:applicationId",
  agencyNotificationRedirect: "/agencies/:agencyId/agency-notifications-redirect/:area/:areaType/:itemType?/:itemId?/:linkTo?",

  /* BackOffice */
  backOfficeAcceptInvitation: "/accept-partner-invitation/:partner/:invite",
  recordingSupport: "/backoffice/:partner/recordingSupport",
  supportTicketReceiptImage: "/:partner/BackOffice/SupportTicket/ShowReceipt",
  supportTicketReceiptExtraPage: "/:partner/BackOffice/SupportTicket/ShowReceiptExtraPage",
  backOffice: "/backoffice/:partner",
  backOfficeAdmin: "/backoffice/:partner/admin",
  backOfficeDashboard: "/backoffice/:partner/overview",
  backOfficeAdminAgencies: "/backoffice/:partner/agencies",
  backOfficeAdminAgency: "/backoffice/:partner/agencies/:agency",
  backOfficeTodo: "/backoffice/:partner/todo",
  backOfficeCompaniesDashboard: "/backoffice/:partner/clients",
  backOfficeUsers: "/backoffice/:partner/users",
  backOfficeSettings: "/backoffice/:partner/settings",

  /* Companies */
  root: "/",
  companyIndex: "/:company([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})",
  selectCompanyFromMail: "/select-company-from-mail",
  selectCompany: "/select-company",
  dashboard: "/:company/dashboard/",
  onboarding: "/:company/onboarding",

  /* Services */
  accountingServices: "/:company/accounting-services",
  submitAgencyInterest: "/:company/accounting-services/submit-interest/agency/:agencyName",
  businessServices: "/:company/business-services",

  /* Accounting */
  bankTransactions: "/:company/bank-transactions",
  assetsManagement: "/:company/reports/assets",

  /* Uploads */
  uploads: "/:company/uploads/:tab?",
  downloadAdditionalFile: "/:company/Accounting/AdditionalFiles/DownloadAdditionalFile/:id",
  downloadAdditionalFilesAsZip: "/:company/Accounting/AdditionalFiles/DownloadAdditionalFilesAsZip",

  /* Integrations */
  integrations: "/:company/integrations",
  stripe: "/:company/integrations/stripe",
  stripeCallback: "/oauth/callback/stripe",
  stripeOnboardingMock: "/mock/stripe/onboarding",

  /* CustomerPortal */
  invoicePortal: "/public/invoice/:id/:token",
  invoicePortalPdf: "/Invoices/InvoicePortal/Pdf",

  /* DirectorsPayroll */
  employerDirectorSetup: "/:company/salary/directorspayroll/employerdirectorsetup",
  directorsPayrollList: "/:company/salary/directorspayroll/payrolls",
  directorsPayrollDetails: "/:company/salary/directorspayroll/payrolldetails/:paydate/:independentpayrollid?",
  addDirector: "/:company/salary/directorspayroll/adddirector",

  /* Sales */
  dailyTakings: "/:company/sales/dailytakings/:accessCode?",
  ecommerceJournalPdf: "/:company/Invoices/ECommerceBookkeeping/GetJournalPdf/:id",
  ecommerceOrderList: "/:company/sales/ecommerce/ecommerceorderlist",
  ecommerceBookkeeping: "/:company/sales/ecommerce/ecommercebookkeeping",
  ecommercepaymentmethods: "/:company/sales/ecommerce/connect/:platform/paymentmethods",
  ecommercesetup: "/:company/sales/ecommerce/connect/:platform",
  ecommerce: "/:company/sales/ecommerce",
  ecommercebookkeepinvoicejournalpage: "/:company/sales/ecommerce/bookkeep/:journalId",
};

export interface WithCompany {
	company: string;
}

export interface WithPartner {
	partner: string;
}

export interface WithPartnerCompaniesTab {
	tab: "recurrent" | "non-recurrent" | "";
}

type WithPartnerCompanyDetailsTabType = "overview" | "bookkeeping" | "salary" | "notes" | "vat" | "agreement" | "";
export interface WithPartnerCompanyDetailsTab {
	tab: WithPartnerCompanyDetailsTabType;
}

export interface WithReturnUrl {
	returnUrl?: string;
}

export interface WithVatPeriod {
	year: string;
	period: string;
}

export interface WithPayslipPeriod {
	month: string;
	year: string;
}

export interface WithInvoiceSceneParams {
	invoiceId?: string;
	invoiceType?: string;
}

/* Signup */
function getRoute(name: "signUp", tokenParams?: { step?: string; subStep?: string }, queryParams?: { returnUrl?: string; voucher?: string; utm_source?: string; utm_medium?: string; utm_campaign?: string; utm_id?: string; utm_term?: string; utm_content?: string; ru?: string; c?: string }): string;
function getRoute(name: "signUpAccountant", tokenParams?: { step?: string; subStep?: string }, queryParams?: {  }): string;
function getRoute(name: "login", tokenParams?: {  }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "connectAgency", tokenParams?: {  }, queryParams?: {  }): string;
function getRoute(name: "forgotPassword", tokenParams?: { step?: string }): string;
function getRoute(name: "resetPassword", tokenParams: { userId: string; deprecatedCode?: string }, queryParams: { returnUrl?: string; code: string }): string;
function getRoute(name: "acceptInvitation", tokenParams?: { inviteId?: string; token?: string; type?: string }): string;
function getRoute(name: "acceptCompanyOwnerInvitation", tokenParams?: { inviteId?: string; token?: string }): string;
function getRoute(name: "confirmEmail", tokenParams?: { subStep?: string }, queryParams?: {  }): string;
function getRoute(name: "integrationTests", tokenParams?: { subPage?: string }, queryParams?: {  }): string;
function getRoute(name: "voucherFlow", tokenParams: { token: string }): string;
/* Settings */
function getRoute(name: "createCompany", tokenParams?: { id?: string; step?: string }, queryParams?: { source?: string; voucher?: string }): string;
function getRoute(name: "preCreateClient", tokenParams: { source: string; step: string }, queryParams?: { agencyId?: string }): string;
function getRoute(name: "settingsImportCompany", tokenParams: { company: string; step?: string }): string;
function getRoute(name: "settingsImportOverview", tokenParams: { company: string; step?: string }, queryParams?: { source?: string }): string;
function getRoute(name: "fiscalList", tokenParams: { company: string }): string;
function getRoute(name: "fiscalEdit", tokenParams: { company: string; id: string }): string;
function getRoute(name: "fiscalCreate", tokenParams: { company: string; mode: string }): string;
function getRoute(name: "accountPlan", tokenParams: { company: string }): string;
function getRoute(name: "mapAccounts", tokenParams: { company: string }): string;
function getRoute(name: "ecommerceSettings", tokenParams: { company: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "invoiceSettings", tokenParams: { company: string }): string;
function getRoute(name: "eInvoiceSettings", tokenParams: { company: string }): string;
function getRoute(name: "automaticCheckingSettings", tokenParams: { company: string }): string;
function getRoute(name: "paymentMethodSettings", tokenParams: { company: string }): string;
function getRoute(name: "settingsOverview", tokenParams: { company: string }): string;
function getRoute(name: "personalSettings", tokenParams: { returnUrl: string }): string;
function getRoute(name: "companyDataStorage", tokenParams?: {  }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "totpActivate", tokenParams: { returnUrl: string }): string;
function getRoute(name: "totpDeactivate", tokenParams: { returnUrl: string }): string;
function getRoute(name: "totpSettings", tokenParams: { returnUrl: string }): string;
function getRoute(name: "totpRecoveryDocument", tokenParams: { encryptedSecret: string }): string;
function getRoute(name: "recentLogins", tokenParams: { returnUrl: string }): string;
function getRoute(name: "logOutEverywhere", tokenParams: { returnUrl: string }): string;
function getRoute(name: "bankidSettings", tokenParams: { returnUrl: string }): string;
function getRoute(name: "bankidActivate", tokenParams: { returnUrl: string }): string;
function getRoute(name: "bankidDeactivate", tokenParams: { returnUrl: string }): string;
function getRoute(name: "bankIdRequired", tokenParams: { returnUrl: string }): string;
function getRoute(name: "updateUsername", tokenParams?: {  }): string;
function getRoute(name: "confirmUpdateUsername", tokenParams: { id: string; code?: string }): string;
function getRoute(name: "moneyAccounts", tokenParams: { company: string }): string;
function getRoute(name: "tagTypes", tokenParams: { company: string }): string;
function getRoute(name: "exportBookkeeping", tokenParams: { company: string }): string;
function getRoute(name: "openingBalances", tokenParams: { company: string }, queryParams?: { year?: string }): string;
function getRoute(name: "accountPayables", tokenParams: { company: string }): string;
function getRoute(name: "accountReceivables", tokenParams: { company: string }): string;
function getRoute(name: "layoutLogoColor", tokenParams: { company: string }): string;
function getRoute(name: "companySettings", tokenParams: { company: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "changeCompanySystem", tokenParams: { company: string }): string;
function getRoute(name: "deleteCompany", tokenParams: { company: string }): string;
function getRoute(name: "leaveCompany", tokenParams: { company: string }): string;
function getRoute(name: "deleteUserAccount", tokenParams?: {  }): string;
function getRoute(name: "featureToggles", tokenParams: { company: string; key?: string }): string;
function getRoute(name: "agreements", tokenParams?: {  }): string;
function getRoute(name: "signTerms", tokenParams?: {  }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "settings", tokenParams: { company: string }): string;
function getRoute(name: "companySetting", tokenParams: { company: string }): string;
function getRoute(name: "inbox", tokenParams: { company: string }): string;
function getRoute(name: "inboxHistory", tokenParams: { company: string; messageId?: string }): string;
function getRoute(name: "inboxAttachmentDownload", tokenParams: { company: string; messageId: string; filename: string }): string;
function getRoute(name: "exportData", tokenParams: { company: string }): string;
function getRoute(name: "users", tokenParams: { company: string; mode?: string; id?: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "changePassword", tokenParams?: {  }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "auditHistory", tokenParams: { company: string }): string;
function getRoute(name: "exportAuditHistory", tokenParams: { company: string; page: string }): string;
function getRoute(name: "companyVacationSettings", tokenParams: { company: string }): string;
function getRoute(name: "placeOfEmploymentSettings", tokenParams: { company: string }): string;
function getRoute(name: "salarySettingUK", tokenParams: { company: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "activateVoucher", tokenParams: { company: string; code: string }, queryParams?: {  }): string;
function getRoute(name: "billing", tokenParams: { company: string }, queryParams?: { tab?: string; from?: string }): string;
function getRoute(name: "viewBill", tokenParams: { company: string; billId: string }): string;
function getRoute(name: "downloadBillingReceiptPdf", tokenParams: { company: string; receiptId: string }): string;
function getRoute(name: "cardList", tokenParams: { company: string }): string;
function getRoute(name: "card", tokenParams: { company: string; cardId: string }): string;
function getRoute(name: "orderCard", tokenParams: { company: string; accountId: string; userId: string }): string;
function getRoute(name: "supportMessages", tokenParams: { company: string; tab?: string }): string;
function getRoute(name: "bokioBusinessAccountOffboarding", tokenParams: { company: string }): string;
function getRoute(name: "sveaBankConsent", tokenParams: { company: string }): string;
function getRoute(name: "sveaKycScene", tokenParams: { company: string }): string;
function getRoute(name: "notificationSettings", tokenParams: { company: string }): string;
function getRoute(name: "notificationRedirect", tokenParams: { company: string; area: string; areaType: string; itemType?: string; itemId?: string }): string;
function getRoute(name: "exportSIEFile", tokenParams: { company: string; settingsId: string }): string;
function getRoute(name: "customTemplates", tokenParams: { company: string; templateId?: string }): string;
function getRoute(name: "automationRules", tokenParams: { company: string }): string;
function getRoute(name: "mirChangeSignatory", tokenParams: { company: string }): string;
function getRoute(name: "removeImport", tokenParams: { company: string }): string;
/* Bookkeeping */
function getRoute(name: "hmrcAuth", tokenParams?: {  }): string;
function getRoute(name: "accounting", tokenParams: { company: string }): string;
function getRoute(name: "preBookkeep", tokenParams: { company: string; receiptId?: string }, queryParams?: { noPrediction?: boolean; category?: string; showSupport?: boolean }): string;
function getRoute(name: "preBookkeepSupplierInvoice", tokenParams: { company: string; receiptId?: string }, queryParams?: { supplierId?: string; supplierInvoiceId?: string; total?: number; invoiceDate?: string; dueDate?: string }): string;
function getRoute(name: "bookkeepReceipt", tokenParams: { company: string; category: string; receiptId?: string }, queryParams?: { noPrediction?: boolean; tab?: string; templateId?: string; showSupport?: boolean }): string;
function getRoute(name: "recordingCheckList", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "recordingCheckCreate", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "recordingCheckView", tokenParams: { company: string; id: string }, queryParams?: {  }): string;
function getRoute(name: "bookkeepExpense", tokenParams: { company: string; expenseId: string; receiptId?: string }, queryParams?: { showSupport?: boolean }): string;
function getRoute(name: "bookkeepSupplier", tokenParams: { company: string; supplierInvoiceId: string; receiptId?: string }, queryParams?: { showSupport?: boolean }): string;
function getRoute(name: "bookkeepTransaction", tokenParams: { company: string; transactionId: string; receiptId?: string }, queryParams?: { showSupport?: boolean }): string;
function getRoute(name: "editVerification", tokenParams: { company: string; id: string; returnUrl: string }): string;
function getRoute(name: "attest", tokenParams: { company: string; id: string; returnUrl: string }): string;
function getRoute(name: "eventsToRecord", tokenParams: { company: string; rowId?: string; eventType?: string }, queryParams?: { runsync?: boolean }): string;
function getRoute(name: "receiptImage", tokenParams: { company: string; receiptId?: string }, queryParams?: { v?: number }): string;
function getRoute(name: "downloadReceipt", tokenParams: { company: string; receiptId: string }): string;
function getRoute(name: "receiptThumbnail", tokenParams: { company: string; receiptId?: string; version?: number; imageSize: number }): string;
function getRoute(name: "receiptThumbnailAlt", tokenParams: { company: string; receiptId: string; version?: number; imageSize: number }): string;
function getRoute(name: "receiptExtraImage", tokenParams: { company: string; imageId?: string }, queryParams?: { v?: number }): string;
function getRoute(name: "loginToArsRedovisningOnline", tokenParams: { company: string }): string;
function getRoute(name: "importSi", tokenParams: { company: string }): string;
/* Bank */
function getRoute(name: "bankImport", tokenParams: { company: string }, queryParams?: { account?: string }): string;
function getRoute(name: "bankList", tokenParams: { company: string }, queryParams?: { plan?: string; scrollTo?: string }): string;
function getRoute(name: "bokioBusinessAccountMigration", tokenParams: { company: string }): string;
function getRoute(name: "sveaOnboarding", tokenParams: { company: string }): string;
function getRoute(name: "sveaOnboardingAgreementPdf", tokenParams: { company: string }): string;
function getRoute(name: "sveaAddInternationalPaymentsScene", tokenParams: { company: string }): string;
function getRoute(name: "sveaInternationalPayments", tokenParams: { company: string }, queryParams?: { open?: string }): string;
function getRoute(name: "sveaPreregistrationViewScene", tokenParams: { company: string; id: string }): string;
function getRoute(name: "sveaMatchingScene", tokenParams: { company: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "sveaAddSavingsAccount", tokenParams: { company: string }): string;
function getRoute(name: "sveaSwishOverview", tokenParams: { company: string }): string;
function getRoute(name: "sveaSwishOnboarding", tokenParams: { company: string }): string;
function getRoute(name: "bankManage", tokenParams: { company: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "bankAccountEdit", tokenParams: { company: string; account: string }): string;
function getRoute(name: "bankAccount", tokenParams: { company: string; account: string; tab?: string }, queryParams?: { transaction?: string; pageSize?: number; pageNumber?: number; searchTerm?: string }): string;
function getRoute(name: "bankInbox", tokenParams: { company: string }): string;
function getRoute(name: "sveaInboxAttachmentFile", tokenParams: { company: string }, queryParams: { messageId: string; attachmentId: string }): string;
function getRoute(name: "bankAccountQrCode", tokenParams: { company: string }, queryParams: { bankAccountId: string }): string;
function getRoute(name: "bankPayments", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "bankCreatePayment", tokenParams: { company: string }, queryParams?: { receiptId?: string; receiptType?: m.Core.Contracts.Todo.ReceiptCategoryType }): string;
function getRoute(name: "bankCreatePaymentSuccess", tokenParams: { company: string; paymentId: string }, queryParams?: { supplierInvoiceId?: string }): string;
function getRoute(name: "bokioBusinessAccountOnboardingSteps", tokenParams: { company: string; step?: string }): string;
function getRoute(name: "mirOnboardingAgreementPdf", tokenParams: { company: string; onboardingId: string }): string;
function getRoute(name: "bankStatementPdf", tokenParams: { company: string }, queryParams: { bankAccountId: string; fromDate: string; toDate: string }): string;
function getRoute(name: "transactionDetailsPdf", tokenParams: { company: string }, queryParams: { bankAccountId: string; transactionId: string }): string;
function getRoute(name: "blockedBankList", tokenParams: { company: string }): string;
/* Supplier invoices */
function getRoute(name: "suppliersInvoicesAll", tokenParams: { company: string }): string;
function getRoute(name: "supplierInvoices", tokenParams: { company: string; supplierId: string }): string;
function getRoute(name: "supplierInvoice", tokenParams: { company: string; invoiceId: string }): string;
function getRoute(name: "suppliers", tokenParams: { company: string }): string;
function getRoute(name: "suppliersImport", tokenParams: { company: string }): string;
function getRoute(name: "supplierInvoiceQrCode", tokenParams: { company: string }, queryParams: { qrJson: string }): string;
/* Customer invoices */
function getRoute(name: "periodicReport", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "invoicesShow", tokenParams: { company: string; invoiceId: string }, queryParams?: {  }): string;
function getRoute(name: "invoicesCreate", tokenParams: { company: string; invoiceType?: string }, queryParams?: { customerId?: string; recurringInvoiceEnabled?: boolean }): string;
function getRoute(name: "invoicesEdit", tokenParams: { company: string; invoiceId: string }, queryParams?: {  }): string;
function getRoute(name: "invoices", tokenParams: { company: string; mode?: string }, queryParams?: { completedQuickGenerate?: boolean }): string;
function getRoute(name: "articles", tokenParams: { company: string }): string;
function getRoute(name: "articlesImport", tokenParams: { company: string }): string;
function getRoute(name: "rotRutErrands", tokenParams: { company: string; mode?: string }): string;
function getRoute(name: "logoImageUrl", tokenParams: { company: string; version: string }): string;
function getRoute(name: "factoringActivation", tokenParams: { company: string }): string;
function getRoute(name: "exportArticles", tokenParams: { company: string }): string;
function getRoute(name: "customerDownload", tokenParams: { company: string; id: string }): string;
function getRoute(name: "invoiceLatestPdf", tokenParams: { company: string; invoiceId: string; invoiceSystem?: string }): string;
function getRoute(name: "invoicePdf", tokenParams: { company: string }, queryParams: { invoiceId: string; reminderId?: string; invoiceSystem?: string }): string;
function getRoute(name: "rotXML", tokenParams: { company: string }, queryParams: { rotIds: string[] }): string;
function getRoute(name: "rutXML", tokenParams: { company: string }, queryParams: { rutIds: string[] }): string;
function getRoute(name: "supplierInvoiceImage", tokenParams: { company: string; receiptId?: string }): string;
function getRoute(name: "exportInvoices", tokenParams: { company: string }): string;
function getRoute(name: "customersImport", tokenParams: { company: string }): string;
function getRoute(name: "customers", tokenParams: { company: string; mode?: string }, queryParams?: { add?: boolean; invoiceViewOption?: string }): string;
function getRoute(name: "customerInvoices", tokenParams: { company: string; customerId: string; mode?: string }, queryParams?: {  }): string;
function getRoute(name: "exportCustomers", tokenParams: { company: string }): string;
function getRoute(name: "exportAccountsReceivable", tokenParams: { company: string }, queryParams?: { date?: string }): string;
function getRoute(name: "exportAccountsPayable", tokenParams: { company: string }, queryParams?: { date?: string }): string;
function getRoute(name: "invoiceAttachment", tokenParams: { company: string; attachmentId: string }): string;
function getRoute(name: "quotesCreate", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "quotesEdit", tokenParams: { company: string; quoteId: string }, queryParams?: {  }): string;
function getRoute(name: "quoteDetails", tokenParams: { company: string; quoteId: string }, queryParams?: { justPublished?: boolean }): string;
function getRoute(name: "quotes", tokenParams: { company: string; mode?: string }, queryParams?: {  }): string;
function getRoute(name: "quotePdf", tokenParams: { company: string; quoteId: string }): string;
function getRoute(name: "recurringInvoiceDetailsOverview", tokenParams: { company: string; recurringInvoiceId: string }, queryParams?: {  }): string;
function getRoute(name: "recurringInvoices", tokenParams: { company: string; mode?: string }, queryParams?: {  }): string;
/* Expenses */
function getRoute(name: "expenses", tokenParams: { company: string }): string;
function getRoute(name: "expensesUpload", tokenParams: { company: string }): string;
function getRoute(name: "expensesUploadShow", tokenParams: { company: string; id: string }): string;
function getRoute(name: "expenseReceiptView", tokenParams: { company: string; id: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "expensesPaymentsList", tokenParams: { company: string }): string;
function getRoute(name: "expensesPayments", tokenParams: { company: string }): string;
function getRoute(name: "showExpensePayment", tokenParams: { company: string; paymentId: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "expensesPermissions", tokenParams: { company: string }): string;
function getRoute(name: "expensesOverview", tokenParams: { company: string }): string;
function getRoute(name: "expenseSettings", tokenParams: { company: string }): string;
function getRoute(name: "myExpenseAccounts", tokenParams: { company: string }): string;
function getRoute(name: "companyCards", tokenParams: { company: string }): string;
function getRoute(name: "convertToExpenses", tokenParams: { company: string; receiptId: string }, queryParams?: { returnUrl?: string }): string;
/* Salary */
function getRoute(name: "salarySettings", tokenParams: { company: string; returnUrl: string }): string;
function getRoute(name: "salaryPayrollPayslipShow", tokenParams: { company: string; year: string; month: string; id: string; fromEmployee?: string }): string;
function getRoute(name: "salaryPayrollTaxreport", tokenParams: { company: string; year: string; month: string }): string;
function getRoute(name: "salaryTaxreportRedirect", tokenParams: { company: string; reportId: string }): string;
function getRoute(name: "myPayslipPdf", tokenParams: { company: string; id: string }): string;
function getRoute(name: "bulkpayslipPdf", tokenParams: { company: string }, queryParams: { isBackup: boolean; independentPayrollId?: string; paydate?: string; year: string | number; month: string | number }): string;
function getRoute(name: "p60Pdf", tokenParams: { company: string }, queryParams: { independentPayrollId: string; payDate: string }): string;
function getRoute(name: "P45Pdf", tokenParams: { company: string }, queryParams: { employeeIds: string[] }): string;
function getRoute(name: "salary2Payroll_old", tokenParams: { company: string; tab?: string }): string;
function getRoute(name: "salaryPayrollDetails", tokenParams: { company: string; paydate: string; independentpayrollid?: string }): string;
function getRoute(name: "salaryAGIdownload", tokenParams: { company: string; year: string | number; month: string | number }, queryParams?: {  }): string;
function getRoute(name: "salaryTaxPeriodList", tokenParams: { company: string }): string;
function getRoute(name: "salaryTaxPeriodView", tokenParams: { company: string; year: string; month: string }): string;
/* Salary/Absence */
function getRoute(name: "salaryAbsense", tokenParams: { company: string }): string;
function getRoute(name: "salaryAGI", tokenParams: { company: string; year: number; month: number }, queryParams?: { returnUrl?: string }): string;
/* Reports */
function getRoute(name: "balanceReport", tokenParams: { company: string }): string;
function getRoute(name: "balanceReportAccountPage", tokenParams: { company: string; accountNo: string }, queryParams?: {  }): string;
function getRoute(name: "vatList", tokenParams: { company: string; tab?: string }): string;
function getRoute(name: "vatReport", tokenParams: { company: string; year: number; period: number }): string;
function getRoute(name: "vatReport2", tokenParams: { company: string; year: number; period: number }): string;
function getRoute(name: "vatReportHmrc", tokenParams: { company: string; key: string }): string;
function getRoute(name: "vatCheck", tokenParams: { company: string; year: number; period: number }): string;
function getRoute(name: "hmrcVatCheck", tokenParams: { company: string; key: string }): string;
function getRoute(name: "resultReport", tokenParams: { company: string }, queryParams?: { filter?: string }): string;
function getRoute(name: "tagReport", tokenParams: { company: string }, queryParams?: { splitby?: string }): string;
function getRoute(name: "drillDownReport", tokenParams: { company: string }): string;
function getRoute(name: "resultReportAccountPage", tokenParams: { company: string; accountNo: string }, queryParams?: { prev?: string; filter?: string }): string;
function getRoute(name: "ledgerReconcilliation", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "ledgerReport", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "accountedList", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "accountingCheckShow", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "checkVerifications", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "vatReportXmlDownload", tokenParams: { company: string }, queryParams: { savedReportId: string }): string;
function getRoute(name: "balanceReportPdf", tokenParams: { company: string; fromDate: string; toDate: string }): string;
function getRoute(name: "balanceReportExcel", tokenParams: { company: string; fromDate: string; toDate: string }): string;
function getRoute(name: "resultReportPdf", tokenParams: { company: string; fromDate: string; toDate: string; allowedTagTypeValueId?: string }): string;
function getRoute(name: "resultReportExcel", tokenParams: { company: string; fromDate: string; toDate: string; allowedTagTypeValueId?: string }): string;
function getRoute(name: "ledgerPdf", tokenParams: { company: string; fromDate: string; toDate: string; showDeleted: boolean }): string;
function getRoute(name: "trialBalanceExcel", tokenParams: { company: string; fromDate: string; toDate: string }): string;
function getRoute(name: "ledgerAccountExcel", tokenParams: { company: string; fromDate: string; toDate: string; account: string }): string;
function getRoute(name: "invoicesReport", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "salaryReports", tokenParams: { company: string }): string;
function getRoute(name: "salaryReportSalaryTotal", tokenParams: { company: string }): string;
function getRoute(name: "salaryReportVacationSummary", tokenParams: { company: string }): string;
function getRoute(name: "salaryReportSickDays", tokenParams: { company: string }): string;
function getRoute(name: "salaryReportHourlyPaid", tokenParams: { company: string }): string;
function getRoute(name: "salaryReportVacationDebt", tokenParams: { company: string }): string;
function getRoute(name: "agedDebtorsReport", tokenParams: { company: string }, queryParams?: { invoiceViewOption?: string }): string;
function getRoute(name: "agedDebtorsReportPdf", tokenParams: { company: string }, queryParams?: { toDate?: string }): string;
function getRoute(name: "agedCreditorsReport", tokenParams: { company: string }): string;
function getRoute(name: "accountPayablePdf", tokenParams: { company: string }, queryParams?: { date?: string }): string;
/* EmployeesWithContracts */
function getRoute(name: "employeeList", tokenParams: { company: string; tab?: string }): string;
function getRoute(name: "employeeAdd", tokenParams: { company: string; mode?: string; id?: string; periodSettingId?: string; vacationSetting?: string }): string;
function getRoute(name: "manageActiveEmployees", tokenParams: { company: string }): string;
function getRoute(name: "employeeDetails", tokenParams: { company: string; mode?: string; id?: string; periodSettingId?: string; vacationSetting?: string }): string;
function getRoute(name: "employeeVacationTrackingForm", tokenParams: { company: string; id?: string; periodSettingId?: string }): string;
function getRoute(name: "employeeVacationForm", tokenParams: { company: string; id?: string; periodSettingId?: string }): string;
function getRoute(name: "vacationTracking", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "vacationTrackingSetup", tokenParams: { company: string }): string;
function getRoute(name: "employeeView", tokenParams: { company: string; id: string; tab?: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "editDirector", tokenParams: { company: string; id: string }): string;
function getRoute(name: "contractView", tokenParams: { company: string; id: string; periodSettingId: string }): string;
function getRoute(name: "contractAdd", tokenParams: { company: string; id: string; periodSettingId: string }): string;
function getRoute(name: "contractEdit", tokenParams: { company: string; id: string; periodSettingId: string }): string;
function getRoute(name: "myContractView", tokenParams: { company: string; periodSettingId: string }): string;
function getRoute(name: "myEmployee", tokenParams: { company: string; tab?: string }): string;
function getRoute(name: "myEmployeeSalary", tokenParams: { company: string }): string;
function getRoute(name: "employeesImport", tokenParams: { company: string }): string;
function getRoute(name: "exportEmployees", tokenParams: { company: string }): string;
function getRoute(name: "employeeDownload", tokenParams: { company: string; id: string }): string;
function getRoute(name: "vacationYearEnd", tokenParams: { company: string; startDate: string }): string;
function getRoute(name: "vacationYearEndEmployeeDetail", tokenParams: { company: string; vacationYearEndEmployeeId: string }): string;
function getRoute(name: "GetMyP45", tokenParams: { company: string }): string;
function getRoute(name: "GetMyP60", tokenParams: { company: string }, queryParams: { payrollId: string }): string;
/* Development */
function getRoute(name: "developerTools", tokenParams: { company: string }): string;
function getRoute(name: "mockTools", tokenParams: { company: string }): string;
function getRoute(name: "stagingEnableBankingScene", tokenParams: { company: string }): string;
/* Closures */
function getRoute(name: "annualReportForm", tokenParams: { company: string; reportId: string }): string;
function getRoute(name: "closureNEAttachment", tokenParams: { company: string; year: string | number; endYear: string | number }): string;
function getRoute(name: "closuresShow", tokenParams: { company: string; year: string | number; endYear: string | number }, queryParams?: { stripeId?: string; checkoutId?: string; paymentSuccess?: boolean }): string;
function getRoute(name: "annualReportArchive", tokenParams: { company: string; year: string | number; endYear: string | number }): string;
function getRoute(name: "FetchFiles", tokenParams: { company: string }, queryParams: { settingId: string }): string;
function getRoute(name: "closures", tokenParams: { company: string }): string;
/* Todo */
function getRoute(name: "todo", tokenParams: { company: string; page?: string; item?: string }): string;
function getRoute(name: "importantDates", tokenParams: { company: string }): string;
function getRoute(name: "preliminaryTaxes", tokenParams: { company: string }): string;
function getRoute(name: "preliminaryTaxesPaymentDetails", tokenParams: { company: string; paymentDetailsId: string }): string;
function getRoute(name: "todoPromotionAvtal24", tokenParams: { company: string; returnUrl: string }): string;
function getRoute(name: "todoPromotionMynt", tokenParams: { company: string; returnUrl: string }): string;
/* Agencies */
function getRoute(name: "agencyIndex", tokenParams: { agencyId: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "createAgency", tokenParams?: { id?: string; step?: string }, queryParams?: { source?: string; invitationId?: string }): string;
function getRoute(name: "preCreateAgency", tokenParams?: {  }, queryParams?: { source?: string; invitationId?: string }): string;
function getRoute(name: "agencyOverview", tokenParams: { agencyId: string }, queryParams?: { source?: string; invitationId?: string }): string;
function getRoute(name: "agencyPartnerInfo", tokenParams: { agencyId: string }, queryParams?: { source?: string; invitationId?: string }): string;
function getRoute(name: "agencyClients", tokenParams: { agencyId: string }, queryParams?: { clientCompanyId?: string; newAgency?: boolean; setColumnPreference?: boolean }): string;
function getRoute(name: "agencyUsers", tokenParams: { agencyId: string }, queryParams?: { inviteAccountant?: boolean; changeOwner?: boolean; cancelInviteId?: string; editUserId?: string; removeUserId?: string }): string;
function getRoute(name: "agencyNotes", tokenParams: { agencyId: string; tab?: string }): string;
function getRoute(name: "agencyClientDetails", tokenParams: { agencyId: string; companyId: string; tab: string }): string;
function getRoute(name: "exportNotes", tokenParams: { agencyId: string }, queryParams: { companyId: string }): string;
function getRoute(name: "AgencyPartnerContractPdf", tokenParams: { agencyId: string }): string;
function getRoute(name: "agencySettings", tokenParams: { agencyId: string }): string;
function getRoute(name: "agencyImportClients", tokenParams: { agencyId: string }): string;
function getRoute(name: "agencyClientRequests", tokenParams: { agencyId: string }): string;
function getRoute(name: "agencyDetailsScene", tokenParams: { agencyId: string }): string;
function getRoute(name: "agencyNotificationSettings", tokenParams: { agencyId: string }): string;
function getRoute(name: "agencyExport", tokenParams: { agencyId: string }): string;
function getRoute(name: "leaveAgency", tokenParams: { agencyId: string }): string;
function getRoute(name: "deleteAgency", tokenParams: { agencyId: string }): string;
function getRoute(name: "backofficeFeatureToggles", tokenParams: { agencyId: string }): string;
function getRoute(name: "agencyPartnerProgram", tokenParams: { agencyId: string }): string;
function getRoute(name: "agencyQuoteRequestsList", tokenParams: { agencyId: string }, queryParams?: { acceptedQuote?: boolean }): string;
function getRoute(name: "agencyQuoteRequest", tokenParams: { agencyId: string; company: string; applicationId: string }): string;
function getRoute(name: "agencyNotificationRedirect", tokenParams: { agencyId: string; area: string; areaType: string; itemType?: string; itemId?: string; linkTo?: string }): string;
/* BackOffice */
function getRoute(name: "backOfficeAcceptInvitation", tokenParams: { partner: string; invite: string }, queryParams: { status: string }): string;
function getRoute(name: "recordingSupport", tokenParams: { partner: string }): string;
function getRoute(name: "supportTicketReceiptImage", tokenParams: { partner: string }, queryParams: { v?: number; companyId: string; receiptId: string }): string;
function getRoute(name: "supportTicketReceiptExtraPage", tokenParams: { partner: string }, queryParams: { v?: number; companyId: string; receiptId: string }): string;
function getRoute(name: "backOffice", tokenParams: { partner: string }): string;
function getRoute(name: "backOfficeAdmin", tokenParams: { partner: string }): string;
function getRoute(name: "backOfficeDashboard", tokenParams: { partner: string }): string;
function getRoute(name: "backOfficeAdminAgencies", tokenParams: { partner: string }): string;
function getRoute(name: "backOfficeAdminAgency", tokenParams: { partner: string; agency: string }): string;
function getRoute(name: "backOfficeTodo", tokenParams: { partner: string }): string;
function getRoute(name: "backOfficeCompaniesDashboard", tokenParams: { partner: string }): string;
function getRoute(name: "backOfficeUsers", tokenParams: { partner: string }): string;
function getRoute(name: "backOfficeSettings", tokenParams: { partner: string }): string;
/* Companies */
function getRoute(name: "root", tokenParams?: {  }): string;
function getRoute(name: "companyIndex", tokenParams: { company: string }, queryParams?: { returnUrl?: string }): string;
function getRoute(name: "selectCompanyFromMail", tokenParams?: {  }, queryParams?: { type?: string; keys?: string[] }): string;
function getRoute(name: "selectCompany", tokenParams?: {  }, queryParams?: { deepLink?: string }): string;
function getRoute(name: "dashboard", tokenParams: { company: string }, queryParams?: { showDemoModel?: boolean; welcome?: boolean }): string;
function getRoute(name: "onboarding", tokenParams: { company: string }): string;
/* Services */
function getRoute(name: "accountingServices", tokenParams: { company: string }): string;
function getRoute(name: "submitAgencyInterest", tokenParams: { company: string; agencyName: string }): string;
function getRoute(name: "businessServices", tokenParams: { company: string }): string;
/* Accounting */
function getRoute(name: "bankTransactions", tokenParams: { company: string }, queryParams?: {  }): string;
function getRoute(name: "assetsManagement", tokenParams: { company: string }): string;
/* Uploads */
function getRoute(name: "uploads", tokenParams: { company: string; tab?: string }): string;
function getRoute(name: "downloadAdditionalFile", tokenParams: { company: string; id: string }): string;
function getRoute(name: "downloadAdditionalFilesAsZip", tokenParams: { company: string }): string;
/* Integrations */
function getRoute(name: "integrations", tokenParams: { company: string }): string;
function getRoute(name: "stripe", tokenParams: { company: string }): string;
function getRoute(name: "stripeCallback", tokenParams?: {  }, queryParams?: { state?: string }): string;
function getRoute(name: "stripeOnboardingMock", tokenParams?: {  }): string;
/* CustomerPortal */
function getRoute(name: "invoicePortal", tokenParams: { id: string; token: string }, queryParams?: { messageId?: string }): string;
function getRoute(name: "invoicePortalPdf", tokenParams: undefined, queryParams: { id: string; token: string; inViewMode?: boolean }): string;
/* DirectorsPayroll */
function getRoute(name: "employerDirectorSetup", tokenParams: { company: string }): string;
function getRoute(name: "directorsPayrollList", tokenParams: { company: string }): string;
function getRoute(name: "directorsPayrollDetails", tokenParams: { company: string; paydate: string; independentpayrollid?: string }): string;
function getRoute(name: "addDirector", tokenParams: { company: string }): string;
/* Sales */
function getRoute(name: "dailyTakings", tokenParams: { company: string; accessCode?: string }): string;
function getRoute(name: "ecommerceJournalPdf", tokenParams: { company: string; id: string }): string;
function getRoute(name: "ecommerceOrderList", tokenParams: { company: string }): string;
function getRoute(name: "ecommerceBookkeeping", tokenParams: { company: string }, queryParams?: { firstBookkeeping?: boolean }): string;
function getRoute(name: "ecommercepaymentmethods", tokenParams: { company: string; platform: string }): string;
function getRoute(name: "ecommercesetup", tokenParams: { company: string; platform: string }): string;
function getRoute(name: "ecommerce", tokenParams: { company: string }, queryParams?: { connection?: string }): string;
function getRoute(name: "ecommercebookkeepinvoicejournalpage", tokenParams: { company: string; journalId: string }): string;
// This line guards against using the template function instead of the generated.
function getRoute(name: "never", tokenParams?: {}, searchParams?: {}): string;
function getRoute(name: string, tokenParams?: object, searchParams?: object): string {
	let route = routes[name] || "";

	if (tokenParams) {
		const pathFunction = compile(route);
		route = pathFunction(tokenParams);
	}

	if (searchParams) {
		const [pathname, search] = route.split("?");
		const routeSearch = qs.parse(search);
		const searchBody = qs.stringify({ ...routeSearch, ...searchParams }, { strictNullHandling: true });

		route = `${pathname}?${searchBody}`;
	}

	return route;
}

export { getRoute };
