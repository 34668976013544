import * as React from "react";

import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useCompanyUser } from "@bokio/shared/state/requests";

import { cashbackContext, emptyCashbackContract } from "./CashbackContext";

import BokioPlan = m.Entities.BokioPlan;
import SubscriptionInterval = m.Bokio.Common.Billing.Model.SubscriptionInterval;
type CashbackValuesDto = m.Bokio.Backbone.Web.Controllers.Settings.CashbackValuesDto;

export const CashbackContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { company } = useCompanyUser();
	const companyId = company?.Id;
	const [getCashbackContracts, cashbackContractsRequest] = useLazyApi(
		proxy.Bokio.Common.Web.Settings.SubscriptionController.GetCashbackContracts.Get,
	);
	const [getCashbackDefaultValue, cashbackDefaultValueRequest] = useLazyApi(
		proxy.Bokio.Common.Web.Settings.SubscriptionController.GetCashbackDefaultValue.Get,
	);

	React.useEffect(() => {
		if (companyId) {
			getCashbackContracts(companyId);
			getCashbackDefaultValue(companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);

	const defaultCashback = cashbackContractsRequest.data?.Data?.DefaultCashback ?? emptyCashbackContract;
	const availableCashbackOffer = cashbackContractsRequest.data?.Data?.AvailableCashbackOffer;

	const bbaValues = cashbackDefaultValueRequest.data?.Data?.BBAValues;
	const bookkeepingValues = cashbackDefaultValueRequest.data?.Data?.BookkeepingValues;

	const getEstimatedCashback = (values: CashbackValuesDto | undefined) => {
		if (!values || defaultCashback === emptyCashbackContract) {
			return 0;
		}

		return (
			(values.CardSpend * (defaultCashback.CardSpendCashbackPercentage / 100) +
				values.DepositedCapital * (defaultCashback.DepositedCapitalCashbackPercentage / 100)) /
			12
		);
	};

	const estimatedCashbackBasedOnBBA = getEstimatedCashback(bbaValues);
	const estimatedCashbackBasedOnBookkeeping = getEstimatedCashback(bookkeepingValues);

	const estimatedCashback =
		estimatedCashbackBasedOnBBA > estimatedCashbackBasedOnBookkeeping
			? estimatedCashbackBasedOnBBA
			: estimatedCashbackBasedOnBookkeeping;

	const premiumPlusCost = cashbackContractsRequest.data?.Data?.PremiumPlusMonthlyCost || 0;
	const premiumQuarterlyCost = cashbackContractsRequest.data?.Data?.PremiumQuarterlyMonthlyCost || 0;
	const premiumYearlyCost = cashbackContractsRequest.data?.Data?.PremiumYearlyMonthlyCost || 0;

	const wouldBenefitFromPlus = (
		compareWithPlan: BokioPlan,
		compareWithInterval: SubscriptionInterval,
		useValues: "BBA" | "Bookkeeping" | "Both",
	) => {
		const cashbackValue =
			useValues === "Both"
				? estimatedCashback
				: useValues == "BBA"
					? estimatedCashbackBasedOnBBA
					: estimatedCashbackBasedOnBookkeeping;

		if (compareWithPlan === m.Entities.BokioPlan.Free) {
			return cashbackValue > premiumPlusCost;
		} else if (
			compareWithPlan === m.Entities.BokioPlan.Premium &&
			compareWithInterval === SubscriptionInterval.Quarter
		) {
			return cashbackValue > premiumPlusCost - premiumQuarterlyCost;
		} else if (compareWithPlan === m.Entities.BokioPlan.Premium && compareWithInterval === SubscriptionInterval.Year) {
			return cashbackValue > premiumPlusCost - premiumYearlyCost;
		}

		return false;
	};

	return (
		<cashbackContext.Provider
			value={{
				initialized:
					defaultCashback !== emptyCashbackContract && bbaValues !== undefined && bookkeepingValues !== undefined,
				defaultContract: defaultCashback,
				availableContract: availableCashbackOffer,
				bbaValues: bbaValues,
				bookkeepingValues: bookkeepingValues,
				estimatedCashback: estimatedCashback,
				estimatedCashbackBasedOnBBA: estimatedCashbackBasedOnBBA,
				estimatedCashbackBasedOnBookkeeping: estimatedCashbackBasedOnBookkeeping,
				premiumPlusMonthlyCost: premiumPlusCost,
				wouldBenefitFromPlus: wouldBenefitFromPlus,
			}}
		>
			{children}
		</cashbackContext.Provider>
	);
};
