import upperFirst from "lodash-es/upperFirst";
import * as React from "react";

import { getLegacyMarginClassNames } from "@bokio/designsystem/infrastructure/legacyComponentInterop/legacyMarginProp";
import SlideAnimation from "@bokio/elements/Animations/SlideAnimation";
import DropdownIcon from "@bokio/elements/DropdownIcon/DropdownIcon";
import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import type { NoticeColor } from "./Notice.types";
import type { LegacyMarginProp } from "@bokio/designsystem/infrastructure/legacyComponentInterop/legacyMarginProp";

import * as styles from "./notice.scss";

export interface NoticeProps {
	/**
	 * Title of the notice.
	 * Title is always bold compared to the children.
	 * If you want only a line of plain text, use children instead.
	 */
	title?: React.ReactNode;
	// Children is explicility typed because sometimes we pass the props around.
	children?: React.ReactNode;
	color?: NoticeColor;
	/**
	 * @default true
	 */
	hasIcon?: boolean;
	/**
	 * Legacy margin property, default value is for keeping legacy behaviour.
	 * Automatically cancelled within spacing group (SG).
	 * @default ["top"]
	 */
	margin?: LegacyMarginProp;
	/**
	 * Use this to render a <Button> or <ButtonGroup>
	 * @example
	 * <Notice
	 *   fixActionRenderer={() =>
	 *     <Button
	 *       appearance="secondary"
	 *       onclick={() => {}}
	 *     >
	 *       Button
	 *     </Button>
	 *   }
	 * >
	 *   Content
	 * </Notice>
	 */
	fixActionRenderer?: (() => React.ReactNode) | React.ReactNode;
	className?: string;
	dismissible?: boolean;
	onDismiss?: () => void;
	testId?: string;
	initialExpand?: boolean;
	collapsible?: boolean;
	// This can be removed once we removed InfoBar
	deprecatedInfoBarTestId?: boolean;
	isClickable?: boolean;
}

interface NoticeState {
	open: boolean;
	visible: boolean;
}

//@TODO this should be made with an icon instead of !, i.
const iconConstructorMapping: Record<NoticeColor, () => React.ReactNode> = {
	error: () => <span className={styles.symbolCircle}>!</span>,
	grey: () => <span className={styles.symbolCircle}>i</span>,
	info: () => <span className={styles.symbolCircle}>i</span>,
	success: () => (
		<span className={styles.iconContainer}>
			<Icon name="check" size="24" />
		</span>
	),
	warning: () => <span className={styles.symbolCircle}>!</span>,
	white: () => <span className={styles.symbolCircle}>i</span>,
	tips: () => (
		<span className={styles.iconContainer}>
			<Icon name="lamp" color="yellow" size="24" />
		</span>
	),
	promotion: () => (
		<span className={styles.iconContainer}>
			<Icon name="megaphone" color="burgundy" size="24" />
		</span>
	),
};

export const Notice: React.FC<NoticeProps> = ({
	className,
	color,
	children,
	title,
	hasIcon = true,
	// SS 2022-01-07
	// Default to top to keep legacy behaviour so I don't have to eject a bunch of existing usage.
	// This is automatically cancelled inside a Spacing Group.
	margin = ["top"],
	dismissible,
	onDismiss,
	testId,
	initialExpand = false,
	collapsible = true,
	fixActionRenderer,
	deprecatedInfoBarTestId,
	isClickable = true,
}) => {
	const [state, setState] = React.useState<NoticeState>({
		open: title && !initialExpand ? false : true,
		visible: true,
	});
	const iconType = color || "info";

	const noticeClasses = mergeClassNames(
		className,
		getLegacyMarginClassNames(margin),
		styles.notice,
		styles[`color${upperFirst(color || iconType)}`],
		children && title && isClickable && collapsible && styles.clickable,
	);

	const hideNotice = () => {
		setState(s => ({ ...s, visible: false }));
		onDismiss && onDismiss();
	};

	if (!state.visible) {
		return null;
	}

	return (
		<div
			className={noticeClasses}
			onClick={() => children && title && setState(s => ({ ...s, open: !s.open }))}
			data-testid={testId}
		>
			{/* Legacy <Notice><div><Button></Button></div></Notice> will have their first line of text misaligned with icon. */}
			{hasIcon && (
				<div className={mergeClassNames(styles.symbol, fixActionRenderer && styles.alignSymbolWithActionButton)}>
					{iconConstructorMapping[iconType]?.()}
				</div>
			)}

			<div className={styles.contentAndButtonWrapper}>
				<div
					className={mergeClassNames(
						styles.content,
						// SS 2022-12-13
						// The grid to center the text content toward the Notice block.
						// This makes the first line of text to look more centered toward the icon.
						// But this doesn't work with the legacy usage when we didn't have `fixActionRenderer` previously.
						//
						// and the grid layout around the content and button should only be applied when there's button specified from fixActionRenderer,
						// This is to be compatible with the legacy layout where sometimes devs code like
						// <Notice><div><Button>  </Button></div></Notice>
						// so the div in the example above can correctly get the full width.
						fixActionRenderer && styles.contentGrid,
						"testId_noticeContent",
					)}
				>
					<div>
						{title ? (
							<React.Fragment>
								<div className={styles.titleRow}>
									<div className={styles.title}>{title}</div>
									{!!children && collapsible && <DropdownIcon active={state.open} />}
								</div>
								{collapsible ? (
									<SlideAnimation targetHeight={100} isVisible={state.open}>
										{children}
									</SlideAnimation>
								) : (
									children
								)}
							</React.Fragment>
						) : (
							children
						)}
					</div>
				</div>
				{fixActionRenderer && (
					<div>{typeof fixActionRenderer === "function" ? fixActionRenderer() : fixActionRenderer}</div>
				)}
			</div>

			{dismissible && (
				<div className={styles.iconCancelBox}>
					<Icon
						name="cancel"
						onClick={hideNotice}
						size="16"
						className={styles.iconCancel}
						testId={`${testId}_${deprecatedInfoBarTestId ? "Dismiss" : "Close"}`}
					/>
				</div>
			)}
		</div>
	);
};
