// extracted by mini-css-extract-plugin
export var linethrough = "pVa";
export var batch = "qVa";
export var batchRow = "rVa";
export var margin0 = "sVa";
export var mobileHeader = "tVa";
export var buttons = "uVa";
export var error = "vVa";
export var originalColor = "wVa";
export var paymentFor = "xVa";
export var date = "yVa";
export var sum = "zVa";
export var tr = "AVa";
export var expand = "BVa";
export var paymentWarning = "a_a";