import * as React from "react";

import { trackTrace } from "@bokio/utils/t";

export const readFromLocalStorage = <T>(key: string): T | undefined => {
	try {
		const value = localStorage ? localStorage.getItem(key) : null;
		if (value) {
			return JSON.parse(value);
		}
		return undefined;
	} catch (error) {
		return undefined;
	}
};

export const writeToLocalStorage = <T>(key: string, value: T | undefined) => {
	try {
		if (value) {
			localStorage.setItem(key, JSON.stringify(value));
		} else {
			localStorage.removeItem(key);
		}
	} catch (error) {
		trackTrace("Could not write to localstorage", { key, error }, "warn");
	}
};

type Transition<S> = (oldState: S) => S;

interface UseLocalStorageProps<S> {
	storageKey: string;
	initialState: S;
}
/**
 * Equivalent to React.useState, with the addition that
 * it also stores the state in localStorage. This means
 * that the state will be persistent over sessions and
 * page reloads.
 *
 * Use cases:
 * - Persisting state in long forms.
 * - "Remember my choice"-checkboxes
 * - "Dont show this again"-checkboxes
 * @param props
 */
export const useLocalStorage = <S>(props: UseLocalStorageProps<S>): [S, (t: Transition<S>) => void] => {
	const storedState: S = readFromLocalStorage(props.storageKey) || props.initialState;
	const [state, setState] = React.useState<S>(storedState);

	return [
		state,
		transition => {
			setState(oldState => {
				const newState = transition(oldState);
				writeToLocalStorage(props.storageKey, newState);
				return newState;
			});
		},
	];
};
