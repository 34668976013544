import type * as React from "react";

interface MultiProviderProps {
	providers: React.FC<React.PropsWithChildren>[];
}

export const MultiContextProvider: React.FC<React.PropsWithChildren<MultiProviderProps>> = ({
	providers,
	children,
}) => (
	<>
		{/* Reverse so they render in the order you specify them*/}
		{providers
			.slice()
			.reverse()
			.reduce(
				(acc, Provider) => (
					<Provider>{acc}</Provider>
				),
				children,
			)}
	</>
);
