import * as React from "react";

import { AppMessageType, useAppContext } from "@bokio/contexts/AppContext/AppContext";
import { Steps } from "@bokio/elements/RatingToast/utils";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useAgencyStatus } from "@bokio/shared/state/requests";

import PartnerRatingType = m.Entities.PartnerRatingType;
export const useAgencyCESRating = (
	partnerRatingType: PartnerRatingType,
	data: string,
	ratingTitle: string,
	ratingDescription: string,
	completed?: boolean,
) => {
	const app = useAppContext();

	const { agencyStatus } = useAgencyStatus();

	const renderStepTitle = (step: Steps) => {
		const lang = GeneralLangFactory();
		switch (step) {
			case Steps.Rating:
				return ratingTitle;
			case Steps.Feedback:
				return lang.CESRating_Feedback_Title;
			case Steps.Complete:
				return lang.CESRating_Complete_Title;
		}
	};

	const renderStepDescription = (step: Steps) => {
		const lang = GeneralLangFactory();
		switch (step) {
			case Steps.Rating:
				return "";
			case Steps.Feedback:
				return ratingDescription;
			case Steps.Complete:
				return lang.CESRating_Complete_Description;
		}
	};
	React.useEffect(() => {
		async function checkAndSendMessage() {
			const isRatingOpen = app.messages.some(message => message.type === AppMessageType.CESFeedbackRating);
			// Check if it's already shown
			if (isRatingOpen) {
				return;
			}
			if (!completed) {
				return;
			}

			const hasRated = (
				await proxy.BackOffice.AgencyRatingController.HasUserRated.Get(agencyStatus.Id, partnerRatingType)
			).Data;
			// Don't show if user has already rated or cancelled rating
			if (hasRated) {
				return;
			}
			app.addMessage({
				type: AppMessageType.AgencyCESFeedbackRating,
				ratingType: partnerRatingType,
				renderStepTitle: renderStepTitle,
				renderStepDescription: renderStepDescription,
				persist: true,
				additionalData: data,
			});
		}

		checkAndSendMessage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [completed]);
};
