import { stringify } from "qs";

import HttpError from "./HttpError";

import type { FetchOptions } from "./AppMiddleware";

export function toQueryString(obj: Record<string, unknown>): string {
	return stringify(obj, { allowDots: true });
}

export function fromJson(response: Response) {
	const contentType = response.headers.get("Content-Type");
	const mimeType = contentType && contentType.split(";")[0];

	// NE 2018-08-21
	// If the mimetype is something else than "application/json"
	// then the response.json() call will fail.
	// Unless we handle the case, it will throw an error
	// that typically says something like "Error: can not parse token <"

	// Better to throw an error that we can handle specifically
	// with
	//   catch (err: HttpError)
	// and examine the error details (status code, status text)

	if (mimeType !== "application/json") {
		throw new HttpError(
			response.status,
			`${response.url} failed with status code ${response.status} and status text ${response.statusText}`,
		);
	}

	return response.json();
}

export function withData(method: string, data: Record<string, unknown>, isFormData = false) {
	const options: FetchOptions = {
		method,
		credentials: "include",
		headers: {
			Accept: "application/json, */*",
		},
	};

	if (isFormData) {
		options.body = data;
	} else if (Object.keys(data).length > 0) {
		/* This causes problems when trying to send an object which only contains nullable objects where
		 * all objects should be null. Instead of passing it in as {} you'll need to pass in {foo: undefined}
		 * otherwise core BE will give 415
		 */
		options.headers["Content-Type"] = "application/json;charset=UTF-8";
		options.body = JSON.stringify(data);
	}

	return options;
}
