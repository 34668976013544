import Icon from "@bokio/elements/Icon/Icon";

import * as styles from "./closeButton.scss";

interface CloseButtonProps {
	onClick: () => void;
}

//@TODO This component to create this specific button is unneccessary and should be removed/replaced by Button using component.
export const CloseButton = ({ onClick }: CloseButtonProps) => (
	<button type="button" onClick={onClick} className={styles.closeButton}>
		<Icon name="cancel" size="20" />
	</button>
);
