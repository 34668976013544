import * as React from "react";
import * as ReactDOM from "react-dom";

import { DisableGlobalFileDropZone } from "@bokio/hooks/useDisableGlobalFileDropZone/useDisableGlobalFileDropZone";

import ModalBase from "./ModalBase";

import type { ModalWidth } from "./ModalBase";

export enum ModalCloseReason {
	/** If the user closed the dialog with the esc key */
	Key,
	/** If the user closed the dialog by clicking the background  */
	Background,
	/** If the user closed the dialog by clicking the cross button */
	Button,
}

export interface ModalProps {
	visible: boolean;
	/**
	 * When `true`, ignore any modal actions like open and close.
	 */
	disabled?: boolean;
	disableBackgroundClick?: boolean;
	noHeader?: boolean;
	title?: React.ReactNode;
	width?: ModalWidth;
	testId?: string;
	onClose: (reason: ModalCloseReason) => void;
	/**
	 * When `true`, the first focusable element in modal will be automatically focused on modal mount.
	 *
	 * When a CSS selector (string) is passed, that element will be automatically focused on modal mount
	 *
	 * Defaults to `true`.
	 */
	autoFocus?: boolean | string;
	/**
	 * Should only be set to `true` when using with deprecated modal hooks (`useModalStack`, `useModalsAsync`).
	 */
	alwaysMounted?: boolean;
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
	onClose,
	visible,
	title,
	noHeader,
	children,
	disableBackgroundClick,
	disabled,
	width,
	testId,
	autoFocus = true,
	alwaysMounted = false,
}) => {
	const modalMountPoint = React.useRef<HTMLDivElement | null>();
	const modalRoot = React.useRef<HTMLElement | null>(null);
	const [mounted, setMounted] = React.useState(false);

	React.useEffect(() => {
		// Create modal-root element when it doesn't exist in dom tree
		const modalId = "modal-root";
		if (!document.getElementById(modalId)) {
			const modalElement = document.createElement("div");
			modalElement.id = modalId;
			document.body.appendChild(modalElement);
		}
		modalMountPoint.current = document.createElement("div");
		modalRoot.current = document.getElementById(modalId);
		modalRoot.current?.appendChild(modalMountPoint.current);

		setMounted(true);

		return () => {
			if (!modalRoot.current || !modalMountPoint.current) {
				return;
			}

			modalRoot.current.removeChild(modalMountPoint.current);
		};
	}, []);

	const handleClose = (reason: ModalCloseReason) => {
		!disabled && visible && onClose(reason);
	};

	const renderModal = () => {
		return (
			<ModalBase
				alwaysMounted={alwaysMounted}
				visible={visible}
				width={width}
				testId={testId}
				title={noHeader ? undefined : title}
				disableEasyClosing={disabled}
				onBackgroundClick={() => !disableBackgroundClick && handleClose(ModalCloseReason.Background)}
				onCloseButtonClick={() => handleClose(ModalCloseReason.Button)}
				onEscape={() => handleClose(ModalCloseReason.Key)}
				autoFocus={autoFocus}
			>
				{/** The `alwaysMounted` check is for backward compatibility with modal hooks. */}
				{!alwaysMounted && <DisableGlobalFileDropZone />}
				{children}
			</ModalBase>
		);
	};

	return modalMountPoint.current
		? ReactDOM.createPortal(mounted ? renderModal() : null, modalMountPoint.current)
		: null;
};
