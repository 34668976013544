import figuresWithLogo from "@bokio/assets/images/figures_with_logo.svg";
import halfCircleAndBox from "@bokio/assets/images/half_circle_and_box.svg";

import type { ModalWidth } from "../Modal/ModalBase";

import * as styles from "./fancyModal.scss";

export const fancyModalBackgroundColors = ["white", "burgundy", "blue", "green"] as const;
export type FancyModalBackgroundColor = (typeof fancyModalBackgroundColors)[number];
export type FancyModalGraphic = "figuresWithLogo" | "halfCircleAndBox";
export type FancyModalImageSize = "medium" | "large";
export type FancyModalWidth = Exclude<ModalWidth, "verySmall">;

export const getSizeClassName = (width: FancyModalWidth | undefined) => {
	switch (width) {
		case "extraWide":
			return styles.contentExtraWide;
		case "wide":
			return styles.contentWide;
		case "small":
			return styles.contentSmall;
		case "medium":
		default:
			return styles.contentMedium;
	}
};

export const getGraphic = (graphic?: FancyModalGraphic) => {
	switch (graphic) {
		case "halfCircleAndBox":
			return halfCircleAndBox;
		case "figuresWithLogo":
		default:
			return figuresWithLogo;
	}
};

export const getBackgroundColorClass = (color: FancyModalBackgroundColor) => {
	switch (color) {
		case "burgundy":
			return styles.burgundyBackground;
		case "blue":
			return styles.blueBackground;
		case "green":
			return styles.greenBackground;
		case "white":
		default:
			return styles.whiteBackground;
	}
};

export const getImageClassName = (size: FancyModalImageSize) => {
	switch (size) {
		case "large":
			return styles.largeImage;
		case "medium":
		default:
			return styles.mediumImage;
	}
};
