import * as React from "react";

import asComponent from "@bokio/utils/asComponent";

import EmailDeliveryContext from "./EmailDeliveryContext";

import type { EmailDeliveryProps } from "./EmailDeliveryContext";

export const withEmailActivity = <OwnProps,>(Component: React.ComponentType<OwnProps & EmailDeliveryProps>) =>
	asComponent((ownprops: OwnProps) => (
		<EmailDeliveryContext.Consumer>{props => <Component {...ownprops} {...props} />}</EmailDeliveryContext.Consumer>
	));

export const useEmailActivity = () => React.useContext(EmailDeliveryContext);
