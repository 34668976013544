import { LottieAnimation } from "@bokio/elements/LottieAnimation/LottieAnimation";
import { mergeClassNames } from "@bokio/utils/classes";

import Icon from "../Icon/Icon";
import { Link } from "../Link/Link";
import Markdown from "../Markdown/Markdown";

import type * as React from "react";

import * as styles from "./ratingToast.scss";

interface CompleteStepProps {
	description: string;
	title: string;
	onClose: () => void;
}

export const CompleteStep: React.FC<CompleteStepProps> = ({ description, title, onClose }) => {
	return (
		<article>
			<header className={styles.completeStepHeader}>
				<Link onClick={onClose} className={styles.closeButton}>
					<Icon name="cancel" />
				</Link>
			</header>
			<section className={styles.complete}>
				<LottieAnimation
					animation={import("@bokio/companies/src/assets/lottieAnimations/ces-completed")}
					height="144px"
					testId={"Rating_Complete"}
				/>
				<span className={mergeClassNames(styles.title, styles.completeTitle)}>{title}</span>
				<Markdown
					className={mergeClassNames(styles.description, styles.completeDescription)}
					markdownContent={description}
				/>
			</section>
		</article>
	);
};
