import * as React from "react";

import { useNotificationActivity } from "@bokio/contexts/NotificationActivityContext/useNotificationActivity";
import Icon from "@bokio/elements/Icon/Icon";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useAgencyStatus, useCompanyUser } from "@bokio/shared/state/requests";
import { mergeClassNames } from "@bokio/utils/classes";

import { CounterBadge } from "../CounterBadge/CounterBadge";
import { NotificationFlyout } from "../NotificationFlyout/NotificationFlyout";
import { TopBarButton } from "../TopBar/TopBarButton";

import * as styles from "./menuTopBarNotificationButton.scss";

interface MenuTopBarNotificationButtonProps {
	isAccountantPanelIconVisible?: boolean;
	backOfficeMode?: boolean;
}
export const MenuTopBarNotificationButton: React.FC<MenuTopBarNotificationButtonProps> = ({
	isAccountantPanelIconVisible,
	backOfficeMode,
}) => {
	const [isNotificationFlyoutOpen, setIsNotificationFlyoutOpen] = React.useState(false);
	const [renderedBadgeCount, setRenderedBadgeCount] = React.useState(0);
	const ping = useNotificationActivity();
	const { company } = useCompanyUser();
	const { agencyStatus } = useAgencyStatus();
	const [executeBadgeCountRequest] = useLazyApi(
		!!agencyStatus?.Id
			? proxy.BackOffice.NotificationController.GetNewNotificationsCount.Get
			: proxy.Notifications.NotificationController.GetNewNotificationsCount.Get,
		{ onSuccess: data => !isNotificationFlyoutOpen && setRenderedBadgeCount(data) },
	);

	React.useEffect(() => {
		if (!isNotificationFlyoutOpen && company !== undefined) {
			executeBadgeCountRequest(company.Id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ping]);

	React.useEffect(() => {
		if (isNotificationFlyoutOpen) {
			// The real reset request is in an useEffect of isOpen in NotificationFlyout
			setRenderedBadgeCount(0);
			return;
		}
	}, [isNotificationFlyoutOpen]);

	React.useEffect(() => {
		(async () => {
			if (company != null || agencyStatus?.Id != null) {
				const id = company?.Id ?? agencyStatus?.Id;
				await executeBadgeCountRequest(id);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company, agencyStatus]);

	return (
		<>
			<TopBarButton
				className={mergeClassNames(styles.button, !isAccountantPanelIconVisible && styles.lastIcon)}
				onClick={() => setIsNotificationFlyoutOpen(!isNotificationFlyoutOpen)}
				testId="OpenNotificationFlyout"
			>
				<div>
					<Icon
						className={styles.iconHover}
						name="notification"
						size="28"
						color={backOfficeMode ? "lightGrey" : "darkGrey"}
					/>
					{renderedBadgeCount > 0 && (
						<span className={styles.badgeWrapper}>
							<CounterBadge size="xsmall" border>
								{renderedBadgeCount > 9 ? "9+" : `${renderedBadgeCount}`}
							</CounterBadge>
						</span>
					)}
				</div>
			</TopBarButton>
			<NotificationFlyout
				companyId={company?.Id ?? agencyStatus?.Id}
				isOpen={isNotificationFlyoutOpen}
				onClose={() => setIsNotificationFlyoutOpen(false)}
				isBackOffice={!!agencyStatus?.Id}
			/>
		</>
	);
};
