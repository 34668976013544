import * as React from "react";

import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export const useFeatureToggle = () => {
	const { companyUser, agencyStatus } = useTopLevelUser();
	const currentFeatures = companyUser?.Features ?? agencyStatus?.Features;

	const isFeatureEnabled = React.useCallback(
		(feature: m.Core.Features) => {
			return currentFeatures?.find(f => f.Key === feature)?.Active ?? false;
		},
		[currentFeatures],
	);

	return { isFeatureEnabled };
};
