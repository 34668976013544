import { GeneralLangFactory } from "@bokio/lang";

export function getRatingLabel(rating: number) {
	const lang = GeneralLangFactory();
	switch (rating) {
		case 1:
		case 2:
		case 3:
		case 4:
		case 5:
		case 6:
		case 7:
		case 8:
			return lang.NPS_Label_Improve;
		case 9:
		case 10:
			return lang.NPS_Label_Good;
		default:
			return "";
	}
}

export function getRatingMessage(rating: number) {
	const lang = GeneralLangFactory();
	switch (rating) {
		case 1:
		case 2:
		case 3:
		case 4:
			return lang.NPS_Message_1To4;
		case 5:
		case 6:
		case 7:
		case 8:
			return lang.NPS_Message_5To8;
		case 9:
		case 10:
			return lang.NPS_Message_9To10;
		default:
			return "";
	}
}
