import * as React from "react";

export interface DeviceQueryParams {
	// Everything below is treated as mobile
	minTabletWidth?: number;
	// Everything between is treated as tablet
	minDesktopWidth?: number;
	// Everything above is treated as desktop
}

export interface DeviceQueryResult {
	isDesktop: boolean;
	isTablet: boolean;
	isMobile: boolean;
}
/**
 * Use this hook when you need to know the type of the current device.
 *
 * When to use:
 * - When we want different rendering paths, depending on which device we use.
 * - When we want different behaviour depending on device. For example on desktops we often want to autofocus the first input elements in a modal. On mobile devices this often leads to bad UX. This is a typical use case for useDeviceQuery.
 *
 * When *not* to use:
 * - When making a responsive layout. The goto-tool for doing this is CSS, not resize listeners.
 * @param props
 */
export const useDeviceQuery = (props: DeviceQueryParams = {}) => {
	const checkScreenSize = React.useCallback((): DeviceQueryResult => {
		const MINIMUM_DESKTOP_WIDTH = props.minDesktopWidth || 1280;
		const MINIMUM_TABLET_WIDTH = props.minTabletWidth || 768;

		return {
			isDesktop: window?.innerWidth >= MINIMUM_DESKTOP_WIDTH,
			isTablet: window?.innerWidth >= MINIMUM_TABLET_WIDTH && window.innerWidth < MINIMUM_DESKTOP_WIDTH,
			isMobile: window?.innerWidth < MINIMUM_TABLET_WIDTH,
		};
	}, [props.minTabletWidth, props.minDesktopWidth]);

	const [state, setState] = React.useState<DeviceQueryResult>(checkScreenSize);

	const onResize = React.useCallback(() => setState(checkScreenSize), [checkScreenSize]);

	React.useEffect(() => {
		window?.addEventListener("resize", onResize);
		return () => window?.removeEventListener("resize", onResize);
	}, [onResize]);

	return state;
};
