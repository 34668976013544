import { mergeClassNames } from "@bokio/utils/classes";

import { getMarginClassName } from "../Button/button.helper";
import {
	buttonGroupChildrenAreValid,
	getButtonGroupAlignClassName,
	getButtonGroupDirectionClassName,
} from "./buttonGroup.helper";

import type { Margin } from "../Button/button.types";
import type { ButtonGroupChildren, OneOrMore } from "./buttonGroup.helper";
import type { ButtonGroupAlign, ButtonGroupDirection } from "./buttonGroup.types";
import type * as React from "react";

import * as styles from "./buttonGroup.scss";

interface ButtonGroupProps {
	children: OneOrMore<ButtonGroupChildren>;
	direction?: ButtonGroupDirection;
	align?: ButtonGroupAlign;
	/**
	 * @description
	 * Makes the component 100% width
	 * @default false
	 */
	stretch?: boolean;
	/**
	 * @description
	 * Stretches children to parent size
	 * @default false
	 */
	fillButtons?: boolean;
	/**
	 * @description
	 * Adds a 16px padding to wrapper
	 * @default false
	 */
	padding?: boolean;
	/**
	 * @description
	 * Adds a 16px margin to the button group in any direction.
	 * Usage: an array with any of the wanted margins `["top", "bottom", "left", "right"]` or `"all"`.
	 * @default undefined
	 */
	margin?: Margin;
	/**
	 * @description
	 * Adds `flex-wrap: wrap`
	 * @default true
	 */
	wrap?: boolean;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
	children,
	direction,
	align,
	stretch,
	fillButtons,
	padding,
	margin,
	wrap = true,
}) => {
	const allChildrenAreValid = buttonGroupChildrenAreValid(children);

	if (!allChildrenAreValid) {
		throw new Error(
			"One or more children of <ButtonGroup /> are not a design system button. Please double check with other devs and update VALID_BUTTON_COMPONENTS or registerAsValidButtonComponent() if needed.",
		);
	}

	const outerClassNames = mergeClassNames(
		stretch && styles.stretch,
		padding && styles.padding,
		margin && getMarginClassName(margin),
	);

	const classNames = mergeClassNames(
		styles.group,
		wrap && styles.wrap,
		stretch && styles.stretch,
		fillButtons && styles.fillButtons,
		direction && getButtonGroupDirectionClassName(direction),
		align && getButtonGroupAlignClassName(align),
	);

	return (
		<div className={outerClassNames}>
			<div className={classNames}>{children}</div>
		</div>
	);
};
