/** Derived from my fork of NanoPop: https://github.com/Atmos4/nanopop.
 * Improvements: overall positioning, arrow support, fallback values.
 * @author Arnaud PERRIN 2023
 */

type Direction = "top" | "left" | "bottom" | "right";
type Alignment = "start" | "middle" | "end";

export type VariantFlipOrder = {
	start: string;
	middle: string;
	end: string;
};

export type PositionFlipOrder = {
	top: string;
	right: string;
	bottom: string;
	left: string;
};

export type TooltipPlacement = `${Direction}-${Alignment}` | Direction;

export type PopperOptions = {
	container: DOMRect;
	placement: TooltipPlacement;
	variantFlipOrder: VariantFlipOrder;
	positionFlipOrder: PositionFlipOrder;
	offset: number;
	reference?: HTMLElement;
	popper?: HTMLElement;
	padding?: number;
	arrow?: HTMLElement;
	arrowOffset?: number;
};

type AvailablePositions = {
	t: number;
	b: number;
	l: number;
	r: number;
};

type AvailableVariants = {
	vs: number;
	vm: number;
	ve: number;
	hs: number;
	hm: number;
	he: number;
};

type PositionPairs = [Direction, Direction];

// Export default
export const defaults = {
	variantFlipOrder: { start: "sme", middle: "mse", end: "ems" },
	positionFlipOrder: { top: "tbrl", right: "rltb", bottom: "btrl", left: "lrbt" },
	placement: "bottom",
	offset: 8,
	padding: 0,
	arrowOffset: 8,
};

/**
 * Repositions an element once using the provided options and elements.
 * @param reference Reference element
 * @param popper Popper element
 * @param opt Optional, additional options
 */
export const reposition = (reference: HTMLElement, popper: HTMLElement, opt?: Partial<PopperOptions>): void => {
	const { container, arrow, offset, padding, placement, variantFlipOrder, positionFlipOrder, arrowOffset } = {
		container: document.documentElement.getBoundingClientRect(),
		...defaults,
		...opt,
	};

	/**
	 * Reset position to resolve viewport
	 * See https://developer.mozilla.org/en-US/docs/Web/CSS/position#fixed
	 */
	const originalLeft = popper.style.left;
	const originalTop = popper.style.top;
	popper.style.left = "0";
	popper.style.top = "0";
	popper.style.height = "auto";

	const refBox = reference.getBoundingClientRect();
	const popBox = popper.getBoundingClientRect();

	/**
	 * Holds coordinates of top, left, bottom and right alignment
	 */
	const positionStore: AvailablePositions = {
		t: refBox.top - popBox.height - offset,
		b: refBox.bottom + offset,
		r: refBox.right + offset,
		l: refBox.left - popBox.width - offset,
	};

	// Arrow displacement.
	// 3 is a magic number just to represent a refBox a lot smaller than the arrow offset
	const ahd = refBox.width / 3 < arrowOffset ? arrowOffset : 0;
	const avd = refBox.height / 3 < arrowOffset ? arrowOffset : 0;

	/**
	 * Holds corresponding variants (start, middle, end).
	 * The values depend on horizontal / vertical orientation
	 */
	const variantStore: AvailableVariants = {
		vs: refBox.left - ahd,
		vm: refBox.left + refBox.width / 2 - popBox.width / 2,
		ve: refBox.left + refBox.width - popBox.width + ahd,
		hs: refBox.top - avd,
		hm: refBox.bottom - refBox.height / 2 - popBox.height / 2,
		he: refBox.bottom + refBox.height - popBox.height + avd,
	};

	// Extract position and variant
	// Top-start -> top is "position" and "start" is the variant
	const [posKey, varKey = "middle"] = placement.split("-");
	const positions = positionFlipOrder[posKey as keyof PositionFlipOrder];
	const variants = variantFlipOrder[varKey as keyof VariantFlipOrder];

	// Try out all possible combinations, starting with the preferred one.
	const { top, left, bottom, right } = container;

	for (const p of positions) {
		const vertical = p === "t" || p === "b";

		// The position-value
		let positionVal = positionStore[p as keyof AvailablePositions];

		// Which property has to be changes.
		const [positionKey, variantKey] = (vertical ? ["top", "left"] : ["left", "top"]) as PositionPairs;

		/**
		 * box refers to the size of the popper element. Depending on the orientation this is width or height.
		 * The limit is the corresponding, maximum value for this position.
		 */
		const [positionSize, variantSize] = vertical ? [popBox.height, popBox.width] : [popBox.width, popBox.height];

		const [positionMaximum, variantMaximum] = vertical ? [bottom, right] : [right, bottom];
		const [positionMinimum, variantMinimum] = vertical ? [top, left] : [left, top];

		// Skip pre-clipped values
		if (positionVal < positionMinimum || positionVal + positionSize > positionMaximum) {
			continue;
		}
		positionVal -= popBox[positionKey];

		popper.style[positionKey] = `${positionVal}px`;
		if (arrow) {
			arrow.style[positionKey] = `${positionVal < refBox[positionKey] ? positionSize : 0}px`;
		}

		// Calculate refBox's center offset from its variant position for arrow positioning
		const refBoxCenterOffset = vertical ? refBox.width / 2 : refBox.height / 2;

		for (const v of variants) {
			// The position-value, the related size value of the popper and the limit
			let variantVal = variantStore[((vertical ? "v" : "h") + v) as keyof AvailableVariants];

			if (variantVal < variantMinimum || variantVal + variantSize > variantMaximum) {
				continue;
			}

			// Substract popBox's initial position
			variantVal -= popBox[variantKey];

			// Apply styles and normalize viewport
			popper.style[variantKey] = `${variantVal}px`;

			if (arrow) {
				// When refBox is larger than popBox, have the arrow's variant position be the center of popBox instead.
				arrow.style[variantKey] = `${
					refBoxCenterOffset * 2 < variantSize
						? refBox[variantKey] + refBoxCenterOffset - variantVal - padding
						: variantSize / 2
				}px`;
			}

			return;
		}

		// Fallback: when the position is valid but all variants fail, it's because the tooltip is larger than the viewport
		popper.style[variantKey] = vertical ? originalLeft : originalTop;
		if (arrow) {
			arrow.style[variantKey] = `${refBox[variantKey] + refBoxCenterOffset - popBox[variantKey]}px`;
		}
		return;
	}
	// The engine can't compute a valid position. It means that the tooltip is out of the viewport.
	// Then we fall back to bottom position
	popper.style.top = `${positionStore["b"]}px`;
	if (arrow) {
		arrow.style.top = "0";
		arrow.style.left = `${refBox.left + refBox.width / 2 - popBox.left}px`;
	}

	//Fix for tooltip height being too large
	if (positionStore["b"] + popBox.height > container.height) {
		popper.style.height = `${container.height - positionStore["b"] - 2 * padding}px`;
	}
};
