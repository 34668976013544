import { ToggleSwitch } from "@bokio/elements/ToggleSwitch";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./toggleListItem.scss";

interface ToggleListItemProps {
	className?: string;
	title: string;
	checked: boolean;
	onChange: (newValue: boolean) => void;
	content: React.ReactNode;
	disabled?: boolean;
	testId?: string;
}

export const ToggleListItem = (props: ToggleListItemProps) => (
	<div className={mergeClassNames(styles.wrapper, props.className)}>
		<div className={styles.titleRow}>
			<h3>{props.title}</h3>
			<ToggleSwitch
				disabled={props.disabled}
				size="small"
				checked={props.checked}
				onChange={props.onChange}
				testId={props.testId}
			/>
		</div>
		<div>{props.content}</div>
	</div>
);
