import * as m from "@bokio/mobile-web-shared/core/model/model";

export const BOKIO_GROUP_PROD_PARTNER_ID = (country: m.CountryCode) => {
	switch (country) {
		case m.CountryCode.GB:
			return "f50f71b2-a0c7-42b1-9cfc-03a02aa75df3";
		case m.CountryCode.SE:
		default:
			return "18e661ae-bb95-40ee-b26e-22fb13148b56";
	}
};
export const BOKIO_GROUP_STAGING_PARTNER_ID = (country: m.CountryCode) => {
	switch (country) {
		case m.CountryCode.GB:
			return ""; // TODO: add partner id
		case m.CountryCode.SE:
		default:
			return "b4d59582-0a76-402f-afb9-e292937d03a2";
	}
};

export const isAccountorOrBokioGroup = (partnerId: string, country: m.CountryCode) =>
	partnerId === BOKIO_GROUP_PROD_PARTNER_ID(country) || // Bokio Group in production
	partnerId === BOKIO_GROUP_STAGING_PARTNER_ID(country); // Bokio Group in staging
