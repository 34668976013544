import * as React from "react";
import { Key } from "w3c-keys";

import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Caption } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Link } from "@bokio/elements/Link/Link";
import { useDebounce } from "@bokio/hooks/useDebounce/useDebounce";
import { useMetric } from "@bokio/hooks/useMetric/useMetric";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useRouter } from "@bokio/shared/containers/router/useRouter";

import type { PaletteCommand, PaletteCommandSource } from "../../utils/commandPalette.types";

import * as styles from "./commandSearch.scss";

interface CommandSearchState {
	searchParam: string;
	searchResults: PaletteCommand[];
	focusedIndex: number;
}

interface CommandSearchProps {
	source: PaletteCommandSource;
	onComplete: () => void;
}
import AnalyticsEventCategory = m.Bokio.Common.Contract.Metrics.Analytics.Requests.AnalyticsEventCategory;

export const CommandSearch: React.FC<CommandSearchProps> = ({ source, onComplete }) => {
	const router = useRouter();
	const generalLang = GeneralLangFactory();
	const { placeholder, search } = source;
	const [addMetric] = useMetric();
	const [{ searchParam, searchResults, focusedIndex }, setCommandSearchState] = React.useState<CommandSearchState>({
		searchParam: "",
		searchResults: [],
		focusedIndex: 0,
	});

	const debouncedSearch = useDebounce(
		async (debouncedSearch: string) => {
			const result = await search(debouncedSearch);
			setCommandSearchState(old => ({ searchParam: old.searchParam, searchResults: result, focusedIndex: 0 }));
		},
		[search],
	);

	const doSearch = React.useCallback(
		async (s: string) => {
			setCommandSearchState(old => ({
				searchParam: s,
				searchResults: old.searchResults,
				focusedIndex: old.focusedIndex,
			}));
			debouncedSearch(s);
		},
		[debouncedSearch],
	);

	React.useEffect(() => {
		doSearch("");
	}, [doSearch]);

	const trackClick = async (commandName: string, commandRoute: string, isKeyboard: number) => {
		await addMetric(AnalyticsEventCategory.CommandPalette, commandName, commandRoute, isKeyboard);
	};

	const handleKeyUp = (e: React.KeyboardEvent) => {
		if (e.key === Key.Enter) {
			const command = searchResults[focusedIndex];
			trackClick(command.kind, command.name as string, 1);
			if (command?.kind === "route") {
				onComplete();
				if (e.ctrlKey || e.metaKey) {
					window?.open(command.route, "_blank");
				} else {
					router.redirect(command.route, { isRedirectedFromNotification: false });
				}
			} else {
				command?.action();
			}
		} else if (e.key === Key.ArrowDown) {
			const newIndex = (focusedIndex + 1) % searchResults.length;
			setCommandSearchState(old => ({
				searchParam: old.searchParam,
				searchResults: old.searchResults,
				focusedIndex: newIndex,
			}));
		} else if (e.key === Key.ArrowUp) {
			const newIndex = focusedIndex - 1 < 0 ? searchResults.length - 1 : focusedIndex - 1;
			setCommandSearchState(old => ({
				searchParam: old.searchParam,
				searchResults: old.searchResults,
				focusedIndex: newIndex,
			}));
		}
	};

	const handleMouseClick = (command: PaletteCommand) => {
		trackClick(command.kind, command.name as string, 0);
		if (command?.kind === "route") {
			onComplete();
			router.redirect(command.route, { isRedirectedFromNotification: false });
		} else {
			command?.action();
		}
	};

	return (
		<SG gap="8">
			<SG>
				<input
					autoFocus={true}
					type="search"
					value={searchParam}
					placeholder={placeholder}
					data-testid="CommandPalette_SearchField"
					onChange={e => doSearch(e.target.value)}
					onKeyUp={handleKeyUp}
					className={styles.searchField}
				/>
			</SG>
			<SG gap="4" borderBetween testId="CommandPalette_SearchResults">
				{searchResults.length ? (
					searchResults.map((sr, i) => {
						return (
							<Link
								key={`CommandPalletSearchResult_${i}`}
								style={i === focusedIndex ? "normal" : "plain"}
								onClick={() => handleMouseClick(sr)}
								testId={`CommandPalletSearchResult_${i}`}
							>
								<SG gap="4" padding="4" horizontal>
									<span className={i === focusedIndex ? styles.highlightLeft : styles.noHighlight}></span>
									<Caption>{sr.prefix}</Caption>
									<span>{sr.name}</span>
								</SG>
							</Link>
						);
					})
				) : searchParam.length > 0 ? (
					<SG>{generalLang.CommandSearch_NoSearchResultFound}</SG>
				) : null}
			</SG>
		</SG>
	);
};
