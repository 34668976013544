import { mergeClassNames } from "@bokio/utils/classes";

import { Grade } from "./utils";

import type * as React from "react";

import * as styles from "./ratingToast.scss";

interface SmileyProps {
	grade: Grade;
}

export const Smiley: React.FC<SmileyProps> = ({ grade }) => {
	const getSmileyDetails = () => {
		switch (grade) {
			case Grade.VeryBad:
				return (
					<>
						<circle cx="11" cy="15" r="2" fill="#5C6B78" className={styles.details} />
						<circle cx="21" cy="15" r="2" fill="#5C6B78" className={styles.details} />
						<path
							d="M6.56266 10.8048C6.06599 11.0464 5.85918 11.6448 6.10073 12.1415C6.34228 12.6381 6.94071 12.845 7.43738 12.6034L6.56266 10.8048ZM12.9171 9.27705C13.3512 8.93553 13.4261 8.30682 13.0846 7.87279C12.7431 7.43877 12.1144 7.36378 11.6804 7.70531L12.9171 9.27705ZM7.43738 12.6034L10.2337 11.2435L9.35893 9.44489L6.56266 10.8048L7.43738 12.6034ZM11.1388 10.6764L12.9171 9.27705L11.6804 7.70531L9.90202 9.10465L11.1388 10.6764ZM10.2337 11.2435C10.5547 11.0873 10.8582 10.8972 11.1388 10.6764L9.90202 9.10465C9.73366 9.23713 9.55159 9.35119 9.35893 9.44489L10.2337 11.2435Z"
							fill="#5C6B78"
							className={styles.details}
						/>
						<path
							d="M24.736 10.8048C25.2327 11.0464 25.4395 11.6448 25.198 12.1415C24.9564 12.6381 24.358 12.845 23.8613 12.6034L24.736 10.8048ZM18.3816 9.27705C17.9475 8.93553 17.8726 8.30682 18.2141 7.87279C18.5556 7.43877 19.1843 7.36378 19.6184 7.70531L18.3816 9.27705ZM23.8613 12.6034L21.0651 11.2435L21.9398 9.44489L24.736 10.8048L23.8613 12.6034ZM20.1599 10.6764L18.3816 9.27705L19.6184 7.70531L21.3967 9.10465L20.1599 10.6764ZM21.0651 11.2435C20.744 11.0873 20.4405 10.8972 20.1599 10.6764L21.3967 9.10465C21.565 9.23713 21.7471 9.35119 21.9398 9.44489L21.0651 11.2435Z"
							fill="#5C6B78"
							className={styles.details}
						/>
						<path
							d="M9.84539 23.0378C9.59014 23.5276 9.78024 24.1315 10.27 24.3868C10.7597 24.642 11.3637 24.4519 11.619 23.9622L9.84539 23.0378ZM21.381 23.9622C21.6362 24.4519 22.2402 24.642 22.7299 24.3868C23.2197 24.1315 23.4098 23.5276 23.1545 23.0378L21.381 23.9622ZM11.619 23.9622C12.5375 22.1998 14.3795 21 16.5 21V19C13.605 19 11.0949 20.6405 9.84539 23.0378L11.619 23.9622ZM16.5 21C18.6205 21 20.4624 22.1998 21.381 23.9622L23.1545 23.0378C21.9051 20.6405 19.3949 19 16.5 19V21Z"
							fill="#5C6B78"
							className={styles.details}
						/>
					</>
				);
			case Grade.Bad:
				return (
					<>
						<circle cx="11" cy="13" r="2" fill="#5C6B78" className={styles.details} />
						<circle cx="21" cy="13" r="2" fill="#5C6B78" className={styles.details} />
						<path
							d="M10.7311 21.707C10.378 22.1316 10.436 22.7621 10.8606 23.1153C11.2852 23.4684 11.9157 23.4105 12.2689 22.9858L10.7311 21.707ZM20.7311 22.9858C21.0843 23.4105 21.7148 23.4684 22.1394 23.1153C22.564 22.7621 22.622 22.1316 22.2689 21.707L20.7311 22.9858ZM12.2689 22.9858C13.2793 21.7708 14.7992 21 16.5 21V19C14.1799 19 12.1053 20.0547 10.7311 21.707L12.2689 22.9858ZM16.5 21C18.2008 21 19.7207 21.7708 20.7311 22.9858L22.2689 21.707C20.8947 20.0547 18.8201 19 16.5 19V21Z"
							fill="#5C6B78"
							className={styles.details}
						/>
					</>
				);
			case Grade.Okay:
				return (
					<>
						<circle cx="11" cy="13" r="2" fill="#5C6B78" className={styles.details} />
						<circle cx="21" cy="13" r="2" fill="#5C6B78" className={styles.details} />
						<path
							d="M10.9659 20.7412C10.4137 20.7412 9.96594 21.1889 9.96594 21.7412C9.96594 22.2935 10.4137 22.7412 10.9659 22.7412V20.7412ZM20.9659 22.7412C21.5182 22.7412 21.9659 22.2935 21.9659 21.7412C21.9659 21.1889 21.5182 20.7412 20.9659 20.7412V22.7412ZM10.9659 22.7412H20.9659V20.7412H10.9659V22.7412Z"
							fill="#5C6B78"
							className={styles.details}
						/>
					</>
				);
			case Grade.Good:
				return (
					<>
						<circle cx="11" cy="13" r="2" fill="#5C6B78" className={styles.details} />
						<circle cx="21" cy="13" r="2" fill="#5C6B78" className={styles.details} />
						<path
							d="M10.7311 21.6394C10.378 21.2148 10.436 20.5843 10.8606 20.2311C11.2852 19.878 11.9157 19.936 12.2689 20.3606L10.7311 21.6394ZM20.7311 20.3606C21.0843 19.936 21.7148 19.878 22.1394 20.2311C22.564 20.5843 22.622 21.2148 22.2689 21.6394L20.7311 20.3606ZM12.2689 20.3606C13.2793 21.5756 14.7992 22.3464 16.5 22.3464V24.3464C14.1799 24.3464 12.1053 23.2918 10.7311 21.6394L12.2689 20.3606ZM16.5 22.3464C18.2008 22.3464 19.7207 21.5756 20.7311 20.3606L22.2689 21.6394C20.8947 23.2918 18.8201 24.3464 16.5 24.3464V22.3464Z"
							fill="#5C6B78"
							className={styles.details}
						/>
					</>
				);
			case Grade.Excellent:
				return (
					<>
						<path
							d="M14 13C14 11.8954 13.1046 11 12 11C10.8954 11 10 11.8954 10 13"
							stroke="#5C6B78"
							strokeWidth="2"
							strokeLinecap="round"
							className={styles.great}
						/>
						<path
							d="M22 13C22 11.8954 21.1046 11 20 11C18.8954 11 18 11.8954 18 13"
							stroke="#5C6B78"
							strokeWidth="2"
							strokeLinecap="round"
							className={styles.great}
						/>
						<path
							d="M16 26C18.9745 26 21.4434 23.8356 21.9177 20.9958C22.0087 20.4511 21.5523 20 21 20H11C10.4477 20 9.99127 20.4511 10.0823 20.9958C10.5566 23.8356 13.0255 26 16 26Z"
							fill="#5C6B78"
							className={styles.details}
						/>
					</>
				);
		}
	};

	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={mergeClassNames(styles.smiley)}
		>
			<circle cx="16" cy="16" r="15" fill="white" stroke="#5C6B78" strokeWidth="2" className={styles.outline} />
			{getSmileyDetails()}
		</svg>
	);
};
