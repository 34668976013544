import { Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Link } from "@bokio/elements/Link/Link";
import Video from "@bokio/elements/Video/Video";
import { AnalyticsEventCategory, useMetric } from "@bokio/hooks/useMetric/useMetric";
import { BankLangFactory, GeneralLangFactory } from "@bokio/lang";
import { mergeClassNames } from "@bokio/utils/classes";

import { BbaOnboardingPopoverNotInterestedLink } from "./BbaOnboardingPopoverNotInterestedLink";

import type * as React from "react";

import * as styles from "./bbaOnboardingPopoverContent.scss";

interface BbaOnboardingPopoverContentProps {
	variant: "modal" | "popover";
	onNotInterest?: () => void;
}

export const BbaOnboardingPopoverContent: React.FC<BbaOnboardingPopoverContentProps> = ({ variant, onNotInterest }) => {
	const bankLang = BankLangFactory();
	const generalLang = GeneralLangFactory();
	const [addMetric] = useMetric();

	return (
		<div className={mergeClassNames(styles.root, variant === "popover" && styles.rootInPopover)}>
			<div>
				<Paragraph>
					{bankLang.BbaOnboardingPopoverContent_Description}{" "}
					<Link external={generalLang.PricePlan_ReadMoreAboutBusinessAccount_Link}>
						{generalLang.PricePlan_ReadMoreAboutBusinessAccount}
					</Link>
				</Paragraph>
			</div>
			<div>
				<Video
					youtubeEmbedLink={bankLang.BbaPromotionVideoEmbedPath}
					onStateChange={e => {
						if (e.data === YT.PlayerState.PLAYING) {
							addMetric(AnalyticsEventCategory.Bba, "BbaOnboardingPopover_PlayBbaVideo");
						}
					}}
				/>
			</div>
			{variant === "modal" && (
				<div className={styles.notInterestedLinkWrapperMobile}>
					<Paragraph>
						<BbaOnboardingPopoverNotInterestedLink onClick={onNotInterest} />
					</Paragraph>
				</div>
			)}
		</div>
	);
};
