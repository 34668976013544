import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button/Button";

import { useBokioPlanFeatureButton } from "./useBokioPlanFeatureButton";

import type { BokioPlanFeatureButtonProps } from "./useBokioPlanFeatureButton";
import type { ButtonProps } from "@bokio/designsystem/components/Button/Button";
import type {
	ExternalLinkButtonProps,
	LinkButtonProps,
	NormalButtonProps,
	SubmitButtonProps,
} from "@bokio/designsystem/components/Button/button.types";

type Props = ButtonProps &
	(NormalButtonProps | LinkButtonProps | ExternalLinkButtonProps | SubmitButtonProps) &
	BokioPlanFeatureButtonProps;

export const BokioPlanFeatureButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>((props, ref) => {
	const { renderButton } = useBokioPlanFeatureButton({
		featureButtonProps: props,
		lockButtonProps: props,
		lockButtonRef: ref,
		renderActualButton: () => <Button {...props} ref={ref} />,
	});

	return renderButton();
});
