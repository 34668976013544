import * as React from "react";

import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { OnboardingChecksForm } from "@bokio/components/SveaBankIdAuthModal/OnboardingCheckForms";
import { Button } from "@bokio/designsystem/components/Button";
import { Paragraph, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { FormGroup, FormWithValidation, PersonNrField } from "@bokio/elements/Form";
import { asValidatorFunction } from "@bokio/elements/Form/FormWithValidation/FormWithValidation";
import { Notice } from "@bokio/elements/Notice/Notice";
import { writeToLocalStorage } from "@bokio/hooks/useLocalStorage/useLocalStorage";
import { BankLangFactory, GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useApi, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import {
	EntityValidator,
	FieldRuleFactory,
	FieldRuleLevel,
	FieldValidator,
} from "@bokio/shared/validation/entityValidator";
import { trackEvent } from "@bokio/utils/t";

import type { Validator, ValidatorResult } from "@bokio/shared/validation/entityValidator";

import BokioPlan = m.Entities.BokioPlan;
interface StartBokioBusinessAccountOnboardingModalPartialProps {
	prependedModalContent?: React.ReactNode;
	selectedPlan: m.Entities.BokioPlan;
	onClose: () => void;
	onSubmit?: () => Promise<void>;
}

type StartBokioBusinessAccountOnboardingModalProps = {
	title: string;
	visible: boolean;
} & StartBokioBusinessAccountOnboardingModalPartialProps;

type MirOnboardingPromptFormValues = {
	personalNumber: string;
	ownerOfEmail: boolean;
	realCompany: boolean;
	notSharedAccount: boolean;
};

class MirOnboardingPromptFormValidator extends EntityValidator<MirOnboardingPromptFormValues> {
	getRules(): Validator[] {
		const generalLang = GeneralLangFactory();
		const factory = new FieldRuleFactory(generalLang);
		return [
			new FieldValidator(this.propertyOf("personalNumber"), [
				factory.Required(generalLang.Personal_Number, FieldRuleLevel.MustFixNow),
				factory.PersonNumberLoose(generalLang.Personal_Number, FieldRuleLevel.MustFixNow),
			]),
		];
	}
}

export const StartBokioBusinessAccountOnboardingModalPartial: React.FC<
	StartBokioBusinessAccountOnboardingModalPartialProps
> = ({ selectedPlan, onClose, prependedModalContent, onSubmit }) => {
	const noRemainingBankGiros = false; // TODO fix this
	const bankLang = BankLangFactory();

	const generalLang = GeneralLangFactory();
	const { companyInfo } = useCompanyInfo();
	const { redirect } = useRouter();
	const [mismatchingPersonalNumber, setMismatchingPersonalNumber] = React.useState(false);
	const [forbiddenPlansRequest] = useApi(proxy.Bank.MirOnboardingController.GetForbiddenPlans.Get, [companyInfo.Id]);
	const [existingLoginMethodMismatch] = useLazyApi(
		proxy.Bank.MirOnboardingController.PersonalNumberMismatchWithExisting.Get,
	);

	//ME: PP2022 This breaks the test, not sure why it worked before...

	//const planOptions = getPlanOptions(selectedPlan);
	// const selectedPlanOption = planOptions.find(p => p.selected);
	// if (!selectedPlanOption) {
	// 	throw new Error("Started onboarding modal without a selected plan");
	// }

	// ME: I took this allow list approach because it's safer.
	const canOnboardThisType = companyInfo.CompanySystem === "Default";
	const validator = new MirOnboardingPromptFormValidator();
	const forbiddenPlan = forbiddenPlansRequest.data?.Data?.some(x => x === selectedPlan);

	const [verifiedByUser, setVerifiedByUser] = React.useState(false);

	return (
		<FormWithValidation<MirOnboardingPromptFormValues, ValidatorResult>
			initialState={{ personalNumber: "", ownerOfEmail: false, realCompany: false, notSharedAccount: false }}
			validator={asValidatorFunction(validator)}
			onSubmit={async ({ personalNumber }) => {
				const mismatchingPersonalNumber = await existingLoginMethodMismatch(companyInfo.Id, personalNumber);
				if (mismatchingPersonalNumber.Data === true) {
					setMismatchingPersonalNumber(true);
					return;
				}
				onSubmit && (await onSubmit());
				// SS 2021-02-25:
				// This needs to be run synchronously before transitioning (redirect) to avoid weird race conditions that's hard to reproduce.
				// We pick up this value on mounting MirOnboardingStepsScene.
				writeToLocalStorage("BusinessAccountOnboarding_PersonalNumber", personalNumber);

				trackEvent("BokioBusinessAccount", "Click", `ContinueToOnboardingWith${selectedPlan}`);
				onClose();
				redirect(getRoute("bokioBusinessAccountOnboardingSteps", { company: companyInfo.Id }));
			}}
		>
			{({ formData, setFormData, validation }) => {
				const personalNumberValidation = validator.filterByTypedField("personalNumber", validation.errors);
				const personalNumberOk = personalNumberValidation === undefined;
				return (
					<>
						<ModalContent>
							{prependedModalContent}
							<Paragraph>{bankLang.ActivateBusinessAccount_Modal_Description}</Paragraph>
							<Section>
								{forbiddenPlan && <Notice color="warning">{bankLang.MirOnboarding_InvalidPlan_Free}</Notice>}
								<FormGroup>
									<PersonNrField
										testId="MirOnboardingPrompt_PersonNrField"
										label={bankLang.YourPersonalNumber}
										value={formData.personalNumber}
										onChange={personalNumber => {
											mismatchingPersonalNumber && setMismatchingPersonalNumber(false);
											setFormData(prev => ({ ...prev, personalNumber }));
										}}
										disabled={!canOnboardThisType || noRemainingBankGiros}
									/>
								</FormGroup>
								{personalNumberOk && (
									<OnboardingChecksForm
										bankLang={bankLang}
										personalId={formData.personalNumber}
										setCheckedState={setVerifiedByUser}
									/>
								)}
								{!canOnboardThisType && (
									<Notice color="warning">{generalLang.TestCompany_ThisActionIsNotAvailable}</Notice>
								)}
								{mismatchingPersonalNumber && (
									<Notice color="error">{bankLang.MirOnboarding_AlreadyUsingDifferentPersonalId}</Notice>
								)}
							</Section>
						</ModalContent>
						<ModalFooter>
							<Button type="button" onClick={onClose} appearance="secondary">
								{generalLang.Cancel}
							</Button>
							<Button
								type="submit"
								icon="bankid-solid"
								iconAlign="right"
								testId="MirOnboardingPrompt_Submit"
								disabled={
									!canOnboardThisType ||
									!!validation.errors.length ||
									noRemainingBankGiros ||
									forbiddenPlan ||
									forbiddenPlansRequest.isLoading ||
									mismatchingPersonalNumber ||
									!verifiedByUser
								}
							>
								{bankLang.ContinueWithBankID}
							</Button>
						</ModalFooter>
					</>
				);
			}}
		</FormWithValidation>
	);
};

export const StartBokioBusinessAccountOnboardingModal = (props: StartBokioBusinessAccountOnboardingModalProps) => {
	return (
		<Modal
			visible={props.visible}
			onClose={props.onClose}
			title={props.title}
			testId="StartBokioBusinessAccountOnboardingModal"
		>
			<StartBokioBusinessAccountOnboardingModalPartial {...props} />
		</Modal>
	);
};

export type UseBokioBusinessAccountOnboardingModalReturn = {
	bbaOnboardingModal: {
		render: (plan: BokioPlan) => React.ReactNode;
		isVisible: boolean;
		show: () => void;
	};
};

export const useBokioBusinessAccountOnboardingModal = (): UseBokioBusinessAccountOnboardingModalReturn => {
	const bankLang = BankLangFactory();
	const generalLang = GeneralLangFactory();
	const [isVisible, setIsVisible] = React.useState(false);

	const render = (plan: BokioPlan) => (
		<StartBokioBusinessAccountOnboardingModal
			selectedPlan={plan}
			visible={isVisible}
			onClose={() => setIsVisible(false)}
			title={plan === BokioPlan.Free ? bankLang.ActivateBokioBusinessAccount : generalLang.GetStarted}
		/>
	);

	const show = () => setIsVisible(true);

	return {
		bbaOnboardingModal: {
			render,
			isVisible,
			show,
		},
	};
};
