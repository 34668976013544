import * as ReactDOM from "react-dom";

import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./flyout.scss";

interface FlyoutProps {
	isOpen: boolean;
	onClickOutside?: () => void;
	testId?: string;
	asideClassName?: string;
	backgroundClassName?: string;
}

export const Flyout: React.FC<React.PropsWithChildren<FlyoutProps>> = ({
	children,
	isOpen,
	onClickOutside,
	testId,
	asideClassName,
	backgroundClassName,
}) => {
	const flyoutRoot = document.getElementById("flyout-root");

	if (!flyoutRoot) {
		return null;
	}

	const handleClickOutside = () => {
		onClickOutside && onClickOutside();
	};

	return ReactDOM.createPortal(
		<>
			<aside className={mergeClassNames(styles.wrapper, isOpen && styles.open, asideClassName)} data-testid={testId}>
				{children}
			</aside>
			<div
				onClick={handleClickOutside}
				className={mergeClassNames(styles.background, isOpen && styles.backgroundOpen, backgroundClassName)}
				data-testid="Flyout_outside"
			/>
		</>,
		flyoutRoot,
	);
};
