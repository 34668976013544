import { ProgressBar } from "@bokio/companies/src/scenes/Overview/components/OnboardingChecklist/ProgressBar/ProgressBar";
import { Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import color from "@bokio/mobile-web-shared/color";

import type * as React from "react";

import * as styles from "./planProgressBar.scss";

interface PlanProgressBarProps {
	daysRemanining?: number;
	percent?: number;
	max?: number;
	visibleNumbericalProgress?: boolean;
	headingText?: string;
	isLosingPlanAccess?: boolean;
}

export const PlanProgressBar: React.FC<PlanProgressBarProps> = ({
	daysRemanining = 0,
	percent = 0,
	headingText = "",
	isLosingPlanAccess = false,
}) => {
	const progressColor = isLosingPlanAccess ? color.warning[500] : color.primary[500];

	return (
		<div data-testid="planProgressBar_test">
			<Paragraph data-testid="planProgressBar_headingText_test">
				<span className={styles.headingDaysRemaining}>{daysRemanining}</span>
				{headingText}
			</Paragraph>
			<ProgressBar
				percent={percent}
				showNumericalProgress={false}
				barColor={progressColor}
				overRidingPixelHeight={12}
			></ProgressBar>
		</div>
	);
};
