import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./dropdown.scss";

interface DropdownProps {
	trigger: DropdownTrigger;
	children?: React.ReactNode;
	onChange?: (isActive: boolean) => void;
	isActive?: boolean;
	className?: string;
	inPage?: boolean;
}

interface DropdownState {
	isActive: boolean;
}

interface DropdownTrigger {
	(props: { onClick: () => void }): JSX.Element;
}

/**
 * @deprecated Use the new design system dropdown instead.
 */
export class Dropdown extends React.Component<DropdownProps, DropdownState> {
	node: HTMLDivElement | null = null;

	state: DropdownState = {
		isActive: this.props.isActive || false,
	};

	UNSAFE_componentWillReceiveProps(nextProps: DropdownProps) {
		const { isActive } = nextProps;
		if (this.state.isActive !== isActive && isActive !== undefined) {
			this.setState({ isActive });
		}
	}
	onWindowClick = (event: MouseEvent) => {
		if (this.node && event.target !== this.node && !this.node.contains(event.target as Node)) {
			this.setActive(false);
		}
	};

	setActive = (isActive: boolean) => {
		const { onChange } = this.props;
		this.setState({ isActive });
		onChange && onChange(isActive);
	};

	toggleActive = () => {
		this.setActive(!this.state.isActive);
	};

	componentDidMount() {
		document.addEventListener("mousedown", this.onWindowClick);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onWindowClick);
	}

	render() {
		const { className, inPage: marginLeft } = this.props;
		const passProps = { setActive: this.setActive, isActive: this.state.isActive };

		const triggerProps = {
			onClick: this.toggleActive,
		};

		const triggerElement = this.props.trigger(triggerProps);

		return (
			<div
				ref={node => (this.node = node)}
				className={mergeClassNames(styles.dropdown, className, marginLeft && styles.inPage)}
			>
				{triggerElement}
				{
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					React.Children.map(this.props.children, child => React.cloneElement(child as any, passProps))
				}
			</div>
		);
	}
}
