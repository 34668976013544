import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./modal.scss";

interface ModalContentProps {
	centered?: boolean;
	children?: React.ReactNode;
	bgColor?: string;
	className?: string;
	testId?: string;
}

export const ModalContent = ({ centered, children, className, bgColor, testId }: ModalContentProps) => {
	const modalClassName = mergeClassNames(centered && styles.contentCentered, !centered && styles.content, className);

	return (
		<div className={modalClassName} style={{ backgroundColor: bgColor }} data-testid={testId}>
			{children}
		</div>
	);
};
