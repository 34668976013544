import { useModalStack } from "@bokio/hooks/useModalStack/useModalStack";

interface DeferredPromise<T> {
	reject: (e?: unknown) => void;
	resolve: (t: T | PromiseLike<T>) => void;
}

type AsyncModal<T> = (promise: DeferredPromise<T>, visible: boolean) => JSX.Element;

/**
 * @deprecated Directly using Modal2 component should be preferred
 * over {@link useModalStack} and {@link useModalsAsync} because modal hooks doesn't pass prop updates like
 * the normal React Portal behaviour, which is causing confusion.
 * This hook is kept mainly for backward compatibility.
 */
export const useModalsAsync = () => {
	const modals = useModalStack();
	return {
		showModal: <T = void>(modal: AsyncModal<T>) =>
			new Promise<T>((resolve, reject) =>
				modals.addModal(modalProps =>
					modal(
						{
							resolve: (t: T | PromiseLike<T>) => {
								modalProps.onClose();
								resolve(t);
							},
							reject: (e?: unknown) => {
								modalProps.onClose();
								reject(e);
							},
						},
						modalProps.visible,
					),
				),
			),
	};
};
