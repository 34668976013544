import * as React from "react";

import * as m from "@bokio/mobile-web-shared/core/model/model";
import { empty } from "@bokio/mobile-web-shared/services/api/requestState";
import { noop } from "@bokio/shared/utils";

import type { BadgeColor } from "@bokio/elements/Badge/Badge";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

import BokioPlan = m.Entities.BokioPlan;
type PlanInfoDto = m.Bokio.Common.Contract.ViewData.PlanInfoDto;

export type PricePlanContextValue = {
	plan: BokioPlan | undefined;
	planInfo: PlanInfoDto | undefined;
	plannedChange: m.Day | undefined;
	nextPlan: BokioPlan | undefined;
	inTrial: boolean;
	hasPurchasedPlan: boolean;
	daysUntilTrialEnded: number | undefined;
	planIconStatus: BadgeColor;
	planName: string;
	nextPlanName: string | undefined;
	canPurchase: boolean;
	request: RequestState<m.Envelope<m.Bokio.Common.Contract.ViewData.BokioPlanDto, m.SimpleError>>;
	refresh: () => Promise<void>;
};

export const pricePlanContext = React.createContext<PricePlanContextValue>({
	plan: undefined,
	planInfo: undefined,
	plannedChange: undefined,
	nextPlan: undefined,
	inTrial: false,
	hasPurchasedPlan: false,
	daysUntilTrialEnded: undefined,
	planIconStatus: "active",
	planName: "Free",
	nextPlanName: undefined,
	canPurchase: false,
	request: empty(),
	refresh: noop,
});

export const usePricePlanContext = () => React.useContext(pricePlanContext);
