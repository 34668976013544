import * as React from "react";

import { useAccessibleKeyboardBinding } from "./useAccessibleKeyboardbinding";

import type { CustomKeyboardBinding } from "./useAccessibleKeyboardbinding";

type KeyProperty = { key: React.Key; label: string | React.ReactNode };

interface UseAccessibleDropdownProps<T> {
	options: T[];
	customBindings: CustomKeyboardBinding<T>[];
	focusedOptionSelectorFn?: (focusedIndex: number) => T | null;
}

export function useAccessibleDropdown<T extends KeyProperty>({
	options,
	customBindings,
	focusedOptionSelectorFn,
}: UseAccessibleDropdownProps<T>) {
	const { focusedIndex, handleKeyDown, resetFocusedIndex } = useAccessibleKeyboardBinding(options, customBindings);

	const optionRefs = React.useRef<Map<React.Key, HTMLElement>>(new Map<React.Key, HTMLElement>());

	const setOptionRef = (key: React.Key) => (ref: HTMLElement | null) => {
		if (ref) {
			optionRefs.current.set(key, ref);
		}
	};

	React.useEffect(() => {
		if (focusedIndex === -1) {
			return;
		}
		const focusedOption = focusedOptionSelectorFn ? focusedOptionSelectorFn(focusedIndex) : options[focusedIndex];
		const element = focusedOption?.key ? optionRefs.current.get(focusedOption.key) : undefined;
		if (element && element.focus) {
			element.focus({ preventScroll: false });
		}
	}, [focusedIndex, options, focusedOptionSelectorFn]);

	return {
		focusedIndex,
		handleKeyDown,
		resetFocusedIndex,
		setOptionRef,
	};
}
