import * as React from "react";

import { ListHeader } from "./ListHeader/ListHeader";
import { ListItem } from "./ListItem/ListItem";

import * as styles from "./style.scss";

export interface ListProps {
	children?: React.ReactNode;
	checked?: boolean;
	onChange?: (selected: number[]) => void;
	defaultSelectAll?: boolean;
}

interface ListState {
	selected: { [index: number]: boolean };
	selectAll: boolean;
}

export class List extends React.Component<React.PropsWithChildren<ListProps>, ListState> {
	constructor(props: ListProps) {
		super(props);
		let selected: { [index: number]: boolean } = {};
		if (this.props.defaultSelectAll) {
			selected = this.getAllSelected();
		}
		this.state = {
			selected,
			selectAll: !!this.props.defaultSelectAll,
		};
	}

	componentDidMount() {
		this.onChange();
	}

	onChange() {
		const selected: number[] = [];
		for (const i in this.state.selected) {
			if (this.state.selected[i]) {
				// -1 to index from 0 due to header checkbox
				selected.push(parseInt(i, 10) - 1);
			}
		}
		if (this.props.onChange) {
			this.props.onChange(selected);
		}
	}

	select(checked: boolean, index: number) {
		this.setState(
			{
				selectAll: false,
				selected: {
					...this.state.selected,
					[index]: checked,
				},
			},
			() => this.onChange(),
		);
	}

	selectAll() {
		if (this.state.selectAll) {
			this.setState(
				{
					selected: {},
					selectAll: false,
				},
				() => this.onChange(),
			);
		} else {
			this.setState(
				{
					selected: this.getAllSelected(),
					selectAll: true,
				},
				() => this.onChange(),
			);
		}
	}

	getAllSelected() {
		const selected: { [index: number]: boolean } = {};
		React.Children.forEach(this.props.children, (child, index) => {
			const e = child as JSX.Element;
			if (e.type === ListItem) {
				selected[index] = true;
			}
		});
		return selected;
	}

	renderChildren() {
		return React.Children.map(this.props.children, (child, index) => {
			const e = child as JSX.Element;
			if (e.type === ListItem) {
				return React.cloneElement(e, {
					...e.props,
					onChange: (checked: boolean) => this.select(checked, index),
					checked: this.state.selected[index],
					check: true,
				});
			}
			if (e.type === ListHeader) {
				return React.cloneElement(e, {
					...e.props,
					onChange: () => this.selectAll(),
					checked: this.state.selectAll,
					check: true,
				});
			}
			return e;
		});
	}

	render() {
		return (
			<div className={styles.list}>
				{this.props.checked && this.renderChildren()}
				{!this.props.checked && this.props.children}
			</div>
		);
	}
}
