import {
	EntityValidator,
	FieldRuleFactory,
	FieldRuleLevel,
	FieldValidator,
} from "@bokio/shared/validation/entityValidator";

import type { GeneralLang } from "@bokio/lang/GeneralLangFactory";
import type { Validator } from "@bokio/shared/validation/entityValidator";

export default class OtpFormValidator extends EntityValidator<{ code: string }> {
	constructor(protected general: GeneralLang) {
		super(true);
	}

	getRules(): Validator[] {
		const { general } = this;
		const factory = new FieldRuleFactory(general);
		return [new FieldValidator(this.propertyOf("code"), [factory.Required(general.Code, FieldRuleLevel.MustFixNow)])];
	}
}
