import type { InitialBreakpoints, RotateBreakpoint } from "./theme.types";

const isRotateBreakpoint = (breakpoint: number | RotateBreakpoint): breakpoint is RotateBreakpoint => {
	if (typeof breakpoint === "object") {
		return true;
	}
	return false;
};

/* *
 * Replace these help functions when we can use `lodash-es` in the `themeGenerator`.
 * We currently can't use `lodash-es` since it depends on webpack
 * */
const isNumber = (value: unknown): value is number => typeof value === "number";

const kebabCase = (str: string) =>
	str
		.replace(/([a-z])([A-Z])/g, "$1-$2")
		.replace(/[\s_]+/g, "-")
		.toLowerCase();

interface Breakpoint {
	name: string;
	value: number;
}

export function generateBreakpoints(initialBreakpoints: InitialBreakpoints) {
	const devices = Object.keys(initialBreakpoints);
	// This map will be used to access the next device in list
	const deviceMap = devices.reduce((map, device, i) => ((map[i] = device), map), {});

	const breakpoints: Breakpoint[] = [];

	devices.forEach((device, i) => {
		const deviceName = kebabCase(device);
		const deviceBreakpoints = initialBreakpoints[device];
		if (isRotateBreakpoint(deviceBreakpoints)) {
			const rotateDirections = Object.keys(deviceBreakpoints);
			rotateDirections.forEach(direction =>
				breakpoints.push({ name: `${deviceName}-${direction}`, value: deviceBreakpoints[direction] }),
			);
		} else {
			breakpoints.push({ name: deviceName, value: deviceBreakpoints });
		}

		const nextDevice = devices.length >= i + 1 ? deviceMap[i + 1] : null;
		// If there's a device after the current one, the current one should have a `max` property
		if (nextDevice) {
			const nextDeviceBreakpoints = initialBreakpoints[nextDevice];
			const nextSmallestBreakpoint = isRotateBreakpoint(nextDeviceBreakpoints)
				? Math.min(...Object.values(nextDeviceBreakpoints).filter(isNumber))
				: nextDeviceBreakpoints;

			if (isRotateBreakpoint(deviceBreakpoints)) {
				const rotateDirections = Object.keys(deviceBreakpoints);
				rotateDirections.forEach((direction, i) => {
					// If it's not the last direction, take the next directions breakpoint.
					// Else, take the last device (landscape), then take the next device's smallest breakpoint
					breakpoints.push({
						name: `${deviceName}-${direction}-max`,
						value:
							i === rotateDirections.length - 1
								? nextSmallestBreakpoint - 1
								: deviceBreakpoints[rotateDirections[i + 1]] - 1,
					});
				});
			} else {
				breakpoints.push({ name: `${deviceName}-max`, value: nextSmallestBreakpoint - 1 });
			}
		}
	});

	return breakpoints
		.sort((a, b) => a.value - b.value)
		.map(b => ({ ...b, value: `${b.value}px` }))
		.reduce((breakpoints, breakpoint) => ((breakpoints[breakpoint.name] = breakpoint.value), breakpoints), {});
}
