import { useCashbackContext } from "@bokio/contexts/CashbackContext/CashbackContext";
import { Checklist } from "@bokio/designsystem/components/Checklist/Checklist";
import { Heading, Paragraph, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import Icon from "@bokio/elements/Icon/Icon";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory, OnboardingLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { formatMessage, formatNumberCurrency } from "@bokio/shared/utils/format";
import { mergeClassNames } from "@bokio/utils/classes";
import { formatPriceplanPercentage } from "@bokio/utils/priceplanUtils";

import { PromotionModalButtons } from "../PromotionModalButtons/PromotionModalButtons";

import type { PromotionModalInnerProps } from "../promotionModalModels";
import type * as React from "react";

import * as styles from "../promotionModal.scss";

import BokioPlan = m.Entities.BokioPlan;
import SubscriptionInterval = m.Bokio.Common.Billing.Model.SubscriptionInterval;

interface PremiumToBbaPromotionListItemProps {
	recommended?: boolean;
	cashbackContract?: m.Bokio.Common.Billing.Model.CashbackContract;
	estimatedCashback: number;
	cashback: boolean;
}

const PremiumToBbaPromotionListItem: React.FC<PremiumToBbaPromotionListItemProps> = ({
	recommended,
	cashback,
	cashbackContract,
	estimatedCashback,
}) => {
	const onboardingLang = OnboardingLangFactory();

	const containerClass = recommended
		? mergeClassNames([styles.promotionListItem, styles.recommended, styles.premiumToBba])
		: mergeClassNames([styles.promotionListItem, styles.premiumToBba]);

	const formattedPreamble = () => {
		const translationWouldBenefit =
			onboardingLang.Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_cashback_benefit;
		const translationWouldNotBenefit =
			onboardingLang.Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_cashback_not_benefit;

		const formattedNumber = formatNumberCurrency(estimatedCashback, "SEK", 0);
		const translation = recommended ? translationWouldBenefit : translationWouldNotBenefit;

		return formatMessage(translation, formattedNumber);
	};

	const bbaList = [
		onboardingLang.Onboarding_PremiumToBba_PromotionListItem_bba_1,

		onboardingLang.Onboarding_PremiumToBba_PromotionListItem_bba_2,

		onboardingLang.Onboarding_PremiumToBba_PromotionListItem_bba_3,
	];

	const cashbackCard = () => {
		const translation = onboardingLang.Onboarding_PremiumToBba_PromotionListItem_cashback_2;
		const formattedNumber = formatPriceplanPercentage(cashbackContract?.CardSpendCashbackPercentage || 0);

		return formatMessage(translation, formattedNumber);
	};

	const cashbackAccount = () => {
		const translation = onboardingLang.Onboarding_PremiumToBba_PromotionListItem_cashback_3;
		const formattedNumber = formatPriceplanPercentage(cashbackContract?.DepositedCapitalCashbackPercentage || 0);

		return formatMessage(translation, formattedNumber);
	};

	const cashbackList = [
		cashbackAccount(),
		cashbackCard(),

		onboardingLang.Onboarding_PremiumToBba_PromotionListItem_cashback_free_support,
	];

	return (
		<div className={containerClass}>
			{recommended && (
				<span className={styles.recommendedBadge}>{onboardingLang.Onboarding_PromotionListItem_Recommended}</span>
			)}
			<Section resetHeadingLevelTo={3}>
				<Heading>
					{cashback
						? onboardingLang.Onboarding_PremiumToBba_PromotionListItem_heading_cashback
						: onboardingLang.Onboarding_PremiumToBba_PromotionListItem_heading_banking}
					<Icon color="blue" name="bba-filled" />
				</Heading>
				<Markdown
					markdownContent={
						cashback
							? formattedPreamble()
							: onboardingLang.Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_banking
					}
				/>
			</Section>
			<Section resetHeadingLevelTo={3}>
				<Checklist
					heading={
						cashback ? onboardingLang.Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_listheading : ""
					}
					list={cashback ? cashbackList : bbaList}
				/>
			</Section>
		</div>
	);
};

export const PremiumToBba: React.FC<PromotionModalInnerProps> = ({ resolve, dismiss }) => {
	const generalLang = GeneralLangFactory();
	const onboardingLang = OnboardingLangFactory();

	const cashbacks = useCashbackContext();
	if (!cashbacks.initialized) {
		return null;
	}

	const recommendPlus = cashbacks.wouldBenefitFromPlus(BokioPlan.Free, SubscriptionInterval.Month, "Both");

	const highlightPlus = () => <span className={styles.highlightPlus}>Plus</span>;
	const cashback = () => (
		<strong>
			{cashbacks.estimatedCashback} / {generalLang.Month}
		</strong>
	);

	return (
		<div className={styles.promotionModal} data-testid="PromotionModal_PremiumToBba">
			<Section resetHeadingLevelTo={2}>
				<Heading>
					{recommendPlus
						? formatMessage(
								onboardingLang.Onboarding_PremiumToBba_PromotionModalHeading_PremiumToBba_cashback,
								highlightPlus(),
								cashback(),
							)
						: onboardingLang.Onboarding_PremiumToBba_PromotionModalHeading_PremiumToBba_noCashback}
				</Heading>
				<Paragraph>{onboardingLang.Onboarding_PremiumToBba_PromotionModalHeading_PremiumToBba_preamble}</Paragraph>
			</Section>

			<ol className={styles.promotionItemList}>
				<li>
					<PremiumToBbaPromotionListItem
						recommended={false}
						cashbackContract={cashbacks.defaultContract}
						estimatedCashback={cashbacks.estimatedCashback}
						cashback={false}
					/>
				</li>
				<li>
					<PremiumToBbaPromotionListItem
						recommended={recommendPlus}
						cashbackContract={cashbacks.defaultContract}
						estimatedCashback={cashbacks.estimatedCashback}
						cashback={true}
					/>
				</li>
			</ol>

			<PromotionModalButtons
				ctaButtonText={onboardingLang.Onboarding_PremiumToBba_PromotionModal_CTA_button_bba}
				resolve={resolve}
				dismiss={dismiss}
			/>
		</div>
	);
};
