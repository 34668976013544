import * as React from "react";
import { flushSync } from "react-dom";

import { useClickOutside } from "@bokio/hooks/useClickOutside/useClickOutside";
import { mergeClassNames } from "@bokio/utils/classes";

import {
	getPopoverAnimationClassName,
	getPopoverArrowColorClassName,
	getPopoverBorderRadiusClassName,
	getPopoverDirectionClassName,
	getPopoverVariantClassName,
} from "./Popover.helper";

import type { PopoverAlign, PopoverDirection, PopoverProps } from "./Popover.types";

import * as styles from "./popover.scss";

interface PositionInfo {
	align: Exclude<PopoverAlign, "middle">; // middle is implement as left with alignValue < 0
	alignValue: number;
	direction: PopoverDirection;
}

const PADDING = 16;

export const Popover: React.FC<React.PropsWithChildren<PopoverProps>> = ({
	refIncludingToggle,
	testId,
	children,
	isOpen,
	onClose,
	align: overrideAlign = "left",
	direction: overrideDirection = "down",
	variant = "default",
	borderRadius = "small",
	stretchOnMobile = false,
	showArrow = false,
	arrowAnchor,
	fitContent = false,
	clickOutsideCondition,
}) => {
	const popoverRef = React.useRef<HTMLDivElement>(null);

	const [shouldRender, setShouldRender] = React.useState(false);

	const [position, setPosition] = React.useState<PositionInfo>({
		align: "left",
		alignValue: 0,
		direction: "down",
	});

	const [arrowAlignValue, setArrowAlignValue] = React.useState(8);

	useClickOutside({
		/**
		 * There's usually a toggle button that opens/closes the popover,
		 * so both the toggle button and Popover should be treated as "inside".
		 * Otherwise the toggle function can run either before/after the on click outside event handler depending on the React version,
		 * causing the button to toggle open the popover -> then click outside callback immediately closes the opened popover.
		 */
		ref: refIncludingToggle ?? popoverRef,
		onClickOutside: onClose,
		condition: isOpen && (!clickOutsideCondition || clickOutsideCondition()),
	});

	/**
	 * VM 2020-08-04:
	 * `shouldRender` and `isOpen` work in conjuction to properly add or remove the component
	 * from the DOM at the same time as making sure the animation runs on both open and close. When the component
	 * mounts or when `isOpen` is set to `true`, we also set `shouldRender` to `true` rendering everything as well as
	 * setting the correct css classes to make the animation run. When closing, we do something similar, but we wait for
	 * the animation to finish with `handleAnimationEnd` before removing the elements from the DOM, and this makes sure the
	 * animation works and the content is lazy-loaded.
	 *
	 * SS 2024-10-10:
	 * This state change needs to be synchronously flushed i.e. using useLayoutEffect,
	 * otherwise it might have race condition with the on click outside callback above.
	 * We've observed a behavioural change between React 16 and React 18.
	 * */
	React.useLayoutEffect(() => {
		if (isOpen) {
			setShouldRender(true);
		}
	}, [isOpen]);

	// calculate popover
	React.useLayoutEffect(() => {
		const calculateAligment = (
			align: PopoverAlign,
			popoverElem: HTMLElement,
		): { align: Exclude<PopoverAlign, "middle">; alignValue: number } => {
			const popoverBounds = popoverElem.getBoundingClientRect();
			switch (align) {
				case "left":
					if (popoverBounds.left + popoverBounds.width < window.innerWidth) {
						return {
							align: "left",
							alignValue: 0,
						};
					}
					return {
						align: "right",
						alignValue: 0,
					};
				case "middle":
					const anchorBounds = arrowAnchor?.getBoundingClientRect();
					const parentBounds = anchorBounds ?? popoverElem.parentElement?.getBoundingClientRect();
					if (parentBounds) {
						let left = (parentBounds.width - popoverBounds.width) / 2.0;
						const styleLeft = parseInt(popoverElem.style.left);
						const maxX = popoverBounds.left - styleLeft + popoverBounds.width + left;
						if (maxX > window.innerWidth - PADDING) {
							left = left - (maxX - (window.innerWidth - PADDING));
						}
						return {
							align: "left",
							alignValue: left,
						};
					}
					return {
						align: "right",
						alignValue: 0,
					};
				// fallback if cannot align left, middle
				case "right":
				default:
					return {
						align: "right",
						alignValue: 0,
					};
			}
		};

		const calculateDirection = (direction: PopoverDirection, popoverElem: HTMLElement): PopoverDirection => {
			const popoverBounds = popoverElem.getBoundingClientRect();
			return popoverBounds.top + popoverBounds.height > window.innerHeight ? "up" : direction;
		};

		if (popoverRef.current && isOpen && shouldRender) {
			const _align = calculateAligment(overrideAlign, popoverRef.current);
			const _direction = calculateDirection(overrideDirection, popoverRef.current);

			setPosition({
				align: _align.align,
				alignValue: _align.alignValue,
				direction: _direction,
			});
		}
	}, [children, isOpen, overrideAlign, overrideDirection, arrowAnchor, shouldRender]);

	// calculate arrow
	React.useLayoutEffect(() => {
		const popoverBounds = popoverRef.current?.getBoundingClientRect();
		const anchorBounds = arrowAnchor?.getBoundingClientRect();
		if (shouldRender && popoverBounds && anchorBounds) {
			const anchorCenterX = anchorBounds.left + anchorBounds.width / 2.0;
			const arrowOffset =
				position.align === "left"
					? anchorCenterX - popoverBounds.left - 7 // $arrow-size
					: popoverBounds.right - anchorCenterX - 7;
			setArrowAlignValue(arrowOffset);
		}
	}, [position.align, position.alignValue, position.direction, arrowAnchor, shouldRender]);

	const handleAnimationEnd = () => {
		if (!isOpen) {
			/**
			 * SS 2024-10-10
			 * Since animation end is not an user-initialised event,
			 * React doesn't flush the state change here synchronously.
			 * Here we force synchronising to make sure the animation doesn't have a strange blink at the end.
			 * This was not an issue in React 16, but it shows up in Reat 18.
			 */
			flushSync(() => {
				setShouldRender(false);
			});
		}
	};

	const classNames = mergeClassNames(
		!fitContent && styles.popoverMinWidth,
		getPopoverDirectionClassName(position.direction),
		getPopoverVariantClassName(variant),
		getPopoverBorderRadiusClassName(borderRadius),
		getPopoverAnimationClassName(position.direction, isOpen),
		stretchOnMobile && styles.stretch,
	);

	const arrowClassNames = mergeClassNames(
		styles.arrow,
		getPopoverArrowColorClassName(variant),
		position.direction === "down" ? styles.arrowUp : styles.arrowDown,
	);

	return shouldRender ? (
		<div
			ref={popoverRef}
			className={classNames}
			style={position.align === "left" ? { left: position.alignValue } : { right: position.alignValue }}
			onAnimationEnd={handleAnimationEnd}
			data-testid={testId}
		>
			{children}
			{showArrow && arrowAnchor && (
				<div
					className={arrowClassNames}
					style={position.align === "left" ? { left: arrowAlignValue } : { right: arrowAlignValue }}
				/>
			)}
		</div>
	) : null;
};
