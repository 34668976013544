import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";
import { createConnectHoc } from "@bokio/utils/createConnectHoc";

import type { GetAgencyCountProps } from "./agency/GetAgencyCount";
import type { GetAgencyStatusProps } from "./agency/GetAgencyStatus";
import type { GetTodoStatusProps } from "./todo/GetTodoStatus";
import type { GetCompanyUserProps } from "./user/GetCompanyUser";
import type { GetMembershipsProps } from "./user/GetMemberships";
import type { GetUserProps } from "./user/GetUser";

export const useUserMemberships = (): GetMembershipsProps => {
	const { memberships, isLoadingMemberships, reloadMemberships } = useTopLevelUser();
	return { memberships, isLoadingMemberships, reloadMemberships };
};

export const useUser = (): GetUserProps => {
	const { user, reloadUser } = useTopLevelUser();
	return { user, reloadUser };
};

/**
 * @deprecated Use {@link useUser} instead.
 */
export const getUser = {
	/**
	 * @deprecated Use {@link useUser} instead.
	 */
	connect: () => createConnectHoc(() => useUser()),
};

export const useCompanyUser = (): GetCompanyUserProps => {
	const { companyInfo, companyUser, companyUserPermissions, reloadCompanyUser } = useTopLevelUser();
	return {
		company: companyInfo,
		userStatus: companyUser,
		companyUserPermissions,
		reloadCompanyUser,
	};
};

/**
 * @deprecated Use {@link useCompanyUser} instead.
 */
export const getCompanyUser = {
	/**
	 * @deprecated Use {@link useCompanyUser} instead.
	 */
	connect: () => createConnectHoc(() => useCompanyUser()),
};

// Todo (get todo count)
export const useTodoStatus = (): GetTodoStatusProps => {
	const { todoCount, todoCacheBuster, updateTodoStatus, isLoadingTodo } = useTopLevelUser();
	return { todoCount, todoCacheBuster, updateTodoStatus, isLoadingTodo };
};
/**
 * @deprecated Use {@link useTodoStatus} instead.
 */
export const getTodoStatus = {
	/**
	 * @deprecated Use {@link useTodoStatus} instead.
	 */
	connect: () => createConnectHoc(() => useTodoStatus()),
};

// Partner/Agency
export const useAgencyStatus = (): GetAgencyStatusProps => {
	const { agencyStatus, isLoadingAgencyStatus, reloadAgencyStatus } = useTopLevelUser();
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return { agencyStatus: agencyStatus!, isLoadingAgency: isLoadingAgencyStatus, reloadAgencyStatus };
};

export const useAgencyCount = (): GetAgencyCountProps => {
	const { agencyCount, isLoadingAgencyCount, reloadAgencyCount } = useTopLevelUser();
	return { agencyCount, isLoadingAgency: isLoadingAgencyCount, reloadAgencyCount };
};
