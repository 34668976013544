import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./boxContent.scss";

interface BoxContentProps {
	children?: React.ReactNode;
	noPadding?: boolean;
	noMargin?: boolean;
	noBorder?: boolean;
	className?: string;
	testId?: string;
}

const BoxContent = (props: BoxContentProps) => (
	<div
		className={mergeClassNames(
			styles.content,
			props.noPadding && styles.noPadding,
			props.noMargin && styles.noMargin,
			props.noBorder && styles.noBorder,
			props.className,
		)}
		data-testid={props.testId}
	>
		{props.children}
	</div>
);

export default BoxContent;
