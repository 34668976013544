import { Day } from "@bokio/mobile-web-shared/core/model/model";

// Thread: https://twitter.com/awbjs/status/1018166939598770177
// You should only use deepCopy for simple data
export function deepCopy<T>(obj: T): T {
	if (isNullOrUndefined(obj) || isPrimitiveType(obj) || isRegex(obj) || isError(obj)) {
		return obj;
	} else if (Array.isArray(obj)) {
		return obj.map(deepCopy) as unknown as T;
	} else if (obj instanceof Day) {
		return new Day(obj.getFullYear(), obj.getMonth(), obj.getDayOfMonth()) as unknown as T;
	} else if (obj instanceof Date) {
		return new Date(obj.getTime()) as unknown as T;
	} else if (obj instanceof Set) {
		const arr = Array.from(obj).map(deepCopy);
		return new Set(arr) as unknown as T;
	} else if (typeof obj === "object") {
		const newObj = Object.create(Object.getPrototypeOf(obj));
		for (const iter in obj) {
			// eslint-disable-next-line @typescript-eslint/ban-types
			if ((obj as Object).hasOwnProperty(iter)) {
				newObj[iter] = deepCopy(obj[iter]);
			}
		}
		return newObj;
	}

	throw new Error(`${typeof obj} is not deep-copied properly.`);
}

function isNullOrUndefined(obj: unknown) {
	return obj === null || obj === undefined;
}

function isPrimitiveType(obj: unknown) {
	const type = typeof obj;

	return ["boolean", "string", "number", "symbol", "function", "undefined"].includes(type);
}

function isRegex(obj: unknown) {
	return obj instanceof RegExp;
}

function isError(obj: unknown) {
	return obj instanceof Error;
}
