import * as React from "react";

import * as m from "@bokio/mobile-web-shared/core/model/model";

import BokioPlan = m.Entities.BokioPlan;
import SubscriptionInterval = m.Bokio.Common.Billing.Model.SubscriptionInterval;
type CashbackContract = m.Bokio.Common.Billing.Model.CashbackContract;
type CashbackContractOffer = m.Bokio.Common.Billing.Model.CashbackContractOffer;
type CashbackValuesDto = m.Bokio.Backbone.Web.Controllers.Settings.CashbackValuesDto;

export type CashbackContextValue = {
	initialized: boolean;
	defaultContract: CashbackContract;
	availableContract: CashbackContractOffer | undefined;
	bbaValues: CashbackValuesDto | undefined;
	bookkeepingValues: CashbackValuesDto | undefined;
	estimatedCashback: number;
	estimatedCashbackBasedOnBBA: number;
	estimatedCashbackBasedOnBookkeeping: number;
	premiumPlusMonthlyCost: number;
	wouldBenefitFromPlus: (
		compareWithPlan: BokioPlan,
		compareWithInterval: SubscriptionInterval,
		useValues: "BBA" | "Bookkeeping" | "Both",
	) => boolean;
};

export const emptyCashbackContract: CashbackContract = {
	DepositedCapitalCashbackPercentage: 0,
	CardSpendCashbackPercentage: 0,
};

export const cashbackContext = React.createContext<CashbackContextValue>({
	initialized: false,
	defaultContract: emptyCashbackContract,
	availableContract: undefined,
	bbaValues: undefined,
	bookkeepingValues: undefined,
	estimatedCashback: 0,
	estimatedCashbackBasedOnBBA: 0,
	estimatedCashbackBasedOnBookkeeping: 0,
	premiumPlusMonthlyCost: 599,
	wouldBenefitFromPlus: () => false,
});

export const useCashbackContext = () => React.useContext(cashbackContext);
