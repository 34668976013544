import fromPairs from "lodash-es/fromPairs";
import * as qs from "qs";

import { getRoute } from "@bokio/shared/route";

import type { Location } from "history";

export type QueryParams = { [key: string]: string | undefined };

const filterProtoFromSearch = (search?: string): string | undefined => {
	return search
		?.split("&")
		.filter(q => {
			const [key] = q.split("=");

			return !/__proto__/.test(key);
		})
		.join("&");
};

export function parseSearchParams<T extends { [key: string]: string | undefined }>(search?: string): T {
	const params = new URLSearchParams(filterProtoFromSearch(search));

	return fromPairs([...params.entries()]) as T;
}

// FIXME: MQ 2019-08-09 Unify it with parseSearchParams
export function parseUrl(location?: Location) {
	const search = filterProtoFromSearch(location?.search);

	return qs.parse(search ?? "", { ignoreQueryPrefix: true });
}

export function replaceQuery(
	location: Location | undefined,
	newQueryParams: QueryParams,
	transformQueryParams: (queryParams: QueryParams) => QueryParams = q => q,
) {
	const currentQuery = parseUrl(location);

	const { pathname, hash } = location || { pathname: "", hash: "" };
	const updatedQueryParams = transformQueryParams({ ...currentQuery, ...newQueryParams });
	const search = `?${qs.stringify(updatedQueryParams)}`;

	return `${pathname}${search}${hash}`;
}

export function getCurrentRoute(location: { pathname: string; search: string; hash: string }) {
	const { pathname, search, hash } = location;
	return `${pathname}${search}${hash}`;
}

export function addCompanyId(path: string, companyId?: string) {
	if (companyId && path.toLowerCase().indexOf(companyId.toLowerCase()) === -1) {
		return `/${companyId}${path}`;
	}
	return path;
}

export function getLoginBackUrl() {
	return getRoute("login", {}, { returnUrl: `${location.pathname}${location.search}` });
}

export function getRedirectUrl(redirectUrl: string, companyId: string) {
	if (redirectUrl.toLowerCase().indexOf(companyId.toLowerCase()) === -1) {
		return `/${companyId}${redirectUrl.startsWith("/") ? "" : "/"}${redirectUrl}`;
	}
	return redirectUrl.toLowerCase();
}

const urlGuidMatcher = /^\/?([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/i;

export function urlStartsWithCompanyId(url: string) {
	return urlGuidMatcher.test(url);
}

export function getCompanyIdFromUrl(url: string): string | undefined {
	const matches = urlGuidMatcher.exec(url);
	if (matches && matches.length > 1) {
		return matches[1];
	}
	return undefined;
}

export const isRelativePath = (path: string) => {
	// See https://docs.microsoft.com/en-us/aspnet/mvc/overview/security/preventing-open-redirection-attacks
	// This implementation is a port of the c# code in the example.

	return (
		path !== undefined &&
		path.length > 0 &&
		((path[0] == "/" && (path.length == 1 || (path[1] != "/" && path[1] != "\\"))) || // "/" or "/foo" but not "//" or "/\"
			(path.length > 1 && path[0] == "~" && path[1] == "/")) // "~/" or "~/foo"
	);
};

export const getCurrentReturnUrl = (location: Location | undefined): string | undefined => {
	const { returnUrl } = parseUrl(location);
	if (!returnUrl) {
		return undefined;
	}

	if (!isRelativePath(returnUrl)) {
		return undefined;
	}

	return returnUrl;
};

export const withCurrentReturnUrl = (location: Location | undefined, path: string) => {
	// If path has a returnUrl, this overrides the current returnUrl.
	if (path.indexOf("returnUrl=") !== -1) {
		return path;
	}

	const returnUrl = location && getCurrentReturnUrl(location);
	if (!returnUrl) {
		return path;
	}

	if (path === returnUrl) {
		return path;
	}

	return `${path}${path.indexOf("?") !== -1 ? "&" : "?"}returnUrl=${encodeURIComponent(returnUrl)}`;
};

export const removeIfFalse = (value: boolean | undefined, fallbackIfUndefined: boolean | undefined) => {
	return value === true ? value : value === undefined ? fallbackIfUndefined : undefined;
};

export const parseBooleanQueryString = (value: string | undefined) => {
	return value === "true" ? true : value === "false" ? false : undefined;
};

export const toPath = (location: Location) => `${location.pathname}${location.search}${location.hash}`;
