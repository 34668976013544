import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import RenderRequestError from "./RenderRequestError";

import type { LoadingProps } from "./LoadingProps";

import * as styles from "./loading.scss";

interface LoadingProgressProps extends LoadingProps {
	width?: string;
	children?: React.ReactNode;
	paddingTop?: boolean;
	paddingBottom?: boolean;
	padding?: boolean;
	testId?: string;
}

class LoadingProgress extends React.Component<LoadingProgressProps> {
	render() {
		const { request, requests, children, hideSoftErrors } = this.props;

		return (
			<RenderRequestError
				request={request}
				requests={requests}
				showWhenLoaded={children}
				hideSoftErrors={hideSoftErrors}
			>
				{this.renderLoading()}
			</RenderRequestError>
		);
	}

	renderLoading() {
		const { width, className, paddingTop, paddingBottom, padding, testId } = this.props;
		const customStyle = { width: width || "100%" };
		const divClass = mergeClassNames(
			styles.progressLine,
			className,
			(paddingTop || padding) && styles.paddingTop,
			(paddingBottom || padding) && styles.paddingBottom,
		);

		return <div data-testid={testId} className={divClass} style={customStyle} />;
	}
}

export default LoadingProgress;
