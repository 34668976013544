import * as React from "react";

import { LayoutFull } from "@bokio/components/LayoutFull/LayoutFull";
import { Link } from "@bokio/elements/Link/Link";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";
import { getCompanyUser } from "@bokio/shared/state/requests";
import htmlDecode from "@bokio/shared/utils/htmlDecode";

import type { GetCompanyUserProps } from "@bokio/shared/state/requests/user/GetCompanyUser";

import * as styles from "./genericNotFound.scss";

type GenericNotFoundProps = GetCompanyUserProps;

class GenericNotFound extends React.Component<GenericNotFoundProps> {
	render() {
		const generalLang = GeneralLangFactory();
		const { company } = this.props;
		return (
			<LayoutFull noTopBarMode={true}>
				<div className={styles.notFound}>
					<h1>{generalLang.PageNotFoundHeading}</h1>
					<div>
						{/* eslint-disable-next-line react/no-danger */}
						{<div dangerouslySetInnerHTML={{ __html: htmlDecode(generalLang.PageNotFoundText) }} />}
						{company && (
							<div>
								<div className={styles.or}>{generalLang._OR}</div>
								<ul className={styles.linkList}>
									<li>
										<Link external={`/${company.Id}/accounting`}>{generalLang.AccountingApplication}</Link>
									</li>
									<li>
										<Link route={`/${company.Id}/invoicing/invoices`}>{generalLang.Menu_Invoices}</Link>
									</li>
									<li>
										<Link route={`/${company.Id}/salary/payroll`}>{generalLang.Menu_Salaries}</Link>
									</li>
									<li>
										<Link route={`/${company.Id}/expenses/overview`}>{generalLang.ExpenseClaims}</Link>
									</li>
								</ul>
							</div>
						)}
					</div>
				</div>
			</LayoutFull>
		);
	}
}

export default getCompanyUser.connect()(GenericNotFound);
