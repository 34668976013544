import * as React from "react";
import { Key } from "w3c-keys";

import { GeneralLangFactory } from "@bokio/lang";
import { mergeClassNames } from "@bokio/utils/classes";

import { Smiley } from "./Smiley";
import { StepContainer } from "./StepContainer";
import { Grade } from "./utils";

import * as styles from "./ratingToast.scss";

interface RatingStepProps {
	canProceed: boolean;
	description: string;
	title: string;
	selectedGrade?: Grade;
	setGrade: (grade: Grade) => void;
	handleClose: () => void;
	handleSubmit: () => Promise<void>;
}

export const RatingStep: React.FC<RatingStepProps> = ({
	canProceed,
	description,
	title,
	selectedGrade,
	setGrade,
	handleClose,
	handleSubmit,
}) => {
	const lang = GeneralLangFactory();

	const grades: { value: Grade; text: string }[] = [
		{ value: Grade.VeryBad, text: lang.Rating_VeryBad },
		{ value: Grade.Bad, text: lang.Rating_Bad },
		{ value: Grade.Okay, text: lang.Rating_Okay },
		{ value: Grade.Good, text: lang.Rating_Good },
		{ value: Grade.Excellent, text: lang.Rating_Excellent },
	];

	const handleKeyDown = (event: React.KeyboardEvent, grade: Grade) => {
		switch (event.key) {
			case Key.Enter:
			case Key.Space:
				setGrade(grade);
				break;
		}
	};

	return (
		<StepContainer
			title={title}
			description={description}
			canProceed={canProceed}
			handleClose={handleClose}
			handleSubmit={handleSubmit}
		>
			<section className={styles.rating}>
				{grades.map((g, index) => (
					<React.Fragment key={g.value}>
						<div
							className={mergeClassNames(styles.ratingItem, selectedGrade === g.value && styles.selected)}
							onKeyDown={e => handleKeyDown(e, g.value)}
							onClick={() => setGrade(g.value)}
							tabIndex={0}
							data-testid={`Grade_${g.value}`}
						>
							<Smiley grade={g.value} />
							<span className={mergeClassNames(styles.grade, selectedGrade === g.value && styles.selected)}>
								{g.text}
							</span>
						</div>
						{index < grades.length - 1 && <div className={styles.line} />}
					</React.Fragment>
				))}
			</section>
		</StepContainer>
	);
};
