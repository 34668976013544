import * as React from "react";

import { ModalFooter } from "@bokio/components/Modal";
import { Button } from "@bokio/designsystem/components/Button";
import { Link } from "@bokio/elements/Link/Link";
import { SettingsLangFactory } from "@bokio/lang";
import { getRoute } from "@bokio/shared/route";

import * as styles from "../addFiscalYearModal.scss";

interface RedirectViewProps {
	showSelectCompanyLink: boolean;
	companyId: string;
}

export const RedirectView = ({ showSelectCompanyLink, companyId }: RedirectViewProps) => {
	const lang = SettingsLangFactory();
	return (
		<React.Fragment>
			<div className={styles.container} data-testid="AddFiscalYear_RedirectView">
				{lang.AddFiscalYearModal_RedirectText}
				{showSelectCompanyLink && (
					<React.Fragment>
						<br />
						<br />
						<Link route={getRoute("selectCompany")}> {lang.AddFiscalYearModal_SwitchCompanies} </Link>
					</React.Fragment>
				)}
			</div>
			<ModalFooter>
				<Button type="link" appearance="primary" route={getRoute("fiscalList", { company: companyId })}>
					{lang.GoToFiscalYears}
				</Button>
			</ModalFooter>
		</React.Fragment>
	);
};
