import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { FormGroup, FormWithValidation, InputField } from "@bokio/elements/Form";
import { asValidatorFunction } from "@bokio/elements/Form/FormWithValidation/FormWithValidation";
import { Link } from "@bokio/elements/Link/Link";
import { LinkButton } from "@bokio/elements/LinkButton/LinkButton";
import { RenderRequestError } from "@bokio/elements/Loading";
import { GeneralLangFactory, SignUpLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { filterByField } from "@bokio/shared/validation/entityValidator";
import { trackTrace } from "@bokio/utils/t";
import { withCurrentReturnUrl } from "@bokio/utils/url";

import PasswordValidator from "./validators/PasswordValidator";

import type { LoginUserResult } from "@bokio/guest/src/scenes/SignUp/signUpUtils";
import type { ValidatorResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./loginForm.scss";

import LoginError = m.Bokio.Services.Account.LoginError;
interface LoginPasswordStepProps {
	loginSuccess: (loginuserStatus: LoginUserResult) => Promise<void>;
	requireVerification: () => void;
	emailAddress: string;
	changeEmail: () => void;
	loginButtonText?: string;
	disableChangeEmail?: boolean;
}

const LoginPasswordStep = (props: LoginPasswordStepProps) => {
	const router = useRouter();
	const { isMobile } = useDeviceQuery();
	const lang = GeneralLangFactory();
	const signupLang = SignUpLangFactory();
	const { loginSuccess } = props;

	const doLogin = async (formData: { password: string }) =>
		// We do this complicated stuff to be able to still show the loader when login request is finished and the page is
		// redirecting. We have to redirect due to Anti forgery token that has to be updated when logged in.
		new Promise<m.Envelope<LoginUserResult, LoginError>>((resolve, reject) => {
			proxy.Bokio.AccountController.Login.Post(props.emailAddress, formData.password)
				.then(data => {
					// eslint-disable-next-line promise/always-return
					if (data.Success) {
						// eslint-disable-next-line promise/no-nesting
						loginSuccess(data.Data)
							.then(() => resolve(data))
							// eslint-disable-next-line promise/no-nesting
							.catch(reject);
					} else {
						if (data.Error === LoginError.RequiresVerification) {
							props.requireVerification();
						}
						resolve(data);
					}
				})
				.catch(reject);
		});

	const [login, loginRequest] = useLazyApi(doLogin, {
		onApiError: error => trackTrace("login error", { error: "" + error }, "warn"),
	});
	const initialState = { password: "" };
	return (
		<FormWithValidation<typeof initialState, ValidatorResult>
			initialState={initialState}
			onSubmit={login}
			validator={asValidatorFunction(new PasswordValidator(lang))}
		>
			{({ formData, setValue, validation }) => (
				<React.Fragment>
					<p className={styles.intro}>{signupLang.Login_PasswordDescription}</p>
					<FormGroup>
						<InputField
							value={props.emailAddress}
							disabled={true}
							inlineControls={
								props.disableChangeEmail
									? undefined
									: () => (
											<div className={styles.changeEmailLink}>
												<LinkButton onClick={props.changeEmail} testId="login_changeEmail" type="button">
													{lang.Change}
												</LinkButton>
											</div>
										)
							}
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							onChange={setValue("password")}
							nameForAutofill={"password"}
							label={lang.Password}
							value={formData.password}
							fieldProps={{ type: "password" }}
							disabled={loginRequest.isLoading}
							autoFocus={!isMobile}
							errors={filterByField("password", validation.errors)}
							testId="LoginPasswordStep_Password"
						/>
					</FormGroup>

					<p className={styles.forgotPassword}>
						<Link
							className="testId_forgotPassword"
							route={withCurrentReturnUrl(router.location, getRoute("forgotPassword", {}))}
							testId="LoginForm_ForgotPassword"
						>
							{lang.ForgotPassword}
						</Link>
					</p>
					<FormGroup align="center">
						<Button type="submit" loading={loginRequest.isLoading} testId="LoginPasswordStep_LogIn">
							{props.loginButtonText ?? lang.LogIn}
						</Button>
					</FormGroup>
					<RenderRequestError request={loginRequest} />
				</React.Fragment>
			)}
		</FormWithValidation>
	);
};

export default LoginPasswordStep;
