import { SearchField } from "@bokio/components/SearchField/SearchField";
import { useHelpContext } from "@bokio/contexts/HelpContext/HelpContext";
import LoadingProgressText from "@bokio/elements/Loading/LoadingProgressText";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";

import { helpSearchAnalytics } from "./HelpSearchAnalytics";
import HelpSearchResult from "./HelpSearchResult";

import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";
import type { HelpSearchResultModel } from "@bokio/shared/models/HelpSearchResultModel";
import type { HelpSearchSuggestion } from "@bokio/shared/models/HelpSearchSuggestion";
import type * as React from "react";

import * as styles from "./helpStyle.scss";

export interface HelpSearchProps {
	itemClicked: (item: HelpSearchSuggestion) => void;
	onClearSearch: () => void;
	onSearch: (searchString: string) => void;
	searchRequest: RequestState<HelpSearchResultModel>;
}

export const HelpSearch: React.FC<HelpSearchProps> = ({ onClearSearch, itemClicked, onSearch, searchRequest }) => {
	const { searchString, setSearchString } = useHelpContext();
	const doSearch = (value: string) => {
		onSearch(value);
		helpSearchAnalytics.trackSearch(value);
	};

	const lang = GeneralLangFactory();

	return (
		<div className={styles.helpSearch}>
			<div>
				<LoadingProgressText key="loader" text={lang.Searching} request={searchRequest} />
				{!searchRequest.isLoading && (
					<div>
						<div className={styles.searchBar}>
							<SearchField
								value={searchString || ""}
								placeholder={lang.Help_Search}
								onChange={setSearchString}
								doSearch={doSearch}
								wide={true}
								bigIcon={true}
								allowClear={!!searchString && !!searchRequest.data}
								onClearSearch={onClearSearch}
								testId="Help_SearchField"
							/>
						</div>
						{searchRequest.data && <HelpSearchResult searchResult={searchRequest.data} itemClicked={itemClicked} />}
					</div>
				)}
			</div>
		</div>
	);
};
