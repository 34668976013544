import { Link } from "@bokio/elements/Link/Link";

import type * as React from "react";

export interface SelectOptionProps<TValue> {
	labelWhenSelected: React.ReactNode;
	labelInDropDown: React.ReactNode;
	value: TValue;
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>, value: TValue) => void;
	testId?: string;
}

const SelectOption = <TValue,>(props: SelectOptionProps<TValue>) => {
	const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		if (props.onClick) {
			props.onClick(e, props.value);
		}
	};
	return (
		<Link onMouseDown={onClick} style="none" testId={props.testId}>
			{props.labelInDropDown}
		</Link>
	);
};

export default SelectOption;
