import type { PopoverBorderRadius, PopoverDirection, PopoverVariant } from "./Popover.types";

import * as styles from "./popover.scss";

export function getPopoverDirectionClassName(direction: PopoverDirection): string {
	switch (direction) {
		case "up":
			return styles.popoverDirectionUp;
		case "down":
			return styles.popoverDirectionDown;
		default:
			return "";
	}
}

export const getPopoverAnimationClassName = (direction: PopoverDirection, isOpen: boolean) => {
	switch (direction) {
		case "up":
			return isOpen ? styles.popoverInUp : styles.popoverOutUp;
		case "down":
			return isOpen ? styles.popoverInDown : styles.popoverOutDown;
	}
};

export function getPopoverVariantClassName(color: PopoverVariant): string {
	switch (color) {
		case "default":
			return styles.variantDefault;
		case "notice":
			return styles.variantNotice;
		default:
			return "";
	}
}

export function getPopoverBorderRadiusClassName(color: PopoverBorderRadius): string {
	switch (color) {
		case "small":
			return styles.borderRadiusSmall;
		case "medium":
			return styles.borderRadiusMedium;
		default:
			return "";
	}
}

export function getPopoverArrowColorClassName(color: PopoverVariant): string {
	switch (color) {
		case "default":
			return styles.arrowColorDefault;
		case "notice":
			return styles.arrowColorNotice;
		default:
			return "";
	}
}
