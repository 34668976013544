/* eslint-disable import/no-duplicates */
import { format as fnsFormat, formatDistanceToNow, isValid } from "date-fns";
import { enGB, sv } from "date-fns/locale";
import * as React from "react";

import { GeneralLangFactory } from "@bokio/lang";
import { CountryCode, Day } from "@bokio/mobile-web-shared/core/model/model";
import { Config } from "@bokio/shared/config";
import memoize from "@bokio/utils/memoize";

import type { SalaryLang } from "@bokio/lang/SalaryLangFactory";

const formatNumberCache = memoize((lang: string, decimals: number) =>
	Intl.NumberFormat(lang, { maximumFractionDigits: decimals }),
);

/**
 * Returns a number formatted as 1,234.21 in EN and 1 124,21 in SV
 * Use {@link formatWithDecimalCount} if you want to specify both minimum and maximum fraction digits.
 * @param amount the amount to format
 * @param parentheses
 * @param decimals is number of decimals after decimal separator
 */
export function formatNumber(amount?: number, parentheses = false, decimals = 5): string {
	if (!amount) {
		return parentheses ? "(0)" : "0";
	}
	return formatNumberCache(Config.env.lang, decimals).format(amount);
}

const formatWithDecimalCountCache = memoize(
	(lang: string, minimumFractionDigits: number, maximumFractionDigits: number) =>
		Intl.NumberFormat(lang, { maximumFractionDigits, minimumFractionDigits }),
);

/**
 * Similar to {@link formatNumber} but allows you to specify minimum fraction digits
 */
export function formatWithDecimalCount(
	amount: number,
	minimumFractionDigits: number,
	maximumFractionDigits: number,
): string {
	return formatWithDecimalCountCache(Config.env.lang, minimumFractionDigits, maximumFractionDigits).format(amount);
}

const formatNumberCurrencyCache = memoize((lang: string, currency: string, decimals: number) =>
	Intl.NumberFormat(lang, {
		currency,
		style: "currency",
		maximumFractionDigits: decimals,
		minimumFractionDigits: decimals,
	}),
);

/**
 * Returns a number with currency formatted as £ 1,234.21 in EN and 1 124,21 kr in SV
 * @param value the amount to format
 * @param currency ISO 4217 currency code for the amount
 * @param decimals the number of decimals after decimal separator
 */
export function formatNumberCurrency(value: number, currency: string, decimals = 2) {
	return formatNumberCurrencyCache(Config.env.lang, currency, decimals).format(value);
}

export function formatAmountWithCurrency(value: { Amount: number; CurrencyIsoCode: string }, decimals = 2) {
	return formatNumberCurrencyCache(Config.env.lang, value.CurrencyIsoCode, decimals).format(value.Amount);
}

/**
 * Similar to `formatNumberCurrency` but no decimals when the input is integer.
 * @param value the amount to format
 * @param currency ISO 4217 currency code for the amount
 * @param decimals the number of decimals after decimal separator if the input is not integer
 */
export function formatNumberCurrencyWithoutDecimalWhenInteger(value: number, currency: string, decimals = 2) {
	return formatNumberCurrencyCache(Config.env.lang, currency, Number.isInteger(value) ? 0 : decimals).format(value);
}

/**
 * Format with `formatNumberCurrency` for foreign currency and `formatWithDecimalCount` for local currency
 * @param localCurrency ISO 4217 currency code for the currency that's local to the user
 * @param value the amount to format
 * @param currency ISO 4217 currency code for the amount
 * @param decimals the number of decimals after decimal separator
 */
export function formatWithDecimalCountAndCurrencyOnlyOnForex(
	localCurrency: string,
	value: number,
	currency: string,
	decimals = 2,
) {
	if (localCurrency === currency) {
		return formatWithDecimalCount(value, decimals, decimals);
	}
	return formatNumberCurrency(value, currency, decimals);
}

/**
 * Returns a number (rounded down) with currency formatted as £ 1,234.21 in EN and 1 124,21 kr in SV
 * @param value the amount to format
 * @param decimals is number of decimals after decimal separator
 */
export function formatNumberCurrencyRoundDown(value: number, currency: string, decimals = 2) {
	const floored = Math.floor(value);
	return formatNumberCurrencyCache(Config.env.lang, currency, decimals).format(floored);
}

/**
 * Returns the rendered currency unit symbol.
 * e.g. SEK renders to "kr" in sv-SE and en-SE; "SEK" in en-GB.
 * @param currency
 */
export function formatCurrencySymbol(currency: string) {
	const formatter = formatNumberCurrencyCache(Config.env.lang, currency, 0);

	// Catch legacy iOS Safari
	// https://caniuse.com/mdn-javascript_builtins_intl_numberformat_formattoparts
	if (formatter.formatToParts) {
		return (
			formatter.formatToParts(0).filter((part: { type: string; value: string }) => part.type === "currency")[0]
				?.value ?? currency
		);
	}

	return currency;
}

const formatPercentageCache = memoize((lang: string, minDecimals: number, maxDecimals: number) =>
	Intl.NumberFormat(lang, {
		style: "percent",
		maximumFractionDigits: maxDecimals,
		minimumFractionDigits: minDecimals,
	}),
);
export function formatPercentage(value: number, minDecimals = 0, maxDecimals = 0) {
	return formatPercentageCache(Config.env.lang, minDecimals, Math.max(minDecimals, maxDecimals)).format(value);
}

export function leadingZero(num: number | string): string {
	let res = typeof num === "number" ? num.toString() : num;

	if (res.length === 1) {
		res = "0" + res;
	}

	return res;
}

const monthNumberToTextCache = memoize((lang: string, format: "short" | "long") =>
	Intl.DateTimeFormat(lang, { month: format }),
);

export function monthNumberToText(num: string | number, format: "short" | "long" = "long"): string {
	const value = typeof num === "string" ? parseInt(num, 10) : num;

	const formatter = monthNumberToTextCache(Config.env.lang, format);
	// NOTE: Dummy year in order to pass date object into formatter
	return formatter.format(new Date(2017, value, 0));
}

export function toMonthString(date: Day, format: "short" | "long" = "long"): string {
	const formatter = monthNumberToTextCache(Config.env.lang, format);
	// NOTE: Dummy year in order to pass date object into formatter
	return formatter.format(date.toDate());
}

export function formatEmployeeName(firstName: string | undefined, lastName: string | undefined, lang: SalaryLang) {
	let fullName = firstName;

	if (lastName) {
		fullName += " " + lastName;
	}

	return fullName || lang.NEW_EMPLOYEE;
}

/**
 * E.g. `en-GB` ⇒ `GB`
 */
export function getCountryFromLocale(locale: string) {
	return locale.split("-")[1];
}

/**
 * E.g. `en-GB` ⇒ `en`
 */
export function getLanguageFromLocale(locale: string) {
	return locale.split("-")[0];
}

export function getDateFnsLocalFromLang() {
	const lang = getLanguageFromLocale(Config.env.lang);
	switch (lang) {
		case "en":
			return enGB;
		case "sv":
		default:
			return sv;
	}
}

export function getDateFnsLocalFromCountry() {
	const country = getCountryFromLocale(Config.env.lang);
	switch (country) {
		case "GB":
			return enGB;
		case "SE":
		default:
			return sv;
	}
}

export function composeDateFormatter(formatter: Intl.DateTimeFormat, date: DateType | undefined, format?: string) {
	if (!date) {
		return "";
	} else if (date instanceof Date && isNaN(date.getTime())) {
		return date.toString();
	}

	let dateObj: Date;
	if (date instanceof Date) {
		dateObj = date;
	} else {
		dateObj = date.toDate();
	}

	if (!isValid(dateObj)) {
		return "";
	} else if (format) {
		return fnsFormat(dateObj, format, { locale: getDateFnsLocalFromCountry() });
	} else {
		return formatter.format(dateObj);
	}
}

export type DateType = Day | Date;

export type Timezone = "Europe/Stockholm" | "Europe/London"; // Timezonze can be found in a tar.lz file here https://www.iana.org/time-zones

const formatDateCache = memoize((lang: string) => Intl.DateTimeFormat(lang));
/** If format is used, it is one-way you cannot parse it back */
export function formatDate(date: DateType | undefined, format?: string) {
	const formatter = formatDateCache(Config.env.lang);
	return composeDateFormatter(formatter, date, format);
}

const formatDateTimeCache = memoize((lang: string, timeZone?: Timezone) =>
	Intl.DateTimeFormat(lang, {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		timeZone,
	}),
);
export function formatDatetime(date: DateType | undefined, format?: string, timeZone?: Timezone): string {
	const formatter = formatDateTimeCache(Config.env.lang, timeZone);

	return composeDateFormatter(formatter, date, format);
}

const formatWeekdayCache = memoize((lang: string, type: "long" | "short") =>
	Intl.DateTimeFormat(lang, {
		weekday: type,
	}),
);

export function formatWeekday(date: Day | undefined, type: "long" | "short"): string {
	const formatter = formatWeekdayCache(Config.env.lang, type);
	return date === undefined ? "" : formatter.format(date.toDate());
}

type BasicArg = string | number;
type FuncArg = (str: string) => React.ReactNode;
type TokenArg = FuncArg | React.ReactNode;
type FormatterArg = [string, FuncArg];

function isFuncArg(obj: unknown): obj is FuncArg {
	return typeof obj === "function";
}

function isFormatterArg(obj: unknown): obj is FormatterArg {
	return typeof obj?.[0] === "string" && typeof obj?.[1] === "function";
}

export function formatMessage(template: string, ...args: BasicArg[]): string;
export function formatMessage(template: string, ...args: TokenArg[]): React.ReactNode;
export function formatMessage(template: string, ...args: FormatterArg[]): React.ReactNode;
export function formatMessage(template: string, ...args: unknown[]) {
	let hasReactElement = false;
	let repeatedTokens = 0;
	const mappingTokens = {};
	const messages = template
		.split(/(?:\{)([^\{\}]*)(?:})/) // split message into multiple parts with delimiter `{}`
		.map((value, index) => {
			if (index % 2 === 0) {
				return value;
			} else {
				let element: React.ReactNode;

				if (Object.keys(mappingTokens).includes(value)) {
					element = mappingTokens[value];
					repeatedTokens += 1;
				} else {
					const argIndex = Math.floor(index / 2) - repeatedTokens;
					const argToken = args[argIndex];

					element = isFormatterArg(argToken)
						? argToken[1](argToken[0])
						: isFuncArg(argToken)
							? argToken(value)
							: (argToken as React.ReactNode);
				}

				mappingTokens[value] = element;
				if (React.isValidElement(element)) {
					hasReactElement = true;
					return React.cloneElement(element, { key: index });
				} else {
					return element;
				}
			}
		})
		.filter(value => value || typeof value === "number");

	return hasReactElement ? messages : messages.join("");
}

/**
 * Returns a string with the format "MMM yyyy", i.e. year as four-digit number and full localised month name
 * E.g. "2018 december"
 */
// Arguably this method should be internationalised to adhere to the preferred order: "year month" vs "month year"
export const formatDateMonthAndYear = (d: Day) => `${monthNumberToText(d.getMonth() + 1)} ${d.getFullYear()}`;

/**
 * Returns a string with format `dd MMM`
 * Example: `26 Mar`
 */
export const formatDateWithShortMonth = (date: Day) => {
	// TODO Using leadingZero in sweden is wrong. Fix this.
	const day = leadingZero(date.getDayOfMonth());
	const monthText = monthNumberToText(date.getISOMonthNumber(), "short");

	return `${day} ${monthText}`;
};

/**
 * Returns a string with format `dd MMM yyyy`
 * Example: `26 Mar 2019`
 */
export const formatDateWithShortMonthAndYear = (date: Day) => {
	const year = date.getFullYear();

	return `${formatDateWithShortMonth(date)} ${year}`;
};

/**
 * Returns a string with format `dd MMM yy`
 * Example: `26 Mar 19`
 */
export const formatDateWithShortMonthAndShortYear = (date: Day) => {
	const year = date.format("yy");

	return `${formatDateWithShortMonth(date)} ${year}`;
};

/**
 * Returns a date range similar to `01 Jan 2018 – 31 Dec 2018` (localised)
 */
export function formatPeriodWithDay(from: Day, to: Day): string {
	return `${formatDateWithShortMonthAndYear(from)} – ${formatDateWithShortMonthAndYear(to)}`;
}

interface FormatPeriodMonthOptions {
	singleIfSame: boolean;
}
/**
 * Returns a date range similar to `januari 2018 – december 2018` (localised)
 */
export function formatPeriodMonth(from: Day, to: Day, options?: FormatPeriodMonthOptions): string {
	if (options && options.singleIfSame && from.getFullYear() === to.getFullYear() && from.getMonth() === to.getMonth()) {
		return formatDateMonthAndYear(from);
	}
	return `${formatDateMonthAndYear(from)} – ${formatDateMonthAndYear(to)}`;
}

export function formatPeriod(from: Day, to: Day): string {
	//Month case
	if (from.getDayOfMonth() === 1 && Day.lastDayOfMonth(from).getTime() === to.getTime()) {
		return `${from.getFullYear()} ${monthNumberToText(from.getMonth() + 1)}`;
	}

	const getQuarter = (date: Day) => Math.floor((date.getMonth() + 3) / 3);
	//Quarter case
	if (
		from.getDayOfMonth() === 1 &&
		Day.lastDayOfMonth(Day.addMonths(from, 2)).getTime() === to.getTime() &&
		getQuarter(from) === getQuarter(to)
	) {
		return `${from.getFullYear()} Q${getQuarter(from)}`;
	}

	//Year case
	if (
		from.getDayOfMonth() === 1 &&
		from.getMonth() + 1 === 1 &&
		Day.lastDayOfMonth(Day.addMonths(from, 11)).getTime() === to.getTime()
	) {
		return `${from.getFullYear()}`;
	}

	return `${formatDate(from)} – ${formatDate(to)}`;
}

/**
 * E.g. `en-GB` ⇒ `English`
 */
export function getLanguageStringFromLocale(locale: string) {
	const generalLang = GeneralLangFactory();
	const country = getCountryFromLocale(locale);
	const lang = getLanguageFromLocale(locale);
	switch (lang) {
		case "SV":
			return generalLang.LanguageSwedish;
		case "EN":
			return generalLang.LanguageSwedish;
		default:
			return country === CountryCode.GB ? "English" : "Swedish";
	}
}

export function formatDistanceToDate(date: Date) {
	return formatDistanceToNow(date, { addSuffix: true, locale: getDateFnsLocalFromLang() });
}

export function formatAccount(accountName: string, accountNumber: number) {
	return `${accountName} (${accountNumber})`;
}
