import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./labelFor.scss";

interface LabelProps {
	label: React.ReactNode;
	hint?: React.ReactNode;
	className?: string;
	mandatory?: boolean;
}

export const Label: React.FC<LabelProps> = ({ label, hint, className, mandatory = false }) => {
	return (
		<div className={mergeClassNames(styles.floating, className)}>
			{label}
			{mandatory && " *"}
			{hint && <small className={styles.hint}>{hint}</small>}
		</div>
	);
};
