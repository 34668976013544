import * as React from "react";

import { useApi, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { trackEvent } from "@bokio/utils/t";

import { RatingMessageStep, RatingScoreStep } from "./components";
import { RatingReviewStep } from "./components/RatingReviewStep/RatingReviewStep";

import * as styles from "./NPSFeedback.scss";

export const NPSFeedback: React.FC = () => {
	const [score, setScore] = React.useState<number>();

	const [shouldRateRequest, refreshShouldRate] = useApi(proxy.Feedback.NPSCollectorController.ShouldRate.Get, []);
	const [saveRating, saveRatingRequest] = useLazyApi(proxy.Feedback.NPSCollectorController.SetRating.Post);

	const handleRating = async (ratingScore: number) => {
		setScore(ratingScore);
		await saveRating({
			DontWantToAnswer: false,
			Rating: ratingScore,
		});

		trackEvent("NPS", "Rate", ratingScore.toString());
	};

	const handleNoRating = async () => {
		await saveRating({ DontWantToAnswer: true });
		refreshShouldRate();
	};

	const handleRatingWithMessage = async (message: string) => {
		if (!saveRatingRequest.data?.Data) {
			return;
		}
		await saveRating({
			RatingId: saveRatingRequest.data.Data,
			DontWantToAnswer: false,
			Rating: score,
			Reason: message,
		});

		refreshShouldRate();
	};

	const shouldRate = shouldRateRequest.data?.Data;
	if (!shouldRate) {
		return null;
	}

	return (
		<div className={styles.container} data-testid="NPSFeedback">
			{score && score < 9 ? (
				<RatingMessageStep score={score} onSubmit={handleRatingWithMessage} loading={saveRatingRequest.isLoading} />
			) : score ? (
				<RatingReviewStep onClose={refreshShouldRate} />
			) : (
				<RatingScoreStep
					score={score}
					onRatingScore={handleRating}
					onNoRating={handleNoRating}
					loading={saveRatingRequest.isLoading}
				/>
			)}
		</div>
	);
};
