// extracted by mini-css-extract-plugin
export var flyoutAside = "o9a";
export var flyoutBackground = "p9a";
export var header = "q9a";
export var actions = "r9a";
export var headerText = "s9a";
export var dropdown = "t9a";
export var scrollable = "u9a";
export var emptyState = "v9a";
export var noteItem = "w9a";
export var notesText = "x9a";