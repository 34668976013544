// extracted by mini-css-extract-plugin
export var container = "Tv";
export var warning = "Uv";
export var icon = "Zv";
export var error = "Vv";
export var info = "t";
export var success = "ml";
export var contentLeft = "Wv";
export var contentCenter = "Xv";
export var contentRight = "Yv";
export var closeButton = "_v";
export var noticeSymbol = "nl";