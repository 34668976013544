import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./toaster.scss";

interface ToasterProps {
	type: "warning" | "error" | "info" | "success";
	children: React.ReactNode;
	onClose: () => void;
	className?: string;
	testId?: string;
}

const Toaster = ({ type, children, onClose, className, testId }: ToasterProps) => (
	<div data-testid={testId} className={mergeClassNames(styles.container, styles[type], className)}>
		<div className={styles.contentLeft}>
			{type == "error" && <Icon name="attention-alt" size="16" />}
			{type == "success" && <Icon name="check" size="16" />}
			{(type === "info" || type === "warning") && (
				<div className={styles.noticeSymbol}>
					<span className={styles.icon}>
						{type === "info" && "i"}
						{type === "warning" && "!"}
					</span>
				</div>
			)}
		</div>
		<div className={styles.contentCenter}>{children}</div>
		<div className={styles.contentRight}>
			<button className={mergeClassNames("ficon-cancel", styles.closeButton)} type="button" onClick={onClose} />
		</div>
	</div>
);
export default Toaster;
