import * as React from "react";

import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useLoginsControls } from "@bokio/shared/containers/logins/withLoginsControls";

import { LoginBokioPayStep } from "./LoginBokioPayStep";
import LoginEmailStep from "./LoginEmailStep";
import LoginPasswordStep from "./LoginPasswordStep";
import LoginTOTPStep from "./LoginTOTPStep";

import type { LoginUserResult } from "@bokio/guest/src/scenes/SignUp/signUpUtils";

import LoginMethod = m.Bokio.Common.Contract.LoginMethod;
interface LoginFormProps {
	loginSuccess: (loginuserStatus: LoginUserResult) => Promise<void>;
	loginButtonText?: string;
	/** @param username If the username is set it can't be changed. This is used for the recent login modal */
	username?: string;
	baseTestId?: string; // Optional to separate multiple active login forms test ids
}

const LoginForm = (props: LoginFormProps) => {
	const { currentUserEmail, logins, setUserEmail, setUserLoginMethod } = useLoginsControls();
	const [getLoginMethod, getLoginMethodRequest] = useLazyApi(
		proxy.Bokio.AccountController.GetPossibleLoginMethodsPost.Post,
	);
	const [email, setEmail] = React.useState<string>(props.username ?? currentUserEmail);
	const [step2FA, setStep2FA] = React.useState<boolean>(false);
	const [loginMethod, setLoginMethod] = React.useState<LoginMethod | undefined>(logins[email]?.userLoginMethod);

	const refreshLoginMethod = async (currentEmail: string) => {
		const newLoginMethod = await getLoginMethod({ Username: currentEmail });
		if (newLoginMethod.Success) {
			if (newLoginMethod.Data[0] !== loginMethod) {
				setLoginMethod(newLoginMethod.Data[0]);
			}
		}
	};

	React.useEffect(() => {
		if (email) {
			refreshLoginMethod(email);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onLoginSuccess = (loginuserStatus: LoginUserResult) => {
		setUserEmail(email);
		if (loginMethod) {
			setUserLoginMethod(loginMethod);
		}

		return props.loginSuccess(loginuserStatus);
	};

	if (!loginMethod) {
		return (
			<LoginEmailStep
				defaultValue={email}
				getLoginMethodRequest={getLoginMethodRequest}
				onSubmit={email => {
					setEmail(email);
					setUserEmail(email); //Update the current user email to prevent timing issues in onLoginSuccess when you have multiple usernames stored
					refreshLoginMethod(email);
				}}
			/>
		);
	}

	//This might look weird but the reasoning is that we have moved all MirAuthentication users to BokioPayBankIdAuthentication but we still get a few users calling the endpoints and those calls will fail
	//The current theory is that they still have mir cached in local storage so lets force BokioPay which will update the cached login method when they log in
	if (loginMethod == LoginMethod.BokioPayBankIdAuthentication || loginMethod == LoginMethod.MirAuthentication) {
		return (
			<LoginBokioPayStep
				onLoginSuccess={loginuserStatus => {
					onLoginSuccess(loginuserStatus);
				}}
				emailAddress={email}
				onChangeEmail={() => setLoginMethod(undefined)}
				disableChangeEmail={!!props.username}
				baseTestId={props.baseTestId}
			/>
		);
	}

	if (step2FA) {
		return <LoginTOTPStep loginSuccess={onLoginSuccess} abort2FA={() => setStep2FA(false)} />;
	}

	return (
		<LoginPasswordStep
			loginButtonText={props.loginButtonText}
			loginSuccess={onLoginSuccess}
			requireVerification={() => setStep2FA(true)}
			emailAddress={email}
			changeEmail={() => setLoginMethod(undefined)}
			disableChangeEmail={!!props.username}
		/>
	);
};

export default LoginForm;
