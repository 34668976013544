// extracted by mini-css-extract-plugin
export var loading = "QZ";
export var smallLoading = "RZ";
export var text = "SZ";
export var logo = "TZ";
export var noMargin = "Cqa";
export var noMarginTop = "yj";
export var noMarginBottom = "zj";
export var docImgWrapper = "UZ";
export var docImg = "VZ";
export var p = "WZ";
export var icon = "XZ";
export var activeWrapper = "h0";
export var mainText = "w8";
export var subText = "x8";
export var dropzoneDefaultSize = "Dqa";
export var dropzone = "y8";
export var active = "z8";
export var rejected = "s9";
export var small = "t9";
export var disabled = "u9";
export var transparentButton = "Eqa";
export var fullscreen = "Aj";