import * as React from "react";

import { helpSearchAnalytics } from "@bokio/components/Help/HelpSearchAnalytics";
import { HelpWindowMode } from "@bokio/components/Help/helpSupportUtils";
import { useLoader } from "@bokio/hooks/useLoader/useLoader";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useUmbracoApi } from "@bokio/shared/services/api/UmbracoApi";
import { trackError } from "@bokio/utils/t";

import { helpContext } from "./HelpContext";

export const HelpContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [visible, setVisible] = React.useState<boolean>(false);
	const defaultViewHistory = [HelpWindowMode.Default];
	const [viewHistory, setViewHistory] = React.useState<HelpWindowMode[]>(defaultViewHistory);
	const onNavigate = (view: HelpWindowMode) => setViewHistory(prev => [...prev, view]);
	const onClearHistory = () => setViewHistory(defaultViewHistory);
	const { getHelp, getCachedHelpPageModel } = useUmbracoApi();

	const openHelpArticleLoader = useLoader({
		endpoint: (id: string) => getHelp(id),
		onSuccess: model => helpSearchAnalytics.trackHelpArticle(model.Url),
		onError: (error, params) => trackError(error, "app.HelpContextProvider", { parameters: params }),
	});

	const [searchString, setSearchString] = React.useState<string>("");
	const [areaPrefilled, setAreaPrefilled] = React.useState<m.Contracts.SupportFormArea>(
		m.Contracts.SupportFormArea.NotSet,
	);
	const [modalMode, setModalMode] = React.useState<boolean>(false);
	const [messagePrefilled, setMessagePrefilled] = React.useState<string>("");
	const [referenceId, setReferenceId] = React.useState<string>("");

	const openHelpArticle = async (id: string) => {
		await openHelpArticleLoader.load(id);
	};

	const resetHelpArticle = () => openHelpArticleLoader.reset();

	const onGoBack = () => {
		setViewHistory(prev => {
			const newHistory = prev.slice(0, -1);
			return newHistory.length ? newHistory : defaultViewHistory;
		});
		resetHelpArticle();
	};

	React.useEffect(() => {
		if (!visible) {
			setModalMode(false);
			setAreaPrefilled(m.Contracts.SupportFormArea.NotSet);
			setMessagePrefilled("");
			setReferenceId("");
		}
	}, [visible]);

	return (
		<helpContext.Provider
			value={{
				visible,
				toggleVisible: () => setVisible(prev => !prev),
				show: () => setVisible(true),
				view: viewHistory[viewHistory.length - 1],
				onNavigate,
				onClearHistory,
				onGoBack,
				activeHelpArticle: openHelpArticleLoader.request.data,
				openHelpArticle,
				resetHelpArticle,
				openArticleIsLoading: openHelpArticleLoader.request.isLoading,
				openArticleIsError: openHelpArticleLoader.request.error,
				searchString,
				setSearchString,
				getCachedHelpPageModel,
				areaPrefilled,
				setAreaPrefilled,
				messagePrefilled,
				setMessagePrefilled,
				referenceId,
				setReferenceId,
				modalMode,
				setModalMode,
			}}
		>
			{children}
		</helpContext.Provider>
	);
};
