import LoadingProgress from "./LoadingProgress";
import RenderRequestError from "./RenderRequestError";

import type { LoadingProps } from "./LoadingProps";
import type * as React from "react";

import * as styles from "./loading.scss";

interface LoadingProgressTextProps extends LoadingProps {
	text: React.ReactNode;
	width?: string;
}

const LoadingProgressText = ({ request, text, width, hideSoftErrors, requests }: LoadingProgressTextProps) => {
	return (
		<RenderRequestError request={request} requests={requests} hideSoftErrors={hideSoftErrors}>
			<div className={styles.loadingProgressText}>
				{(!request || request.isLoading) && <p>{text}</p>}
				<LoadingProgress width={width} request={request} paddingTop={true} />
			</div>
		</RenderRequestError>
	);
};

export default LoadingProgressText;
