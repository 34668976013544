import * as React from "react";

import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { Button } from "@bokio/designsystem/components/Button";
import { EmailField } from "@bokio/elements/Form";
import { LoadingProgress } from "@bokio/elements/Loading";
import { BackOfficeLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

import { PermissionsInput, toMembershipType } from "../PermissionsInput/PermissionsInput";

import type { Permissions } from "../PermissionsInput/PermissionsInput";

import * as styles from "./addUserModal.scss";

interface AddUserModalProps {
	onSuccess: () => void;
	onCancel: () => void;
	visible: boolean;
	partner: m.Bokio.BackOffice.PartnerStatusDto;
	isAgency?: boolean;
}

interface FormState {
	email: string;
	permissions: Permissions;
}

export const AddUserModal = ({ visible, partner, onCancel, onSuccess, isAgency = false }: AddUserModalProps) => {
	const backOfficeLang = BackOfficeLangFactory();
	const initialState: FormState = {
		email: "",
		permissions: {
			BookkeepingSupport: false,
			ClosureSupport: false,
			ManageCompanies: false,
			ManageUsers: false,
			PartnerSettings: false,
			SelfAssign: false,
			ManageQuoteRequests: false,
		},
	};
	const [state, setState] = React.useState(initialState);

	const [invite, inviteRequest] = useLazyApi(proxy.BackOffice.UsersController.Invite.Post, {
		onSuccess: onSuccess,
	});
	const isFormValid = state.email.length > 0;

	return (
		<Modal
			alwaysMounted={true}
			onClose={onCancel}
			title={backOfficeLang.InviteAccountant}
			testId="AddUserModal_Modal"
			visible={visible}
		>
			<ModalContent>
				<EmailField
					value={state.email}
					label={backOfficeLang.EmailAddress}
					onChange={email => setState(s => ({ ...s, email }))}
				/>
				<div className={styles.permissionsSection}>
					<PermissionsInput
						isAgency={isAgency}
						isPartnerAgency={partner.PartnerType === m.Entities.PartnerType.AgencyPartner}
						permissions={state.permissions}
						onChange={permissions => setState(s => ({ ...s, permissions }))}
					/>
				</div>
			</ModalContent>
			<LoadingProgress request={inviteRequest} />
			<ModalFooter>
				<Button
					testId="AddUserModal_SendInvite"
					onClick={() => invite(partner.Id, state.email, toMembershipType(state.permissions))}
					disabled={!isFormValid}
					loading={inviteRequest.isLoading}
				>
					{backOfficeLang.SendInvite}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
