import * as React from "react";

import { SplitButtonItem } from "@bokio/elements/SplitButton/SplitButtonItem";
import { useActiveBankConnection } from "@bokio/hooks/useActiveBankConnection/useActiveBankConnection";
import { AccountingLangFactory, GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getSalaryOverviewRoute } from "@bokio/salaries/src/scenes/Salary2/utils/salaryUrlHelpers";
import { getRoute } from "@bokio/shared/route";
import { useCompanyUser } from "@bokio/shared/state/requests";
import { trackEvent } from "@bokio/utils/t";

import type { BookkeepingMenuButtonProps } from "../../BookkeepingMenuButton";
import type { EventCategory } from "@bokio/utils/t";

import * as styles from "./dropdownContent.scss";

type DropdownContentProps = BookkeepingMenuButtonProps & {
	onUpload: () => void;
	onClick: () => void;
	labelTrackingPrefix: string;
	eventCateogry: EventCategory;
};

function getBankImportRoute(country: m.CountryCode, companyId: string) {
	switch (country) {
		case m.CountryCode.GB:
			return getRoute("bankTransactions", { company: companyId });
		case m.CountryCode.SE:
			return getRoute("bankImport", { company: companyId });
		default:
			return "";
	}
}

export const DropdownContent = (props: DropdownContentProps) => {
	const { companyUserPermissions, userStatus } = useCompanyUser();
	const { anyConnectionSupportsPayment } = useActiveBankConnection();

	if (!companyUserPermissions || !userStatus) {
		return null;
	}

	const access = companyUserPermissions;
	const company = userStatus.Company;
	const accountingLang = AccountingLangFactory();
	const generalLang = GeneralLangFactory();

	const getSalaryRoute = () => {
		if (
			userStatus.Company.Country == m.CountryCode.GB &&
			company.CompanyType == m.Entities.CompanyType.LimitedCompany
		) {
			return getRoute("directorsPayrollList", { company: company.Id });
		} else {
			return getSalaryOverviewRoute(company.Id);
		}
	};

	const trackClick = (key: string) =>
		trackEvent(props.eventCateogry, "Click", `${props.labelTrackingPrefix}MainSplitCta_Secondary${key}_`);

	const trackAndClick = (key: string) => {
		trackClick(key);
		props.onClick();
	};

	return (
		<React.Fragment>
			<div className={styles.category}> {accountingLang.BookkeepingMenu_CategoryBookkeep} </div>
			{access.UploadReceipt && company.Country !== m.CountryCode.GB && (
				<SplitButtonItem
					value="witReceipt"
					onClick={() => {
						props.onUpload();
						trackClick("RecordWithReceipt");
					}}
					testId="SplitButton_WithReceipt"
				>
					{accountingLang.BookkeepingMenu_UploadMaterial}
				</SplitButtonItem>
			)}
			{access.Bookkeep && (
				<SplitButtonItem
					value="noReceipt"
					route={getRoute("preBookkeep", { company: company.Id })}
					onClick={() => trackAndClick("RecordWithoutReceipt")}
					testId="SplitButton_WithoutReceipt"
				>
					{accountingLang.BookkeepingMenu_WithoutReceipt}
				</SplitButtonItem>
			)}
			<hr />
			<div className={styles.category}> {accountingLang.BookkeepingMenu_CategoryImport} </div>
			{access.UploadBank && (
				<SplitButtonItem
					value="bankImport"
					route={getBankImportRoute(userStatus.Company.Country, company.Id)}
					onClick={() => trackAndClick("BankImport")}
				>
					{accountingLang.BookkeepingMenu_ImportFromBank}
				</SplitButtonItem>
			)}
			{access.Bookkeep && access.ViewBookkeeping && userStatus.Company.Country === m.CountryCode.SE && (
				<SplitButtonItem
					value="importSi"
					route={getRoute("importSi", { company: company.Id })}
					onClick={() => trackAndClick("ImportSi")}
				>
					{accountingLang.BookkeepingMenu_ImportFromFile}
				</SplitButtonItem>
			)}
			<hr />
			{(access.Salaries || access.Invoices || (access.UploadBank && anyConnectionSupportsPayment)) && (
				<div className={styles.category}> {accountingLang.BookkeepingMenu_CategoryCreate} </div>
			)}
			{access.Invoices && (
				<SplitButtonItem
					value="Invoice"
					route={getRoute("invoicesCreate", { company: company.Id })}
					onClick={() => trackAndClick("Invoice")}
				>
					{accountingLang.BookkeepingMenu_Invoice}
				</SplitButtonItem>
			)}
			{access.Salaries && (
				<SplitButtonItem value="Salary" route={getSalaryRoute()} onClick={() => trackAndClick("Salary")}>
					{accountingLang.BookkeepingMenu_Salary}
				</SplitButtonItem>
			)}
			{access.UploadBank && anyConnectionSupportsPayment && (
				<SplitButtonItem
					value="Payment"
					route={getRoute("bankCreatePayment", { company: company.Id })}
					onClick={() => trackAndClick("Payment")}
				>
					{generalLang.Payment}
				</SplitButtonItem>
			)}
		</React.Fragment>
	);
};
