import * as React from "react";

import * as m from "@bokio/mobile-web-shared/core/model/model";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useAgencyStatus } from "@bokio/shared/state/requests";

import { useLazyApi } from "../useApi/useApi";

import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

import AgencyAnalyticsEventCategory = m.Bokio.Common.Contract.Metrics.Analytics.Requests.AgencyAnalyticsEventCategory;
export { AgencyAnalyticsEventCategory };

type UseMetric = [
	(
		Category: AgencyAnalyticsEventCategory,
		Action: string,
		Label?: string | undefined,
		Value?: number | undefined,
		AgencyID?: string,
	) => Promise<m.Envelope<boolean, m.SimpleError>>,
	RequestState<m.Envelope<boolean, m.SimpleError>>,
];

export const useAgencyMetric = (): UseMetric => {
	const { agencyStatus } = useAgencyStatus();
	const [execute, request] = useLazyApi(proxy.BackOffice.AgencyMetricsController.SaveAgencyAnalytics.Post);

	const addMetric = React.useCallback(
		(Category: AgencyAnalyticsEventCategory, Action: string, Label?: string, Value?: number, AgencyID?: string) =>
			execute(AgencyID ? AgencyID : agencyStatus.Id, { Category, Action, Label, Value }),
		[agencyStatus, execute],
	);

	return [addMetric, request];
};
