import * as React from "react";

import { isAccountorOrBokioGroup } from "@bokio/backoffice/src/backOffice.helper";
import { useReleaseNotesContext } from "@bokio/contexts/ReleaseNotesContext/ReleaseNotesContext";
import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";
import { Button } from "@bokio/designsystem/components/Button";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Badge } from "@bokio/elements/Badge/Badge";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingContent } from "@bokio/elements/Loading";
import { Tooltip } from "@bokio/elements/Tooltip";
import { AccountingLangFactory, GeneralLangFactory } from "@bokio/lang";
import BackOfficeLangFactory from "@bokio/lang/BackOfficeLangFactory";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { getCompanyTypeLabel } from "@bokio/shared/utils/companyInfoUtils";
import { getCurrentCountryIso } from "@bokio/shared/utils/culture";
import { formatMessage } from "@bokio/shared/utils/format";
import * as searchUtils from "@bokio/shared/utils/searchUtils";
import { parseSearchParams } from "@bokio/utils/url";

import { SearchField } from "../SearchField/SearchField";

import type { Memberships } from "@bokio/contexts/TopLevelUserContext/TopLevelUserContext";

import * as styles from "./companyList.scss";

import CompanyDraftSource = m.Entities.CompanyDraftSource;

export interface CompanyListViewProps {
	currentOrganisationId?: string;
	onChange?: () => void;
	isTopNav?: boolean;
	isLoading: boolean;
	memberships: Memberships | undefined;
}

const filterMemberships = (search: string, memberships: Memberships) => {
	const accountingLang = AccountingLangFactory();

	const filteredMemberships: Memberships = {
		companies: [],
		partners: [],
	};
	filteredMemberships.companies = memberships.companies.filter(c =>
		searchUtils.visibleInSearchResult(
			[c.CompanyName, getCompanyTypeLabel(c.CompanyType, accountingLang), c.OrgNumber],
			search,
		),
	);
	filteredMemberships.partners = memberships.partners.filter(c =>
		searchUtils.visibleInSearchResult([c.PartnerName, c.PartnerId], search),
	);
	return filteredMemberships;
};

type CompanyBadgeType = "no-badge" | "test" | "agency";

const renderWhatsNewBadge = (numberOfAgencyReleaseNotes: number) => {
	const backOfficeLang = BackOfficeLangFactory();

	return (
		<Tooltip showArrow contentGenerator={() => backOfficeLang.ReleaseNotes_Message_Company}>
			<Badge
				noMargin
				className={styles.badge}
				color="activeDark"
				name={numberOfAgencyReleaseNotes}
				icon="whats-new"
				iconPosition="left"
				testId="CompanyList_WhatsNew_Badge"
			></Badge>
		</Tooltip>
	);
};

const renderBadges = (type: CompanyBadgeType, numberOfAgencyReleaseNotes: number) => {
	const generalLang = GeneralLangFactory();
	const backOfficeLang = BackOfficeLangFactory();

	switch (type) {
		case "test":
			return <Badge noMargin className={styles.badge} color="draft" name={generalLang.TestCompany_Label} />;
		case "agency":
			return (
				<SG horizontal>
					{numberOfAgencyReleaseNotes > 0 && renderWhatsNewBadge(numberOfAgencyReleaseNotes)}
					<Badge noMargin className={styles.badge} color="base" name={backOfficeLang.Agency} />
				</SG>
			);
		case "no-badge":
			return null;
	}
};

export const CompanyListView: React.FC<CompanyListViewProps> = props => {
	const [searchText, setSearchText] = React.useState("");
	const router = useRouter();
	const { noOfUpdates: numberOfAgencyUpdates } = useReleaseNotesContext();

	const renderOrganisation = (id: string, title: string, subTitle: string, route: string, badge: CompanyBadgeType) => {
		const { onChange, currentOrganisationId, isTopNav } = props;
		const isActive = currentOrganisationId === id;
		const searchParams = parseSearchParams(router.location?.search ?? "");
		const deepLink = searchParams["deepLink"];

		return (
			<div className={styles.company} key={id}>
				<Link onClick={onChange} external={route + (deepLink || "")} style="none">
					<div className={isActive && isTopNav ? styles.itemActive : styles.itemClickable}>
						<SG gap="4">
							<SG gap="space-between" horizontal>
								<Paragraph style="company-list-heading">{title}</Paragraph>
								{renderBadges(badge, numberOfAgencyUpdates)}
							</SG>
							<Paragraph>{subTitle}</Paragraph>
						</SG>
					</div>
				</Link>
			</div>
		);
	};

	const renderCompany = (company: m.SettingsNoConflict.ViewModels.CompanyMembershipDto) => {
		const accountingLang = AccountingLangFactory();

		const companyTypeLabel = getCompanyTypeLabel(company.CompanyType, accountingLang);
		let label = companyTypeLabel;
		if (company.OrgNumber) {
			label = `${label} - ${company.OrgNumber}`;
		}

		return renderOrganisation(
			company.CompanyId,
			company.CompanyName,
			label,
			getRoute("companyIndex", { company: company.CompanyId }),
			company.CompanySystem === "TestCompany" ? "test" : "no-badge",
		);
	};

	const renderPartner = (partner: m.Settings.Controllers.PartnerMembershipDto) => {
		const country = getCurrentCountryIso().toLocaleUpperCase() as m.CountryCode;
		const isOldFlow = isAccountorOrBokioGroup(partner.PartnerId, country);

		return renderOrganisation(
			partner.PartnerId,
			partner.PartnerName,
			"",
			isOldFlow
				? getRoute("backOffice", { partner: partner.PartnerId })
				: getRoute("agencyIndex", { agencyId: partner.PartnerId }),
			"agency",
		);
	};

	const goToCreateCompany = () => {
		const { currentOrganisationId, memberships } = props;

		const isCurrentOrganisationIdAgency =
			memberships && memberships.partners.filter(m => m.PartnerId === currentOrganisationId).length > 0;

		memberships && memberships.partners.length > 0
			? isCurrentOrganisationIdAgency
				? router.redirect(
						getRoute(
							"preCreateClient",
							{ step: "choose-type", source: "dropdown" },
							{ agencyId: currentOrganisationId },
						),
					)
				: router.redirect(
						getRoute("preCreateClient", { step: "choose-type", source: "dropdown" }, { agencyId: undefined }),
					)
			: router.redirect(
					getRoute("createCompany", { id: undefined, step: undefined }, { source: CompanyDraftSource.ExistingUser }),
				);
	};

	const renderSearchBar = (filteredCompanies: Memberships) => {
		const lang = GeneralLangFactory();
		return (
			<React.Fragment>
				<SearchField
					placeholder={lang.CompanyList_SearchField_Placeholder}
					value={searchText || ""}
					onChange={setSearchText}
					allowClear={true}
					onClearSearch={() => setSearchText("")}
				/>
				<p>
					{filteredCompanies.companies.length + filteredCompanies.partners.length === 0 &&
						formatMessage(lang.CompanyList_SearchField_NoResults, searchText)}
				</p>
			</React.Fragment>
		);
	};

	const renderCompanyDropDown = () => {
		const { memberships } = props;
		const lang = GeneralLangFactory();
		if (props.isLoading) {
			return (
				<div className={styles.isTopNav}>
					<LoadingContent className={styles.padding} />
				</div>
			);
		}
		if (!memberships) {
			return <div />;
		}

		const filteredCompanies: Memberships = filterMemberships(searchText, memberships);
		const moreThan8 = memberships.companies.length + memberships.partners.length >= 8;
		return (
			<div className={styles.isTopNav}>
				{moreThan8 && <div className={styles.searchField}>{renderSearchBar(filteredCompanies)}</div>}
				{filteredCompanies.partners.map(partner => renderPartner(partner))}
				{filteredCompanies.companies.map(company => renderCompany(company))}
				<div data-testid="CompanyList_CreateCompany" className={styles.manageCompanies}>
					<Button appearance="secondary" onClick={goToCreateCompany} stretch={true} icon={"plus"}>
						{lang.CreateCompany}
					</Button>
				</div>
				<Link // GF 2023: This link now allows test companies to create test companies.
					className={styles.createTestCompanyLink}
					onClick={() =>
						router.redirect(
							getRoute(
								"createCompany",
								{ id: undefined, step: undefined },
								{ source: CompanyDraftSource.CompanyList_CreateTestCompany },
							),
						)
					}
					style={"normal"}
				>
					{lang.CompanyList_CreateATestCompany}
				</Link>
			</div>
		);
	};

	const renderFullPage = () => {
		const { isLoading, memberships } = props;

		if (isLoading) {
			return <LoadingContent />;
		}

		if (!memberships) {
			return <div />;
		}
		const filteredCompanies: Memberships = filterMemberships(searchText, memberships);
		const moreThan8 = memberships.companies.length + memberships.partners.length >= 8;
		return (
			<div>
				{moreThan8 && <div className={styles.searchFieldFull}>{renderSearchBar(filteredCompanies)}</div>}
				<div className={styles.partners}>{filteredCompanies.partners.map(partner => renderPartner(partner))}</div>
				{filteredCompanies.companies.map(company => renderCompany(company))}
			</div>
		);
	};

	return props.isTopNav ? renderCompanyDropDown() : renderFullPage();
};

export interface CompanyListProps {
	currentOrganisationId?: string;
	onChange?: () => void;
	isTopNav?: boolean;
}

const CompanyList: React.FC<CompanyListProps> = props => {
	const { isLoadingMemberships, memberships } = useTopLevelUser();

	return <CompanyListView {...props} isLoading={isLoadingMemberships} memberships={memberships} />;
};

export default CompanyList;
