import { useAgencyCount } from "@bokio/shared/state/requests";

import { CounterBadge } from "../CounterBadge/CounterBadge";

export enum AgencyCounterType {
	ClientRequests,
	QuoteRequests,
}

interface AgencyMenuCounterProps {
	type: AgencyCounterType;
	testId?: string;
}

const AgencyMenuCounter = (props: AgencyMenuCounterProps) => {
	const { agencyCount } = useAgencyCount();

	const count = (): number => {
		if (!agencyCount) {
			return 0;
		}

		switch (props.type) {
			case AgencyCounterType.ClientRequests:
				return agencyCount.ClientRequestCount;
			case AgencyCounterType.QuoteRequests:
				return agencyCount.QuoteRequestCount;
			default:
				return agencyCount.TotalCount;
		}
	};

	const countValue = count();

	if (countValue === 0) {
		return null;
	}

	return (
		<CounterBadge size="small" updateCounter={countValue.toString()}>
			{countValue}
		</CounterBadge>
	);
};

export default AgencyMenuCounter;
