// extracted by mini-css-extract-plugin
export var flyoutAside = "o5";
export var flyoutBackground = "p5";
export var header = "_5";
export var closeButtonContainer = "a6";
export var headerText = "s6";
export var unreadText = "q7";
export var flexRight = "x7";
export var scrollable = "A8";
export var sectionHeader = "B8";
export var notificationItem = "D8";
export var notificationItemRead = "F8";
export var notificationItemTitle = "G8";
export var notificationItemCaption = "H8";
export var emptyState = "I8";
export var paragraph = "J8";
export var subduedText = "K8";
export var regularText = "L8";
export var manage = "M8";