import { useDocumentTitle } from "@bokio/hooks/useDocumentTitle/useDocumentTitle";

interface PageTitleProps {
	title: string;
}

const PageTitle = ({ title }: PageTitleProps) => {
	useDocumentTitle(`${title} - Bokio`);

	return null;
};

export default PageTitle;
