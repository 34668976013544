import range from "lodash-es/range";

import { Day } from "@bokio/mobile-web-shared/core/model/model";

export interface FormatHandler {
	tryHandle: (input: string) => Day | undefined;
}

const textMonthBeforeRegex = /^([^\d]{3,})(\d{1,2})$/;
export class TextMonthBeforeFormatHandler implements FormatHandler {
	tryHandle = (input: string) => {
		const res = textMonthBeforeRegex.exec(input);
		if (res === null) {
			return undefined;
		}

		const monthCandidate = res[1].trim().toLowerCase();
		const dayCandidate = Number(res[2]);

		const format = new Intl.DateTimeFormat(undefined, { month: "long" });
		const months = range(0, 12).map(m => ({ m, text: format.format(new Date(2018, m, 1)).toLowerCase() }));

		const possibleMatches = months.filter(x => x.text.startsWith(monthCandidate));

		if (possibleMatches.length !== 1) {
			return undefined;
		}

		return new Day(Day.today().getFullYear(), possibleMatches[0].m, dayCandidate);
	};
}

const textMonthAfterRegex = /^(\d{1,2})([^\d]{3,})$/;
export class TextMonthAfterFormatHandler implements FormatHandler {
	tryHandle = (input: string) => {
		const res = textMonthAfterRegex.exec(input);
		if (res === null) {
			return undefined;
		}

		const dayCandidate = Number(res[1]);
		const monthCandidate = res[2].trim().toLowerCase();

		const format = new Intl.DateTimeFormat(undefined, { month: "long" });
		const months = range(0, 12).map(m => ({ m, text: format.format(new Date(2018, m, 1)).toLowerCase() }));

		const possibleMatches = months.filter(x => x.text.startsWith(monthCandidate));

		if (possibleMatches.length !== 1) {
			return undefined;
		}

		return new Day(Day.today().getFullYear(), possibleMatches[0].m, dayCandidate);
	};
}

const isoDateWithoutDash = /^(\d{4})(\d{2})(\d{2})$/;
export class IsoDateWithoutDashFormatHandler implements FormatHandler {
	tryHandle = (input: string) => {
		const res = isoDateWithoutDash.exec(input);
		if (res === null) {
			return undefined;
		}

		const yearCandidate = Number(res[1]);
		const monthCandidate = Number(res[2]);
		const dayCandidate = Number(res[3]);

		return new Day(yearCandidate, monthCandidate - 1, dayCandidate);
	};
}

export class TodayFormatHandler implements FormatHandler {
	tryHandle = (input: string) => {
		const valids = ["idag", "today"];
		if (valids.indexOf(input.toLowerCase()) !== -1) {
			return Day.today();
		}
		return undefined;
	};
}

export class YesterdayFormatHandler implements FormatHandler {
	tryHandle = (input: string) => {
		const valids = ["igår", "yesterday"];
		if (valids.indexOf(input.toLowerCase()) !== -1) {
			return Day.yesterday();
		}
		return undefined;
	};
}
export class TomorrowFormatHandler implements FormatHandler {
	tryHandle = (input: string) => {
		const valids = ["imorgon", "tomorrow"];
		if (valids.indexOf(input.toLowerCase()) !== -1) {
			return Day.tomorrow();
		}
		return undefined;
	};
}

export function getFormatHandlers(): FormatHandler[] {
	return [
		new TextMonthBeforeFormatHandler(),
		new TextMonthAfterFormatHandler(),
		new IsoDateWithoutDashFormatHandler(),
		new YesterdayFormatHandler(),
		new TodayFormatHandler(),
		new TomorrowFormatHandler(),
	];
}
