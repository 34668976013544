import * as tracking from "@bokio/utils/t";

class Later {
	private readonly callback: () => void;
	private timerId?: NodeJS.Timeout;
	constructor(callback: () => void) {
		this.callback = callback;
	}

	start(interval: number) {
		this.cancel();
		this.timerId = setTimeout(this.callback, interval);
	}

	cancel() {
		if (this.timerId) {
			clearTimeout(this.timerId);
			this.timerId = undefined;
		}
	}
}

class HelpSearchAnalytics {
	public searchString = "";
	public pageUrl = "";

	private lastTrackedSearchString = "";
	private searchLater: Later;

	constructor() {
		this.searchLater = new Later(() => this.articleNotSelectedAfterSearch());
	}

	trackSearch(searchString: string) {
		this.searchLater.cancel();
		this.pageUrl = "";
		this.articleNotSelectedAfterSearch();
		this.searchString = searchString;
		this.searchLater.start(10000);
	}

	cancelSearchTracking() {
		this.searchLater.cancel();
	}

	resetSearch() {
		this.pageUrl = "";
		this.searchString = "";
		this.lastTrackedSearchString = "";
	}

	trackHelpArticle(pageUrl: string) {
		this.pageUrl = pageUrl;
		tracking.trackEvent("Help", this.searchString, pageUrl);
		this.lastTrackedSearchString = this.searchString;
	}

	trackMessage() {
		tracking.trackEvent("HelpMessage", this.searchString, this.pageUrl);
		this.lastTrackedSearchString = this.searchString;
	}

	trackContactSupport(contactSupport: string) {
		tracking.trackEvent("Help", contactSupport, this.pageUrl);
	}

	trackSubscriptionSaved() {
		tracking.trackPageView("/settings-r/add-ons?saved=true");
	}

	private articleNotSelectedAfterSearch() {
		if (this.searchString && this.lastTrackedSearchString !== this.searchString) {
			tracking.trackEvent("Help", this.searchString, "");
			this.lastTrackedSearchString = this.searchString;
		}
	}
}

export const helpSearchAnalytics: HelpSearchAnalytics = new HelpSearchAnalytics();
