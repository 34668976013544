import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./dropdownIcon.scss";

interface DropdownIconProps {
	active?: boolean;
}

const DropdownIcon: React.FC<DropdownIconProps> = ({ active }) => (
	<div className={mergeClassNames(styles.wrapper, active && styles.rotated)}>
		<Icon name="down-open-big" size="18" />
	</div>
);

export default DropdownIcon;
