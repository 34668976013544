import { Modal, ModalContent } from "@bokio/components/Modal";

import { AnimatedFeedbackGraphicModalContent } from "./AnimatedFeedbackGraphicModalContent";

import type * as React from "react";

import * as styles from "./animatedFeedbackGraphicModal.scss";

interface AnimatedFeedbackGraphicModalProps {
	type: "success" | "fail";
	title: string;
	onClose: () => void;
	visible: boolean;
	heading: string;
	description: React.ReactNode;
}

export const AnimatedFeedbackGraphicModal: React.FC<AnimatedFeedbackGraphicModalProps> = ({
	type,
	heading,
	title,
	description,
	onClose,
	visible,
}) => {
	return (
		<Modal alwaysMounted={true} title={title} onClose={onClose} visible={visible}>
			<ModalContent>
				<AnimatedFeedbackGraphicModalContent
					type={type}
					description={description}
					heading={heading}
					className={styles.content}
				/>
			</ModalContent>
		</Modal>
	);
};
