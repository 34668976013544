import * as React from "react";

import { noop } from "@bokio/shared/utils";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export type TutorialContextValue = {
	startTutorial: (
		key: string,
	) => Promise<m.Bokio.Accounting.Contract.BoookkeepingTutorial.Dtos.ActiveTutorialDto | undefined>;
	currentTutorial: m.Bokio.Accounting.Contract.BoookkeepingTutorial.Dtos.ActiveTutorialDto | undefined;
	isLoadingTutorial: boolean;
};

export const tutorialContext = React.createContext<TutorialContextValue>({
	currentTutorial: undefined,
	startTutorial: noop,
	isLoadingTutorial: false,
});

export const useTutorialContext = () => {
	const context = React.useContext(tutorialContext);
	return context;
};
