import orderBy from "lodash-es/orderBy";
import * as React from "react";

import { MenuLoyaltyProgram } from "@bokio/backoffice/src/components/MenuLoyaltyProgram/MenuLoyaltyProgram";
import { AgencyMenuButton } from "@bokio/backoffice/src/scenes/AgencyMenuButton/AgencyMenuButton";
import { BookkeepingMenuButton } from "@bokio/bookkeeping/src/scenes/components/BookkeepingMenuButton/BookkeepingMenuButton";
import BokioLogo from "@bokio/components/BokioLogo/BokioLogo";
import { MenuLink } from "@bokio/components/Menu/components/MenuLink/MenuLink";
import TodoMenuCounter from "@bokio/components/TodoMenuCounter/TodoMenuCounter";
import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";
import { Badge } from "@bokio/elements/Badge/Badge";
import Icon from "@bokio/elements/Icon/Icon";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { useActiveBankConnection } from "@bokio/mobile-web-shared/hooks/useActiveBankConnection/useActiveBankConnection";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { isActiveRoute } from "@bokio/utils/routeUtils";

import AgencyMenuCounter from "../Route/AgencyMenuCounter";
import { MenuCurrentCompanyMobile } from "./components/MenuCurrentCompany/MenuCurrentCompanyMobile";
import { MenuCurrentPlan } from "./components/MenuCurrentPlan/MenuCurrentPlan";
import MenuCurrentUserMobile from "./components/MenuCurrentUser/MenuCurrentUserMobile";
import MenuSection from "./components/MenuSection/MenuSection";

import type { AgencyCounterType } from "../Route/AgencyMenuCounter";
import type { ToDoCounterType } from "./components/MenuCounter/MenuCounter";
import type { MenuSection as MenuBuilderSection } from "./utils/MenuHelper";
import type { BadgeColor } from "@bokio/elements/Badge/Badge";

import * as styles from "./menu.scss";

export type MenuHeaderProps = {
	title: string;
	status?: { name: string; color: BadgeColor };
	children?: React.ReactElement;
};

interface MenuProps {
	showUserSettingsMobile: () => void;
	showCompanySelector: () => void;
	closeMenu: () => void;
	sections: MenuBuilderSection[];
	showBookkeepingButton: boolean;
	header?: MenuHeaderProps;
	isAgency?: boolean;
}

interface MenuState {
	expandedSections: string[];
}

export const Menu = ({
	sections,
	closeMenu,
	showBookkeepingButton,
	showCompanySelector,
	showUserSettingsMobile,
	header,
	isAgency,
}: MenuProps) => {
	const { currentCompanyId } = useTopLevelUser();
	const { redirect, location } = useRouter();

	const [state, setMenuState] = React.useState<MenuState>({ expandedSections: [] });
	const { companyInfo } = useCompanyInfo();

	const { hasActiveBusinessAccount } = useActiveBankConnection();

	const onItemClick = () => {
		closeMenu();
		setMenuState(prevState => ({
			...prevState,
			expandedSections: [],
		}));
	};

	const openPreBookkeepingPage = (receiptId: string, companyId: string) => {
		redirect(getRoute("preBookkeep", { company: companyId, receiptId }));
		onItemClick();
	};

	const toggleSection = (key: string) => {
		setMenuState(prevState => {
			const removeIndex = prevState.expandedSections.indexOf(key);
			return {
				...prevState,
				expandedSections:
					removeIndex !== -1
						? prevState.expandedSections.slice(0, removeIndex).concat(prevState.expandedSections.slice(removeIndex + 1))
						: [...prevState.expandedSections, key],
			};
		});
	};

	const getCounter = (counter: AgencyCounterType | ToDoCounterType, companyId?: string) => {
		if (companyId) {
			return (
				<span className={styles.menuCounter}>
					<TodoMenuCounter type={counter as ToDoCounterType} companyId={companyId} />
				</span>
			);
		} else {
			// If the companyid is undefined then we are in the backoffice menu
			return (
				<span className={styles.menuCounter}>
					<AgencyMenuCounter type={counter as AgencyCounterType} />
				</span>
			);
		}
	};
	const hideCurrentPlan = companyInfo === undefined;

	return (
		<React.Fragment>
			<div className={styles.menuLogo}>
				<BokioLogo className={styles.logo} />
				<a className={styles.closeIcon} onClick={onItemClick}>
					<Icon name="cancel" />
				</a>
			</div>
			{showBookkeepingButton && !isAgency && (
				<BookkeepingMenuButton
					onSubmit={openPreBookkeepingPage}
					onItemClick={onItemClick}
					onPrimaryButtonClick={onItemClick}
				/>
			)}
			{isAgency && <AgencyMenuButton />}
			<div className={styles.menuItemsWrapper}>
				{header && (
					<div className={styles.header}>
						<div className={styles.headerRow}>
							<p className={styles.headerTitle}>{header.title}</p>
							{header.status && <Badge {...header.status} />}
						</div>
						{header.children && <div className={styles.headerRow}>{header.children}</div>}
					</div>
				)}
				{sections.map((s: MenuBuilderSection, i: number) => {
					const active = orderBy(
						[...s.subs.map(s => s.route), s.route || (s.subs.length ? s.subs[0].route : "")].filter(route =>
							isActiveRoute(false, location.pathname, route),
						),
						route => route.length,
						"desc",
					)[0];

					const route = s.route || (s.subs.length ? s.subs[0].route : "");
					const isCurrentRoute = isActiveRoute(true, location.pathname, route);
					const isParentActive = !!active || isCurrentRoute;

					return !s.isDivider ? (
						<MenuSection
							key={route}
							currentRoute={location.pathname}
							isActive={isParentActive}
							isCurrentRoute={isCurrentRoute}
							icon={s.icon}
							title={s.title}
							route={route}
							badge={s.badge}
							counter={s.counter !== undefined ? getCounter(s.counter, currentCompanyId) : undefined}
							onClick={() => {
								s.onClick && s.onClick();
								onItemClick();
							}}
							hasSubs={s.subs.filter(s => !s.hidden).length > 0}
							toggleSection={toggleSection}
							expanded={state.expandedSections.includes(s.title)}
							testId={`MenuSection_${s.title}`}
						>
							{s.subs
								.filter(sub => !sub.hidden)
								.map(sub => {
									return (
										<MenuLink
											isActive={active === sub.route}
											key={sub.route}
											route={sub.route}
											currentRoute={location.pathname}
											badge={sub.badge}
											onClick={() => {
												sub.onClick && sub.onClick();
												onItemClick();
											}}
											testId={`MenuLink_${sub.title}`}
										>
											{sub.title}
											{sub.counter !== undefined && getCounter(sub.counter, currentCompanyId)}
										</MenuLink>
									);
								})}
						</MenuSection>
					) : (
						<hr className={styles.divider} key={`divider-${i}`} />
					);
				})}

				<div className={styles.bottomNavMobile}>
					<MenuCurrentCompanyMobile showManageCompanies={showCompanySelector} />
					<MenuCurrentUserMobile showManageUser={showUserSettingsMobile} />
				</div>
			</div>
			{currentCompanyId && !hideCurrentPlan && (
				<MenuCurrentPlan
					companyId={currentCompanyId}
					hasActiveBusinessAccount={hasActiveBusinessAccount}
					onLinkClick={onItemClick}
				/>
			)}

			{isAgency && <MenuLoyaltyProgram onLinkClick={onItemClick} />}
		</React.Fragment>
	);
};
